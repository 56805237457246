import moment, { Moment } from 'moment'
import { useTranslation } from 'react-i18next'
import { useFreezePlan } from 'src/query/planning/freezePlan'
import { IKeypoint } from 'src/service/OrgTypes'
import {
  getKeyPointErrorMessage,
  StructureValidationError,
  getErrorMessage,
  ValidationError,
} from 'src/service/ValidationErrors'
import useAlert from 'src/ui-elements/toast/useAlert'

export const useKeypointUpdateValidation = () => {
  const { data: freezePlan } = useFreezePlan('key_point')
  const { addAlert } = useAlert()
  const { t } = useTranslation()

  const showAlert = (text: string) => {
    addAlert({
      type: 'warning',
      title: t('something_went_wrong'),
      description: text,
    })
  }

  const isUpdatingDateInFreeze = (updatedDate: string, oldDate: string) => {
    const planFreezePeriod = freezePlan?.plan_freeze_period ?? 0
    const freezeUntil =
      planFreezePeriod > 0
        ? moment()
            .add(planFreezePeriod - 1, 'weeks')
            .endOf('week')
        : moment().startOf('day')
    const isMovingForward = moment(updatedDate).isAfter(oldDate, 'day')
    return isMovingForward && moment(oldDate).isBefore(freezeUntil, 'day')
  }

  const validateDate = (keypoint: IKeypoint, date?: Moment): string => {
    if (!date || !moment(date).isValid()) {
      return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
    }
    const backInTime = moment(date).isBefore(keypoint.endTime, 'day')
    if (backInTime) return ''
    if (
      keypoint.mile_stone &&
      moment(keypoint.mile_stone.deadline).isBefore(date, 'day')
    ) {
      showAlert(
        getKeyPointErrorMessage(
          StructureValidationError.PAST_PARENT_DEADLINE,
          t,
          moment(keypoint.mile_stone.deadline).format('L'),
        ),
      )
      return 'past_milestone_deadline'
    }
    if (
      keypoint.improvement &&
      moment(keypoint.improvement.deadline).isBefore(date, 'day')
    ) {
      showAlert(
        getKeyPointErrorMessage(
          StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
          t,
          moment(keypoint.improvement.deadline).format('L'),
        ),
      )
      return 'past_improvement_deadline'
    }
    return ''
  }

  const validateStatus = (data: { open_children?: number }, status: string) => {
    if (status !== 'done') return true
    if (data?.open_children && data.open_children > 0) {
      showAlert(
        getKeyPointErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return false
    }
    return true
  }

  return {
    isUpdatingDateInFreeze,
    validateDate,
    validateStatus,
  }
}
