import { useQuery } from '@tanstack/react-query'
import { getDocuments } from 'src/service/ImageService'
import useProjectId from '../hooks/useProjectId'

export const useGetDocuments = (parentId: number, parentType: string) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['documents', projectId, parentType, parentId],
    queryFn: () => getDocuments(projectId, parentType, parentId),
  })
}
