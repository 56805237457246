import { useMutation } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  createConstructionPlan,
  deleteConstructionPlanDraft,
} from 'src/service/constructionPlan'

export const useCreateConstructionPlan = () => {
  const projectId = useProjectId()

  const mutation = useMutation({
    mutationFn: () => createConstructionPlan(projectId),
  })

  return mutation
}

export const useDeleteConstructionPlanDraft = (reload?: () => void) => {
  const projectId = useProjectId()
  const mutation = useMutation({
    mutationFn: () => deleteConstructionPlanDraft(projectId),
    onSettled: reload,
  })

  return mutation
}
