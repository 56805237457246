import { ChangeEvent, useState, FC, useEffect, useRef } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { useExpandableTableStoreState } from 'src/systematic-completion/components/expandable-table/hooks/useExpandableTableStore'

interface IExpandableTextArea {
  finishEditing: (newValue: string) => void
  value: string
  placeholder?: string
  onClick?: () => void
  sectionId: number
  rowId?: number
  initialItem?: Record<string, unknown>
}

const ExpandableTextArea: FC<IExpandableTextArea> = ({
  finishEditing,
  value,
  placeholder,
  onClick,
  sectionId,
  rowId,
  initialItem,
}) => {
  const ref = useRef<HTMLTextAreaElement>(null)
  const [internalValue, setInternalValue] = useState(value ?? '')

  const handleChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value
    setInternalValue(val)
  }

  const [createRow, recentlyAddedRow, resetRecentlyAddedRow] =
    useExpandableTableStoreState((state) => [
      state.createRow,
      state.recentlyAddedRow,
      state.resetRecentlyAddedRow,
    ])

  useEffect(() => {
    if (!recentlyAddedRow) return
    if (
      recentlyAddedRow.sectionId === sectionId &&
      recentlyAddedRow.rowId === rowId
    ) {
      ref.current?.focus()
      resetRecentlyAddedRow()
    }
  }, [value])

  return (
    <div className={'w-full p-0.5 break-words'}>
      <TextareaAutosize
        ref={ref}
        onBlur={() => {
          if (internalValue === value) return
          finishEditing(internalValue)
        }}
        tabIndex={0}
        className="w-full bg-transparent whitespace-pre-line"
        onChange={handleChange}
        placeholder={placeholder}
        value={internalValue ?? ''}
        onKeyDownCapture={(e) => {
          if (e.key === 'Escape') {
            finishEditing(internalValue)
          }
          if (e.key === 'Enter' && e.shiftKey) {
            e.preventDefault()
            finishEditing(internalValue)
            createRow(sectionId, initialItem ?? {})
          }
          e.stopPropagation()
        }}
        onClick={onClick}
      />
    </div>
  )
}

export default ExpandableTextArea
