import { useQuery, keepPreviousData } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getMilestone,
  getProjectMilestonesPaginated,
} from 'src/service/MilestoneService'

export const useMilestone = (id: number) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['milestone', projectId, id],
    queryFn: () => getMilestone(projectId, id),
  })
}

export const useFilteredMilestonesWithPagination = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: Parameters<typeof getProjectMilestonesPaginated>[1]['sort']
  filters: Omit<Parameters<typeof getProjectMilestonesPaginated>[1], 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'milestonesFilteredPaginated',
      { projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getProjectMilestonesPaginated(projectId, filters, page, pageSize),
    placeholderData: keepPreviousData,
    enabled,
  })
}
