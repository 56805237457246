import { Switch, Route, useRouteMatch } from 'react-router-dom'
import ChecklistTable from 'src/components/checklist/ChecklistTable'
import ChecklistDetailPage from 'src/components/checklist/checklist-detail/ChecklistDetailPage'
import { ChecklistDomains } from 'src/components/checklist/utils'
import NotFound from 'src/page/dashboard/error/NotFound'

const ChecklistTreeRouter = ({ domain }: { domain: ChecklistDomains }) => {
  const { path: basePath } = useRouteMatch()

  return (
    <Switch>
      <Route
        exact
        path={basePath}
        render={(_routeProps) => <ChecklistTable key={1} domain={domain} />}
      />
      <Route
        exact
        path={`${basePath}/checklists/:domain`}
        render={(routeProps) => (
          <ChecklistTable
            key={1}
            domain={domain}
            domainFilter={routeProps.match.params.domain}
          />
        )}
      />
      <Route
        path={`${basePath}/checklists/:domain/:mId`}
        render={(routeProps) => (
          <ChecklistDetailPage checklistId={+routeProps.match.params.mId} />
        )}
      />
      <Route
        render={() => {
          return <NotFound />
        }}
      />
    </Switch>
  )
}

export default ChecklistTreeRouter
