import {
  IDocumentHistory,
  IFileContainer,
} from 'src/document/types/IFileContainer'
import { IFdvuFileContainer } from 'src/fdvu/types/IFdvuFileContainer'
import { ISubcontractor } from 'src/fdvu/types/ISubcontractor'
import {
  FdvuSubcontractorRequest,
  getSubcontractorOrgUrl,
} from 'src/service/service-utils'
import { GenericSubcontractorService } from 'src/subcontractor/service/GenericSubcontractorService'
import { IStatusLEnum, StatusEnum, StatusLEnum } from 'src/utility/statusEnums'
import { getAccessToken } from '../utils/AccessToken'

class SubcontractorService extends GenericSubcontractorService {
  readonly authenticate = () => {
    return this.doRequest<boolean>({
      url: this.getUrl('authenticate'),
      method: 'GET',
    })
  }

  readonly getCurrentSubcontractor = () => {
    return this.doRequest<ISubcontractor>({
      url: this.getUrl('current'),
      method: 'GET',
    })
  }

  readonly getSubContractorDocuments = async (hideInReview: boolean) => {
    const token = `Bearer ${getAccessToken()}`

    return FdvuSubcontractorRequest<IFdvuFileContainer[]>(
      getSubcontractorOrgUrl(`/file_containers?hide_in_review=${hideInReview}`),
      token,
      { method: 'GET' },
    )
  }

  readonly getFileContainer = (id: number) => {
    return this.doRequest<IFdvuFileContainer>({
      url: this.getUrl('file-container/file-container-id', id),
      method: 'GET',
    })
  }

  readonly downloadFile = (projectFileId: number) => {
    return this.doRequest({
      url: this.getUrl(`files/download/project-file-id/${projectFileId}`),
      method: 'GET',
      blob: true,
    })
  }

  readonly deleteFile = (documentId: number, projectFileId: number) => {
    const token = `Bearer ${getAccessToken()}`
    return FdvuSubcontractorRequest(
      getSubcontractorOrgUrl(
        `/file_containers/${documentId}/project_files/${projectFileId}`,
      ),
      token,
      { method: 'DELETE' },
    )
  }

  readonly updateDocumentStatus = (
    documentId: number,
    status: IStatusLEnum,
  ): Promise<IFileContainer> => {
    const token = `Bearer ${getAccessToken()}`
    const mappedStatus = status === StatusLEnum.DELIVERED ? 6 : 3
    return FdvuSubcontractorRequest(
      getSubcontractorOrgUrl(`/file_containers/${documentId}`),
      token,
      { method: 'POST', body: { status: mappedStatus } },
    )
  }

  readonly uploadFile = (
    documentId: number,
    status: StatusEnum.OPEN | StatusEnum.DELIVERED,
  ): Promise<IFileContainer> => {
    const token = `Bearer ${getAccessToken()}`
    const mappedStatus = status === StatusEnum.DELIVERED ? 6 : 3
    return FdvuSubcontractorRequest(
      getSubcontractorOrgUrl(`/file_containers/${documentId}`),
      token,
      { method: 'POST', body: { status: mappedStatus } },
    )
  }

  readonly getDocumentHistorySubcontractor = (
    documentId: number,
  ): Promise<IDocumentHistory[]> => {
    const token = `Bearer ${getAccessToken()}`

    return FdvuSubcontractorRequest(
      getSubcontractorOrgUrl(`/file_containers/${documentId}/history`),
      token,
      { method: 'GET' },
    ).then((res) => res.history)
  }
}
export default new SubcontractorService()
