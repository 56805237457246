import { request } from './service-utils'

export const createConstructionPlan = (project_id: number): Promise<any> => {
  return request(`projects/${project_id}/construction_plans/`, 'POST')
}

export const deleteConstructionPlanDraft = (
  project_id: number,
): Promise<unknown> => {
  return request(`projects/${project_id}/construction_plans/`, 'DELETE')
}
