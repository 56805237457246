import { useQuery, keepPreviousData } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getSystemsWithPage,
  getPaginatedSystemImportHistories,
} from 'src/service/SystemService'

export const useSystemImportHistory = (
  systemSettingId: number,
  {
    sort,
    filters,
    page,
    pageSize,
    enabled = true,
  }: {
    sort: Parameters<typeof getSystemsWithPage>[3]['sort']
    filters: Omit<Parameters<typeof getSystemsWithPage>[3], 'sort'>
    page: number
    pageSize: number
    enabled?: boolean
  },
) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'systemImportHistory',
      { projectId, systemSettingId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getPaginatedSystemImportHistories(
        projectId,
        systemSettingId,
        page,
        pageSize,
        {
          ...filters,
          sort,
        },
      ),
    placeholderData: keepPreviousData,
    enabled,
  })
}
