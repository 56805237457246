import KeyboardArrowDown from '@icons/keyboard_arrow_down.svg'
import moment from 'moment'
import { createRef, Fragment, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { styleClass } from 'src/ui-elements/Table/Columns'
import Badge from 'src/ui-elements/badge/Badge'
import { BadgeColor } from 'src/ui-elements/badge/BadgeEnums'
import { closedStatusTypes } from '../../service/SystemValues'
import { capFirstLetter } from '../../utility/utils'
import { Direction } from '../multi-selector/MultiSelector'
import TableStatusDropdown, { IStatusTypes } from './TableStatusDropdown'

export interface IStatusCell {
  id?: number
  status: string
  rtb_status?: string
  endTime?: string
  deadline?: string
  execution_date?: string
  expired_children?: number
  open_children?: number
  done_children?: number
  behind_schedule_children?: number
}
interface ITableStatusLabelProps {
  cell: IStatusCell
  rowId: number
  onStatusSelect?: (
    statusTypes: IStatusTypes,
    key: number,
    cell: IStatusCell,
  ) => void
  statusTypes: IStatusTypes[]
  showDropdownAlways?: boolean
  disableEdit?: boolean
  selectColor?: (cell: IStatusCell) => BadgeColor
}

const TableStatusLabel = ({
  cell,
  rowId,
  onStatusSelect,
  statusTypes,
  showDropdownAlways = false,
  disableEdit,
  selectColor = (cell: IStatusCell) => {
    const isClosedStatus = closedStatusTypes.some((x) => x === cell.status)
    const isAfterDeadline = moment(
      cell.endTime || cell.deadline || cell.execution_date,
    ).isBefore(moment(), 'day')
    const delayed =
      (cell.expired_children && cell.expired_children > 0) ||
      (cell.behind_schedule_children && cell.behind_schedule_children > 0)

    if (isClosedStatus) return Badge.BadgeColor.GREEN
    else if (isAfterDeadline) return Badge.BadgeColor.RED
    else if (delayed) return Badge.BadgeColor.YELLOW
    else return Badge.BadgeColor.BLUE
  },
}: ITableStatusLabelProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const ref = useRef(createRef<HTMLDivElement>())
  const hasOpenChildren = cell?.open_children && cell?.open_children > 0
  const { t } = useTranslation()
  const [openDirection, setOpenDirection] = useState<Direction>(Direction.DOWN)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const onClickRow = (e: any) => {
    e.stopPropagation()
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleClickOutside = (event: any) => {
    if (
      ref &&
      ref.current &&
      ref.current.current &&
      !ref.current.current.contains(event.target)
    ) {
      setOpen(false)
    }
  }

  const onStatusSelectRow = (status: IStatusTypes) => {
    setOpen(false)
    onStatusSelect?.(status, rowId, cell)
  }

  const handleClick = (e: any) => {
    const target = e.target.getBoundingClientRect()
    const closeToBottom = window.innerHeight - target.top < 180
    setOpenDirection(closeToBottom ? Direction.UP : Direction.DOWN)
    if (!disableEdit) {
      if (!hasOpenChildren) {
        onClickRow(e)
      } else {
        if (showDropdownAlways) {
          onClickRow(e)
        }
      }
    }
  }

  return (
    <Fragment>
      {open ? (
        <Fragment>
          <div
            ref={ref.current}
            className={'w-full'}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
          />
          <div className={'-ml-2 w-[calc(100%_+_10px)]'} ref={ref.current}>
            <TableStatusDropdown
              selectedStatus={cell?.status}
              onSelectClick={onStatusSelectRow}
              statusTypes={statusTypes}
              onClose={onClose}
              openDirection={openDirection}
            />
          </div>
        </Fragment>
      ) : cell && cell?.status !== '' ? (
        <div
          className={twMerge(
            'h-full w-full cursor-pointer flex justify-between items-center',
            disableEdit ? styleClass.cell : styleClass.editableCell,
          )}
          onClick={(e) => {
            e.stopPropagation()
            onStatusSelect && handleClick(e)
          }}
        >
          <Badge
            size={Badge.BadgeSize.LARGE}
            text={capFirstLetter(t(cell.status))}
            color={selectColor(cell)}
          />
          {!disableEdit && (
            <KeyboardArrowDown className={'fill-gray-800 text-lg'} />
          )}
        </div>
      ) : null}
    </Fragment>
  )
}
export default TableStatusLabel
