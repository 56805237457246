import { isEmpty } from 'lodash'
import { create } from 'zustand'
import { ISystem } from 'src/service/OrgTypes'
import { ICategory, ISelectedCell, ICategoryGroup } from './types'
import { ISystemConfig } from './types/ISystem'
import { last } from './utils/GridArrayUtil'

type GridState = {
  systems: ISystem[]
  selectedCell?: ISelectedCell
  categories: ICategory[]
  multipleSelectedCells: Record<number, string[]>
  disableMultiselect: boolean
  intialSystemsConfig: Record<number, ISystemConfig>
  systemsConfig: Record<number, ISystemConfig>
  sortedSystemIds: number[]
  isFdvu: boolean
  categoryGroupMeta: Record<string, ICategoryGroup[]>
  showPanel: boolean
  rowRecordIdWidth: number
  rowTitleWidth: number
  rowSystemStatusWidth: number
}

type GridActions = {
  setIsFdvu: (value: boolean) => void
  setShowPanel: (showPanel: boolean) => void
  setDisableMultiselect: (value: boolean) => void
  setSystems: (systems: ISystem[]) => void
  addSystems: (system: ISystem[]) => void
  addToIntialSystemConfig: (
    systemsConfig: Record<number, ISystemConfig>,
  ) => void
  setSystemsConfig: (systemsConfig: Record<number, ISystemConfig>) => void
  addToSystemsConfig: (systemsConfig: Record<number, ISystemConfig>) => void
  removeFromSystemsConfig(systemIds: number[]): void
  setSortedSystemIds: (sortedSystemIds: number[]) => void
  indexOfSystemId: (id: number) => number
  updateConfigForSystem: (id: number, newConfig: Partial<ISystemConfig>) => void
  updateConfigForMultipleSystems: (
    newConfig: Partial<ISystemConfig> & { id: number }[],
  ) => void
  setCategories: (categories: ICategory[]) => void
  setCategoryGroupMeta: (groups: Record<string, ICategoryGroup[]>) => void
  getCategoryFromId: (categoryId: string) => ICategory | undefined
  setSelectedCell: (selectedCell?: ISelectedCell) => void
  setMultipleSelectedCells: (
    multipleSelectedCells: Record<number, string[]>,
  ) => void
  addMultipleSelectedCell: (systemId: number, categoryId?: string) => void
  removeMultipleSelectedCell: (
    systemId: number,
    categoryId?: string,
  ) => ISelectedCell | undefined
  setMultipleSelectedCellsGroup: (
    systemIds: number[],
    categoryIds: string[],
  ) => void
  resetMultipleSelectedCells: () => void
  addCategory: (category: ICategory) => void
  removeCategory: (categoryId: string) => void
  updateCategory: (category: ICategory) => void
  reset: (options?: { preserve?: string[] }) => void
}

const initialState: GridState = {
  systems: [],
  systemsConfig: {},
  intialSystemsConfig: {},
  sortedSystemIds: [],
  categories: [],
  categoryGroupMeta: {},
  rowRecordIdWidth: 176,
  rowTitleWidth: 242,
  rowSystemStatusWidth: 32,
  disableMultiselect: false,
  isFdvu: true,
  showPanel: false,

  multipleSelectedCells: {},
  selectedCell: undefined,
}

export const useGridStore = create<GridState & GridActions>()((set, get) => ({
  ...initialState,
  setIsFdvu: (value: boolean) => set({ isFdvu: value }),
  setShowPanel: (showPanel: boolean) => set({ showPanel }),
  setDisableMultiselect: (value: boolean) => set({ disableMultiselect: value }),
  setSystems: (systems) => set({ systems }),
  addSystems: (systems) => {
    set((state) => ({
      systems: [
        ...state.systems,
        ...systems.filter(
          (system) =>
            !state.systems.find(
              (existingSystem) => existingSystem.id === system.id,
            ),
        ),
      ],
    }))
  },
  setSystemsConfig: (systemsConfig) => set({ systemsConfig }),
  addToIntialSystemConfig: (intialSystemsConfig) =>
    set({
      intialSystemsConfig: {
        ...get().intialSystemsConfig,
        ...intialSystemsConfig,
      },
    }),
  addToSystemsConfig: (systemsConfig) =>
    set((state) => ({
      systemsConfig: { ...state.systemsConfig, ...systemsConfig },
    })),
  removeFromSystemsConfig: (systemIds) => {
    const systemsConfig = get().systemsConfig
    const tmpConfig = {}
    systemIds.forEach((id) => {
      delete systemsConfig[id]
    })
    set({ systemsConfig: { ...systemsConfig, ...tmpConfig } })
  },
  setSortedSystemIds: (sortedSystemIds) => set({ sortedSystemIds }),
  indexOfSystemId: (id) => get().sortedSystemIds.indexOf(id),
  getCategoryFromId: (categoryId) =>
    get().categories.find((category) => category.id === categoryId),
  updateConfigForSystem: (id, newConfig) => {
    const systemConfig = get().systemsConfig
    const configForSystem = systemConfig[id]
    const tmpConfig = { ...configForSystem, ...newConfig }
    set((state) => ({
      systemsConfig: { ...state.systemsConfig, [id]: tmpConfig },
    }))
  },
  updateConfigForMultipleSystems: (configList) => {
    const systemConfig = get().systemsConfig
    const tmpConfig = {}
    configList.forEach((config) => {
      const configForSystem = systemConfig[config.id]
      tmpConfig[config.id] = { ...configForSystem, ...config }
    })
    set((state) => ({
      systemsConfig: { ...state.systemsConfig, ...tmpConfig },
    }))
  },
  setCategories: (categories) => {
    set({
      categories,
    })
  },

  setCategoryGroupMeta: (categoryGroupMeta) => {
    set({
      categoryGroupMeta,
    })
  },

  setSelectedCell: (selectedCell) => {
    set({ selectedCell })
  },
  setMultipleSelectedCells: (multipleSelectedCells) => {
    set({ multipleSelectedCells })
  },
  addMultipleSelectedCell: async (systemId, categoryId) => {
    const tmpMultipleSelectedCells = { ...get().multipleSelectedCells }
    const categories = get().categories

    if (categoryId) {
      tmpMultipleSelectedCells[systemId] = tmpMultipleSelectedCells[systemId] =
        [...(tmpMultipleSelectedCells[systemId] || []), categoryId]
    } else {
      tmpMultipleSelectedCells[systemId] = categories.map(
        (category) => category.id,
      )
    }
    set({ multipleSelectedCells: tmpMultipleSelectedCells })
  },
  removeMultipleSelectedCell: (systemId, categoryId) => {
    const tmpMultipleCells = { ...get().multipleSelectedCells }
    if (tmpMultipleCells[systemId].length === 1 || !categoryId) {
      delete tmpMultipleCells[systemId]
    } else {
      tmpMultipleCells[systemId] = tmpMultipleCells[systemId].filter(
        (multipleCellCategoryId) => multipleCellCategoryId !== categoryId,
      )
    }
    set({ multipleSelectedCells: tmpMultipleCells })
    if (!isEmpty(tmpMultipleCells) && get().selectedCell) {
      if (
        Object.values(tmpMultipleCells).every(
          (val, i, arr) => val.length === arr[0].length,
        )
      ) {
        const ids = Object.keys(tmpMultipleCells).map(Number)
        return {
          systemIds: ids,
          categoryId: tmpMultipleCells[ids[0]][0],
        }
      }
      const systemIds = Object.keys(tmpMultipleCells).map(Number)
      const categoryId = last(tmpMultipleCells[systemId])
      return {
        systemIds,
        categoryId,
      }
    }
    return
  },
  setMultipleSelectedCellsGroup: async (systemIds, categoryIds) => {
    const tmpMultipleSelectedCells: Record<number, string[]> = {}
    systemIds.forEach((systemId) => {
      tmpMultipleSelectedCells[systemId] = categoryIds
    })
    set({ multipleSelectedCells: tmpMultipleSelectedCells })
  },
  resetMultipleSelectedCells: () => {
    if (!isEmpty(get().multipleSelectedCells)) {
      set({ multipleSelectedCells: {} })
    }
  },
  addCategory: (newCategory) => {
    set((state) => ({
      categories: [...state.categories, newCategory],
    }))
  },
  removeCategory: (categoryId) => {
    set((state) => ({
      categories: state.categories.filter(
        (category) => category.id !== categoryId,
      ),
    }))
  },
  updateCategory: (updatedCategory) => {
    set((state) => ({
      categories: state.categories.map((category) => {
        if (category.id === updatedCategory.id) {
          return updatedCategory
        } else return category
      }),
    }))
  },
  reset: (options = { preserve: [] }) =>
    set((state) => ({
      ...initialState,
      ...Object.fromEntries(
        options.preserve?.map((prop: string) => [prop, state[prop]]) || [],
      ),
    })),
}))
