import { createColumnHelper } from '@tanstack/react-table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { queryClient } from 'src/query/client'
import { deleteDocument } from 'src/service/DocumentService'
import { IDocument } from 'src/service/OrgTypes'
import {
  defaultDateColumnObject,
  defaultUserColumn,
  documentColumn,
} from 'src/ui-elements/Table/Columns'
import TableBody from 'src/ui-elements/Table/TableBody'
import useTable from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Modal from 'src/ui-elements/modal/Modal'
import { getOrganizationBaseUrl } from 'src/utility/utils'
import UploadFile from './UploadFile'
import { useGetDocuments } from './query'

interface IDocumentsProps {
  parentType: string
  parentId: number
  projectId: number
  className?: string
  disableEdit?: boolean
}

const DocumentsList = ({
  parentType,
  parentId,
  projectId,
  className,
  disableEdit,
}: IDocumentsProps) => {
  const { t } = useTranslation()
  const [showUploadModal, setUploadModal] = useState(false)

  const { data: documents = [] } = useGetDocuments(parentId, parentType)
  const reload = () =>
    queryClient.invalidateQueries({
      queryKey: ['documents', projectId, parentType, parentId],
    })

  const toggleUploadModal = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    setUploadModal(true)
  }

  const filesUploaded = (_docs: IDocument[]) => {
    setUploadModal(false)
    reload()
  }

  const columnHelper = createColumnHelper<IDocument>()

  const { table } = useTable({
    name: 'DocumentsTable',
    data: documents ?? [],
    legacyColumns: [],
    columns: [
      columnHelper.accessor(
        'fileName',
        documentColumn('fileName', { name: t('name') }),
      ),
      columnHelper.accessor(
        'user',
        defaultUserColumn('created_by', { name: t('created_by') }),
      ),
      columnHelper.accessor(
        'created_at',
        defaultDateColumnObject('created_at', { name: t('created_at') }),
      ),
      columnHelper.accessor(
        'updated_at',
        defaultDateColumnObject('updated_at', { name: t('updated_at') }),
      ),
    ],
    selectable: false,
    enableFilters: false,
    enableSorting: false,
    onDeleteClick: disableEdit
      ? undefined
      : (row) => {
          onDeleteFile(row.id)
        },
  })

  const onClose = () => {
    setUploadModal(false)
    reload()
  }

  const uploadUrl = (): string => {
    return `${getOrganizationBaseUrl()}/projects/${projectId}/parents/${parentType}/${parentId}/documents`
  }

  const onDeleteFile = async (id: number) => {
    await deleteDocument(projectId, id)
    reload()
  }
  return (
    <div className={className}>
      <>
        {!disableEdit && (
          <Button type={ButtonType.PRIMARY} onClick={toggleUploadModal}>
            {t('upload')}
          </Button>
        )}
        <TableBody table={table} enablePagination={false} />
      </>
      <Modal
        show={showUploadModal}
        title={t('upload')}
        closeModal={onClose}
        size={'w-1/2'}
        maxWidth={800}
      >
        <div className="w-full my-8">
          <UploadFile
            uploadedDocuments={filesUploaded}
            uploadUrl={uploadUrl()}
          />
        </div>
      </Modal>
    </div>
  )
}

export default DocumentsList
