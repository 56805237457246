import Delete from '@icons/delete.svg'
import ArrowDown from '@icons/keyboard_arrow_down.svg'
import ArrowUp from '@icons/keyboard_arrow_up.svg'
import * as React from 'react'
import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import CheckBox from 'src/components/switchHoc/CheckBox'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import ExpandableTextArea from 'src/systematic-completion/components/expandable-table/components/expandable-cell/ExpandableTextArea'
import IconButton from 'src/ui-elements/button/IconButton'
import { classNames } from 'src/utility/utils'
import Icon, { Icons } from '../../../../../ui-elements/icon/Icon'
import { ExpandableTableContext } from '../../context/ExpandableTableContext'
import { useExpandableTableInteractionStoreState } from '../../hooks/useExpandableTableInteractionStore'
import { useExpandableTableStoreState } from '../../hooks/useExpandableTableStore'
import { IExpandableSection } from '../../types/IExpandableSection'
import AddRow from '../add-row/AddRow'

const ExpandableRow: React.FC<{
  section: IExpandableSection
  onRowSelect?: () => void
  selected?: boolean
  initialItem?: Record<string, unknown>
}> = ({ section, onRowSelect, selected = false, initialItem }) => {
  const { t } = useTranslation()

  const { interactionStore, canReorderRows } = useContext(
    ExpandableTableContext,
  )
  const sectionConfig = useExpandableTableStoreState(
    (state) => state.expandableSectionsConfig[section.id],
  )
  const tableWidth = useExpandableTableInteractionStoreState((state) =>
    Object.values(state.widthPerCol).reduce((sum, n) => sum + n, 0),
  )
  const updateSectionsConfig = useExpandableTableStoreState(
    (state) => state.updateSectionsConfig,
  )
  const setIsExpanded = () => {
    updateSectionsConfig(section.id, { isExpanded: !sectionConfig.isExpanded })
  }
  const readonly = useExpandableTableStoreState((state) => state.readonly)
  const isRowSelectable = useExpandableTableStoreState(
    (state) => state.isRowSelectable,
  )
  const updateSectionTitle = useExpandableTableStoreState(
    (state) => state.updateSectionTitle,
  )
  const rowIsHovered = useExpandableTableInteractionStoreState((state) => {
    return state.hoveringRow?.rowId === section.id
  })
  const ref = useRef<HTMLTableCellElement>(null)

  const { confirmDelete } = useDeleteModal()

  const onMouseEnter = () => {
    if (!readonly) {
      interactionStore.setState({ hoveringRow: { rowId: section.id, section } })
    }
  }
  const onMouseLeave = () => {
    if (!readonly) {
      interactionStore.setState({ hoveringRow: undefined })
    }
  }

  const sectionIsDraggedOver = useExpandableTableInteractionStoreState(
    (state) =>
      state.dragTargetSection?.id === section.id &&
      state.draggingSection &&
      !state.draggingRow,
  )

  const { deleteSection, sectionTitle } = useExpandableTableStoreState(
    (state) => state,
  )

  const onDeleteClick = async () => {
    const confirmed = await confirmDelete({
      itemName: t(sectionTitle),
      itemIdnType: t(sectionTitle),
    })
    if (confirmed) {
      deleteSection(section.id)
      if (interactionStore.getState().focusedSectionId)
        interactionStore.setState({ focusedSectionId: undefined })
    }
  }

  return (
    <div
      className="flex sticky-without-top z-10 top-8"
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={classNames(
          'flex',
          'items-center',
          sectionIsDraggedOver ? 'bg-blue-300' : 'bg-d-lightblue',
          'border-l',
          'border-r',
          !(sectionConfig.rows.length > 0) && 'border-b',
          readonly ? 'cursor-default' : 'cursor-grab',
        )}
        style={{ width: tableWidth }}
      >
        {isRowSelectable && (
          <div className="pl-1 pt-1 z-10  h-full border-gray">
            <CheckBox
              valueProp={selected}
              onChange={() => {
                onRowSelect && onRowSelect()
              }}
              disableTab
            />
          </div>
        )}
        {!readonly && canReorderRows && (
          <div className={'ml-1'}>
            <Icon
              icon={Icons.DRAG_NEW}
              className="cursor-grab min-w-2 mx-1"
              noPointerEvent
            />
          </div>
        )}
        <div onClick={setIsExpanded} className={'mr-2 ml-2 cursor-auto'}>
          {sectionConfig.isExpanded ? (
            <ArrowUp className={'text-xl'} />
          ) : (
            <ArrowDown className={'text-xl'} />
          )}
        </div>

        <div
          className={classNames(
            'font-bold text-sm overflow-clip w-full h-full pr-2',
            readonly ? 'py-1 mt-1' : '',
          )}
        >
          {readonly ? (
            <div className={'break-words p-0.5'}>{sectionConfig.title}</div>
          ) : (
            <ExpandableTextArea
              finishEditing={(newValue: string) => {
                updateSectionTitle(section.id, newValue ?? '')
              }}
              sectionId={section.id}
              value={sectionConfig.title}
              initialItem={initialItem}
            />
          )}
        </div>
      </div>
      {!readonly && rowIsHovered && (
        <div className={'bg-gray-100 pr-1.5 flex flex-row items-center'}>
          <IconButton
            icon={<Delete className={'text-lg fill-red'} />}
            onClick={onDeleteClick}
          />
          <AddRow
            rowId={section.id}
            section={section}
            rowIndex={0}
            readonly={readonly}
            initialItem={initialItem}
          />
        </div>
      )}
    </div>
  )
}

export default ExpandableRow
