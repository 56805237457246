import moment, { Moment } from 'moment'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { ITask } from 'src/service/OrgTypes'
import {
  getTaskErrorMessage,
  StructureValidationError,
} from 'src/service/ValidationErrors'
import useAlert from 'src/ui-elements/toast/useAlert'

export const useTaskTableValidator = () => {
  const { addAlert } = useAlert()
  const { t } = useTranslation()
  const projectContext = useContext(ProjectContext)

  const showAlert = (text: string) => {
    addAlert({
      type: 'warning',
      title: t('something_went_wrong'),
      description: text,
    })
  }

  const validateDeadline = (task: ITask, date: Moment) => {
    if (
      task &&
      task.parent &&
      ![
        'CheckInParameter',
        'ChecklistParameter',
        'ConstructionLocomotive',
        'ControlArea',
        'Improvement',
      ].includes(task.parent_type)
    ) {
      if (task.parent.deadline || task.parent.endTime || task.parent.endDate) {
        const deadline = moment(
          task.parent.deadline || task.parent.endTime || task.parent.endDate,
        )
        if (deadline.isBefore(date, 'day')) {
          showAlert(
            `${getTaskErrorMessage(
              StructureValidationError.PAST_PARENT_DEADLINE_TASK,
              t,
            )} ( ${moment(deadline).format('L')} )`,
          )
          return 'past_parent_deadline'
        }
        const projectEndDate = projectContext.state.currentProject.endDate
        if (moment(date).isAfter(moment(projectEndDate), 'day')) {
          showAlert(
            `${getTaskErrorMessage(
              StructureValidationError.PAST_PROJECT_DEADLINE,
              t,
            )} ( ${moment(projectEndDate).format('L')} )`,
          )
          return 'past_project_deadline'
        }
      }
    }

    return
  }

  return {
    validateDeadline,
  }
}
