import ArrowBack from '@icons/keyboard_arrow_left.svg'
import ArrowForward from '@icons/keyboard_arrow_right.svg'
import SkipNext from '@icons/skip_next.svg'
import SkipPrevious from '@icons/skip_previous.svg'
import { FC, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { classNames } from '../../utility/utils'

interface IPaginationProps {
  onChangePage: (page: number) => void
  totalPages: number
  totalItems: number
  pageSize?: number
  onChangePageSize?: (page: number) => void
  currentPage: number
}

const styleClass = {
  item: (selected: boolean, invisible?: boolean) =>
    classNames(
      'text-xl',
      'flex',
      'items-center',
      'hover:text-blue-root hover:font-medium hover:fill-blue-root',
      'cursor-pointer',
      'fill-gray-400',
      selected ? 'text-blue-root font-medium' : 'text-gray-400',
      invisible && 'invisible',
    ),
}

const Pagination: FC<IPaginationProps> = ({
  totalPages,
  totalItems,
  currentPage,
  pageSize = 0,
  onChangePage,
  onChangePageSize,
}) => {
  const { t } = useTranslation()
  const [pages, setPages] = useState<Array<{ index: number }>>([])

  const pageSizeOptions = [10, 20, 30, 50, 100]
  const maxPagesToShow = 20

  useEffect(() => {
    const pageArray = []
    for (let i = 1; i <= totalPages; i++) {
      pageArray.push({ index: i })
    }
    setPages(pageArray)
  }, [totalPages])

  const [startIndex, endIndex] = useMemo(() => {
    const pageOffset = currentPage % maxPagesToShow
    const start =
      pageOffset === 0
        ? currentPage - maxPagesToShow + 1
        : currentPage - pageOffset + 1
    const maxEnd = start + maxPagesToShow - 1
    const end = Math.min(maxEnd, totalPages)
    return [start, end]
  }, [currentPage, totalPages])

  const onClickPage = (page: number) => {
    if (currentPage === page) {
      return
    }
    onChangePage(page)
  }

  const pageItem = (page: number) => {
    return (
      <div
        key={page}
        className={twMerge(
          styleClass.item(currentPage === page),
          'text-xs',
          'p-2',
        )}
        onClick={() => onClickPage(page)}
      >
        {page}
      </div>
    )
  }

  const onPreviousBatch = () => {
    onChangePage(Math.max(startIndex - maxPagesToShow, 1))
  }

  const onNextBatch = () => {
    onChangePage(Math.min(endIndex + 1, totalPages))
  }

  const onPreviousPage = () => {
    if (currentPage > 1 && currentPage - 1 < startIndex) {
      onPreviousBatch()
    }
    if (currentPage > 1) {
      onChangePage(currentPage - 1)
    }
  }

  const onNextPage = () => {
    if (currentPage + 1 > endIndex) {
      onNextBatch()
    }
    if (currentPage < totalPages) {
      onChangePage(currentPage + 1)
    }
  }

  const visiblePages = useMemo(() => {
    return pages.filter(
      (page) => page.index >= startIndex && page.index <= endIndex,
    )
  }, [pages, startIndex, endIndex])

  const showPrevBatch = useMemo(() => {
    return startIndex > 1
  }, [startIndex])

  const showNextBatch = useMemo(() => {
    return endIndex < pages.length
  }, [pages, endIndex])

  const max = pageSize * currentPage
  const last = max > totalItems ? totalItems : max
  const first = last !== 0 ? max - pageSize + 1 : 0

  return (
    <div className={'flex-col flex my-2'}>
      <div className={'px-2 flex gap-1 text-gray-400 text-xs'}>
        {last === 0
          ? t('no_results_found')
          : t('show_items_of_total', { first, last, totalItems })}
      </div>
      <div className="flex items-center rounded-b bg-white border border-gray-300 h-8 w-fit">
        <div className={styleClass.item(false, !showPrevBatch)}>
          <SkipPrevious onClick={onPreviousBatch} />
        </div>
        <div className={styleClass.item(false, currentPage === 1)}>
          <ArrowBack onClick={onPreviousPage} />
        </div>
        {visiblePages.map((page) => {
          return pageItem(page.index)
        })}
        <div className={styleClass.item(false, currentPage === pages.length)}>
          <ArrowForward onClick={onNextPage} />
        </div>
        <div className={styleClass.item(false, !showNextBatch)}>
          <SkipNext onClick={onNextBatch} />
        </div>

        {pageSize && onChangePageSize && (
          <div className="px-2 flex gap-1 text-gray-400 text-xs items-center">
            <select
              title="Page size"
              name={t('page_size')}
              className={'p-0.5 text-sm rounded text-blue-root font-medium'}
              value={pageSize}
              onChange={(e) => {
                onChangePageSize(Number(e.target.value))
                onChangePage(1)
              }}
            >
              {pageSizeOptions.map((i) => (
                <option key={i}>{i}</option>
              ))}
            </select>
            <span>{t('items_per_page')}</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default Pagination
