import moment from 'moment'
import { useCallback, useEffect, useRef, useState } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useTranslation } from 'react-i18next'
import Selector from 'src/components/selectors/Selector'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import {
  IDiscipline,
  IStatus,
  ISystemTypeGroup,
  ITestWorkGroup,
} from 'src/service/OrgTypes'
import { priorities } from 'src/service/SystemValues'
import {
  createTestWorkGroup,
  editTestWorkGroup,
  getSingleTestWorkGroup,
  massTestWorkGroup,
} from 'src/service/TestWorkGroupService'
import { getErrorMessage, ValidationError } from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Spinner from 'src/ui-elements/loader/Spinner'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { renderDayContents } from 'src/utility/Utility'
import { classNames } from 'src/utility/utils'
import DocumentMetaDataFields from '../../../document/components/DocumentCreateModal/DocumentMetaDataFields'
import { IMetaValue } from '../../../document/types/IMetaData'
import { getStatusesForType } from '../../../service/SystemStatusService'
import { getProjectSystemTypeGroupsPerDomain } from '../../../service/SystemTypeGroupService'
import { initializeMetaValues, setExistingValues } from '../SystemUtil'

interface ITestWorkGroupFormProps {
  projectId: number
  closeModal: () => void
  editingMode: boolean
  testWorkGroup?: ITestWorkGroup
  testWorkGroupIds?: number[]
}

const TestWorkGroupForm = ({
  projectId,
  closeModal,
  editingMode,
  testWorkGroup,
  testWorkGroupIds,
}: ITestWorkGroupFormProps) => {
  const styleClass = {
    root: classNames('w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row', 'py-1'),
  }

  const massEditing = !!testWorkGroupIds
  const { t } = useTranslation()
  const [recordId, setRecordId] = useState(
    massEditing ? undefined : testWorkGroup?.record_id,
  )
  const [title, setTitle] = useState(
    massEditing ? undefined : testWorkGroup?.title,
  )
  const [description, setDescription] = useState(
    massEditing ? undefined : testWorkGroup?.description,
  )
  const [statusId, setStatusId] = useState(
    massEditing ? undefined : testWorkGroup?.test_work_group_status_id,
  )
  const [location, setLocation] = useState(
    massEditing ? undefined : testWorkGroup?.location,
  )
  const [priority, setPriority] = useState(
    massEditing ? undefined : testWorkGroup?.priority,
  )
  const [completedPercent, setCompletedPercent] = useState(
    massEditing ? undefined : testWorkGroup?.percent_completed,
  )
  const [disciplineId, setDisciplineId] = useState(
    massEditing ? undefined : testWorkGroup?.discipline_id,
  )
  const [contractId, setContractId] = useState(
    massEditing ? undefined : testWorkGroup?.contract_id,
  )
  const [loading, setLoading] = useState<boolean>(false)
  const [statusErrorMessage, setSetatusErrorMessage] = useState('')
  const [priorityErrorMessage, setPriorityErrorMessage] = useState('')
  const [recordIdErrorMessage, setrecordIdErrorMessage] = useState<string>('')
  const [locationErrorMessage, setLocationErrorMessage] = useState<string>('')
  const [disciplineLoading, setDisciplineLoading] = useState<boolean>(false)
  const [disciplines, setDisciplines] = useState<IDiscipline[]>([])
  const [disciplineErrorMessage, setDisciplineErrorMessage] =
    useState<string>('')
  const [createMultiple, setCreateMultiple] = useState<boolean>(false)
  const [userDefinedValues, setUserDefinedValues] = useState<IMetaValue[]>([])
  const [systemTypeGroup, setSystemTypeGroup] = useState<
    ISystemTypeGroup | undefined
  >(undefined)
  const [statuses, setStatuses] = useState<IStatus[]>([])
  const [statusLoading, setStatusLoading] = useState(false)

  const [plannedStart, setPlannedStart] = useState<moment.Moment | null>(null)
  const [plannedEnd, setPlannedEnd] = useState<moment.Moment | null>(null)
  const [actualStart, setActualStart] = useState<moment.Moment | null>(null)
  const [actualEnd, setActualEnd] = useState<moment.Moment | null>(null)
  const [focus, setFocus] = useState<string>('')

  const disciplineIDRef = useRef(disciplineId)

  useEffect(() => {
    fetchSystemGroupType()
  }, [projectId])

  const fetchSystemGroupType = () => {
    getProjectSystemTypeGroupsPerDomain(projectId, 'TestWorkGroup').then(
      (res: ISystemTypeGroup[]) => {
        const value = res.pop()
        setSystemTypeGroup(value)
        if (value) {
          const metaFields = initializeMetaValues(
            value.optional_fields ?? [],
            'TestWorkGroup',
            testWorkGroup?.id,
            editingMode,
          )
          setUserDefinedValues(
            setExistingValues(testWorkGroup?.optional_fields ?? [], metaFields),
          )
        }
      },
    )
  }

  const getStatus = () => {
    setStatusLoading(true)
    getStatusesForType(projectId, 'TestWorkGroup').then((res) => {
      setStatuses(res)
      setStatusLoading(false)
    })
  }

  const onOptionalFieldsUpdate = (values: IMetaValue[]) => {
    setUserDefinedValues(values)
  }

  const onMassEdit = (e: any) => {
    e.preventDefault()
    setLoading(true)
    if (testWorkGroupIds) {
      const updateTestWorkGroup = {
        title,
        description,
        percent_completed: completedPercent,
        discipline_id: disciplineId,
        contract_id: contractId,
        test_work_group_status_id: statusId,
        priority,
        location,
        optional_fields: userDefinedValues,
        system_type_group_id: systemTypeGroup?.id,
        planned_start: plannedStart ?? undefined,
        planned_end: plannedEnd ?? undefined,
        actual_start: actualStart ?? undefined,
        actual_end: actualEnd ?? undefined,
      } as ITestWorkGroup

      massTestWorkGroup(projectId, testWorkGroupIds, updateTestWorkGroup)
        .then(() => {
          setLoading(false)
          closeModal()
        })
        .catch(() => setLoading(false))
    }
  }

  const onSubmit = (e: any) => {
    let error = false
    e.preventDefault()

    setLoading(true)
    setrecordIdErrorMessage('')

    if (!recordId) {
      setrecordIdErrorMessage(t('fill_in_id'))
      error = true
    }

    if (!priority) {
      setPriorityErrorMessage(t('please_select_priority'))
      error = true
    }

    if (!location) {
      setLocationErrorMessage(t('please_enter_location'))
      error = true
    }

    if (!disciplineId) {
      setDisciplineErrorMessage(t('select_discipline'))
      error = true
    }

    if (!statusId) {
      setSetatusErrorMessage(getErrorMessage(ValidationError.MISSING_STATUS, t))
      error = true
    }

    if (!error) {
      if (editingMode && testWorkGroup && testWorkGroup.id) {
        const editedWorkGroup = {
          id: testWorkGroup.id,
          record_id: recordId,
          title,
          description,
          percent_completed: completedPercent,
          discipline_id: disciplineId,
          contract_id: contractId,
          test_work_group_status_id: statusId,
          priority,
          location,
          optional_fields: userDefinedValues,
          system_type_group_id: systemTypeGroup?.id,
          planned_start: plannedStart ?? undefined,
          planned_end: plannedEnd ?? undefined,
          actual_start: actualStart ?? undefined,
          actual_end: actualEnd ?? undefined,
        }
        editTestWorkGroup(editedWorkGroup).then(() => {
          closeModal()
          setLoading(false)
        })
      } else {
        const newTestWorkGroup = {
          record_id: recordId,
          title,
          description,
          percent_completed: completedPercent,
          discipline_id: disciplineId,
          contract_id: contractId,
          test_work_group_status_id: statusId,
          priority,
          location,
          optional_fields: userDefinedValues,
          system_type_group_id: systemTypeGroup?.id,
          planned_start: plannedStart ?? undefined,
          planned_end: plannedEnd ?? undefined,
          actual_start: actualStart ?? undefined,
          actual_end: actualEnd ?? undefined,
        } as ITestWorkGroup
        createTestWorkGroup(newTestWorkGroup, projectId).then(() => {
          if (createMultiple) {
            setRecordId('')
            setLoading(false)
          } else {
            closeModal()
            setLoading(false)
          }
        })
      }
    } else {
      setLoading(false)
    }
  }

  const onRecordIdChange = (e: any) => {
    setRecordId(e.target.value)
    setrecordIdErrorMessage('')
  }

  const onTtileChange = (e: any) => {
    setTitle(e.target.value)
  }

  const onDescriptionChange = (e: any) => {
    setDescription(e.target.value)
  }

  const onLocationChange = (e: any) => {
    setLocation(e.target.value)
  }

  const onPercentageChange = (e: any) => {
    setCompletedPercent(+e.target.value)
  }

  const fetchDisciplines = async () => {
    setDisciplineLoading(true)
    const allDisciplines: IDiscipline[] = await getProjectDisciplines(projectId)
    setDisciplines(allDisciplines)
    setDisciplineLoading(false)
  }

  const onCleared = () => {
    setDisciplineId(-1)
    disciplineIDRef.current = -1
    fetchDisciplines()
    setContractId(-1)
  }

  const onDisciplineSelect = (discipline: number) => {
    const selectedDiscipline = disciplines
      .filter((d) => d.id === discipline)
      .pop()
    setContractId(selectedDiscipline ? selectedDiscipline.contract_id : 0)
    setDisciplineId(discipline)
    disciplineIDRef.current = discipline
    setDisciplineErrorMessage('')
  }

  const onPriorityChange = (val: string) => {
    setPriority(val)
    setPriorityErrorMessage('')
  }

  const onStatusChange = (val: number) => {
    setStatusId(val)
    setSetatusErrorMessage('')
  }

  const onSingleCreate = () => {
    setCreateMultiple(false)
  }

  const onMultipleCreate = () => {
    setCreateMultiple(true)
  }

  const loadTestWorkGroup = useCallback(() => {
    if (testWorkGroup?.id) {
      getSingleTestWorkGroup(testWorkGroup.id).then((res) => {
        if (res) {
          setRecordId(res.record_id)
          setCompletedPercent(res.percent_completed ?? 0)
          setTitle(res.title ?? '')
          setDescription(res.description ?? '')
          setContractId(res.contract_id ?? 0)
          setDisciplines(res.discipline ? [res.discipline] : [])
          setDisciplineId(res.discipline_id ?? 0)
          setLocation(res.location ?? 0)
          setPriority(res.priority ?? 0)
          setStatuses(
            res.test_work_group_status ? [res.test_work_group_status] : [],
          )

          if (systemTypeGroup) {
            const metaFields = initializeMetaValues(
              systemTypeGroup?.optional_fields ?? [],
              'TestWorkGroup',
              testWorkGroup?.id,
            )

            setUserDefinedValues(
              setExistingValues(res.optional_fields ?? [], metaFields),
            )
          }
        }
      })
    }
  }, [testWorkGroup?.id])

  useEffect(() => {
    loadTestWorkGroup()
  }, [loadTestWorkGroup])

  return (
    <form
      className={styleClass.root}
      onSubmit={massEditing ? onMassEdit : onSubmit}
    >
      <div className={styleClass.inputGroup}>
        <Input
          label={t('title')}
          value={title}
          autoFocus={false}
          onChange={onTtileChange}
          block={true}
          required={false}
        />
      </div>
      <div className={`${styleClass.inputGroup} flex-wrap`}>
        {!massEditing && (
          <div className={'w-full  lg:w-1/2'}>
            <Input
              label={t('test_work_group_id')}
              block={true}
              value={recordId}
              onChange={onRecordIdChange}
              required={true}
              errorMessage={recordIdErrorMessage}
              autoFocus={true}
            />
          </div>
        )}

        <div className={'w-full  lg:w-1/2'}>
          <Input
            block={true}
            label={t('completed_percent')}
            type={'number'}
            onChange={onPercentageChange}
            value={completedPercent}
            autoFocus={true}
          />
        </div>
      </div>
      <div className={`${styleClass.inputGroup} flex-wrap`}>
        <div className={'w-full lg:w-1/2'}>
          <Selector
            items={priorities(t)}
            selectedItemId={priority || ''}
            onSelect={onPriorityChange}
            label={t('priority')}
            dataFields={['name']}
            required={true}
            fontSize={'sm'}
            fontWeight={'bold'}
            errorMessage={priorityErrorMessage}
          />
        </div>
        <div className={'w-full lg:w-1/2'}>
          <Selector
            items={statuses}
            selectedItemId={statusId || ''}
            onOpenSelectFunction={getStatus}
            loading={statusLoading}
            onSelect={onStatusChange}
            label={t('status')}
            dataFields={['name']}
            required={true}
            fontSize={'sm'}
            fontWeight={'bold'}
            errorMessage={statusErrorMessage}
          />
        </div>
      </div>
      <div className={`${styleClass.inputGroup} flex-wrap`}>
        <div className={'w-full lg:w-1/2'}>
          <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
            <label className={'text-sm font-medium capitalize'}>
              {t('planned_start')}
            </label>
            <SingleDatePicker
              firstDayOfWeek={1}
              date={plannedStart}
              onDateChange={(d) => {
                if (d) {
                  setPlannedStart(moment(d))
                  setPlannedEnd(moment(d).add(6, 'weeks'))
                }
              }}
              renderDayContents={renderDayContents}
              focused={focus === 'plannedStart'}
              onFocusChange={(focused) =>
                setFocus(focused.focused ? 'plannedStart' : '')
              }
              id="datePicker-plannedStart"
              small={true}
              isOutsideRange={() => false}
              showDefaultInputIcon={true}
              noBorder={true}
              numberOfMonths={1}
              displayFormat={() =>
                moment.localeData('no').postformat('DD.MM.YY')
              }
              required={false}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        </div>
        <div className={'w-full lg:w-1/2'}>
          <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
            <label className={'text-sm font-medium capitalize'}>
              {t('planned_end')}
            </label>

            <SingleDatePicker
              firstDayOfWeek={1}
              date={plannedEnd}
              onDateChange={(d) => setPlannedEnd(d)}
              renderDayContents={renderDayContents}
              focused={focus === 'plannedEnd'}
              onFocusChange={(focused) =>
                setFocus(focused.focused ? 'plannedEnd' : '')
              }
              id="datePicker-plannedEnd"
              small={true}
              isOutsideRange={() => false}
              showDefaultInputIcon={true}
              noBorder={true}
              numberOfMonths={1}
              displayFormat={() =>
                moment.localeData('no').postformat('DD.MM.YY')
              }
              required={false}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        </div>
      </div>
      <div className={`${styleClass.inputGroup} flex-wrap`}>
        <div className={'w-full lg:w-1/2'}>
          <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
            <label className={'text-sm font-medium capitalize'}>
              {t('actual_start')}
            </label>

            <SingleDatePicker
              firstDayOfWeek={1}
              date={actualStart}
              onDateChange={(d) => setActualStart(d)}
              renderDayContents={renderDayContents}
              focused={focus === 'actualStart'}
              onFocusChange={(focused) =>
                setFocus(focused.focused ? 'actualStart' : '')
              }
              id="datePicker-actualStart"
              small={true}
              isOutsideRange={() => false}
              showDefaultInputIcon={true}
              noBorder={true}
              numberOfMonths={1}
              displayFormat={() =>
                moment.localeData('no').postformat('DD.MM.YY')
              }
              required={false}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        </div>
        <div className={'w-full lg:w-1/2'}>
          <div className={'flex flex-col px-2 pb-1 items-start w-full'}>
            <label className={'text-sm font-medium capitalize'}>
              {t('actual_end')}
            </label>

            <SingleDatePicker
              firstDayOfWeek={1}
              date={actualEnd}
              onDateChange={(d) => setActualEnd(d)}
              renderDayContents={renderDayContents}
              focused={focus === 'actualEnd'}
              onFocusChange={(focused) =>
                setFocus(focused.focused ? 'actualEnd' : '')
              }
              id="datePicker-actualEnd"
              small={true}
              isOutsideRange={() => false}
              showDefaultInputIcon={true}
              noBorder={true}
              numberOfMonths={1}
              displayFormat={() =>
                moment.localeData('no').postformat('DD.MM.YY')
              }
              required={false}
              hideKeyboardShortcutsPanel={true}
            />
          </div>
        </div>
      </div>
      <div className={`${styleClass.inputGroup} flex-wrap`}>
        <div className={'w-full lg:w-1/2'}>
          <Selector
            items={disciplines}
            selectedItemId={disciplineId || ''}
            onOpenSelectFunction={fetchDisciplines}
            onSelect={onDisciplineSelect}
            label={t('discipline')}
            loading={disciplineLoading}
            dataFields={['shortName', 'name']}
            required={true}
            fontSize={'sm'}
            fontWeight={'bold'}
            errorMessage={disciplineErrorMessage}
            cancelButton={true}
            onCancel={onCleared}
          />
        </div>

        <div className={'w-full lg:w-1/2'}>
          <Input
            label={t('location')}
            block={true}
            value={location}
            onChange={onLocationChange}
            required={true}
            errorMessage={locationErrorMessage}
            autoFocus={true}
          />
        </div>
      </div>
      <div className={styleClass.inputGroup}>
        <Textarea
          label={t('description')}
          value={description}
          isValid={false}
          autoFocus={false}
          onChange={onDescriptionChange}
          block={true}
          required={false}
        />
      </div>
      {userDefinedValues.length > 0 && (
        <div className="pb-8">
          <DocumentMetaDataFields
            title={''}
            onFieldsUpdate={onOptionalFieldsUpdate}
            values={userDefinedValues}
            fields={systemTypeGroup?.optional_fields ?? []}
            required={false}
          />
        </div>
      )}
      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
          {t('cancel')}
        </Button>
        {editingMode ? (
          <Button
            type={Button.ButtonType.PRIMARY}
            disabled={loading ? true : false}
          >
            {loading ? <Spinner /> : t('update')}
          </Button>
        ) : (
          <>
            <Button
              type={Button.ButtonType.SECONDARY}
              onClick={onMultipleCreate}
              disabled={loading}
            >
              {loading ? <Spinner /> : t('add_multiple')}
            </Button>
            <Button
              type={Button.ButtonType.PRIMARY}
              disabled={loading ? true : false}
              onClick={onSingleCreate}
            >
              {loading ? <Spinner /> : t('add')}
            </Button>
          </>
        )}
      </ModalFooter>
    </form>
  )
}

export default TestWorkGroupForm
