import { useQuery, keepPreviousData } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getProjectTestSystemGroup,
  getProjectTestSystemGroupOnly,
} from 'src/service/TestSystemGroupService'

export const useFilteredTestSystemGroups = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: Parameters<typeof getProjectTestSystemGroup>[2]['sort']
  filters: Omit<Parameters<typeof getProjectTestSystemGroup>[2], 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'testSystemGroupsFiltered',
      { projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getProjectTestSystemGroup(
        projectId,
        page,
        { ...filters, sort },
        pageSize,
      ),
    placeholderData: keepPreviousData,
    enabled,
  })
}

export const useProjectTestSystemGroup = (options?: { enabled: boolean }) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['projectTestSystemGroups', { projectId }],
    queryFn: () => getProjectTestSystemGroupOnly(projectId),
    placeholderData: keepPreviousData,
    enabled: options?.enabled,
  })
}
