import Attachment from '@icons/attachment.svg'
import Comment from '@icons/comment.svg'
import Database from '@icons/database.svg'
import Folder from '@icons/folder.svg'
import Key from '@icons/key.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import useProjectId from 'src/components/hooks/useProjectId'
import AttachmentsInspectorSectionTableWithUpload from 'src/components/inspector-section-tables/AttachmentsInspectorSectionTableWithUpload'
import RelatedKeypointsList from 'src/components/key-point/RelatedKeypointsList'
import MetaDataFieldsForPage from 'src/document/components/DocumentCreateModal/MetaDataFieldsForPage'
import InspectorSections, {
  IInspectorSection,
} from 'src/document/components/Inspector/InspectorSections'
import history from 'src/history'
import useUserAccess from 'src/hooks/useUserAccess'
import { useMilestone } from 'src/query/planning/milestones'
import { useSystemTypeGroupForDomain } from 'src/query/systemTypeGroups'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { updateMilestone } from 'src/service/MilestoneService'
import { IMilestone } from 'src/service/OrgTypes'
import {
  getProjectProcesses,
  IModalOrigin,
  removeMilestoneFromKeypoints,
} from 'src/service/ProcessService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getMainprocessTeams, getProjectTeams } from 'src/service/TeamService'
import {
  getDisciplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import {
  getErrorMessage,
  getMileStoneErrorMessage,
  StructureValidationError,
  ValidationError,
} from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'

interface IMilestoneInspectorPanel {
  milestoneId: number
  open: boolean
  onClose: () => void
  origin: IModalOrigin
  notFound?: (id: number) => void
  onUpdate?: () => void
  defaultIndex?: number
}

const MilestoneInspectorPanel: FC<IMilestoneInspectorPanel> = ({
  milestoneId,
  open,
  onClose,
  origin,
  onUpdate,
  defaultIndex = 0,
}) => {
  const { t } = useTranslation()
  const projectId = useProjectId()
  const { addAlert } = useAlert()

  const { data: milestone, isLoading, refetch } = useMilestone(milestoneId)
  const canEdit = milestone?.can_edit
  const { hasModuleAdminAccess } = useUserAccess('planning')
  const { data: systemTypeGroup } = useSystemTypeGroupForDomain('MileStone')

  const getMetaDataContent = (): JSX.Element => {
    if (!milestone) return <></>
    return (
      <div className={'flex flex-col -ml-4 -mt-2'}>
        {(milestone.user_defined_values?.length ?? 0) > 0 && (
          <MetaDataFieldsForPage
            onFieldsUpdate={onMilestoneUpdate}
            values={milestone.user_defined_values ?? []}
            fields={systemTypeGroup?.optional_fields ?? []}
            required={false}
            editMode={false}
            inspectorPanel={true}
            disabled={!canEdit}
          />
        )}
      </div>
    )
  }

  const removeKeyPoints = async (ids: number[]) => {
    await removeMilestoneFromKeypoints(projectId, ids)
  }

  const getRows = (): IInspectorSection[] => [
    {
      name: t('details'),
      icon: <Folder />,
      content: getMainContent(),
    },
    {
      name: t('additional_information'),
      icon: <Database />,
      content: getMetaDataContent(),
      overflowVisible: true,
    },
    {
      name: t('keypoints'),
      icon: <Key />,
      content: (
        <RelatedKeypointsList
          removeKeypoints={removeKeyPoints}
          tableName={'keypoints-under-milestones'}
          parentFilter={{ mile_stone: [milestoneId] }}
          inInspectorPanel
          origin={origin}
          readonly={
            (milestone ? milestone.status === 'done' : false) || !canEdit
          }
        />
      ),
    },
    {
      name: t('attachments'),
      icon: <Attachment />,
      content: (
        <AttachmentsInspectorSectionTableWithUpload
          parentId={milestoneId}
          parentType="MileStone"
          readonly={!canEdit}
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: <Comments parentId={milestoneId} parentType="MileStone" />,
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentId={milestoneId} parentType="MileStone" />,
    },
  ]

  const onMilestoneUpdate = () => {
    refetch()
    onUpdate?.()
  }

  const onChangeInput = async (update: NullablePartial<IMilestone>) => {
    try {
      if (milestone?.id) {
        await updateMilestone({
          ...convertUndefinedToNull(update),
          id: milestone?.id,
        }).then(() => {
          onMilestoneUpdate()
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  const onStatusSelect = () => {
    if (!milestone) return
    if (milestone.open_children && milestone.open_children > 0) {
      showAlart(
        'error',
        t('an_error_occurred'),
        getMileStoneErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return
    }

    const updatedMilestone = {
      id: milestone.id,
      status: 'done',
    } as IMilestone

    updateMilestone(updatedMilestone).then(() => {
      refetch()
    })
  }

  const showAlart = (
    type: IAlertType,
    alertTitle: string,
    description: string,
  ) => {
    addAlert({ type, title: alertTitle, description })
  }

  const additionalButtons = () => {
    return (
      <div className="flex flex-row justify-end items-center">
        {milestone && milestone.status && milestone.status !== 'done' && (
          <Button
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {t('done')}
          </Button>
        )}
        <Link
          to={`/main-process/milestone/${milestoneId}?rootUrl=${origin?.url}&rootName=${origin?.name}&showHome=${!!origin?.showHome}${addProjectToUrl()}`}
        >
          <Button size={Button.ButtonSize.SMALL}>
            {t('view_or_plan_keypoints')}
          </Button>
        </Link>
      </div>
    )
  }

  const addProjectToUrl = (): string => {
    const params = new URLSearchParams(history.location.search)
    if (params.has('project')) {
      return `&project=${params.get('project')}`
    }
    return ''
  }

  const getMainContent = () => {
    return (
      <div className="-mt-2 -ml-6">
        <InlineComponentsWrapper
          loading={isLoading}
          padding="left"
          border={undefined}
          inputWidth="w-[480px]"
        >
          <TextInlineInputCompontent
            label={t('title')}
            value={milestone?.name}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ name: newValue })
            }}
            validate={(value) => {
              if (value === undefined || value === '')
                return getErrorMessage(ValidationError.MISSING_TITLE, t)
              return
            }}
            disabled={!canEdit}
          />
          <SelectorInlineInputComponent
            items={statusTypes(t)}
            label={t('status')}
            getItemLabel={(stat) => stat?.name}
            initialItem={{
              id: milestone?.status ?? '',
              name:
                statusTypes(t).find(
                  (statData) => statData.id === milestone?.status,
                )?.name ?? '',
            }}
            validate={(value) => {
              if (value === undefined)
                return getErrorMessage(ValidationError.MISSING_STATUS, t)
              if ((milestone?.open_children ?? 0) > 0 && value === 'done') {
                return getMileStoneErrorMessage(
                  StructureValidationError.HAS_OPEN_CHILDREN,
                  t,
                )
              }
              return
            }}
            selectedId={milestone?.status}
            onValueSubmitted={(stat) => {
              onChangeInput({ status: stat })
            }}
            inspectorPanel={true}
            disabled={!canEdit}
          />
          <DateTimeInlineInputComponent
            label={t('deadline')}
            selectedTime={`${milestone?.deadline}`}
            onValueSubmitted={(deadline) => {
              onChangeInput({ deadline })
            }}
            validate={(value) => {
              if (!value || !moment(value).isValid()) {
                return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
              }
              return
            }}
            inspectorPanel={true}
            disabled={!canEdit}
          />
          <DateTimeInlineInputComponent
            label="closed_date"
            selectedTime={`${milestone?.closed_date}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
          <Tooltip
            show={!hasModuleAdminAccess}
            message={t('only_planning_admin_can_edit_baseline')}
          >
            <DateTimeInlineInputComponent
              label={t('baseline_date')}
              selectedTime={`${milestone?.baseline}`}
              onValueSubmitted={(baseline) => {
                onChangeInput({ baseline })
              }}
              validate={(value) => {
                if (!value || !moment(value).isValid()) {
                  return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
                }
                return
              }}
              disabled={!hasModuleAdminAccess}
              inspectorPanel={true}
            />
          </Tooltip>
          <TextInlineInputCompontent
            label={t('duration_days')}
            value={`${milestone?.duration}`}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({ duration: parseInt(`${newValue}`, 10) })
            }}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              return undefined
            }}
            disabled={!canEdit}
          />
          <TextInlineInputCompontent
            label={t('delay_days')}
            disabled={true}
            value={`${milestone?.delay ?? 0}`}
          />
          <TextInlineInputCompontent
            label={t('keypoints')}
            disabled={true}
            value={`${milestone?.done_children || 0}/${
              (milestone?.open_children || 0) + (milestone?.done_children || 0)
            }`}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectDisciplines(projectId)}
            label="discipline"
            initialItem={milestone?.discipline}
            getItemLabel={(discipline) =>
              `${discipline?.shortName} - ${discipline?.name}`
            }
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('discipline'),
                })
              return
            }}
            selectedId={milestone?.discipline_id}
            onValueSubmitted={(discipline_id) => {
              onChangeInput({ discipline_id, responsible_id: null })
            }}
            inspectorPanel={true}
            disabled={!canEdit}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              milestone?.discipline_id
                ? getDisciplineUsers(milestone?.discipline_id)
                : getProjectUsersWithDisciplines(projectId)
            }
            label="responsible"
            getItemLabel={(responsible) =>
              `${responsible?.firstName} ${responsible?.lastName}`
            }
            initialItem={milestone?.responsible}
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('responsible'),
                })
              return
            }}
            selectedId={milestone?.responsible_id}
            onValueSubmitted={(responsible_id) => {
              onChangeInput({ responsible_id })
            }}
            dependencies={[milestone?.discipline_id]}
            inspectorPanel={true}
            disabled={!canEdit}
          />
          <SelectorInlineInputComponent
            label={'contract'}
            disabled={true}
            selectedId={milestone?.contract_id ?? ''}
            getItemLabel={(contract) =>
              `${contract?.contractNumber} - ${contract?.contractName}`
            }
            initialItem={milestone?.contract}
            inspectorPanel={true}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectProcesses(projectId)}
            label={t('main_process')}
            getItemLabel={(process) =>
              `${process?.record_id} - ${process?.name}`
            }
            initialItem={milestone?.main_process}
            validate={(value) => {
              if (!value || parseInt(`${value}`, 10) === 0) {
                return getErrorMessage(ValidationError.MISSING_MAIN_PROCESS, t)
              }
              return
            }}
            selectedId={milestone?.main_process_id}
            onValueSubmitted={(val) => {
              onChangeInput({ main_process_id: val, team_id: undefined })
            }}
            inspectorPanel={true}
            disabled={!canEdit}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              milestone?.main_process_id
                ? getMainprocessTeams(milestone?.main_process_id)
                : getProjectTeams(projectId)
            }
            label={t('team')}
            getItemLabel={(team) => team?.name}
            initialItem={milestone?.team}
            selectedId={milestone?.team_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ team_id: val })
            }}
            cancelButton={true}
            dependencies={[milestone?.main_process_id]}
            inspectorPanel={true}
            disabled={!canEdit}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectTags(projectId)}
            label={t('type')}
            getItemLabel={(tag) => tag?.name}
            initialItem={milestone?.tag}
            selectedId={milestone?.tag_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ tag_id: val })
            }}
            cancelButton={true}
            inspectorPanel={true}
            disabled={!canEdit}
          />
          <TextInlineInputCompontent
            label={t('description')}
            textArea={true}
            value={milestone?.description}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ description: newValue })
            }}
            disabled={!canEdit}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={`${milestone?.created_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={`${milestone?.updated_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={true}
          />
        </InlineComponentsWrapper>
      </div>
    )
  }

  return (
    <>
      <FixedPane
        title={
          milestone?.name
            ? `${milestone?.record_id} - ${milestone?.name}`
            : t('loading...')
        }
        show={open}
        onClose={onClose}
        className={'w-[700px]'}
        detailPageData={{
          key: DetailPageKeys.MILESTONE,
          ids: { milestoneId: milestoneId },
        }}
      >
        <div className={'flex flex-col'}>
          {canEdit && additionalButtons()}
          <InspectorSections defaultIndex={defaultIndex} sections={getRows()} />
        </div>
      </FixedPane>
    </>
  )
}
export default MilestoneInspectorPanel
