import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getProjectPlanFreezePeriod } from 'src/service/ProjectService'

export const freezePlan = 'freeze-plan'

export const useFreezePlan = (dataType: 'key_point' | 'delivery') => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [freezePlan, projectId, dataType],
    queryFn: async () => getProjectPlanFreezePeriod(projectId, dataType),
  })
}
