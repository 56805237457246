import { ColumnDef, RowSelectionState } from '@tanstack/react-table'
import { useState } from 'react'
import Pagination from 'src/ui-elements/Table/Pagination'
import TableBody from 'src/ui-elements/Table/TableBody'
import TableButtons, {
  TableButtonProps,
} from 'src/ui-elements/Table/TableButtons'
import useTable, { UseTableProps } from 'src/ui-elements/Table/useTable'

type SimpleTableProps<T extends { id: string | number }> = {
  data: Array<T>
  columns: ColumnDef<T>[]
  name: string
  onBulkDelete?: (ids: string[]) => void
  enablePagination?: boolean
  title?: string
  customButtons?: TableButtonProps<T>['customButtons']
}

const SimpleTable = <T extends { id: string | number }>({
  data,
  columns,
  name,
  onBulkDelete,
  enablePagination = true,
  customButtons,
  title,
  ...useTableProps
}: SimpleTableProps<T> & UseTableProps<T>) => {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})
  const { table } = useTable<T>({
    columns,
    data,
    name,
    frontendState: true,
    onSelect: setSelectedRows,
    selectionState: selectedRows,
    ...useTableProps,
  })
  const { pageSize, pageIndex } = table.getState().pagination

  return (
    <>
      <TableButtons
        table={table}
        onBulkDelete={onBulkDelete}
        numberIds={false}
        customButtons={[
          ...(customButtons ?? []),
          ...(title
            ? [
                <h3
                  key="title"
                  className="rounded-md border-2 p-1 text-gray-500"
                >
                  {title}
                </h3>,
              ]
            : []),
        ]}
      />
      <TableBody table={table} pageCount={0} enablePagination={false} />
      {enablePagination && (
        <Pagination
          totalPages={Math.ceil(data.length / pageSize)}
          // The built in pagination in react table is zero-indexed while ours is not
          currentPage={pageIndex + 1}
          totalItems={data.length}
          pageSize={pageSize}
          onChangePage={(page) => table.setPageIndex(page - 1)}
          onChangePageSize={(pageSize) => {
            table.setPageSize(pageSize)
          }}
        />
      )}
    </>
  )
}

export default SimpleTable
