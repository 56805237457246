import { keepPreviousData, useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getFilteredTasksData } from 'src/service/TaskService'

interface IFilteredTasksData {
  sort: Parameters<typeof getFilteredTasksData>[1]['sort']
  filters: Omit<Parameters<typeof getFilteredTasksData>[1], 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
  asItem?: boolean
}

export const useFilteredTasksData = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
  asItem,
}: IFilteredTasksData) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'tasks',
      { projectId: projectId, filters, sort, page, pageSize, asItem },
    ],
    queryFn: () =>
      getFilteredTasksData(
        projectId,
        { ...filters, sort },
        page,
        pageSize,
        asItem,
      ),
    placeholderData: keepPreviousData,
    enabled,
  })
}

export const useFilteredTaskItems = (params: IFilteredTasksData) => {
  return useFilteredTasksData({ ...params, asItem: true })
}
