import { createStore } from 'zustand'
import { IExpandableSection } from 'src/systematic-completion/components/expandable-table/types/IExpandableSection'
import TableColumnWidthRowHeightHandler from '../services/TableColumnWidthRowHeightHandler'
import { IDraggingRow } from '../types/IDraggingRow'
import { IHeader } from '../types/IHeader'
import { IRow } from '../types/IRow'

export interface IExpandableTableInteractionStore {
  selectedRowIndex?: number
  selectedColumn?: string
  focusedSectionId?: number
  numberOfColumns: number
  numberOfRows: number
  resizingColSource?: {
    target: HTMLDivElement
    x: number
    y: number
    header: IHeader
  }
  isResizingCol?: boolean
  resizingRowSource?: {
    target: HTMLDivElement
    x: number
    y: number
    rowId: IRow['id']
  }
  isResizingRow?: boolean
  draggingColId?: string
  hoveringColId?: string
  draggingRow?: IDraggingRow
  draggingSection?: IExpandableSection
  hoveringRow?: IDraggingRow
  dragTargetSection?: IExpandableSection
  widthPerCol: Record<string, number>
  getHeaderWidth: (id: string) => number
  heightPerRow: Record<string, number>
  resizeColumn: (headerId: string, newWidth: number) => void
  resizeRow: (rowId: IRow['id'], newWidth: number) => void
}

export const createExpandableInteractionStore = (
  {
    numberOfColumns,
    numberOfRows,
  }: Pick<IExpandableTableInteractionStore, 'numberOfColumns' | 'numberOfRows'>,
  tableName: string,
) =>
  createStore<IExpandableTableInteractionStore>((set, get) => ({
    numberOfColumns,
    numberOfRows,

    widthPerCol: {},
    getHeaderWidth: (id) => {
      return get().widthPerCol[id]
    },
    heightPerRow: {},
    resizeColumn: (headerId, newWidth) => {
      const tmpWidthPerCol = { ...get().widthPerCol }
      tmpWidthPerCol[headerId] = newWidth
      TableColumnWidthRowHeightHandler.saveTable(
        { headers: tmpWidthPerCol, rows: get().heightPerRow },
        tableName,
      )
      set({ widthPerCol: tmpWidthPerCol })
    },
    resizeRow: (rowId, newHeight) => {
      const tmpHeightPerRow = { ...get().heightPerRow }
      tmpHeightPerRow[rowId] = newHeight
      TableColumnWidthRowHeightHandler.saveTable(
        { headers: get().widthPerCol, rows: tmpHeightPerRow },
        tableName,
      )
      set({ heightPerRow: tmpHeightPerRow })
    },
  }))
