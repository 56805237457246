import ArrowDown from '@icons/keyboard_arrow_down.svg'
import ArrowUp from '@icons/keyboard_arrow_up.svg'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize, ButtonType } from 'src/ui-elements/button/ButtonEnums'
import {
  useExpandableTableStore,
  useExpandableTableStoreState,
} from '../../hooks/useExpandableTableStore'

const Collapse = () => {
  const { t } = useTranslation()
  const [collapsed, setCollapsed] = useState(false)

  const store = useExpandableTableStore()
  const readonly = useExpandableTableStoreState((state) => state.readonly)

  if (readonly) {
    return null
  }

  return (
    <Button
      onClick={async () => {
        collapsed
          ? await store.getState().expand()
          : await store.getState().collapse()
        setCollapsed(!collapsed)
      }}
      type={ButtonType.SECONDARY}
      size={ButtonSize.XSMALL}
    >
      <div className="flex items-center">
        {collapsed ? (
          <ArrowDown className={'text-xl'} />
        ) : (
          <ArrowUp className={'text-xl'} />
        )}
        {collapsed ? t('expand_all') : t('collapse_all')}
      </div>
    </Button>
  )
}

export default Collapse
