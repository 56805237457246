
import {IEnvironmentConfig} from "./IEnvironmentConfig";

export const risaConfig: IEnvironmentConfig = {
  host: "risa.bygg.io",
  exportBaseUrl: "https://export-risa.bygg.io/",
  frontendBaseUrl: "https://risa.bygg.io/",
  organizationBaseUrl: "https://org-risa.bygg.io/",
  proxyBaseUrl: "https://proxy-risa.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-risa.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-risa.bygg.io/",
  bimServiceBaseUrl: "not yet set",
  kpiBaseUrl: "https://kpi-risa.bygg.io/"
}
