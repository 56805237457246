import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import { renderConnectionDirection } from 'src/components/system/system-connection-type/SystemConnectionTypeList'
import {
  ISystemConnectionType,
  SystemConnectionDirectionEnum,
} from 'src/service/OrgTypes'
import {
  createSystemConnectionType,
  editSystemConnectionType,
} from 'src/service/SystemService'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Input from 'src/ui-elements/input/Input'
import Spinner from 'src/ui-elements/loader/Spinner'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'

interface ISystemConnectionTypeForm {
  editingMode: boolean
  closeModal: () => void
  systemConnectionType?: ISystemConnectionType
}

const SystemConnectionTypeForm = ({
  editingMode,
  closeModal,
  systemConnectionType,
}: ISystemConnectionTypeForm) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)
  const projectId = useProjectId()

  const { handleSubmit, control, watch } = useForm<ISystemConnectionType>({
    defaultValues: {
      project_id: projectId,
      direction: SystemConnectionDirectionEnum.RIGHT,
      ...systemConnectionType,
    },
  })

  const direction = watch('direction')

  const systemADefaultRole =
    direction === SystemConnectionDirectionEnum.RIGHT
      ? 'supplies'
      : direction === SystemConnectionDirectionEnum.LEFT
        ? 'supplied_by'
        : 'system_a'

  const systemBDefaultRole =
    direction === SystemConnectionDirectionEnum.RIGHT
      ? 'supplied_by'
      : direction === SystemConnectionDirectionEnum.LEFT
        ? 'supplies'
        : 'system_b'

  const dependencyArray = [
    SystemConnectionDirectionEnum.RIGHT,
    SystemConnectionDirectionEnum.LEFT,
    SystemConnectionDirectionEnum.NONE,
  ]

  const onSubmit = async (data: ISystemConnectionType) => {
    setLoading(true)
    const roles = {
      role_a:
        data.role_a && data.role_a !== ''
          ? data.role_a
          : t(systemADefaultRole, { system: t('system') }),
      role_b:
        data.role_b && data.role_b !== ''
          ? data.role_b
          : t(systemBDefaultRole, { system: t('system') }),
    }
    if (editingMode && systemConnectionType) {
      await editSystemConnectionType(projectId, {
        ...data,
        ...roles,
        id: systemConnectionType?.id,
      })
      closeModal()
    } else {
      await createSystemConnectionType(projectId, { ...data, ...roles })
      closeModal()
    }
    setLoading(false)
  }

  return (
    <Modal
      size={'w-full'}
      show={true}
      closeModal={closeModal}
      title={
        editingMode
          ? `${t('update')} ${t('system_connection_type').toLowerCase()}`
          : t('new_w_param', {
              param: t('system_connection_type').toLowerCase(),
            })
      }
      maxWidth={600}
    >
      <form
        className={'w-full pr-4'}
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault()
            handleSubmit(onSubmit)()
          }
        }}
      >
        <div className={'w-full'}>
          <Controller
            name="name"
            control={control}
            rules={{
              required: t('fill_out_w_param', { param: t('name') }),
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={t('name')}
                value={value}
                onChange={onChange}
                errorMessage={error?.message}
                required
                block
              />
            )}
          />
          <div className={'flex-row flex gap-1 items-end'}>
            <Controller
              name="role_a"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  placeholder={t(systemADefaultRole, { system: t('system') })}
                  label={t('role_system_A', {
                    system: t('system').toLowerCase(),
                  })}
                  value={value}
                  onChange={onChange}
                  block
                />
              )}
            />
            <Controller
              name="direction"
              control={control}
              render={({ field: { value, onChange } }) => {
                const { text, icon } = renderConnectionDirection(value)
                return (
                  <Tooltip
                    message={t(text, { system: t('system') })}
                    minWidth={'32'}
                    offset={1}
                  >
                    <Button
                      onClick={(e) => {
                        e.preventDefault()
                        const newIndex =
                          (dependencyArray.indexOf(value) + 1) %
                          dependencyArray.length
                        onChange(dependencyArray[newIndex])
                      }}
                      type={ButtonType.SECONDARY}
                      className={'w-full'}
                    >
                      {icon}
                    </Button>
                  </Tooltip>
                )
              }}
            />
            <Controller
              name="role_b"
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  placeholder={t(systemBDefaultRole, { system: t('system') })}
                  label={t('role_system_B', {
                    system: t('system').toLowerCase(),
                  })}
                  value={value}
                  onChange={onChange}
                  block
                />
              )}
            />
          </div>
          <Controller
            name="description"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Textarea
                label={t('description')}
                value={value}
                onChange={onChange}
                block
              />
            )}
          />
        </div>
        <ModalFooter>
          <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
            {t('cancel')}
          </Button>
          <Button type={Button.ButtonType.PRIMARY} disabled={loading}>
            {loading ? <Spinner /> : editingMode ? t('update') : t('add')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}
export default SystemConnectionTypeForm
