import { useQuery, keepPreviousData } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getProjectKeypointsFilteredWithPagination,
  getProjectKeypoints,
} from 'src/service/ProcessService'

export const useKeypoints = () => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['keypoints', projectId],
    queryFn: () => getProjectKeypoints(projectId),
  })
}

export const useFilteredKeypointsWithPagination = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: Parameters<typeof getProjectKeypointsFilteredWithPagination>[1]['sort']
  filters: Omit<
    Parameters<typeof getProjectKeypointsFilteredWithPagination>[1],
    'sort'
  >
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'keypointsFilteredWithPagination',
      { projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getProjectKeypointsFilteredWithPagination(
        projectId,
        { ...filters, sort },
        page,
        pageSize,
      ),
    placeholderData: keepPreviousData,
    enabled,
  })
}
