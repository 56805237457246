import { useQueryClient } from '@tanstack/react-query'
import { RowSelectionState } from '@tanstack/react-table'
import { useContext, useState } from 'react'
import { useDeliveryColumns } from 'src/components/process/delivery/useDeliveryColumns'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { useFilteredDeliveries } from 'src/query/planning/deliveries'
import { IDelivery } from 'src/service/OrgTypes'
import Table from 'src/ui-elements/Table/Table'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import DeliveryInspectorPanel from './DeliveryInspectorPanel'

interface IProjectDeliveriesListProps {
  tableName?: string
  onSelectDeliveries: (deliveries: number[]) => void
  filter?: Omit<TableFilter, 'sort'>
}

const DeliverySelectList = ({
  tableName = 'projectDeliveriesSelectList',
  onSelectDeliveries,
  filter,
}: IProjectDeliveriesListProps) => {
  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject

  const [openInspectorPanel, setOpenInspection] = useState(false)
  const [selectedDeliveryId, setSelectedDeliveryId] = useState<number>()
  const [selectionState, setSelectionState] = useState<RowSelectionState>({})

  const queryClient = useQueryClient()
  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['deliveries'] })
  }

  const handleSelectedDeliveries = (rows: RowSelectionState) => {
    setSelectionState(rows)
    onSelectDeliveries(Object.keys(rows).map((row) => +row))
  }

  const handlePreviewClick = (data: IDelivery) => {
    setOpenInspection(true)
    if (data.id) {
      setSelectedDeliveryId(data.id)
    }
  }

  const { columns, defaultOrdering } = useDeliveryColumns({ disabled: true })

  return (
    <div className={'w-full flex flex-col'}>
      <Table
        selectionState={selectionState}
        setSelectedRowsParent={handleSelectedDeliveries}
        name={tableName}
        useDataQuery={useFilteredDeliveries}
        onPreviewClick={handlePreviewClick}
        columns={columns}
        defaultOrdering={defaultOrdering}
        initialFilter={filter}
      />
      {openInspectorPanel && selectedDeliveryId ? (
        <DeliveryInspectorPanel
          deliveryId={selectedDeliveryId}
          open={openInspectorPanel}
          onClose={() => setOpenInspection(false)}
          projectId={projectId}
          onUpdate={reload}
        />
      ) : (
        <></>
      )}
    </div>
  )
}

export default DeliverySelectList
