import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityType, IActivityLog } from 'src/service/OrgTypes'
import useAlert from 'src/ui-elements/toast/useAlert'
import { getItemNameFromActivityLog } from 'src/utility/activityLogUtils'

const useNewNotification = () => {
  const ref = useRef<number[]>()
  const { t } = useTranslation()
  const { addAlert } = useAlert()

  const text = (log: IActivityLog) => {
    const name = `${log.user?.firstName ?? ''} ${log.user?.lastName ?? ''}`

    const itemName = getItemNameFromActivityLog(log, t)

    switch (log.activity_type) {
      case ActivityType.SYSTEM_NOTIFICATION:
        return t('system_notification')

      case ActivityType.COMMENT:
        return t('comment_notification', { name })

      case ActivityType.UPDATE:
        return t('update_notification', { name })

      case ActivityType.CREATE:
        return t('create_notification', { name })

      case ActivityType.IMPORT:
        return t('import_notification', { name })

      case ActivityType.CREATE_GROUP:
        return t('create_group_notification', { name })

      case ActivityType.UPDATE_GROUP:
        return t('update_group_notification', { name })

      case ActivityType.DOCUMENT_DELETE:
        return t('delete_document_notification', { name })

      case ActivityType.DOCUMENT_UPLOAD:
        return t('document_upload_notification', { name })

      case ActivityType.COMMENT_MENTION:
        return t('comment_mention_notification', { name })

      case ActivityType.DELETE:
        return t('delete_notification', { name, itemName })

      case ActivityType.RESPONSIBLE_ASSIGNMENT:
        return t('responsible_assignment_notification', { name, itemName })

      case ActivityType.CREATED_FMI_SUGGESTION:
        return t('created_fmi_suggestion_notification', { name })

      case ActivityType.APPROVED_FMI_SUGGESTION:
        return t('approved_fmi_suggestion_notification', { name })

      case ActivityType.DECLINED_FMI_SUGGESTION:
        return t('declined_fmi_suggestion_notification', { name })
      case ActivityType.OMEGA_SYNC:
        return t('omega_sync_completed')
    }
  }

  const handleNewNotification = (notification: IActivityLog) => {
    if (ref.current?.includes(notification.id)) return
    ref.current = [...(ref.current ?? []), notification.id]
    const description = text(notification)
    addAlert({
      type: 'info',
      title: t('new_activity'),
      description,
      autoClose: true,
    })
  }

  return handleNewNotification
}

export default useNewNotification
