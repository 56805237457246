import moment from 'moment'
import { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { ITest } from 'src/service/OrgTypes'
import { getProjectTests } from 'src/service/TestService'
import List from 'src/ui-elements/list/List'
import { IListFilter } from 'src/ui-elements/list/ListContextProvider'
import {
  filterType,
  IListColumns,
  ISorting,
} from 'src/ui-elements/list/ListTypes'
import { TestStatusColumn } from '../../../components/TableColumns/Columns'
import {
  ContractColumn,
  DisciplineColumnWithOutEditing,
} from '../../../components/TableColumns/DisciplineResponsibleColumns'
import { testStatus } from '../../../service/SystemValues'
import {
  classNames,
  constructFilterJson,
  IActiveFilter,
} from '../../../utility/utils'
import TestInspectorPanel from './TestInspectorPanel'

const styleClass = {
  root: classNames('py-2', 'w-full'),
}

interface ITestTypeListProps {
  testTypeId?: number
  onTestSelect: (data: number[]) => void
  selectedTests: number[]
  mustHaveTestCases?: boolean
}

const TestListSelector = ({
  testTypeId,
  onTestSelect,
  selectedTests,
  mustHaveTestCases,
}: ITestTypeListProps) => {
  const { t } = useTranslation()
  const [tests, setTests] = useState<ITest[]>([])
  const [selectedTest, setSelectedTest] = useState<ITest | null>(null)
  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const [totalPages, setTotalPages] = useState<number>(0)
  const testRef = useRef(tests)
  const [showInspector, setShowInspector] = useState(false)

  const projectContext = useContext(ProjectContext)

  const projectId = projectContext.state.currentProject.id

  useEffect(() => {
    reload()
  }, [projectId])

  useEffect(() => {
    testRef.current = tests
  })

  const columns: IListColumns[] = [
    {
      name: 'id',
      size: '120',
      id: 'recordId',
      sortingField: 'record_id',
      dataField: 'record_id',
      filter: [],
      filterType: filterType.TEXT,
    },
    {
      name: 'name',
      size: '200',
      id: 'name',
      sortingField: 'name',
      dataField: 'name',
      filter: [],
      filterType: filterType.TEXT,
    },
    TestStatusColumn(false, testStatus(t), () => {}, true),
    {
      name: 'deadline',
      size: '200',
      id: 'executionDate',
      sortingField: 'execution_date',
      dataField: 'execution_date',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (executionDate: any) => (
        <span>{executionDate ? moment(executionDate).format('L') : ''}</span>
      ),
    },
    {
      name: 'description',
      size: '300',
      id: 'description',
      sortingField: 'description',
      dataField: 'description',
      filter: [],
      filterType: filterType.TEXT,
    },
    DisciplineColumnWithOutEditing(),
    ContractColumn(),
  ]

  const handlePreviewClick = (e: any, data: ITest) => {
    e.preventDefault()
    e.stopPropagation()
    setSelectedTest(data)
    setShowInspector(true)
  }

  const onCloseInspector = () => {
    setShowInspector(false)
    setSelectedTest(null)
  }

  const reload = () => {
    setReloadTable((n) => !n)
  }

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    if (testTypeId) {
      activeFilters['test_type'] = [`${testTypeId}`]
    }
    if (mustHaveTestCases) {
      activeFilters['has_test_cases'] = true
    }
    testFilter(activeFilters, page)
  }

  const testFilter = (activeFilters: IActiveFilter, page: number) => {
    getProjectTests(projectId, page, activeFilters, 25).then((res) => {
      setTests(res.items)
      setTotalPages(res.pages)
    })
  }

  return (
    <>
      <div className={styleClass.root}>
        <List
          columns={columns}
          data={tests}
          tableName={'testSelectorTable'}
          itemsPerPage={25}
          pagination={true}
          filterParent={'test'}
          getFilteredData={getFilteredData}
          reload={reloadTable}
          totalPages={totalPages}
          isRowSelectable={true}
          onSelectRow={onTestSelect}
          selectedRows={selectedTests}
          filterResetOption={true}
          disableConfigStore={true}
          disableColumnSelector={true}
          onPreviewClick={handlePreviewClick}
        />

        {selectedTest && selectedTest?.id && showInspector && (
          <TestInspectorPanel
            testId={selectedTest.id}
            open={showInspector}
            onClose={onCloseInspector}
            onUpdate={reload}
          />
        )}
      </div>
    </>
  )
}

export default TestListSelector
