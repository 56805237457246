import * as React from 'react'
import { IProjectData, IProject } from '../../service/OrgTypes'

export interface IProjectContextProps {
  userId: number
  children: React.ReactNode
}

export interface IProjectContext {
  actions: {
    setProject: (projectId: number) => void
    reload: () => void
  }
  state: IProjectContextState
}

export interface IProjectContextState {
  projects: IProjectData[]
  currentProject: IProject
  isProjectAdmin: boolean
  isProjectRiskAdmin: boolean
  meetingModuleAccess: boolean
  status_manager: boolean
  isConstructionManager: boolean
}

export const initialState: IProjectContextState = {
  currentProject: {} as IProject,
  isConstructionManager: false,
  isProjectAdmin: false,
  isProjectRiskAdmin: false,
  meetingModuleAccess: false,
  status_manager: false,
  projects: [],
}

export type ProjectContext = IProjectContext

export const ProjectContext = React.createContext<ProjectContext>({
  actions: {
    reload: () => null,
    setProject: () => null,
  },
  state: initialState,
})
