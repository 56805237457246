import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { capFirstLetter, classNames } from '../../utility/utils'
import MilestoneCanvasIcon from './MilestoneCanvasIcon'

export interface IMilestoneTimelineItemProps {
  getItemProps: any
  item: any
  openMilestone: (data: any, timelineContext: any) => void
  itemContext: any
  timelineContext: any
  alwaysShowLabel?: boolean
}

const styleClass = {
  item: classNames('flex', 'overflow-visible', 'cursor-pointer', 'justify-end'),
  floatLabel: classNames(
    'fixed',
    'bg-white',
    'text-sm',
    'shadow',
    'p-3',
    'flex',
    'z-30',
    'flex-col',
  ),
  floatLabelSmallText: classNames('text-xxs', 'py-1'),
}

const MilestoneTimelineProgressItem = ({
  getItemProps,
  item,
  openMilestone,
  itemContext: _itemContext,
  timelineContext,
  alwaysShowLabel: alwaysShowLabel,
}: IMilestoneTimelineItemProps) => {
  const [showFloatLabel, setShowFloatLabel] = useState<{
    left: number
    top: number
  }>({ left: 0, top: 0 })
  const [showLabel, setShowLabel] = useState<boolean>(false)
  const [selectedLabel, setSelectedLabel] = useState<number>(-1)
  const [countStatus, setCountStatus] = useState<string>('')
  const doneChildren = item.done_children
  const openChildren = item.open_children
  const expiredChildren = item.expired_children
  const totalChildren = doneChildren + openChildren

  const { t } = useTranslation()

  useEffect(() => {
    const incompleteStatus =
      totalChildren > 0 && expiredChildren > 0 ? 'incomplete' : 'normal'
    const sta =
      doneChildren > 0 && doneChildren === totalChildren
        ? 'done'
        : incompleteStatus
    setCountStatus(sta)
  }, [item])

  const onMouseEnter = (e: any, milestoneId: number) => {
    setShowLabel(true)
    const target = e.target.getBoundingClientRect()
    const tmpFloatLabel = showFloatLabel
    if (window.innerWidth - target.x < 200) {
      tmpFloatLabel.left = target.x - (200 - (window.innerWidth - target.x))
    } else {
      tmpFloatLabel.left = target.x + 20
    }
    tmpFloatLabel.top = target.y
    setShowFloatLabel(tmpFloatLabel)
    setSelectedLabel(milestoneId)
  }

  const onMouseLeave = () => {
    setShowLabel(false)
  }

  const getStatusColor = (stat: string) => {
    switch (stat) {
      case 'done':
        return '#0e9f6e'
      case 'incomplete':
        return 'red'
      case 'normal':
        return ''
      default:
        return 'black'
    }
  }

  const milestone = item
  const st = moment(timelineContext.visibleTimeStart)
  const et = moment(timelineContext.visibleTimeEnd)
  return (
    <div
      className={'flex flex-col'}
      onClick={() => openMilestone(item.item_id, timelineContext)}
    >
      <div
        {...getItemProps({
          style: {
            backgroundColor: 'transparent',
            color: item.color,
            zIndex: 16,
            borderStyle: 'solid',
            minWidth: '20px',
            minHeight: '20px',
            borderColor: 'black',
            borderWidth: 0,
          },
        })}
      >
        <div
          className={styleClass.item}
          style={{
            height: '23px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            background: item.is_done ? '#bcf0da' : '#a4cafe',
          }}
        >
          {!item.is_done && (
            <>
              <span
                title={item?.name}
                className="no-underline cursor-pointer"
                style={{ color: getStatusColor(countStatus) }}
              >
                {(et.diff(st) < 30000000000 || alwaysShowLabel) &&
                  item?.record_id}{' '}
                ({item?.done_children}/
                {item?.done_children + item?.open_children})
              </span>
              <div
                style={{ zIndex: 17 }}
                onMouseEnter={(e) => onMouseEnter(e, item?.id)}
                onMouseLeave={onMouseLeave}
                className="py-1 -mr-3"
              >
                <MilestoneCanvasIcon item={item} />
              </div>
            </>
          )}
        </div>
      </div>
      {showLabel && selectedLabel === milestone.id ? (
        <div
          className={styleClass.floatLabel}
          style={{ left: showFloatLabel.left, top: showFloatLabel.top }}
        >
          <div>
            <span className={'float-left'}>
              <MilestoneCanvasIcon item={item} />
            </span>
            {milestone.name}
          </div>
          <div className={styleClass.floatLabelSmallText}>
            {milestone.main_process ? milestone.main_process.name : ''}
          </div>
          <div className={styleClass.floatLabelSmallText}>
            {`${capFirstLetter(t('discipline'))}:`}{' '}
            {item.discipline ? item.discipline.name : ''}
          </div>
          <div className={styleClass.floatLabelSmallText}>
            {`${capFirstLetter(t('responsible'))}:`}{' '}
            {item.responsible
              ? item.responsible.firstName + ' ' + item.responsible.lastName
              : ''}
          </div>
          <div className={styleClass.floatLabelSmallText}>
            {`${capFirstLetter(t('deadline'))}:`}{' '}
            {item.deadline ? moment(item.deadline).format('L') : ''}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default MilestoneTimelineProgressItem
