import { useTranslation } from 'react-i18next'
import KeypointInspectorPanel from 'src/components/key-point/KeyPointInspectorPanel'
import MilestoneInspectorPanel from 'src/components/milestones/MilestoneInspectorPanel'
import RiskInspectorPanel from 'src/components/risk/RiskInspectorPanel'
import TaskInspectorPanel from 'src/components/task/TaskInspectorPanel'
import { UpdateDependency } from 'src/service/OrgTypes'
import { IModalOrigin } from 'src/service/ProcessService'
import { capFirstLetter } from '../../../utility/utils'
import DeliveryInspectorPanel from '../delivery/DeliveryInspectorPanel'

interface IMainTableProps {
  closeModal: () => void
  showModal: boolean
  superItem: any
  projectId: number
  origin: MainTableModalOrigin
  onUpdate?: () => void
  updateDependency?: (itemType: 'K' | 'D', dependency: UpdateDependency) => void
  readonly?: boolean
}

export enum MainTableModalOrigin {
  GANTT,
  MAIN_TABLE,
  MAIN_PROCESS_TIMELINE,
}

const MainProcessModalHelper = ({
  closeModal,
  showModal,
  superItem,
  projectId,
  origin,
  onUpdate,
  readonly,
  updateDependency,
}: IMainTableProps) => {
  const { t } = useTranslation()
  const getModalOrigin = (): IModalOrigin => {
    switch (origin) {
      case MainTableModalOrigin.GANTT:
        return {
          name: capFirstLetter(t('gantt_chart')),
          url: '/gantt',
        }
      case MainTableModalOrigin.MAIN_PROCESS_TIMELINE:
        return {
          name: capFirstLetter(t('main_process')),
          url: '/main-process-canvas',
        }
      default:
        return {
          name: capFirstLetter(t('main_table_overview')),
          url: '/main-table',
        }
    }
  }

  const sharedProps = {
    open: showModal,
    onClose: closeModal,
    projectId: projectId,
    onUpdate: () => onUpdate?.(),
    readonly: readonly,
  } as const

  return superItem.data_type === 'MileStone' && superItem?.id ? (
    <MilestoneInspectorPanel
      {...sharedProps}
      milestoneId={superItem.id}
      origin={{
        name: t('mainprocess'),
        url: '/main-process-canvas',
      }}
      open={showModal}
      onClose={closeModal}
      onUpdate={() => onUpdate?.()}
    />
  ) : superItem.data_type === 'KeyPoint' && superItem.id ? (
    <KeypointInspectorPanel
      {...sharedProps}
      keyPointId={superItem.id}
      updateDependency={(d) => updateDependency?.('K', d)}
      origin={getModalOrigin()}
    />
  ) : superItem.data_type === 'Delivery' && superItem.id ? (
    <DeliveryInspectorPanel
      {...sharedProps}
      deliveryId={superItem.id}
      updateDependency={(d) => updateDependency?.('D', d)}
    />
  ) : superItem.data_type === 'Task' && superItem && superItem.id ? (
    <TaskInspectorPanel
      {...sharedProps}
      taskId={superItem.id}
      module={'planning'}
    />
  ) : superItem.data_type === 'Risk' && superItem && superItem.id ? (
    <RiskInspectorPanel {...sharedProps} riskId={superItem.id} />
  ) : null
}

export default MainProcessModalHelper
