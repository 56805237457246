import Close from '@icons/close.svg'
import KeyBoardArrowDown from '@icons/keyboard_arrow_down.svg'
import { isBoolean } from 'lodash'
import { PureComponent, ReactElement } from 'react'
import { withTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import IconButton from 'src/ui-elements/button/IconButton'
import Icon, { Icons } from '../../ui-elements/icon/Icon'
import Spinner from '../../ui-elements/loader/Spinner'
import TypeHead from '../../ui-elements/type-head/TypeHead'
import { capFirstLetter, classNames } from '../../utility/utils'
import { Direction } from '../multi-selector/MultiSelector'
import UserIcon from '../user/UserIcon'

const styleClass = {
  root: (noPadding: boolean) =>
    twMerge('flex', 'flex-col', 'w-full', !noPadding && 'px-2', 'relative'),
  label: (fontSize: string, fontWeight: string, mobile: boolean) =>
    classNames(
      'block',
      'font-medium',
      mobile ? 'text-xs' : 'text-sm',
      'leading-5',
      mobile ? 'text-gray-500' : 'text-gray-700',
      'my-2',
    ),
  selected: (
    hasError: boolean,
    inTopMenu: boolean,
    hasBorder: boolean,
    isDisabled: boolean,
  ) => {
    if (isDisabled)
      return classNames(
        isDisabled ? 'bg-gray-100' : 'bg-white',
        'cursor-not-allowed',
        'text-gray-700',
      )
    return classNames(
      'bg-white',
      'text-gray-700',
      'hover:bg-gray-100',
      'hover:text-gray-900',
      'focus:bg-gray-100',
      'focus:text-gray-900',
      inTopMenu && 'bg-white',
      inTopMenu && 'text-gray-900',
      hasError && 'border-red-300',
      inTopMenu && 'border-none',
      hasBorder ? 'border-gray-300' : 'border-none',
      'cursor-pointer',
    )
  },
  selectedContainer: (
    inTopMenu: boolean,
    bg: string,
    hasBorder: boolean,
    isMobile: boolean,
    color?: string,
  ) =>
    classNames(
      'flex',
      'flex-row',
      !isMobile ? 'form-selector' : 'h-10',
      !inTopMenu ? 'justify-between' : 'justify-start',
      'items-center',
      'overflow-hidden',
      'whitespace-nowrap',
      'p-2',
      'text-sm',
      'leading-5',
      !inTopMenu && isMobile ? 'border border-gray-300' : '',
      !hasBorder && 'border-none',
      color
        ? `hover:bg-${color}${color !== 'green' ? '-100' : ''}`
        : 'hover:bg-gray-100',
      color ? 'bg-' + color + '-400' : bg && `bg-${bg}`,
      color ? 'text-' + color + '-900' : 'text-gray-700',
      'rounded-md',
    ),
  nameContainerUnselected: (
    inTopMenu: boolean,
    hasError: boolean,
    bg: string,
    hasBorder: boolean,
    isMobile: boolean,
  ) =>
    classNames(
      'flex',
      'text-gray-400',
      'truncate',
      'font-normal',
      'flex-row',
      'items-center',
      'text-sm',
      'p-2',
      !hasBorder && 'border-none',
      inTopMenu
        ? 'border-none'
        : isMobile
          ? ''
          : 'border border-gray-300 focus:border-blue-seven',
      hasError &&
        'border-red-300 text-red-900 placeholder-red-300 focus:border-red-300',
      hasError ? 'justify-end' : 'justify-between',
      bg && `bg-${bg}`,
    ),
  errorMessage: (hideLabel: boolean, mobile: boolean) =>
    classNames(
      'text-red-600',
      hideLabel ? 'mb-1' : 'my-2',
      'ml-2',
      mobile ? 'text-xs' : 'text-sm',
    ),
  selectIcon: classNames('mt-1', 'h-6', 'w-auto', 'inline-flex', 'pl-1'),
  placeholder: (hasError: boolean) =>
    classNames(
      hasError ? 'text-red-300' : 'text-gray-400',
      'font-normal',
      'text-sm',
    ),
}

interface ISelectorProps {
  onSelect: (id: number | string | any) => void
  items: any[]
  selectedItemId: number | string | null | undefined
  renderItem?: (item: string) => ReactElement
  onOpenSelectFunction?: () => void
  label: string
  dataFields: any[]
  disabled?: boolean
  fontSize?: string
  fontWeight?: string
  required?: boolean
  errorMessage?: string
  icon?: Icons
  userSelector?: boolean
  loading?: boolean
  cancelButton?: boolean
  hasBorder?: boolean
  onCancel?: () => void
  border?: boolean
  showIcon?: boolean
  hidelabel?: boolean
  inTopMenu?: boolean
  bgColor?: string
  isCleared?: boolean
  inMobile?: boolean
  languageSelector?: boolean
  t: any
  openByDefault?: boolean
  typeHeadOpen?: (edit: boolean) => void
  onlyPlaceholder?: boolean
  openDirection?: Direction
  noPadding?: boolean
  // Deprecated props. Remove when using
  scroll?: boolean
}

interface ISelectorState {
  edit: boolean
  isFirstSelect: boolean
}

class Selector extends PureComponent<ISelectorProps, ISelectorState> {
  constructor(props: ISelectorProps) {
    super(props)
    this.state = {
      edit: props.openByDefault ? props.openByDefault : false,
      isFirstSelect: true,
    }
  }

  public componentDidUpdate(prevProps: ISelectorProps) {
    if (
      prevProps.isCleared !== this.props.isCleared &&
      this.props.isCleared === false
    ) {
      this.setState({
        isFirstSelect: false,
      })
    }
  }

  private toggleEdit = () => {
    if (this.props.disabled) {
      return
    }
    this.setState((prevState) => ({
      edit: !prevState.edit,
    }))

    if (
      this.props.onOpenSelectFunction &&
      (((this.props.items.length === 1 || this.props.items.length === 0) &&
        this.state.isFirstSelect) ||
        this.props.isCleared)
    ) {
      this.props.onOpenSelectFunction()
      this.setState({ isFirstSelect: false })
    }
    this.props.typeHeadOpen?.(this.state.edit)
  }

  private onItemClick = (item: any) => {
    this.props.onSelect(item.id)
    this.toggleEdit()
  }

  private renderIcon = () => {
    const { icon } = this.props
    return icon ? (
      <Icon
        icon={icon}
        style={{ width: 18, height: 18, marginTop: 2 }}
        className={'mr-2'}
      />
    ) : null
  }

  private renderImage = (item: any) => {
    return (
      <UserIcon
        userId={item[0].id}
        firstName={''}
        lastName={''}
        large={false}
        image_url={item[0].image_url}
      />
    )
  }

  private getItem = () => {
    const {
      items,
      label,
      selectedItemId,
      dataFields,
      userSelector,
      cancelButton,
      onCancel,
      inTopMenu = false,
      bgColor = '',
      inMobile = false,
      onlyPlaceholder,
      renderItem,
      t,
    } = this.props
    const showIcon: boolean = this.props.showIcon ? this.props.showIcon : true
    const currentItem: any[] = items.filter(
      (item: any) => item.id === selectedItemId,
    )
    const hasError: boolean =
      this.props.errorMessage && this.props.errorMessage !== '' ? true : false
    const hasBorder: boolean = this.props.hasBorder === false ? false : true

    if (currentItem.length > 0) {
      let itemName = ''
      dataFields.map((d: any) => {
        itemName += isBoolean(currentItem[0][d])
          ? currentItem[0][d]
            ? `(${t(d)}) `
            : ` `
          : `${currentItem[0][d]} `
      })

      return (
        <span
          style={inMobile ? this.mobileStyles : {}}
          className={styleClass.selectedContainer(
            inTopMenu || false,
            bgColor || '',
            hasBorder,
            inMobile,
            currentItem[0]?.trainColor,
          )}
        >
          <div className={'flex flex-row flex-nowrap items-center'}>
            {userSelector ? (
              <div className={'mr-2'}>{this.renderImage(currentItem)}</div>
            ) : (
              this.renderIcon()
            )}
            <div className={'truncate'}>
              {renderItem
                ? renderItem(itemName)
                : cancelButton && itemName.length > 20
                  ? itemName.substring(0, 15)
                  : itemName}
            </div>
          </div>
          <div className={'flex items-center'}>
            {cancelButton && onCancel && (
              <IconButton
                onClick={() => {
                  onCancel()
                  this.setState({ isFirstSelect: true })
                }}
                onMouseDown={(e) => {
                  e.preventDefault()
                }}
                icon={<Close className="fill-gray-500" />}
              />
            )}
            {showIcon && (
              <KeyBoardArrowDown className="w-6 h-6 fill-gray-500" />
            )}
          </div>
        </span>
      )
    }
    return (
      <div>
        <span
          style={inMobile ? this.mobileStyles : { maxHeight: 34 }}
          className={styleClass.nameContainerUnselected(
            inTopMenu,
            hasError,
            bgColor,
            hasBorder,
            inMobile,
          )}
        >
          {onlyPlaceholder && !hasError && (
            <span className={styleClass.placeholder(hasError)}>
              {capFirstLetter(label)}
            </span>
          )}
          {!onlyPlaceholder && label && !hasError && (
            <span className={styleClass.placeholder(hasError)}>
              {capFirstLetter(t('select'))} {label.toLowerCase()}
            </span>
          )}
          {showIcon && <KeyBoardArrowDown className="w-6 h-6 fill-gray-500" />}
        </span>
      </div>
    )
  }

  private mobileStyles = {
    maxHeight: 34,
    fontSize: 13,
    borderRadius: 8,
    borderColor: '#E7ECF3',
    borderWidth: 1,
  }

  public render() {
    const { edit } = this.state
    const {
      items,
      label,
      selectedItemId,
      dataFields,
      fontSize,
      fontWeight,
      required,
      errorMessage,
      icon,
      loading,
      inMobile = false,
      t,
      openDirection,
    } = this.props
    const hidelabel: boolean = this.props.hidelabel
      ? this.props.hidelabel
      : false
    const inTopMenu: boolean = this.props.inTopMenu
      ? this.props.inTopMenu
      : false
    const disabled: boolean = this.props.disabled ? this.props.disabled : false
    const hasError: boolean = errorMessage && errorMessage !== '' ? true : false
    const hasBorder: boolean = this.props.hasBorder === false ? false : true
    const placeholder = hidelabel
      ? t('search')
      : t('search_in') + ' ' + label.toLocaleLowerCase()
    const filterItems = items.map((item) => {
      return item
    })
    let sortedItems = filterItems
    const currentItem: any[] = items.filter(
      (item: any) => item.id === selectedItemId,
    )
    if (currentItem.length > 0) {
      sortedItems = sortedItems.sort((x, y) =>
        x.id === selectedItemId ? -1 : y.id === selectedItemId ? 1 : 0,
      )
    }
    return (
      <div className={styleClass.root(this.props.noPadding ?? false)}>
        <div className={'flex flex-row items-center'}>
          {!hidelabel && label && (
            <p
              className={styleClass.label(
                fontSize ? fontSize : 'sm',
                fontWeight ? fontWeight : 'normal',
                inMobile,
              )}
            >
              {capFirstLetter(label)}
              {required ? ' *' : ''}
            </p>
          )}
          {hasError && (
            <span className={styleClass.errorMessage(hidelabel, inMobile)}>
              {errorMessage}
            </span>
          )}
        </div>
        {!edit || disabled ? (
          <div
            className={styleClass.selected(
              hasError,
              inTopMenu,
              hasBorder ? true : false,
              disabled,
            )}
            onClick={(e) => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
              this.toggleEdit()
            }}
            tabIndex={0}
            onFocus={this.toggleEdit}
          >
            {this.getItem()}
          </div>
        ) : (
          <>
            {loading ? (
              <div className={'flex justify-center items-center'}>
                <Spinner />
              </div>
            ) : (
              <TypeHead
                data={sortedItems}
                userSelector={this.props.userSelector}
                placeholder={placeholder}
                dataFields={dataFields}
                icon={
                  icon ? (
                    <Icon
                      icon={icon}
                      style={{ width: 18, height: 18, marginRight: 5 }}
                    />
                  ) : undefined
                }
                autoFocus={true}
                onBlur={() => this.setState({ edit: false })}
                onSelect={this.onItemClick}
                hasSelectedItem={currentItem.length > 0}
                inMobile={inMobile}
                openDirection={openDirection}
              />
            )}
          </>
        )}
      </div>
    )
  }
}

export default withTranslation()(Selector)
