import { t } from 'i18next'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import UploadSummary, {
  UploadedFiles,
} from 'src/document/components/FileUpload/fix-upload/UploadSummary'
import FileUploadModal from '../FileUploadModal'
import { NEXT_STEP } from '../types'

export const validateFiles = (
  files: { name: string }[],
): { error: boolean; message: string } => {
  const illegalChars = /\"|\'/
  if (files.some((file) => file.name.match(illegalChars)))
    return { error: true, message: t('illegal_filename_cannot_contain') }
  return { error: false, message: '' }
}

interface FoldersFileUploadModalProps {
  folderId: number
  show: boolean
  close: () => void
  revisionUpload: boolean
  helpText?: string
}

const FolderFilesUpload: FC<FoldersFileUploadModalProps> = ({
  folderId,
  show,
  close,
  revisionUpload = false,
  helpText,
}) => {
  const [data, setRes] = useState<UploadedFiles>()

  const { t } = useTranslation()

  const steps = !revisionUpload
    ? [<UploadSummary uploadedFiles={data} key={1} folderId={folderId} />]
    : []

  return (
    <FileUploadModal
      multiple={true}
      title={t('upload_files_to_documents')}
      showStepCount={true}
      uploadUrl={
        revisionUpload
          ? `file_containers/${folderId}/project_files`
          : `folders/${folderId}/upload_file`
      }
      handleNext={async (_, data) => {
        setRes(data)
        return NEXT_STEP.CONTINUE
      }}
      stepElements={steps}
      show={show}
      close={close}
      validate={validateFiles}
      helpText={helpText}
    />
  )
}

export default FolderFilesUpload
