import 'react-toggle/style.css'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import UploadFile from 'src/components/document/UploadFile'
import useProjectId from 'src/components/hooks/useProjectId'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { useDocumentFortype } from 'src/query/document'
import { deleteDocument } from 'src/service/DocumentService'
import {
  downloadOrOpenDocument,
  getOrganizationBaseUrl,
} from 'src/utility/utils'
import UserIcon from '../user/UserIcon'

interface ICommentProps {
  parentId: number
  parentType: string
  disabled?: boolean
  inMobile?: boolean
  readonly?: boolean
  inInspector?: boolean
}

const AttachmentsInspectorSectionTableWithUpload = ({
  parentId,
  parentType,
  disabled = false,
  inMobile = true,
  readonly = false,
  inInspector = true,
}: ICommentProps) => {
  const { t } = useTranslation()
  const projectId = useProjectId()

  const {
    data: attachments,
    refetch: refetchAttachments,
    isPending,
    error,
  } = useDocumentFortype(parentType, parentId)

  if (error) return null

  return (
    <div className="flex flex-col gap-4 mt-4">
      {!readonly && (
        <UploadFile
          inMobile={inMobile}
          disable={disabled}
          uploadUrl={`${getOrganizationBaseUrl()}/projects/${projectId}/parents/${parentType}/${parentId}/documents`}
          uploadedDocuments={() => refetchAttachments()}
          singleFileUpload={false}
          inInspector={inInspector}
        />
      )}
      <InspectorSectionTable
        loading={isPending}
        headerColumns={[t('file_name'), t('created_by'), t('created_at')]}
        rowsData={(attachments || []).map((attachment) => {
          return {
            id: attachment?.id,
            cells: [
              <a
                key={attachment.id}
                className="underline text-blue-root cursor-pointer"
                onClick={() => downloadOrOpenDocument(attachment)}
                target="_blank"
                rel="noopener noreferrer"
              >
                {attachment.fileName}
              </a>,
              <UserIcon
                key={attachment.user?.id}
                firstName={attachment.user?.firstName}
                lastName={attachment.user?.lastName}
                image_url={attachment.user?.image_url}
              />,
              attachment?.created_at
                ? moment(attachment?.created_at).format('L')
                : '',
            ],
          }
        })}
        actionMenu={{
          icon: 'delete',
          title: t('delete'),
          onClick: async (id: number) => {
            await deleteDocument(projectId, id)
            refetchAttachments()
          },
          show: (id: number) =>
            attachments?.find((a) => a.id === id)?.delete_access === true,
          className: 'text-red',
        }}
      />
    </div>
  )
}

export default AttachmentsInspectorSectionTableWithUpload
