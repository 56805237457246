import Cancel from '@icons/cancel-fill.svg'
import CheckBox from '@icons/check_box-fill.svg'
import CheckBoxOutline from '@icons/check_box_outline_blank.svg'
import { capitalize } from 'lodash'
import {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import CloseClickOutside from 'src/components/click-outside/CloseClickOutside'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import DropdownArrowIcon from 'src/document/icons/DropdownArrowIcon'
import Spinner from 'src/ui-elements/loader/Spinner'
import { getRandomId } from 'src/utility/getRandomId'
import {
  IBaseDropDownItem,
  IDropdown,
  IInlineBaseProps,
  TDropDownType,
} from './IDropDown'
import InlineBaseComponent from './InlineBaseComponent'
import InlineBorderComponent from './InlineBorderComponent'
import InlineErrorMessageComponent from './InlineErrorMessageComponent'
import InlineInputLabelComponent from './InlineInputLabelComponent'

interface IMultiSelectorInlineInputComponent<
  T extends TDropDownType,
  H extends IBaseDropDownItem<TDropDownType>,
> extends IInlineBaseProps {
  getItems?: () => Promise<H[]>
  items?: H[]
  getItemLabel?: (item: H) => string
  getDropDownLabel?: (item: H) => string | JSX.Element
  selectedIds: T[]
  onValueSubmitted: (newValue?: T[]) => void
  submitOnChange?: boolean
  validate?: (newValue?: T[]) => string | undefined
  initialItems?: H[]
  cancelButton?: boolean
  search?: boolean
  placeholder?: string
  inline?: boolean
  selectAll?: boolean
}

const MultiSelectorInlineInputComponent = <
  T extends TDropDownType,
  H extends IBaseDropDownItem<T> = { id?: T },
>({
  getItems,
  getItemLabel,
  getDropDownLabel,
  items = [],
  label,
  selectedIds,
  validate,
  onValueSubmitted,
  labelWidth,
  inputWidth,
  labelTextSize,
  initialItems,
  disabled = false,
  cancelButton,
  disableTooltip,
  submitOnChange,
  search = true,
  placeholder,
  inline = true,
  selectAll = false,
}: IMultiSelectorInlineInputComponent<T, H>) => {
  const [allItems, setAllItems] = useState<IDropdown<T>[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [internalSelectedIds, setInternalSelectedIds] = useState(
    new Set(selectedIds),
  )
  const internalSelectedIdsRef = useRef(internalSelectedIds)
  const [isFocusing, setIsFocusing] = useState(false)
  const isFocusingRef = useRef(isFocusing)
  const [errorMessage, setErrorMessage] = useState('')
  const [internalItems, setInternalItems] = useState<IDropdown<T>[]>([])
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  const [uniqueId] = useState(() => getRandomId())

  const [isLoading, setIsLoading] = useState(false)
  const hasInteracted = useRef(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const { t } = useTranslation()

  const selectedValues = useMemo(() => {
    const res: IDropdown<T>[] = []

    for (const value of allItems) {
      if (internalSelectedIds.has(value.id)) {
        res.push(value)
      }
    }

    return res
  }, [allItems, internalSelectedIds])

  const internalValues = useMemo((): {
    message: JSX.Element
    values: string[]
  } => {
    if (!getItemLabel) return { message: <div />, values: [] }

    let values = initialItems?.map((item) => getItemLabel(item)) ?? []
    let message = (
      <span className="text-[#9DA3AE]">{placeholder ?? t('____')}</span>
    )

    if (allItems?.length) {
      values = []
      for (const value of selectedValues) {
        values.push(value.name)
      }
    }

    if (values.length === 1) {
      message = <span>{values[0]}</span>
    } else if (values.length > 1) {
      message = <span>{values.join(', ')}</span>
    }
    return { message, values }
  }, [t, selectedValues, initialItems, allItems, getItemLabel, placeholder])

  const filterMatchingItems = useCallback(
    (filter: string) => {
      const lowerCaseFilter = filter.toLocaleLowerCase()
      setSearchTerm(filter)
      const tmpItems = [...allItems]
      if (lowerCaseFilter.length && lowerCaseFilter !== '') {
        const filteredItems = tmpItems?.filter((value) =>
          value.name.toLowerCase().includes(lowerCaseFilter),
        )
        setInternalItems(filteredItems)
      } else {
        setInternalItems(tmpItems)
      }
    },
    [allItems],
  )

  const buildDropdownItems = useCallback(
    (items: H[]) => {
      if (!getItemLabel) return []

      const res: IDropdown<T>[] = []

      for (const item of items) {
        res.push({
          id: item.id as T,
          name: getItemLabel(item) ?? '',
          label: getDropDownLabel?.(item) ?? getItemLabel(item),
        })
      }

      return res
    },
    [getItemLabel],
  )

  const getItemsInternal = useCallback(async () => {
    if (!getItems) return

    const items = await getItems()

    const res = buildDropdownItems(items)

    setAllItems(res)
    setInternalItems(res)
  }, [getItems, buildDropdownItems])

  const save = useCallback(() => {
    if (validate) {
      const errorMessage = validate([
        ...internalSelectedIdsRef.current.values(),
      ])
      if (errorMessage?.length) {
        setErrorMessage(errorMessage)
        return
      }
    }
    onValueSubmitted([...internalSelectedIdsRef.current.values()])
  }, [onValueSubmitted, validate])

  useEffect(() => {
    setAllItems(buildDropdownItems(items))
    setInternalSelectedIds(new Set(selectedIds))
    internalSelectedIdsRef.current = new Set(selectedIds)
  }, [selectedIds, submitOnChange, save])

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHighlightedIndex(0)
    const newValue = event.target.value
    filterMatchingItems(newValue)
    setErrorMessage('')
  }

  const selectAllOptions = () => {
    const tmpSelectedIds = new Set(
      internalItems.map((internalItem) => internalItem.id),
    )
    setInternalSelectedIds(tmpSelectedIds)
    internalSelectedIdsRef.current = tmpSelectedIds

    submitOnChange && save()
  }

  const addInternalSelectedId = (newId: T) => {
    const tmpSelectedIds = new Set(internalSelectedIds)
    tmpSelectedIds.add(newId)
    setInternalSelectedIds(tmpSelectedIds)
    internalSelectedIdsRef.current = tmpSelectedIds

    submitOnChange && save()

    return tmpSelectedIds
  }

  const removeInternalSelectedId = (id: T) => {
    const tmpSelectedIds = new Set(internalSelectedIds)
    tmpSelectedIds.delete(id)
    setInternalSelectedIds(tmpSelectedIds)
    internalSelectedIdsRef.current = tmpSelectedIds

    submitOnChange && save()

    return tmpSelectedIds
  }

  const onSelectedItem = (id: T) => {
    if (internalSelectedIds.has(id)) {
      removeInternalSelectedId(id)
    } else {
      addInternalSelectedId(id)
    }
  }

  const scrollToElement = useCallback(
    (highlightedIndex: number) => {
      const divIdToShow = `${uniqueId}-${highlightedIndex}`
      const element = document.getElementById(divIdToShow)
      element?.scrollIntoView({
        block: 'nearest',
      })
    },
    [uniqueId],
  )

  useEffect(() => {
    scrollToElement(highlightedIndex)
  }, [scrollToElement, highlightedIndex])

  const setNewHiglightedIndex = (newIndex: number) => {
    setHighlightedIndex(newIndex)
  }

  const downButton = () => {
    if (!internalItems.length) return
    const newHiglighted = highlightedIndex + 1
    if (newHiglighted < internalItems.length) {
      setNewHiglightedIndex(newHiglighted)
    }
  }

  const upButton = () => {
    if (!internalItems.length) return
    const newHiglighted = highlightedIndex - 1
    if (newHiglighted > -1) {
      setNewHiglightedIndex(newHiglighted)
    }
  }

  const enterButton = () => {
    if (!isFocusing) {
      openMenu()
      return
    }
    if (highlightedIndex === undefined) return
    const higlightedItem = internalItems[highlightedIndex]
    if (!higlightedItem) return
    onSelectedItem(higlightedItem.id)
  }

  const openMenu = async (_e?: any) => {
    if (disabled) return
    hasInteracted.current = true
    setIsFocusing(true)
    isFocusingRef.current = true
    setSearchTerm('')
    filterMatchingItems('')
    if (items.length) {
      const builtItems = buildDropdownItems(items)
      setInternalItems(builtItems)
      setAllItems(builtItems)
      return
    }
    setIsLoading(true)
    await getItemsInternal()
    setIsLoading(false)
    inputRef?.current?.focus()
  }

  const clearSelection = () => {
    setInternalSelectedIds(new Set())
    internalSelectedIdsRef.current = new Set()
    if (!isFocusingRef.current) {
      save()
    }
  }

  useDidMountEffect(() => {
    if (!isFocusingRef.current) {
      save()
    }
  }, [isFocusing])
  const allItemsSelected = !internalItems.some(
    (item) => !internalSelectedIds.has(item.id),
  )

  return (
    <InlineBaseComponent>
      {label && (
        <InlineInputLabelComponent
          label={label}
          labelWidth={labelWidth}
          labelTextSize={labelTextSize}
          disableTooltip={disableTooltip}
        />
      )}
      <InlineErrorMessageComponent errorMessage={errorMessage} />
      <InlineBorderComponent
        cursor="cursor-pointer"
        errorMessage={errorMessage}
        isFocusing={isFocusing || !inline}
        disabled={disabled}
      >
        <CloseClickOutside
          onClose={() => {
            setIsFocusing(false)
            isFocusingRef.current = false
          }}
        >
          <div className={`${inputWidth ?? 'w-full'} relative`}>
            <div
              className="flex px-1 justify-between items-center w-full"
              onClick={(e) => {
                e.stopPropagation()
                if (!isFocusingRef.current) {
                  openMenu(e)
                } else {
                  setIsFocusing(false)
                  isFocusingRef.current = false
                }
              }}
            >
              <div
                className={`overflow-hidden text-ellipsis whitespace-nowrap h-6 ${
                  disabled ? 'bg-white  cursor-not-allowed' : ''
                }`}
                tabIndex={disabled ? -1 : 0}
              >
                {internalValues.message}
              </div>
              <div
                className={twMerge(
                  'group-hover:flex items-center hidden',
                  !inline && 'flex',
                )}
              >
                {cancelButton && internalItems ? (
                  <Cancel
                    className={
                      'cursor-pointer fill-gray-700 text-sm mx-0.5 self-center hover:fill-blue-root-focus'
                    }
                    onClick={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                      clearSelection()
                    }}
                  />
                ) : null}
                <DropdownArrowIcon isOpen={isFocusing} onClick={() => null} />
              </div>
            </div>
            {isFocusing && (
              <div
                className={`absolute mt-1 z-50 bg-white shadow-xl ${inputWidth ?? 'w-96'} p-1 rounded max-h-[300px] overflow-y-auto border border-gray-300`}
              >
                {isLoading ? (
                  <Spinner />
                ) : (
                  <>
                    {search && (
                      <div className="flex justify-between py-1 px-1 cursor-default border border-gray-300 rounded">
                        <input
                          ref={inputRef}
                          className="overflow-hidden text-ellipsis whitespace-nowrap w-full disabled:bg-white disabled:cursor-not-allowed"
                          value={searchTerm}
                          placeholder={t('search')}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'ArrowDown') {
                              e.preventDefault()
                              downButton()
                            } else if (e.key === 'ArrowUp') {
                              e.preventDefault()
                              upButton()
                            } else if (e.key === 'Escape') {
                              setIsFocusing(false)
                              isFocusingRef.current = false
                            }
                          }}
                          onKeyPressCapture={(e) => {
                            if (e.key === 'Enter') {
                              enterButton()
                            }
                          }}
                          onChange={onChange}
                        />
                      </div>
                    )}
                    {selectAll && (
                      <div key="selectAll">
                        <div
                          className={`hover:bg-gray-100 p-1 mt-1 rounded flex items-center ${
                            allItemsSelected
                              ? 'bg-blue-100'
                              : 'border-transparent'
                          } border flex`}
                          onClick={(e) => {
                            allItemsSelected
                              ? clearSelection()
                              : selectAllOptions()
                            e.nativeEvent.stopImmediatePropagation()
                            e.stopPropagation()
                            e.preventDefault()
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {allItemsSelected ? (
                            <CheckBox className="fill-blue-root text-xl" />
                          ) : (
                            <CheckBoxOutline className="text-xl" />
                          )}
                          <div className="whitespace-nowrap text-ellipsis overflow-hidden w-50 cursor-pointer ml-2">
                            {capitalize(
                              allItemsSelected
                                ? t('clear_selection')
                                : t('select_all'),
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {internalItems.map((item, index) => (
                      <div
                        id={`${uniqueId}-${index}`}
                        key={`${uniqueId}-${index}`}
                      >
                        <div
                          className={`hover:bg-gray-100 p-1 mt-1 rounded flex items-center ${
                            internalSelectedIds.has(item.id)
                              ? 'bg-blue-100'
                              : 'border-transparent'
                          } border flex`}
                          onClick={(e) => {
                            onSelectedItem(item.id)
                            e.nativeEvent.stopImmediatePropagation()
                            e.stopPropagation()
                            e.preventDefault()
                          }}
                          onMouseDown={(e) => e.preventDefault()}
                        >
                          {internalSelectedIds.has(item.id) ? (
                            <CheckBox className="fill-blue-root text-xl" />
                          ) : (
                            <CheckBoxOutline className="text-xl" />
                          )}
                          <div className="whitespace-nowrap text-ellipsis overflow-hidden w-50 cursor-pointer ml-2">
                            {item.label}
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            )}
          </div>
        </CloseClickOutside>
      </InlineBorderComponent>
    </InlineBaseComponent>
  )
}

export default MultiSelectorInlineInputComponent
