import { DateHeader } from '@taskctrl/react-calendar-timeline'
import moment from 'moment/moment'
import { useState } from 'react'
import CustomMonthHeader, {
  QuarterHeader,
} from 'src/components/gantt/CustomMonthHeader'

const TimeScaleTimelineHeaders = ({
  zoom,
  zoomLevel,
}: {
  zoom: 'month' | 'week' | 'quarter' | 'project'
  zoomLevel: number
}) => {
  const [showWeek, setShowWeek] = useState(true)
  return (
    <div className={'text-sm capitalize text-gray-500 whitespace-nowrap'}>
      <DateHeader unit="year" labelFormat="YYYY" />
      {zoom === 'quarter' ? (
        <QuarterHeader />
      ) : (
        moment.duration(zoomLevel).years() < 4 && (
          <CustomMonthHeader setShowWeek={setShowWeek} />
        )
      )}
      {showWeek && moment.duration(zoomLevel).years() < 1 && (
        <DateHeader unit="week" labelFormat="w" />
      )}
    </div>
  )
}

export default TimeScaleTimelineHeaders
