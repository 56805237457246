import { FC, MouseEvent, PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SelectFileContainersList from 'src/page/system/SelectFileContainerList'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

interface ISelectFileContainersListProps {
  tableName?: string
  selectedFileContainerIds?: number[]
  onSelectFileContainers?: (ids: number[]) => void
  closeModal: () => void
  selectSingle?: boolean
  initialFilter?: Omit<TableFilter, 'sort'>
}

const SelectFileContainersModal: FC<
  PropsWithChildren<ISelectFileContainersListProps>
> = ({
  tableName,
  selectedFileContainerIds,
  onSelectFileContainers,
  closeModal,
  selectSingle = false,
  initialFilter,
  children,
}) => {
  const [selectedFileContainers, setSelectedFileContainers] = useState<
    number[]
  >([...(selectedFileContainerIds ?? [])])

  const { t } = useTranslation()

  const onSubmit = (e: MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    onSelectFileContainers?.(selectedFileContainers)
  }

  return (
    <Modal
      show={true}
      title={t('select_items_you_want_to_add_w_param', {
        param: t('document'),
      })}
      closeModal={closeModal}
      size={'w-9/10'}
      maxWidth={1700}
    >
      <div className={'w-full flex px-2 pb-4 flex-col min-h-[48vh]'}>
        <SelectFileContainersList
          selectedFileContainerIds={selectedFileContainerIds}
          tableName={tableName}
          initialFilter={initialFilter}
          selectSingle={selectSingle}
          onSelectFileContainers={
            selectSingle ? onSelectFileContainers : setSelectedFileContainers
          }
        >
          {children}
        </SelectFileContainersList>
      </div>

      {!selectSingle && (
        <ModalFooter>
          <Button onClick={closeModal}>{t('cancel')}</Button>
          <Button type={Button.ButtonType.PRIMARY} onClick={onSubmit}>
            {t('save')}
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default SelectFileContainersModal
