import moment, { Moment } from 'moment/moment'
import { useMemo, useContext } from 'react'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'

export type ZoomLevel = 'week' | 'month' | 'quarter' | 'project'

export interface CanvasTime {
  start: Moment
  end: Moment
}

export const useTimelineZoomLevels = (zoom: ZoomLevel) => {
  const { state: projectState } = useContext(ProjectContext)
  const { currentProject } = projectState
  const { startDate, endDate } = currentProject

  const zoomLevels = useMemo(
    () => ({
      week: moment.duration(2, 'month').as('milliseconds'),
      month: moment.duration(1, 'year').as('milliseconds'),
      quarter: moment.duration(2, 'year').as('milliseconds'),
      project:
        !!endDate && !!startDate
          ? moment
              .duration(moment(endDate).diff(moment(startDate)))
              .as('milliseconds')
          : moment.duration(2, 'year').as('milliseconds'),
    }),
    [projectState],
  )

  const zoomLevel = useMemo(() => zoomLevels[zoom], [zoomLevels, zoom])

  const defaultTimeStart = moment().startOf('month')
  const defaultTimeEnd = useMemo(
    () => moment().add(zoomLevel, 'milliseconds'),
    [zoomLevel],
  )

  return {
    zoomLevel,
    defaultTimeStart,
    defaultTimeEnd,
  }
}
