import { ChangeEventHandler } from 'react'
import { twMerge } from 'tailwind-merge'
import { getRandomId } from 'src/utility/getRandomId'

interface ICheckboxWLabelProps {
  name: string
  value: boolean
  labelRight?: boolean
  onChange?: ChangeEventHandler<HTMLInputElement>
  disabled?: boolean
}

const CheckboxWithLabel = ({
  name,
  value,
  labelRight = false,
  onChange,
  disabled,
}: ICheckboxWLabelProps) => {
  const randomId = getRandomId()
  return (
    <div className="flex flex-row px-2 my-1 cursor-pointer">
      {!labelRight && (
        <label
          htmlFor={randomId}
          className={twMerge(
            'pr-2',
            'font-medium',
            disabled ? 'text-grey-dark' : 'text-gray-700',
          )}
        >
          {name}
        </label>
      )}
      <input
        type="checkbox"
        checked={value}
        className="!accent-[unset] after:!bg-white after:!hidden"
        onChange={onChange}
        readOnly={!onChange}
        id={randomId}
        disabled={disabled}
      />
      {labelRight && (
        <label
          htmlFor={randomId}
          className={twMerge(
            'pl-2',
            'font-medium',
            disabled ? 'text-grey-dark' : 'text-gray-700',
          )}
        >
          {name}
        </label>
      )}
    </div>
  )
}

export default CheckboxWithLabel
