import Download from '@icons/download.svg'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import { IFileContainer } from 'src/document/types/IFileContainer'
import useBackgroundJob from 'src/hooks/useBackgroundJob'
import { downloadMultiple } from 'src/service/FileContainerService'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import ButtonWithDropdownMenu from 'src/ui-elements/button/ButtonWithDropdownMenu'
import useAlert from 'src/ui-elements/toast/useAlert'
import { openFileExternal } from 'src/utility/utils'

interface FileContainerListDownloadButtonProps {
  selectedDocuments: IFileContainer[]
}
const FileContainerListDownloadButton = ({
  selectedDocuments,
}: FileContainerListDownloadButtonProps) => {
  const { t } = useTranslation()
  const { addAlert } = useAlert()
  const projectId = useProjectId()

  const { setTaskId, task, taskComplete } = useBackgroundJob('download')

  useEffect(() => {
    if (taskComplete) {
      openFileExternal(task?.data?.download_url)()
    }
  }, [task, taskComplete])

  const downloadFiles = async (lastRevisionOnly: boolean) => {
    const fileContainerIds = selectedDocuments.map(({ id }) => id)

    const downloadJob = await downloadMultiple(
      projectId,
      fileContainerIds,
      lastRevisionOnly,
    )

    setTaskId(downloadJob.id)

    addAlert({
      type: 'info',
      title: t('download_preparing'),
      description: t('download_preparing_description'),
    })
  }

  const downloadProjectFiles = () => {
    const projectFiles = selectedDocuments.flatMap(
      (doc) => doc?.project_files ?? [],
    )
    if (!projectFiles.length) {
      addAlert({ type: 'warning', description: t('no_files_found') })
      return
    }
    downloadFiles(false)
  }

  const downloadRevisionFiles = () => {
    const revisionFiles = selectedDocuments.flatMap(
      (doc) => doc?.current_revision?.project_files ?? [],
    )
    if (!revisionFiles.length) {
      addAlert({ type: 'warning', description: t('no_revision_files_found') })
      return
    }
    downloadFiles(true)
  }

  return (
    <ButtonWithDropdownMenu
      items={[
        {
          icon: <Download />,
          title: t('files'),
          onClick: downloadProjectFiles,
        },
        {
          icon: <Download />,
          title: t('revision_files'),
          onClick: downloadRevisionFiles,
        },
      ]}
      type={ButtonType.BOUNDARY_WHITE}
      disabled={!selectedDocuments.length}
      toolTipText={
        !selectedDocuments.length ? t('no_documents_selected') : undefined
      }
    >
      <Download className={'text-xl'} />
    </ButtonWithDropdownMenu>
  )
}

export default FileContainerListDownloadButton
