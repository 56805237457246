import 'react-toggle/style.css'
import Delete from '@icons/delete-fill.svg'
import Edit from '@icons/edit-fill.svg'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LinkfyText from 'src/components/linkfyText/LinkfyText'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { FileUploadContext } from 'src/document/components/FileUpload/context/FileUploadContext'
import { UPLOAD_STATE } from 'src/document/components/FileUpload/types'
import { useToggleCommentExportVisbility } from 'src/query/comment'
import { deleteComment } from 'src/service/CommentService'
import { IComment } from 'src/service/OrgTypes'
import IconButton from 'src/ui-elements/button/IconButton'
import CheckboxWithLabel from 'src/ui-elements/checkbox/CheckboxWithLabel'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { formatDateRelativeToNow } from 'src/utility/dateUtils'
import { classNames } from 'src/utility/utils'
import CommentFilesList, { CommentFileItem } from './CommentFilesList'
import CommentWrapper from './CommentWrapper'
import EditComment from './EditComment'

interface CommentItemProps {
  comment: IComment
  disabled?: boolean
  readonly?: boolean
  refetchComments: () => void
  onCommentDeleted?: () => void
  includeExportVisiblity?: boolean
  canToggleExportVisibility?: boolean
}

const CommentItem = ({
  comment,
  disabled = false,
  readonly = false,
  refetchComments,
  onCommentDeleted,
  includeExportVisiblity,
  canToggleExportVisibility,
}: CommentItemProps) => {
  const { i18n, t } = useTranslation()
  const { state: userState } = useContext(UserContext)
  const { files } = useContext(FileUploadContext)

  const { mutate: updateCommentExportVisiblity } =
    useToggleCommentExportVisbility()

  const [editMode, setEditMode] = useState(false)

  const pendingFiles = useMemo(() => {
    return comment.pendingFileNames
      ?.map((name) => ({
        name,
        fileInfo: files[name],
      }))
      .filter((file) => file.fileInfo !== undefined)
  }, [comment.pendingFileNames, files])

  useEffect(() => {
    if (!pendingFiles?.length) return
    if (
      pendingFiles?.every(
        (file) => file.fileInfo.uploadStatus.status === UPLOAD_STATE.UPLOADED,
      ) ||
      pendingFiles?.some(
        (file) => file.fileInfo.uploadStatus.status === UPLOAD_STATE.ERROR,
      )
    ) {
      refetchComments()
    }
  }, [pendingFiles, refetchComments])

  const commentFileList: CommentFileItem[] = useMemo(() => {
    return comment.documents?.map((document) => ({
      documentId: document.id,
      name: document.fileName,
      url: document.signed_url,
      type: document.contentType,
    }))
  }, [comment.documents])

  const onToggleVisibility = () => {
    updateCommentExportVisiblity({
      comment: {
        ...comment,
        show_in_export: !comment.show_in_export,
      },
    })
  }

  return (
    <CommentWrapper
      user={comment.user}
      className={classNames(!editMode && 'px-3 py-2')}
      inputMode={editMode}
    >
      {!editMode ? (
        <div>
          <div className="flex comments-center justify-between">
            <p className="font-bold">{`${comment.user?.firstName || ''} ${
              comment.user?.lastName || ''
            }`}</p>
            <div className={'text-xxs text-gray-500'}>
              {formatDateRelativeToNow(comment.created_at, i18n.language)}
            </div>
          </div>
          <div className="relative py-2 flex flex-col">
            <LinkfyText value={comment.content} />
            <CommentFilesList
              files={commentFileList}
              pendingFiles={pendingFiles}
              className="mt-4"
            />

            {includeExportVisiblity && (
              <div className={'mt-5'}>
                <div className={'absolute bottom-0 left-0 text-sm'}>
                  <Tooltip
                    message={
                      comment.show_in_export
                        ? t('will_be_included_in_report')
                        : t('will_not_be_included_in_report')
                    }
                    offset={0.25}
                  >
                    <CheckboxWithLabel
                      name={t('show_in_report')}
                      value={comment.show_in_export}
                      labelRight
                      disabled={!canToggleExportVisibility}
                      onChange={onToggleVisibility}
                    />
                  </Tooltip>
                </div>
              </div>
            )}
            {!readonly &&
              userState.user &&
              userState.user.id === comment.user?.id &&
              !pendingFiles?.length && (
                <div className="absolute bottom-0 right-0 flex gap-1">
                  <IconButton
                    className="fill-blue-500"
                    icon={<Edit />}
                    onClick={() => setEditMode(true)}
                    tooltipText={t('edit')}
                    disabled={disabled}
                  />
                  <IconButton
                    className="fill-gray-600"
                    icon={<Delete />}
                    onClick={async () => {
                      await deleteComment(comment.id)
                      refetchComments()
                      onCommentDeleted?.()
                    }}
                    tooltipText={t('delete')}
                    disabled={disabled}
                  />
                </div>
              )}
          </div>
        </div>
      ) : (
        <EditComment
          comment={comment}
          files={commentFileList}
          onCommentSaved={() => setEditMode(false)}
          onCancel={() => setEditMode(false)}
        />
      )}
    </CommentWrapper>
  )
}

export default CommentItem
