import Add from '@icons/add.svg'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonSize, ButtonType } from 'src/ui-elements/button/ButtonEnums'
import { getRandomId } from 'src/utility/getRandomId'
import Button from '../../../../../ui-elements/button/Button'
import { padNumber } from '../../ExpandableTable'
import { useExpandableTableStoreState } from '../../hooks/useExpandableTableStore'

const AddExpandableSection: React.FC = () => {
  const { t } = useTranslation()
  const [key, setKey] = useState(() => getRandomId())
  const [loading, setLoading] = useState(false)
  const { randomNumber } = useExpandableTableStoreState((state) => state)

  const { readonly, addSectionTitle } = useExpandableTableStoreState(
    (state) => state,
  )
  const expandableSections = useExpandableTableStoreState(
    (state) => state.expandableSections,
  )
  const createSection = useExpandableTableStoreState(
    (state) => state.createSection,
  )
  const [count, setCount] = useState<number>(expandableSections.length + 1)

  useEffect(() => {
    if (expandableSections.length + 1 !== count) {
      setCount(expandableSections.length + 1)
    }
  }, [expandableSections])

  if (readonly) {
    return null
  }

  const onClick = () => {
    setLoading(true)
    setCount((count) => count + 1)
    createSection(
      !randomNumber ? `${t('group')} ${count}` : `${padNumber(count)}.00`,
    )
      .then(() => setKey(getRandomId()))
      .then(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <Button
        onClick={onClick}
        key={key}
        type={ButtonType.PRIMARY}
        size={ButtonSize.XSMALL}
        disabled={loading}
        className={'h-8'}
      >
        <div className="flex items-center">
          <Add className={'text-xl'} />
          <div className="ml-1">{t(addSectionTitle) ?? t('add_section')}</div>
        </div>
      </Button>
    </div>
  )
}

export default AddExpandableSection
