import { RefObject } from 'react'
import * as React from 'react'

interface IExpandableCellDisplay {
  value?: string
  displayRef: RefObject<HTMLDivElement>
  placeholder?: string
  setEditing?: () => void
}

const ExpandableCellDisplay: React.FC<IExpandableCellDisplay> = ({
  value,
  displayRef,
  placeholder,
  setEditing,
}) => {
  return (
    <div
      ref={displayRef}
      className={`${
        placeholder && !value?.length && 'text-gray-400'
      } w-full h-full break-words whitespace-pre-line cursor-default min-h-5`}
      onClick={setEditing}
    >
      {value ? value : placeholder}
    </div>
  )
}

export default ExpandableCellDisplay
