import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { t } from 'i18next'
import { capitalize } from 'lodash'
import moment from 'moment'
import { useContext, useRef } from 'react'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { IMetaValue } from 'src/document/types/IMetaData'
import { getFilteredProjectImprovementsWithPagination } from 'src/service/ImprovementsService'
import {
  IUserData,
  IDiscipline,
  IMainProcess,
  ITeam,
  ITask,
  ITaskType,
  IStatusTypes,
  ITag,
  IImprovement,
  IRoom,
  ITaskFormData,
} from 'src/service/OrgTypes'
import { getProjectRooms } from 'src/service/RoomService'
import { updateUserDefinedFieldsValue } from 'src/service/SystemTypeFieldService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { editTask, getProjectTaskTypes } from 'src/service/TaskService'
import { getProjectTeams } from 'src/service/TeamService'
import {
  defaultDateColumnObject,
  defaultEditableTextColumn,
  defaultMultiFilterColumn,
  defaultNumberColumn,
  defaultTextColumn,
  defaultUserColumn,
  multiFilterOptionsColumn,
  statusColumn,
} from 'src/ui-elements/Table/Columns'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import {
  ControlAreaColumn,
  SystemColumn,
  userDefinedColumns,
} from '../TableColumns/Columns'
import {
  DisciplineAndResponsibleColumn,
  ResponsibleAndDisciplineColumn,
} from '../TableColumns/DisciplineResponsibleColumns'

import { MainProcessAndThemeColumn } from '../TableColumns/MainProcessTemaColumns'
import useProjectId from '../hooks/useProjectId'
import { IMetaField } from '../system/system-type-fields/SystemTypeFieldsForm'
import { useTaskTableValidator } from './useTaskTableValidator'

interface Options {
  taskTypeTree?: boolean
  parentType?: string
  module?: string
  metaData?: IMetaField[]
}
interface TaskColumnsProps {
  disabled?: boolean
  reload?: () => void
  options: Options
}

export const useTaskColumns = ({
  reload,
  options,
  disabled = false,
}: TaskColumnsProps) => {
  const {
    getTagFilter,
    getImprovementFilter,
    getReporterFilter,
    getContractFilter,
    getTaskTypeFilter,
    getRoomFilter,
    getTeamFilter,
  } = useListHelper()

  const projectId = useProjectId()
  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])
  const mainProcesses = useRef<IMainProcess[]>([])
  const themes = useRef<ITeam[]>([])

  const projectContext = useContext(ProjectContext)
  const isInfrastructure =
    projectContext.state.currentProject.is_infrastructure_project

  const { validateDeadline } = useTaskTableValidator()

  const ct = (key: string) => {
    return capitalize(t(key))
  }

  const onUpdateTask = async (updatedTask: Parameters<typeof editTask>[0]) => {
    if (updatedTask.id) {
      await editTask(updatedTask)
      reload?.()
    }
  }

  const updateMainProcess = (
    id: number,
    mainProcessId: number,
    teamId: number,
  ) => {
    const task = {
      id,
      main_process_id: mainProcessId,
      team_id: teamId,
    }
    onUpdateTask(task)
  }

  const onStatusUpdate = (status: IStatusTypes, key: number) => {
    const dataField: Partial<ITaskFormData> = { id: key }
    if (status.id === 'done') {
      dataField.closed_date = moment()
    }
    dataField.status = status.id
    dataField.id = key
    onUpdateTask(dataField)
  }

  const onFieldChange = (field: string, key: number, value: string) => {
    const task = { id: key, [field]: value }
    onUpdateTask(task)
  }

  const updateResponsible = (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => {
    const task = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    }
    onUpdateTask(task)
  }

  const updateDiscipline = (
    id: number,
    disciplineId: number,
    responsibleId: number,
  ) => {
    const task = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    }
    onUpdateTask(task)
  }

  const taskColumnHelper = createColumnHelper<ITask>()

  const defaultColumn = [
    taskColumnHelper.accessor(
      'record_id',
      defaultTextColumn('record_id', {
        name: ct('id'),
      }),
    ),
    taskColumnHelper.accessor(
      'title',
      defaultEditableTextColumn(
        'title',
        { name: capitalize('title') },
        (id, title) => onUpdateTask({ id: parseInt(id, 10), title }),
        disabled,
      ),
    ),
    MainProcessAndThemeColumn(
      projectId,
      mainProcesses,
      themes,
      updateMainProcess,
      disabled,
    ),
    taskColumnHelper.accessor(
      'team',
      defaultMultiFilterColumn<ITask, ITeam[]>(
        'team',
        {
          name: ct('team'),
          getFilter: getTeamFilter,
          field: 'team.name',
        },
        ['name'],
        () => getProjectTeams(projectId),
        (key, value) => onFieldChange('team_id', key, value),
        disabled,
      ),
    ),
    taskColumnHelper.accessor(
      'task_type',
      defaultMultiFilterColumn<ITask, ITaskType[]>(
        'task_type',
        {
          name: ct('task_type'),
          getFilter: getTaskTypeFilter,
          field: 'task_type.taskTypeName',
        },
        ['taskTypeName'],
        () => getProjectTaskTypes(projectId),
        (key, value) => onFieldChange('task_type_id', key, value),
        disabled,
      ),
    ),
    statusColumn(
      'status',
      ['status', 'deadline'],
      { name: ct('status') },
      statusTypes(t),
      !disabled ? onStatusUpdate : undefined,
    ),
    taskColumnHelper.accessor(
      'deadline',
      defaultDateColumnObject(
        'deadline',
        { name: ct('deadline') },
        undefined,
        false,
        (date, task) => onFieldChange('deadline', task.id, date),
        validateDeadline,
      ),
    ),
    taskColumnHelper.accessor(
      'duration',
      defaultNumberColumn('duration', { name: ct('duration_days') }),
    ),
    ResponsibleAndDisciplineColumn(
      projectId,
      projectUsers,
      projectDiscipline,
      updateResponsible,
      disabled,
    ),
    DisciplineAndResponsibleColumn(
      projectId,
      projectDiscipline,
      projectUsers,
      updateDiscipline,
      disabled,
    ),
    multiFilterOptionsColumn(
      'contract',
      {
        name: ct('contract'),
        getFilter: getContractFilter,
        field: 'contract.contractNumber||contract.contractName',
      },
      ['contractNumber', 'contractName'],
    ),
    taskColumnHelper.accessor(
      'tag',
      defaultMultiFilterColumn<ITask, ITag[]>(
        'tag',
        {
          name: ct('tag'),
          getFilter: getTagFilter,
          field: 'tag.name',
        },
        ['name'],
        () => getProjectTags(projectId),
        (key, value) => onFieldChange('tag_id', key, value),
        disabled,
      ),
    ),
    taskColumnHelper.accessor(
      'improvement',
      defaultMultiFilterColumn<ITask, IImprovement[]>(
        'improvement',
        {
          name: ct('improvement'),
          getFilter: getImprovementFilter,
          field: 'improvement.title',
        },
        ['title'],
        () =>
          getFilteredProjectImprovementsWithPagination(projectId).then(
            (res) => res.items,
          ),
        (key, value) => onFieldChange('improvement_id', key, value),
        disabled,
      ),
    ),
    taskColumnHelper.accessor(
      'reporter',
      defaultUserColumn('reporter', {
        name: ct('reporter'),
        getFilter: getReporterFilter,
        field: 'reporter.firstName||reporter.lastName',
      }),
    ),
    taskColumnHelper.accessor(
      'created_at',
      defaultDateColumnObject('created_at', { name: ct('created_at') }),
    ),
    taskColumnHelper.accessor(
      'updated_at',
      defaultDateColumnObject('updated_at', { name: ct('updated_at') }),
    ),
  ]

  const updateMetaFiled = (data: IMetaValue, _id: number) => {
    if (data.id) {
      updateUserDefinedFieldsValue(data.id, data).then(() => {
        reload?.()
      })
    }
  }

  const userDefinedAttributesColumns = options?.metaData
    ? userDefinedColumns(
        options?.metaData,
        updateMetaFiled,
        'meta_data',
        true,
        false,
      )
    : []

  const legacyColumns = [
    ControlAreaColumn(
      projectId,
      (key, value) => onFieldChange('control_area_id', key, value),
      options.taskTypeTree,
    ),
    SystemColumn(disabled),
    ...userDefinedAttributesColumns,
  ]

  const sourceFilter = () => {
    return Promise.resolve(
      options.module === 'planning'
        ? [
            {
              name: 'delivery',
              value: 'Delivery',
              active: true,
            },
            {
              name: 'meeting',
              value: 'Topic',
              active: true,
            },
            {
              name: 'project',
              value: 'Project',
              active: true,
            },
          ]
        : [],
    )
  }

  const sourceColumn = () => {
    if (options.parentType) return
    return multiFilterOptionsColumn(
      'parent_type',
      {
        name: ct('source'),
        getFilter: sourceFilter,
        field: 'parent_type',
      },
      [],
      true,
    )
  }

  const roomColumn = () => {
    if (isInfrastructure) return
    return taskColumnHelper.accessor(
      'location',
      defaultMultiFilterColumn<ITask, IRoom[]>(
        'location',
        {
          name: ct('room'),
          getFilter: getRoomFilter,
          field: 'location.functional_room_number',
        },
        ['functional_room_number', 'room_name'],
        () => getProjectRooms(projectId),
        (key, value) => onFieldChange('location_id', key, value),
        disabled,
      ),
    )
  }

  const defaultOrdering = [
    'select',
    'record_id',
    'title',
    'main_process',
    'team',
    'task_type',
    'status',
    'system',
    'deadline',
    'duration',
    'responsible',
    'discipline',
    'contract',
    'improvement',
    'control_area',
    'location',
    'reporter',
    'parent_type',
    'tag',
    'created_at',
    'updated_at',
  ]

  const columns = [...defaultColumn, sourceColumn(), roomColumn()].filter(
    (c) => c,
  ) as ColumnDef<ITask>[]

  return {
    columns,
    defaultOrdering,
    legacyColumns,
  }
}
