import * as React from 'react'
import { useTranslation } from 'react-i18next'
import CheckBox from 'src/components/switchHoc/CheckBox'
import { IMetaField } from 'src/components/system/system-type-fields/SystemTypeFieldsForm'
import { IMetaValue } from 'src/document/types/IMetaData'
import { capFirstLetter } from 'src/utility/utils'

interface IDocumentMetaDataBooleanInput {
  value: IMetaValue
  field: IMetaField
  onChange: (newValue: boolean) => void
  isPage: boolean
  editMode: boolean
}

const DocumentMetaDataBooleanInput: React.FC<IDocumentMetaDataBooleanInput> = ({
  value,
  field,
  onChange,
  isPage,
  editMode,
}) => {
  const { t } = useTranslation()
  return (
    <div className={`flex items-center w-1/2 ${isPage ? 'pl-2' : ''}`}>
      <label
        className={`block whitespace-nowrap font-medium text-sm leading-5 pl-2 pr-4`}
      >
        {capFirstLetter(field.name)}
        {value.is_required ? ' *' : ''}
      </label>
      {editMode ? (
        <CheckBox valueProp={value.data_value === 'true'} onChange={onChange} />
      ) : (
        <span className="font-normal text-sm leading-5 text-gray-500">
          {' '}
          {value.data_value === 'true' ? t('yes') : t('no')}
        </span>
      )}
      {value.error && (
        <p className={'text-red-600 ml-2 text-sm font-normal'}>{value.error}</p>
      )}
    </div>
  )
}

export default DocumentMetaDataBooleanInput
