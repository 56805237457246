export const testCaseImportTemplate = {
  name: 'test_cases_import_template',
  displayName: 'test_cases_import_template',
  columns: ['test_chapter', 'description', 'expected_result'],
  templateColumns: ['name', 'required', 'value', 'description', 'example'],
  template: [
    {
      name: 'test_chapter',
      required: true,
      value: 'test_chapter',
      description: 'name_of_test_chapter_test_element_belongs_to',
      example: '01',
    },
    {
      name: 'description',
      required: false,
      value: 'description',
      description: 'description_of_test_element',
      example: 'description',
    },
    {
      name: 'expected_result',
      required: false,
      value: 'expected_result',
      description: 'expected_result_of_test_element',
      example: 'expected result',
    },
  ],
}
