import { useFlag } from '@unleash/proxy-client-react'
import { useLocation } from 'react-router-dom'
import { create } from 'zustand'

type DraftModeStore = {
  draftMode: boolean
  setDraftMode: (value: boolean) => void
}

const useDraftModeStore = create<DraftModeStore>((set) => ({
  draftMode: false,
  setDraftMode: (value) => set({ draftMode: value }),
}))

const useDraftMode = () => {
  const draftModeFlag = useFlag('draft-mode')
  const setDraftMode = useDraftModeStore((state) => state.setDraftMode)
  const draftMode = useDraftModeStore((state) => state.draftMode)

  const turnDraftModeOn = () => setDraftMode(draftModeFlag)

  const location = useLocation()
  const tabHeaderKeys = ['timeline', 'control_areas', 'activities']
  const showDraftModeBanner =
    location.pathname.includes('/constructions/') &&
    tabHeaderKeys.some((key) => location.pathname.includes(key)) &&
    draftMode

  return {
    draftMode,
    setDraftMode,
    turnDraftModeOn,
    showDraftModeBanner,
  }
}

export default useDraftMode
