import { IExpandableSection } from 'src/systematic-completion/components/expandable-table/types/IExpandableSection'
import { arrayInsert } from 'src/utility/arrayInsert'
import { getRandomId } from 'src/utility/getRandomId'
import { IExpandableTableInteractionStore } from '../stores/ExpandableTableInteractionStore'
import { IExpandableTableStore } from '../stores/ExpandableTableStore'
import { IDraggingRow } from '../types/IDraggingRow'
import { IExpandableSectionConfig } from '../types/IExpandableSectionConfig'

export class ReorderRowsHandler {
  private draggingRow?: IDraggingRow
  private hoveringRow?: IDraggingRow
  private dragTargetSection?: IExpandableSection
  private draggingSection?: IExpandableSectionConfig
  private getState: () => IExpandableTableStore
  private readonly setState: (store: Partial<IExpandableTableStore>) => void
  private readonly setInteractionState: (
    store: Partial<IExpandableTableInteractionStore>,
  ) => void

  constructor(
    getState: () => IExpandableTableStore,
    setState: (store: Partial<IExpandableTableStore>) => void,
    setInteractionState: (
      store: Partial<IExpandableTableInteractionStore>,
    ) => void,
  ) {
    this.getState = getState
    this.setState = setState
    this.setInteractionState = setInteractionState
  }

  readonly setHoveringRow = (hoveringRow?: IDraggingRow) => {
    this.hoveringRow = hoveringRow
    this.setInteractionState({ hoveringRow })
  }

  readonly setDraggingRow = (draggingRow?: IDraggingRow) => {
    this.draggingRow = draggingRow
    this.setInteractionState({ draggingRow })
  }

  readonly setDragTargetSection = (dragTargetSection?: IExpandableSection) => {
    this.dragTargetSection = dragTargetSection
    this.setInteractionState({ dragTargetSection })
  }

  readonly setDraggingSection = (
    draggingSection?: IExpandableSectionConfig,
  ) => {
    this.draggingSection = draggingSection
    this.setInteractionState({ draggingSection })
  }

  private swapInternally = (
    configs: Record<string, IExpandableSectionConfig>,
  ) => {
    if (!this.draggingRow || !this.hoveringRow) return configs

    const sectionConfig = configs[this.draggingRow.section.id]
    const rowToMoveIndex = sectionConfig.rows.findIndex(
      (rowId) => this.draggingRow?.rowId === rowId,
    )

    const rowToMove = this.getState().rows[this.draggingRow.rowId]

    const destinationRowIndex = sectionConfig.rows.findIndex(
      (rowId) => rowId === this.hoveringRow?.rowId,
    )

    sectionConfig.rows.splice(rowToMoveIndex, 1)
    arrayInsert(sectionConfig.rows, destinationRowIndex, rowToMove.id)

    sectionConfig.INTERNAL_version = getRandomId()

    const reorderCallback = this.getState().onReorderRows
    if (reorderCallback)
      reorderCallback(
        rowToMove,
        rowToMoveIndex,
        destinationRowIndex,
        this.draggingRow.section.id,
        this.draggingRow.section.id,
      )

    configs[this.draggingRow.section.id] = { ...sectionConfig }

    return configs
  }

  private swapBetweenConfigs = (
    configs: Record<string, IExpandableSectionConfig>,
  ) => {
    if (!this.draggingRow || !this.hoveringRow) return configs

    const draggingSectionConfig = configs[this.draggingRow.section.id]

    const hoveringSectionConfig = configs[this.hoveringRow.section.id]
    const rowToMoveIndex = draggingSectionConfig.rows.findIndex(
      (rowId) => this.draggingRow?.rowId === rowId,
    )

    const rowToMove = this.getState().rows[this.draggingRow.rowId]

    const destinationRowIndex = hoveringSectionConfig.rows.findIndex(
      (rowId) => rowId === this.hoveringRow?.rowId,
    )

    draggingSectionConfig.rows.splice(rowToMoveIndex, 1)
    arrayInsert(
      hoveringSectionConfig.rows,
      destinationRowIndex + 1,
      rowToMove.id,
    )

    draggingSectionConfig.INTERNAL_version = getRandomId()
    hoveringSectionConfig.INTERNAL_version = getRandomId()

    configs[this.draggingRow.section.id] = { ...draggingSectionConfig }
    configs[this.hoveringRow.section.id] = { ...hoveringSectionConfig }

    const reorderCallback = this.getState().onReorderRows
    if (reorderCallback)
      reorderCallback(
        rowToMove,
        rowToMoveIndex,
        destinationRowIndex,
        this.draggingRow.section.id,
        this.hoveringRow.section.id,
      )

    return configs
  }

  readonly onSectionDraggingEnd = () => {
    if (this.draggingSection?.id && this.dragTargetSection?.id) {
      const expandableSections = [...this.getState().expandableSections]
      const expandableSectionIndexes = expandableSections.map((e) => e.id)

      const insertBefore = expandableSectionIndexes.indexOf(
        this.dragTargetSection.id,
      )

      const section = expandableSections.splice(
        expandableSectionIndexes.indexOf(this.draggingSection.id),
        1,
      )[0]

      expandableSections.splice(insertBefore, 0, section)
      this.setState({ expandableSections: expandableSections })

      const reorderCallback = this.getState().onReorderRows
      if (reorderCallback)
        reorderCallback(
          this.draggingSection,
          expandableSectionIndexes.indexOf(this.draggingSection.id),
          insertBefore,
          this.draggingSection.id,
          this.draggingSection.id,
        )
    }

    this.setHoveringRow(undefined)
    this.setDraggingRow(undefined)
    this.setDragTargetSection(undefined)
    this.setDraggingSection(undefined)
  }

  readonly onDraggingEnd = () => {
    const tmpSectionsConfig = { ...this.getState().expandableSectionsConfig }

    if (!this.draggingRow || !this.hoveringRow) {
      this.setState({ expandableSectionsConfig: tmpSectionsConfig })
      return
    }

    if (
      !this.hoveringRow ||
      (this.hoveringRow && this.hoveringRow.rowId === this.draggingRow.rowId)
    ) {
      const draggingSectionConfig =
        tmpSectionsConfig[this.draggingRow.section.id]
      draggingSectionConfig.INTERNAL_version = getRandomId()
      this.setState({ expandableSectionsConfig: tmpSectionsConfig })
    }

    let newConfig: Record<string, IExpandableSectionConfig>

    if (this.draggingRow.section.id === this.hoveringRow.section.id) {
      newConfig = this.swapInternally(tmpSectionsConfig)
    } else {
      newConfig = this.swapBetweenConfigs(tmpSectionsConfig)
    }

    this.setState({ expandableSectionsConfig: newConfig })

    this.setHoveringRow(undefined)
    this.setDraggingRow(undefined)
  }
}
