import { createContext } from 'react'
import { IDelivery } from 'src/service/OrgTypes'

export interface IItemSelectContext {
  actions: {
    addToSelectedItems: (
      res: number,
      dependency?: 'past' | 'future',
      multiSelectDelivery?: IDelivery,
    ) => void
    removeSelectedItem: (
      res: number,
      dependency?: 'past' | 'future',
      multiSelectDelivery?: IDelivery,
    ) => void
    itemSelected: (id: number) => boolean
    itemMultiSelected: (id: number) => boolean
    toggleSelectedModeOn: (
      itemId: number,
      pastDependencies: number[],
      futureDependencies?: number[],
    ) => void
    toggleSelectedModeOff: () => void
    toggleMultiSelectModeOn: () => void
    toggleMultiSelectModeOff: () => void
  }
  state: IItemSelectContextState
}

export interface IItemSelectContextState {
  selectedItemsId: number[]
  multiSelectedDeliveries: IDelivery[]
  pastDependencies: number[]
  futureDependencies: number[]
  selectMode: boolean
  selectParent: number
  multiSelectMode: boolean
}
export type ItemSelectContext = IItemSelectContext

export const ItemSelectContext = createContext<ItemSelectContext>(
  {} as IItemSelectContext,
)
