import { useTranslation } from 'react-i18next'
import ChecklistTreeRouter from 'src/components/checklist/ChecklistTreeRouter'
import { ChecklistDomains } from 'src/components/checklist/utils'
import DropDownExplorer from 'src/components/explorer/DropDownExplorer'
import useTree from 'src/hooks/useTree'
import { getChecklistTree } from 'src/service/ChecklistService'
import BreadCrumb from 'src/ui-elements/breadcrumb/BreadCrumb'
import { TreePageStyleClass } from 'src/ui-elements/tabs/Utils'

const CheckListTree = ({ domain }: { domain: ChecklistDomains }) => {
  const { t } = useTranslation()
  const {
    onItemClick,
    breadCrumbsList,
    activeKey,
    openNodes,
    data,
    setTreeWidth,
  } = useTree(['checklists'], getChecklistTree, 'Checklist')

  return (
    <div className={TreePageStyleClass.root}>
      <DropDownExplorer
        data={data}
        onItemClick={onItemClick}
        header={{
          title: t('checklists'),
        }}
        initialActiveKey={activeKey}
        activeKey={activeKey}
        initialOpenNodes={openNodes}
        resetOpenNodesOnDataUpdate={true}
        onResize={setTreeWidth}
        keyName={'Checklist'}
      />
      <div className={TreePageStyleClass.content}>
        <BreadCrumb breadCrumbs={breadCrumbsList} />
        <ChecklistTreeRouter domain={domain} />
      </div>
    </div>
  )
}

export default CheckListTree
