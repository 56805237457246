import * as React from 'react'
import { IMetaField } from 'src/components/system/system-type-fields/SystemTypeFieldsForm'
import { IMetaValue } from 'src/document/types/IMetaData'
import Input from 'src/ui-elements/input/Input'
import InputPageDisplay from 'src/ui-elements/page-display/InputPageDisplay'

interface IDocumentMetaDataStringInput {
  value: IMetaValue
  field: IMetaField
  onChange: (newValue: string) => void
  isPage: boolean
  editMode: boolean
}

const DocumentMetaDataStringInput: React.FC<IDocumentMetaDataStringInput> = ({
  value,
  field,
  onChange,
  isPage,
  editMode,
}) => {
  return (
    <div className="w-full">
      {isPage ? (
        <InputPageDisplay
          isValid={false}
          block={true}
          editingMode={!!editMode}
          label={field.name}
          required={value.is_required}
          onChange={(e) => onChange(e.target.value)}
          value={value.data_value as string}
          displayData={`${value.data_value}`}
          errorMessage={value.error}
        />
      ) : (
        <Input
          block={true}
          label={field.name}
          required={value.is_required}
          onChange={(e) => onChange(e.target.value)}
          value={value.data_value as string}
          errorMessage={value.error}
        />
      )}
    </div>
  )
}

export default DocumentMetaDataStringInput
