import Delete from '@icons/delete.svg'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { ExpandableTableContext } from 'src/systematic-completion/components/expandable-table/context/ExpandableTableContext'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import { useExpandableTableStoreState } from '../../hooks/useExpandableTableStore'

interface IBulkDelete {
  onBulkDelete: (rowIds: number[], sectionIds: number[]) => Promise<boolean>
}

const BulkDelete = ({ onBulkDelete }: IBulkDelete) => {
  const { t } = useTranslation()
  const { selectedIds, selectedSectionIds, resetSelectedState } =
    useExpandableTableStoreState((state) => state)
  const { interactionStore } = useContext(ExpandableTableContext)

  const disabled = !selectedIds.length && !selectedSectionIds.length
  return (
    <Button
      disabled={disabled}
      type={ButtonType.DANGER}
      className={twMerge(disabled && 'bg-gray-400', 'h-8')}
      onClick={async () => {
        const deleted = await onBulkDelete(selectedIds, selectedSectionIds)
        if (deleted) {
          resetSelectedState(selectedIds, selectedSectionIds)
          if (interactionStore.getState().focusedSectionId)
            interactionStore.setState({ focusedSectionId: undefined })
        }
      }}
      title={t('delete')}
    >
      <Delete
        className={twMerge(
          'w-4 h-4',
          disabled ? 'fill-gray-400' : 'fill-red-500',
        )}
      />
    </Button>
  )
}

export default BulkDelete
