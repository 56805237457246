import {
  Children,
  cloneElement,
  ReactElement,
  ReactNode,
  PropsWithChildren,
  FC,
} from 'react'
import InlineSkeletonComponent from './InlineSkeletonComponent'

interface IInlineComponentsWrapper {
  border?: 'right' | 'left'
  padding?: 'right' | 'left' | 'none' | string
  loading?: boolean
  labelWidth?: string
  inputWidth?: string
  title?: string
  disableTooltips?: boolean
  labelTextSize?: string
}

const InlineComponentsWrapper: FC<
  PropsWithChildren<IInlineComponentsWrapper>
> = ({
  children,
  border,
  padding,
  loading = false,
  labelWidth,
  inputWidth,
  title = '',
  disableTooltips = true,
  labelTextSize = 'text-sm',
}) => {
  const borderStyling = () => {
    if (!border) return
    let borderString = 'border-gray-200'

    if (border === 'left') {
      borderString += ' border-l'
    } else if (border === 'right') {
      borderString += ' border-r'
    }
    return borderString ?? ''
  }

  const paddingStyling = () => {
    if (padding === 'left') return 'py-3 pl-8 pr-2'
    else if (padding === 'right') return 'py-3 pr-8 pl-2'
    return padding ?? ''
  }

  return (
    <div className={`text-sm w-full ${borderStyling()} ${paddingStyling()}`}>
      {title?.length > 0 && (
        <div className="text-[#515558] pb-2 first-capitalize">{title}</div>
      )}
      {Children.map<ReactNode, ReactNode>(children, (child: ReactElement) => {
        if (!child) return <></>
        const clonedElement = cloneElement(child, {
          ...child?.props,
          labelWidth,
          inputWidth,
          disableTooltip: disableTooltips,
          labelTextSize,
        })
        return loading ? (
          <InlineSkeletonComponent
            inputWidth={inputWidth}
            labelWidth={labelWidth}
          />
        ) : (
          clonedElement
        )
      })}
    </div>
  )
}

export default InlineComponentsWrapper
