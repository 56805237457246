import moment from 'moment'
import { useState, useContext, useEffect } from 'react'
import { SingleDatePicker } from 'react-dates'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import Selector from 'src/components/selectors/Selector'
import {
  initializeMetaValues,
  getMetaDataValues,
  setExistingValues,
} from 'src/components/system/SystemUtil'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import DocumentMetaDataFields from 'src/document/components/DocumentCreateModal/DocumentMetaDataFields'
import { useProjectDisciplines } from 'src/query/disciplines'
import { useImprovements } from 'src/query/improvements'
import { useKeypoints } from 'src/query/planning/keypoints'
import {
  useTags,
  useTeams,
  useMainProcessTeams,
  useMainProcesses,
} from 'src/query/planning/process'
import { useSystemTypeGroupForDomain } from 'src/query/systemTypeGroups'
import { useProjectUsers, useDisciplineUsers } from 'src/query/users'
import {
  newDelivery,
  updateDelivery,
  massUpdateDeliveries,
} from 'src/service/DeliveryService'
import {
  IDelivery,
  IDeliveryStatus,
  ITeam,
  IUserData,
} from 'src/service/OrgTypes'
import {
  getDeliveryErrorMessage,
  getErrorMessage,
  StructureValidationError,
  ValidationError,
} from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Spinner from 'src/ui-elements/loader/Spinner'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { renderDayContents } from 'src/utility/Utility'

interface IDeliveryFormProps {
  show: boolean
  reload: (delivery?: IDelivery) => void
  closeModal: () => void
  delivery?: Partial<IDelivery>
  deliveryIds?: number[]
}

const DeliveryForm = ({
  show,
  reload,
  closeModal,
  delivery,
  deliveryIds,
}: IDeliveryFormProps) => {
  const { t } = useTranslation()

  const projectId = useProjectId()
  const userContext = useContext(UserContext)
  const { user } = userContext.state

  const massEditing = !!deliveryIds
  const editing = !!delivery?.id
  const creating = !massEditing && !editing

  const [loading, setLoading] = useState(false)
  const [keypointId, setKeypointId] = useState(delivery?.key_point_id)
  const [mainProcessId, setMainProcessId] = useState(delivery?.main_process_id)
  const [datePickerFocused, setDatePickerFocused] = useState(false)
  const [disciplineId, setDisciplineId] = useState(delivery?.discipline_id)
  const [createMultiple, setCreateMultiple] = useState(false)

  const { data: systemTypeGroup } = useSystemTypeGroupForDomain('Delivery')
  const { data: tags = [] } = useTags()
  const { data: mainProcesses = [] } = useMainProcesses()
  const { data: teams = [] } = useTeams()
  const { data: mainProcessTeams } = useMainProcessTeams(mainProcessId ?? 0, {
    enabled: !!mainProcessId,
  })

  const { data: keypoints = [] } = useKeypoints()
  const { data: improvements = [] } = useImprovements()

  const { data: disciplines = [] } = useProjectDisciplines()
  const { data: projectUsers = [] } = useProjectUsers()
  const { data: disciplineUsers } = useDisciplineUsers(disciplineId ?? 0, {
    enabled: !!disciplineId,
  })

  const { control, handleSubmit, setValue, reset } = useForm<IDelivery>()

  useEffect(() => {
    const optionalFields = initializeMetaValues(
      systemTypeGroup?.optional_fields ?? [],
      'Delivery',
      delivery?.id,
      !creating,
    )
    if (delivery) {
      const optionalValues = setExistingValues(
        getMetaDataValues(delivery.meta_data),
        optionalFields,
      )
      reset({
        ...delivery,
        optional_fields: optionalValues,
      })
    } else reset({ optional_fields: optionalFields })
  }, [delivery, systemTypeGroup])

  const onSelectKeypoint = (id: number) => {
    setKeypointId(id)
    if (!mainProcessId) {
      const keypoint = keypoints.find((del) => del.id === id)
      setMainProcessId(keypoint?.main_process_id)
      setValue('main_process_id', keypoint?.main_process_id)
    }
  }

  const onSelectTeam = (id: number) => {
    const selectedTeam = teams?.find((team: ITeam) => team.id === id)
    setMainProcessId(selectedTeam?.main_process_id)
    setValue('main_process_id', selectedTeam?.main_process_id)
  }

  const onDisciplineChange = async (value: number) => {
    const discipline = disciplines?.find((v) => v.id === value)
    setDisciplineId(value)
    setValue('responsible_id', undefined)
    setValue('contract_id', discipline?.contract_id)
  }

  const onResponsibleChange = (userId: number) => {
    const userSelected: IUserData | undefined = projectUsers
      .filter((u) => u.id === userId)
      .pop()
    const discipline = userSelected?.disciplines?.find(
      (d) => d.project_id === projectId,
    )
    if (discipline) {
      setValue('discipline_id', discipline.id)
      setValue('contract_id', discipline.contract_id)
    }
  }

  const validateDate = (endTime: string) => {
    const date = moment(endTime)
    if (!endTime.length || !date.isValid())
      return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
    const keypoint = keypoints.find((del) => del.id === keypointId)
    if (keypoint?.endTime && date.isAfter(moment(keypoint.endTime), 'day')) {
      return getDeliveryErrorMessage(
        StructureValidationError.PAST_PARENT_DEADLINE,
        t,
        moment(keypoint.endTime).format('L'),
      )
    }
    const improvement = improvements.find(
      (u) => u.id === delivery?.improvement_id,
    )
    if (
      improvement?.deadline &&
      date.isAfter(moment(improvement.deadline), 'day')
    ) {
      return getDeliveryErrorMessage(
        StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
        t,
        moment(improvement.deadline).format('L'),
      )
    }
    return undefined
  }

  const submitDelivery = async (data: IDelivery) => {
    setLoading(true)
    if (massEditing) {
      await massUpdateDeliveries(projectId, {
        optional_fields: data.optional_fields?.filter((item) => item.data_set),
        deliveries: deliveryIds.map((id) => ({
          ...data,
          id,
        })),
      })
      reload()
    } else {
      const res = data?.id
        ? await updateDelivery(data, data.id)
        : await newDelivery(
            {
              ...data,
              status: IDeliveryStatus.OPEN,
              record_id: '',
              project_id: projectId,
            },
            user.id,
          )
      reload(res)
    }
    if (!createMultiple) closeModal()
    setLoading(false)
  }

  return (
    <Modal
      show={show}
      title={
        massEditing
          ? t('edit_multiple_deliveries')
          : editing
            ? t('edit_item', { item: delivery?.record_id ?? t('delivery') })
            : t('new_delivery')
      }
      closeModal={closeModal}
      size={'w-2/3'}
      maxWidth={800}
      confirmOutsideClick={true}
    >
      <form className={'flex flex-col'} onSubmit={handleSubmit(submitDelivery)}>
        <div className={'flex w-full flex-wrap'}>
          {!massEditing && (
            <Controller
              control={control}
              name={'endTime'}
              rules={{
                required: getErrorMessage(ValidationError.MISSING_DEADLINE, t),
                validate: validateDate,
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <div
                  className={'flex flex-col items-start px-2 w-full md:w-1/2'}
                >
                  <label
                    className={
                      'font-medium text-sm first-capitalize text-gray-700 my-2'
                    }
                  >
                    {t('deadline')}
                    {!massEditing && '*'}
                    {error?.message && (
                      <label
                        className={'pl-2 text-sm my-2 text-red-600 font-normal'}
                      >
                        {error.message}
                      </label>
                    )}
                  </label>
                  <SingleDatePicker
                    firstDayOfWeek={1}
                    date={value ? moment(value) : null}
                    onDateChange={(date) => onChange(date?.toISOString())}
                    renderDayContents={renderDayContents}
                    focused={datePickerFocused}
                    onFocusChange={(focused) =>
                      setDatePickerFocused(focused.focused)
                    }
                    isOutsideRange={() => false}
                    id="datepicker-delivery"
                    showDefaultInputIcon
                    numberOfMonths={1}
                    displayFormat={() =>
                      moment.localeData('no').postformat('DD.MM.YY')
                    }
                    hideKeyboardShortcutsPanel
                  />
                </div>
              )}
            />
          )}
          <Controller
            name={'duration'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className={'w-full md:w-1/2'}>
                <Input
                  label={t('duration_days')}
                  value={value}
                  onChange={(e) => onChange(+e.target.value)}
                  block
                  type={'number'}
                  minValue={0}
                />
              </div>
            )}
          />
        </div>
        <Controller
          name={'name'}
          control={control}
          rules={{
            required:
              !massEditing && getErrorMessage(ValidationError.MISSING_TITLE, t),
          }}
          render={({ field: { value, onChange }, fieldState: { error } }) => (
            <Input
              type={'text'}
              label={t('title')}
              value={value}
              onChange={(e) => onChange(e.target.value)}
              block
              required={!massEditing}
              errorMessage={error?.message}
              autoFocus
            />
          )}
        />
        <div className={'flex w-full flex-wrap'}>
          <Controller
            name={'discipline_id'}
            control={control}
            rules={{
              required:
                !massEditing &&
                getErrorMessage(ValidationError.MISSING_DISCIPLINE, t),
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className={'w-full md:w-1/2'}>
                <Selector
                  items={disciplines}
                  selectedItemId={value}
                  onSelect={(id) => {
                    onChange(id)
                    onDisciplineChange(id)
                  }}
                  label={t('discipline')}
                  dataFields={['shortName', 'name']}
                  required={!massEditing}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  errorMessage={error?.message}
                  onCancel={() => onChange(undefined)}
                  cancelButton
                />
              </div>
            )}
          />
          <Controller
            name={'responsible_id'}
            control={control}
            rules={{
              required:
                !massEditing &&
                getErrorMessage(ValidationError.MISSING_RESPONSIBLE, t),
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className={'w-full md:w-1/2'}>
                <Selector
                  items={disciplineUsers ?? projectUsers}
                  selectedItemId={value}
                  onSelect={(id) => {
                    onResponsibleChange(id)
                    onChange(id)
                  }}
                  label={t('responsible')}
                  dataFields={['firstName', 'lastName']}
                  required={!massEditing}
                  fontSize={'sm'}
                  userSelector
                  fontWeight={'bold'}
                  errorMessage={error?.message}
                  onCancel={() => onChange(false)}
                  cancelButton
                />
              </div>
            )}
          />
        </div>
        <div className={'flex w-full flex-wrap'}>
          <Controller
            name={'main_process_id'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className={'w-full md:w-1/2'}>
                <Selector
                  items={mainProcesses}
                  selectedItemId={value}
                  onSelect={(id) => {
                    onChange(id)
                    setMainProcessId(id)
                  }}
                  label={t('main_process')}
                  dataFields={['name']}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                />
              </div>
            )}
          />
          <Controller
            name={'team_id'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <div className={'w-full md:w-1/2'}>
                <Selector
                  items={mainProcessTeams ?? teams}
                  selectedItemId={value}
                  onSelect={(id) => {
                    onSelectTeam(id)
                    onChange(id)
                  }}
                  label={t('team')}
                  dataFields={['name']}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                />
              </div>
            )}
          />
        </div>
        {!massEditing && (
          <div className={'flex w-full flex-wrap'}>
            <Controller
              name={'key_point_id'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className={'w-full md:w-1/2'}>
                  <Selector
                    items={keypoints}
                    selectedItemId={value}
                    onSelect={(id) => {
                      onChange(id)
                      onSelectKeypoint(id)
                    }}
                    label={t('keypoint')}
                    dataFields={['record_id', 'name']}
                    fontSize={'sm'}
                    fontWeight={'bold'}
                  />
                </div>
              )}
            />
            <Controller
              name={'improvement_id'}
              control={control}
              render={({ field: { value, onChange } }) => (
                <div className={'w-full md:w-1/2'}>
                  <Selector
                    items={improvements}
                    selectedItemId={value}
                    onSelect={onChange}
                    label={t('improvement')}
                    dataFields={['record_id', 'title']}
                    fontSize={'sm'}
                    fontWeight={'bold'}
                    cancelButton
                    onCancel={() => onChange(undefined)}
                  />
                </div>
              )}
            />
          </div>
        )}
        <Controller
          name={'tag_id'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <div className={'w-full md:w-1/2'}>
              <Selector
                items={tags}
                selectedItemId={value}
                onSelect={onChange}
                label={t('type')}
                dataFields={['name']}
                fontSize={'sm'}
                fontWeight={'bold'}
                cancelButton
                onCancel={() => onChange(undefined)}
              />
            </div>
          )}
        />
        {['description', 'assumptions', 'notes'].map((field) => (
          <Controller
            key={field}
            name={field as 'description' | 'assumptions' | 'notes'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Textarea
                value={value}
                isValid={false}
                label={t(field)}
                onChange={(e) => onChange(e.target.value)}
                block
                required={false}
              />
            )}
          />
        ))}
        <Controller
          name={'optional_fields'}
          control={control}
          render={({ field: { value, onChange } }) => (
            <DocumentMetaDataFields
              title={t('optional_fields')}
              onFieldsUpdate={onChange}
              values={value ?? []}
              fields={systemTypeGroup?.optional_fields ?? []}
              required={false}
            />
          )}
        />
        <ModalFooter>
          <Button onClick={closeModal}>{t('cancel')}</Button>
          {creating && (
            <Button
              type={Button.ButtonType.SECONDARY}
              onClick={() => setCreateMultiple(true)}
              disabled={loading}
            >
              {loading ? <Spinner /> : t('add_multiple')}
            </Button>
          )}
          <Button
            type={Button.ButtonType.PRIMARY}
            onClick={() => setCreateMultiple(false)}
            disabled={loading}
          >
            {loading ? <Spinner /> : t('save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default DeliveryForm
