import {
  IMeetingData,
  IMeeting,
  IMeetingSeriesData,
  IMeetingSeries,
  IMeetingUser,
  IMeetingTopic,
  IMeetingTasksStat,
  IMeetingSeriesTasksStat,
} from './OrgTypes'
import { getRequest, request } from './service-utils'

export const getAllMeetingSeries = (
  projectId: number,
): Promise<IMeetingSeries[]> => {
  return request(`projects/${projectId}/meeting_series`, 'GET')
}

export const getMeetingSeriesTree = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/meeting_series/tree`, 'GET')
}

export const getMeetingTree = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/meetings/tree`, 'GET')
}

export const createMeetingSeries = (
  meetingSeriesData: IMeetingSeriesData,
  projectId: number,
): Promise<any> => {
  return request(
    `projects/${projectId}/meeting_series`,
    'POST',
    meetingSeriesData,
  )
}

export const editMeetingSeries = (meetingSeriesData: any): Promise<any> => {
  return request(
    `meeting_series/${meetingSeriesData.id}`,
    'PUT',
    meetingSeriesData,
  )
}

export const deleteMeetingSeries = (
  meetingSeriesData: IMeetingSeriesData,
): Promise<any> => {
  return request(`/meeting_series/${meetingSeriesData.id}`, 'DELETE', {}, true)
}

export const getMeetingSeries = (
  meetingSeriesId?: number,
): Promise<IMeetingSeries | void> => {
  if (!meetingSeriesId) return Promise.resolve()
  return request(`meeting_series/${meetingSeriesId}`, 'GET')
}

export const getMeeting = (meetingId?: number): Promise<IMeeting | void> => {
  if (!meetingId) return Promise.resolve()
  return getRequest(`meetings/${meetingId}`, { consider404Error: true })
}

export const editMeeting = (
  meetingData: Partial<IMeetingData>,
): Promise<IMeeting> => {
  return request(`meetings/${meetingData.id}`, 'PUT', meetingData)
}

export const editMeetingParticipants = (
  participants: IMeetingUser[],
  meetingId: number,
): Promise<IMeeting> => {
  return request(`meetings/${meetingId}/participants`, 'PUT', participants)
}

export const createMeetingParticipant = (
  participant: IMeetingUser,
  meetingId: number,
): Promise<IMeetingUser> => {
  return request(`meetings/${meetingId}/participants`, 'POST', participant)
}

export const editMeetingParticipant = (
  participant: IMeetingUser,
): Promise<IMeetingUser> => {
  return request(`/participants/${participant.id}`, 'PUT', participant)
}

export const deleteMeetingParticipant = (participantId: number) => {
  return request(`/participants/${participantId}`, 'DELETE', {}, true)
}

export const createMeetingSeriesParticipant = (
  meetingSeriesId: number,
  participantId: number,
) => {
  return request(
    `/meeting_series/${meetingSeriesId}/add_participant`,
    'PUT',
    { user: participantId },
    true,
  )
}

export const deleteMeetingSeriesParticipant = (
  meetingSeriesId: number,
  participantId: number,
) => {
  return request(
    `/meeting_series/${meetingSeriesId}/remove_participant`,
    'PUT',
    { user: participantId },
    true,
  )
}

export const deleteMeeting = (meetingData: IMeetingData) => {
  return request(`/meetings/${meetingData.id}`, 'DELETE', {}, true)
}

export const createMeetingTopic = (
  meetingTopic: Omit<IMeetingTopic, 'id'>,
): Promise<IMeetingTopic> => {
  return request(`topics`, 'POST', meetingTopic)
}

export const editMeetingTopic = (
  meetingTopic: IMeetingTopic,
): Promise<IMeetingTopic> => {
  return request(`topics/${meetingTopic.id}`, 'PUT', meetingTopic)
}

export const deleteMeetingTopic = (meetingTopic: IMeetingTopic) => {
  return request(`/topics/${meetingTopic.id}`, 'DELETE', {}, true)
}

export const getMeetingTopic = (
  meetingTopicId: number,
): Promise<IMeetingTopic> => {
  return request(`/topics/${meetingTopicId}`, 'GET')
}

export const getMeetingSeriesTopcs = (
  meetingSeriesId: number,
): Promise<IMeetingTopic[]> => {
  return request(`meeting_series/${meetingSeriesId}/topics`, 'GET')
}

export const getMeetingTaskStats = (
  meetingId: number,
): Promise<IMeetingTasksStat> => {
  return request(`meetings/${meetingId}/stats`, 'GET')
}

export const getMeetingSeriesTaskStatus = (
  meetingSeriesId: number,
): Promise<IMeetingSeriesTasksStat> => {
  return request(`meeting_series/${meetingSeriesId}/stats`, 'GET')
}

export const sendInviteToTMeetingParticipant = (
  meetingId: number,
  send: boolean,
  lang: string,
  invitationSubject?: string,
  invitationBody?: string,
  to?: { userId?: number; email: string }[],
): Promise<void> => {
  return send
    ? request(
        `/meetings/${meetingId}/send_invite`,
        'PUT',
        {
          send_invitation: send,
          invitation_subject: invitationSubject,
          invitation_text: invitationBody,
          lang,
          to: to?.filter((i) => i.userId).map((item) => item.email),
          to_outside: to?.filter((i) => !i.userId)?.map((item) => item.email),
          timeZoneOffset: new Date().getTimezoneOffset(),
        },
        true,
      )
    : Promise.resolve()
}

export const sendInviteToTMeetingSeriesParticipant = (
  meetingId: number,
  send: boolean,
  lang: string,
  invitationSubject?: string,
  invitationBody?: string,
  to?: string[],
): Promise<void> => {
  return send
    ? request(
        `/meeting_series/${meetingId}/send_invite`,
        'PUT',
        {
          send_invitation: send,
          invitation_subject: invitationSubject,
          invitation_text: invitationBody,
          lang,
          to,
          timeZoneOffset: new Date().getTimezoneOffset(),
        },
        true,
      )
    : Promise.resolve()
}

export const swapOrderMeetingSeriesTopic = (
  topicId: number,
  sequence: number,
): Promise<any[]> => {
  return request(`topics/${topicId}/update_sequence`, 'PUT', {
    sequence: sequence,
  })
}

export const getMeetingsForCalender = (
  projectId: number,
  data: any,
): Promise<IMeeting[]> => {
  return request(`projects/${projectId}/calender_meetings`, 'POST', data)
}

export const getAllMeetingSeriesFiltered = (
  projectId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
): Promise<any> => {
  return request(
    `projects/${projectId}/meeting_series/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getAllMeetingsFiltered = (
  projectId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
): Promise<any> => {
  return request(
    `/projects/${projectId}/meetings/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const createMeeting = (
  meetingData: IMeetingData,
  projectId: number,
): Promise<IMeeting> => {
  return request(`projects/${projectId}/meetings`, 'POST', meetingData)
}

export const getFilteredMeetingTopics = (
  meetingId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
  meetingSeriesId?: number,
): Promise<{ pages: number; topics: IMeetingTopic[] }> => {
  return request(
    `meetings/${meetingId}/filtered_topics/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    { ...filter, meeting_series_id: meetingSeriesId },
  )
}

export const getFilteredMeetingSeriesTopics = (
  meetingSeriesId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
) => {
  return request(
    `meeting_series/${meetingSeriesId}/filtered_topics/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}
