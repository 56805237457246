import { upperFirst } from 'lodash'
import * as React from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { testCaseImportTemplate } from 'src/export-templates/TestCaseImportTemplateFile'
import {
  deleteBulkTestCaseGroups,
  deleteBulkTestCases,
} from 'src/service/TestService'
import { IImportItemList, ITest, ITestCase } from '../../service/OrgTypes'
import ExpandableTable from '../../systematic-completion/components/expandable-table/ExpandableTable'
import { useExpandableTable } from '../../systematic-completion/components/expandable-table/hooks/useExpandableTable'
import { COLUMN_TYPES } from '../../systematic-completion/components/expandable-table/types/COLUMN_TYPES'
import { ICellUpdate } from '../../systematic-completion/components/expandable-table/types/ICellUpdate'
import { IExpandableSection } from '../../systematic-completion/components/expandable-table/types/IExpandableSection'
import { IExpandableSectionConfig } from '../../systematic-completion/components/expandable-table/types/IExpandableSectionConfig'
import { IHeader } from '../../systematic-completion/components/expandable-table/types/IHeader'
import { IRow } from '../../systematic-completion/components/expandable-table/types/IRow'
import SystematicCompletionService from '../../systematic-completion/services/SystematicCompletionService'

export interface ITestGroup {
  id: number
  name: string
  projectId: number
  testId: number
  status: string
  order: number
}

const TestCaseTable: React.FC<{
  test: Pick<ITest, 'id'>
  projectId: number
  readonly?: boolean
  onSelector?: boolean
  selectAble?: boolean
  selectCallback?: (selectedIds: number[], selectedSectionIds: number[]) => void
  selectedTestCases?: number[]
  selectedTestCaseGroups?: number[]
}> = ({
  test,
  projectId,
  readonly = false,
  onSelector,
  selectAble = false,
  selectCallback,
  selectedTestCases,
  selectedTestCaseGroups,
}) => {
  const [rows, setRows] = useState<Record<string, IRow>>({})
  const [expandableSections, setExpandableSections] = useState<
    IExpandableSection[]
  >([])
  const [expandableSectionsConfig, setExpandableSectionsConfig] = useState<
    Record<string, IExpandableSectionConfig>
  >({})
  const prevTestId = useRef(0)
  const systematicCompletionService = useMemo(
    () => new SystematicCompletionService(projectId, test.id),
    [projectId, test.id],
  )

  const onCellUpdated = ({ header, rowId, newValue }: ICellUpdate) => {
    return systematicCompletionService.updateAtomicTestCase(
      rowId,
      header.field,
      newValue,
    )
  }

  const addRow = async (
    sectionId: IExpandableSection['id'],
    _: any,
    rowIndex: number,
  ) => {
    const response = await systematicCompletionService.createTestCase(
      sectionId,
      rowIndex + 1,
    )
    return response.id
  }

  const addSection = async (sectionTitle: string) => {
    const response =
      await systematicCompletionService.createTestGroup(sectionTitle)
    return response.id
  }

  const updateSection = (sectionId: number, newTitle: string) => {
    return systematicCompletionService.updateTestGroup(sectionId, newTitle)
  }

  const deleteRow = async (rowId: string) => {
    return systematicCompletionService.deleteTestCase(rowId)
  }

  const deleteSection = async (sectionId: number) => {
    systematicCompletionService.deleteTestGroupTestCases(sectionId)
    systematicCompletionService.deleteTestGroup(sectionId)
  }

  const { t } = useTranslation()

  const headers = useMemo(
    (): IHeader[] => [
      {
        id: 'order',
        label: upperFirst(t('item')),
        field: 'order',
        initialWidth: onSelector ? 300 : 100,
        readonly: true,
        minWidth: 100,
        type: COLUMN_TYPES.CUSTOM,
        draggable: false,
        cell: ({
          rowIdx,
          sectionIdx,
        }: {
          rowIdx: number
          sectionIdx: number
        }) => {
          return (
            <span>{`
            ${(sectionIdx + 1).toString().padStart(2, '0')}.${(rowIdx + 1)
              .toString()
              .padStart(2, '0')} `}</span>
          )
        },
      },
      {
        id: 'description',
        label: upperFirst(t('description')),
        field: 'description',
        initialWidth: onSelector ? 500 : 350,
        readonly: readonly,
        minWidth: 200,
        type: COLUMN_TYPES.TEXT,
        draggable: false,
        placeholder: upperFirst(t('add_description')),
      },
      {
        id: 'requirement',
        label: upperFirst(t('expected_result')),
        field: 'requirement',
        initialWidth: onSelector ? 480 : 350,
        readonly: readonly,
        minWidth: 200,
        type: COLUMN_TYPES.TEXT,
        draggable: false,
        placeholder: upperFirst(t('add_expected_result')),
      },
    ],
    [t],
  )

  const createTable = (testGroups: ITestGroup[], testCases: ITestCase[]) => {
    const expandableSectionsConfigTmp: Record<
      string,
      IExpandableSectionConfig
    > = {}

    testGroups.map((testGroup) => {
      expandableSectionsConfigTmp[testGroup.id] = {
        id: testGroup.id,
        isExpanded: true,
        rows: testCases
          .filter((tc) => tc.test_case_group_id === testGroup.id)
          .map((tc) => tc.id!)
          .filter(Boolean),
        title: testGroup.name,
      }
    })

    const expandableSectionsTmp = testGroups.map((e) => {
      return { id: e.id }
    })

    testCases.map((testCase) => {
      rows[testCase.id!] = {
        id: testCase.id!,
        order: testCase.order,
        description: testCase.description,
        requirement: testCase.requirement,
      }
    })

    setExpandableSectionsConfig(expandableSectionsConfigTmp)
    setExpandableSections(expandableSectionsTmp)
    setRows(rows)
  }

  const getData = useCallback(async () => {
    if (test.id) {
      const testGroups: ITestGroup[] =
        await systematicCompletionService.getTestGroups()
      const testCases: ITestCase[] = await systematicCompletionService
        .getTestCases()
        .then((res) => res.test_cases)

      createTable(testGroups, testCases)
    }
  }, [test.id, systematicCompletionService, createTable])

  const updateOrder = async (
    row: IRow,
    _: number,
    newIndex: number,
    oldSectionIndex: IExpandableSection['id'],
    newSectionIndex: IExpandableSection['id'],
  ) => {
    const isSection = row.hasOwnProperty('isExpanded')
    if (isSection) {
      await systematicCompletionService.updateTestGroupOrder(
        row.id,
        newIndex + 1,
      )
    } else {
      if (oldSectionIndex === newSectionIndex) {
        await systematicCompletionService.updateTestCaseOrder(
          row.id,
          newIndex + 1,
        )
      } else {
        await systematicCompletionService.updateTestCaseOrder(
          row.id,
          newIndex + 1,
          newSectionIndex,
        )
      }
    }
  }

  useEffect(() => {
    if (test.id !== prevTestId.current) {
      prevTestId.current = test.id!
      getData()
    }
  }, [getData, test])

  const { store, interactionStore } = useExpandableTable(
    `test-detail-table-${test.id}`,
    {
      onCellUpdated,
      createRow: addRow,
      deleteRow: deleteRow,
      createSection: addSection,
      deleteSection: deleteSection,
      onSectionTitleUpdate: updateSection,
      onReorderRows: updateOrder,
      onSelectCallback: selectCallback,
      partialStore: {
        headers,
        rows,
        expandableSections,
        expandableSectionsConfig,
        readonly,
        sectionTitle: 'test_chapter',
        itemTitle: 'test_element',
        selectedIds: selectedTestCases ?? [],
        selectedSectionIds: selectedTestCaseGroups ?? [],
        addSectionTitle: 'add_test_chapter',
        randomNumber: true,
        bulkDelete: true,
        isRowSelectable: selectAble || !readonly,
      },
    },
  )

  const reload = () => {
    getData()
  }

  const importTestCaseTemplate: IImportItemList = {
    title: t('upload_test_case'),
    templateJson: testCaseImportTemplate,
    type: 'test_cases',
    path: `/test/${test.id}`,
    reload,
  }

  const handleBulkDelete = async (rowIds: number[], sectionIds: number[]) => {
    if (projectId) {
      await deleteBulkTestCases(projectId, rowIds)
      await deleteBulkTestCaseGroups(projectId, sectionIds)
      getData()
      return true
    }
    return false
  }

  return (
    <ExpandableTable
      store={store}
      interactionStore={interactionStore}
      canReorderRows={true}
      projectId={projectId}
      readonly={readonly}
      testId={test.id}
      importItem={importTestCaseTemplate}
      reload={getData}
      onBulkDelete={handleBulkDelete}
    />
  )
}

export default TestCaseTable
