import { FC, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import NoDataFound from 'src/components/no-data-found/NoDataFound'
import Checkbox from 'src/components/switchHoc/CheckBox'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import {
  getDependentDeliveries,
  getDependentOnDeliveries,
  removeDeliveryDependency,
} from 'src/service/DeliveryService'
import { IDelivery, IKeypoint } from 'src/service/OrgTypes'
import {
  getDependentKeyPoints,
  getDependentOnKeyPoints,
  removeKeypointDependency,
} from 'src/service/ProcessService'
import Button from 'src/ui-elements/button/Button'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Modal from '../../ui-elements/modal/Modal'

export enum CanvasDeleteModalType {
  Delivery = 'delivery',
  Keypoint = 'keypoint',
}

type ItemTypeToDataType = {
  [CanvasDeleteModalType.Delivery]: IDelivery
  [CanvasDeleteModalType.Keypoint]: IKeypoint
}

interface IDependenciesDeleteModal<T extends CanvasDeleteModalType> {
  itemType: T
  data: ItemTypeToDataType[T]
  onClose: () => void
  onSubmit: () => void
}

const DependenciesDeleteModal: FC<
  IDependenciesDeleteModal<CanvasDeleteModalType>
> = ({ itemType, data, onClose, onSubmit }) => {
  const { t } = useTranslation()

  const projectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const [dependentOns, setDependentOns] = useState<IDelivery[] | IKeypoint[]>(
    [],
  )
  const [dependents, setDependents] = useState<IDelivery[] | IKeypoint[]>([])
  const [selectedDependencies, setSelectedDependencies] = useState<number[]>([])
  const [selectedDependents, setSelectedDependents] = useState<number[]>([])
  const [loading, setLoading] = useState(false)
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    const fetchDependecies = async () => {
      setLoading(true)
      if (itemType === CanvasDeleteModalType.Delivery) {
        const dependentOnDeliveries = await getDependentOnDeliveries(
          projectId,
          data.id,
        )
        setDependentOns(dependentOnDeliveries ?? [])
        const dependentDeliveries = await getDependentDeliveries(
          projectId,
          data.id,
        )
        setDependents(dependentDeliveries ?? [])
        setLoading(false)
      }
      if (itemType === CanvasDeleteModalType.Keypoint) {
        const dependentOnKeypoints = await getDependentOnKeyPoints(
          projectId,
          data.id,
        )
        setDependentOns(dependentOnKeypoints ?? [])
        const dependentKeypoints = await getDependentKeyPoints(
          projectId,
          data.id,
        )
        setDependents(dependentKeypoints ?? [])
        setLoading(false)
      }
    }
    fetchDependecies()
  }, [data.id, projectId])

  const handleSelectionDependencies = (checked: boolean, depId: number) => {
    if (!checked) {
      setSelectedDependencies((prev) => prev.filter((id) => id !== depId))
      return
    }
    setSelectedDependencies((prev) => [...prev, depId])
  }
  const handleSelectionDependents = (checked: boolean, depId: number) => {
    if (!checked) {
      setSelectedDependents((prev) => prev.filter((id) => id !== depId))
      return
    }
    setSelectedDependents((prev) => [...prev, depId])
  }

  const handleSelectAllDependencies = (checked: boolean) => {
    if (!checked) {
      setSelectedDependencies([])
      return
    }
    setSelectedDependencies(dependentOns?.map((del) => del?.id))
  }

  const handleSelectAllDependents = (checked: boolean) => {
    if (!checked) {
      setSelectedDependents([])
      return
    }
    setSelectedDependents(dependents?.map((del) => del?.id))
  }

  const isAllDependenciesSelected = useMemo(() => {
    return (
      !!dependentOns.length &&
      selectedDependencies.length === dependentOns.length
    )
  }, [selectedDependencies, dependentOns])

  const isAllDependentsSelected = useMemo(() => {
    return (
      !!dependents.length && selectedDependents.length === dependents.length
    )
  }, [selectedDependents, dependents])

  const handleMassDelete = async () => {
    if (
      (!selectedDependencies?.length && !selectedDependents?.length) ||
      saving
    ) {
      return
    }
    setSaving(true)
    if (itemType === CanvasDeleteModalType.Delivery) {
      await removeDeliveryDependency(data.id, {
        dependent_on_deliveries: [...selectedDependencies],
        dependent_deliveries: [...selectedDependents],
      })
    }

    if (itemType === CanvasDeleteModalType.Keypoint) {
      await removeKeypointDependency(data.id, {
        dependent_on_key_point: [...selectedDependencies],
        dependent_key_point: [...selectedDependents],
      })
    }

    setSelectedDependencies([])
    setSelectedDependents([])
    setSaving(false)
    onSubmit()
  }

  return (
    <Modal
      title={t('delete_dependencies_for_w_param', {
        name: `${data.record_id ?? ''} ${data?.name ?? ''}`,
      })}
      closeModal={onClose}
      show={true}
      maxWidth={800}
    >
      <div
        className={
          'flex flex-col gap-2 text-sm min-w-[400px] h-auto min-h-[400px]'
        }
      >
        <div className={'w-full flex flex-row'}>
          <section className={'flex flex-col w-1/2 justify-between pb-4'}>
            <div className={'flex flex-col'}>
              <span
                className={
                  'flex items-center gap-2 w-full text-gray-700 text-sm mb-1 normal-case'
                }
              >
                <Checkbox
                  onChange={(v) => handleSelectAllDependencies(v)}
                  valueProp={isAllDependenciesSelected}
                />
                <span className={'font-medium first-capitalize'}>
                  {`${t('select_all')} ${t('incoming_dependencies')}`}
                </span>
              </span>

              {loading ? (
                <div
                  className={
                    'min-w-full min-h-[400px] grid place-items-center scale-75'
                  }
                >
                  <SpinnerMobile />
                </div>
              ) : dependentOns.length ? (
                dependentOns.map((depItem: IDelivery | IKeypoint) => (
                  <p
                    key={depItem.id}
                    className={`flex py-0.5 text-sm items-center w-full pr-1 mb-1 gap-2`}
                  >
                    <Checkbox
                      onChange={(v: boolean) =>
                        handleSelectionDependencies(v, depItem?.id)
                      }
                      valueProp={selectedDependencies?.includes(depItem?.id)}
                    />
                    <span className={'text-gray-500 whitespace-nowrap'}>
                      {`${depItem?.record_id}`}
                    </span>
                    <span className={'truncate'}>{`${depItem?.name}`}</span>
                  </p>
                ))
              ) : (
                <NoDataFound />
              )}
            </div>
          </section>
          <section className={'flex flex-col w-1/2 justify-between pb-4'}>
            <div className={'flex flex-col'}>
              <span
                className={
                  'flex items-center gap-2 w-full text-gray-700 text-sm mb-1 normal-case'
                }
              >
                <Checkbox
                  onChange={(v) => handleSelectAllDependents(v)}
                  valueProp={isAllDependentsSelected}
                />
                <span className={'font-medium first-capitalize'}>
                  {`${t('select_all')} ${t('outgoing_dependencies')}`}
                </span>
              </span>

              {loading ? (
                <div
                  className={
                    'min-w-full min-h-[400px] grid place-items-center scale-75'
                  }
                >
                  <SpinnerMobile />
                </div>
              ) : dependents.length ? (
                dependents.map((depItem: IDelivery | IKeypoint) => (
                  <p
                    key={depItem.id}
                    className={`flex py-0.5 text-sm items-center w-full pr-1 mb-1 gap-2`}
                  >
                    <Checkbox
                      onChange={(v: boolean) =>
                        handleSelectionDependents(v, depItem?.id)
                      }
                      valueProp={selectedDependents?.includes(depItem?.id)}
                    />
                    <span className={'text-gray-500 whitespace-nowrap'}>
                      {`${depItem?.record_id}`}
                    </span>
                    <span className={'truncate'}>{`${depItem?.name}`}</span>
                  </p>
                ))
              ) : (
                <NoDataFound />
              )}
            </div>
          </section>
        </div>
      </div>
      <ModalFooter>
        <Button onClick={() => onClose()} type={Button.ButtonType.SECONDARY}>
          {`${t('cancel')}`}
        </Button>
        <Button
          onClick={() => handleMassDelete()}
          type={Button.ButtonType.PRIMARY}
          disabled={
            (!selectedDependencies.length && !selectedDependents.length) ||
            saving
          }
        >
          {`${t('delete')} (${
            selectedDependencies?.length + selectedDependents?.length
          })`}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default DependenciesDeleteModal
