import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import ExportService from 'src/service/ExportService'
import { IImportItemList } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Modal from 'src/ui-elements/modal/Modal'
import {
  convertUploadType,
  getOrganizationBaseUrl,
  openFile,
} from '../../utility/utils'
import UploadFile from '../document/UploadFile'
import { translateTemplate } from './ImportHelpers'

export interface IUploadModalProps {
  showModal: boolean
  closeModal: () => void
  importItem: IImportItemList
}

export interface IErrorMessage {
  error_count: number
  error_file_url: string
}

const UploadModal = ({
  showModal,
  closeModal,
  importItem,
}: IUploadModalProps) => {
  const projectContext = useContext(ProjectContext)
  const { t } = useTranslation()

  const [errorMessage, setErrorMessage] = useState<IErrorMessage | undefined>(
    undefined,
  )

  const uploadSuccessfully = () => {
    importItem.reload()
    closeModal()
  }

  const errorResponse = (res: any) => {
    if (res) {
      setErrorMessage(JSON.parse(res))
    }
  }

  const uploadType = (): string => {
    return convertUploadType(importItem.type)
  }

  const uploadUrl = (): string => {
    let base = `${getOrganizationBaseUrl()}/projects/${
      projectContext.state.currentProject.id
    }/${importItem.type}/import`
    if (importItem.path) base += `/${importItem.path}`
    return base
  }

  return (
    <Modal
      show={showModal}
      title={`${importItem.title}`}
      closeModal={closeModal}
      size={'w-1/3'}
      maxWidth={600}
    >
      <div
        style={{ minHeight: '300px' }}
        className="flex justify-center items-center"
      >
        <div className="w-full pr-2">
          {!errorMessage ? (
            <>
              <UploadFile
                uploadedDocuments={() => uploadSuccessfully()}
                uploadUrl={uploadUrl()}
                errorResponse={errorResponse}
                allowRevert={false}
                singleFileUpload={true}
              />
              <div
                className={'flex justify-center items-center text-sm'}
                style={{ color: '#4f4f4f' }}
              >
                <p>
                  {t('use')}{' '}
                  <a
                    className="cursor-pointer underline text-blue-one"
                    onClick={() => {
                      ExportService.exportImportTemplate(
                        translateTemplate(importItem.templateJson, t),
                      )
                    }}
                  >
                    {' '}
                    {t('this_template')}{' '}
                  </a>
                </p>
              </div>
            </>
          ) : (
            <>
              <div
                className={'flex flex-col justify-center items-center text'}
                style={{ color: '#4f4f4f' }}
              >
                <p className="text-center text-red-700">
                  {t('the_file_you_uploaded_to_create')}{' '}
                  {importItem.label ?? uploadType()} {t('has')}{' '}
                  <strong>{errorMessage.error_count}</strong> {t('errors')}
                  .&nbsp;
                  <a
                    className="cursor-pointer underline text-blue-one"
                    onClick={() => {
                      openFile(errorMessage.error_file_url)
                    }}
                  >
                    {t('read_the_log')}
                  </a>
                </p>
                <div>
                  <Button
                    type={ButtonType.PRIMARY}
                    onClick={() => setErrorMessage(undefined)}
                  >
                    {t('try_again')}
                  </Button>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default UploadModal
