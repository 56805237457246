import AttachFile from '@icons/attach_file.svg'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import { IFdvuFileContainer } from 'src/fdvu/types/IFdvuFileContainer'
import { useSubcontractorFileContainers } from 'src/query/fdvu/subcontractor'
import { testDocumentBadgeColorMapper } from 'src/ui-elements/Table/module-columns/DocumentModuleColumns'
import Badge from 'src/ui-elements/badge/Badge'

interface IAssignedFileContainerList {
  selectedFileContainerId?: number
  setSelectedFileContainer: (fileContainer: IFdvuFileContainer) => void
  hideInReview: boolean
}

const AssignedFileContainerList: FC<IAssignedFileContainerList> = ({
  selectedFileContainerId,
  setSelectedFileContainer,
  hideInReview,
}) => {
  const { t } = useTranslation()
  const { data: fileContainers = [] } =
    useSubcontractorFileContainers(hideInReview)

  return (
    <div className={'rounded-sm border bg-white'}>
      <div style={{ maxHeight: '70vh' }} className={'mb-10 overflow-y-auto'}>
        {fileContainers.map((fileContainer, index) => (
          <div
            key={fileContainer.id}
            onClick={() => {
              setSelectedFileContainer(fileContainer)
            }}
            className={twMerge(
              'flex justify-between w-full text-gray-700 cursor-pointer p-2 hover:bg-blue-50',
              selectedFileContainerId === fileContainer.id &&
                'bg-blue-100 font-medium',
              index >= fileContainers.length - 1 && 'rounded-b-sm',
              index === 0 && 'rounded-t-sm',
            )}
          >
            <div className={'flex items-center gap-2'}>
              <div className="min-w-24 flex flex-col">
                <Badge
                  text={t(fileContainer.status)}
                  color={testDocumentBadgeColorMapper[fileContainer.status]}
                />
              </div>
              <p>{fileContainer.record_id}</p>
              <p>{fileContainer.name}</p>
              {fileContainer.project_files.length > 0 && (
                <div className={'text-xs text-blue-root flex'}>
                  <AttachFile className={'fill-blue-root text-lg'} />
                  <span className="font-medium">
                    {fileContainer.project_files.length}
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AssignedFileContainerList
