export enum StatusEnum {
  EMPTY = 'EMPTY',
  OVERDUE = 'OVERDUE',
  DECLINED = 'DECLINED',
  OPEN = 'OPEN',
  COMPLETED = 'COMPLETED',
  APPROVED = 'APPROVED',
  APPROVED_WITH_ISSUES = 'APPROVED_WITH_ISSUES',
  SUGGESTED = 'SUGGESTED',
  UPLOADED = 'UPLOADED',
  NOT_UPLOADED = 'NOT_UPLOADED',
  REVIEWED = 'REVIEWED',
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  IN_REVIEW = 'IN_REVIEW',
  DONE = 'DONE',
  DONE_WITH_ISSUES = 'DONE_WITH_ISSUES',
  NOT_RELEVANT = 'NOT_RELEVANT',
  ERROR = 'ERROR',
  NOT_SET = 'NOT_SET',
  DELIVERED = 'DELIVERED',
}

export const StatusLEnum = {
  ...Object.keys(StatusEnum).reduce(
    (acc, key) => {
      acc[key] = key.toLowerCase()
      return acc
    },
    {} as Record<string, string>,
  ),
}

export type IStatusLEnum = keyof typeof StatusLEnum
