export class RowCounter {
  private currentRowIndex = 0

  readonly incrementCurrentRow = () => {
    this.currentRowIndex++
  }

  readonly decrementRow = () => {
    this.currentRowIndex--
  }

  readonly setCurrentRowIndex = (rowIndex: number) => {
    this.currentRowIndex = rowIndex
  }

  readonly getCurrentRowAndIncrement = () => {
    const tmpCurrentIndex = this.currentRowIndex
    this.currentRowIndex++
    return tmpCurrentIndex
  }
}
