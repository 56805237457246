import * as React from 'react'
import { useContext } from 'react'
import { useExpandableTableInteractionStoreState } from 'src/systematic-completion/components/expandable-table/hooks/useExpandableTableInteractionStore'
import { ExpandableTableContext } from '../../context/ExpandableTableContext'
import { useExpandableTableStoreState } from '../../hooks/useExpandableTableStore'
import { IExpandableSection } from '../../types/IExpandableSection'
import AddRowBottom from '../add-row/AddRowBottom'
import Row from '../row/Row'
import ExpandableRow from './ExpandableRow'

interface ExpandableSectionProps {
  section: IExpandableSection
  initialItem?: Record<string, unknown>
  isLastSection?: boolean
}

const ExpandableSection: React.FC<ExpandableSectionProps> = ({
  section,
  initialItem,
  isLastSection,
}) => {
  const sectionConfig = useExpandableTableStoreState(
    (state) => state.expandableSectionsConfig[section.id],
  )
  const { interactionStore } = useContext(ExpandableTableContext)
  const {
    readonly,
    updateSelectedIds,
    selectedIds,
    updateSelectedSectionIds,
    selectedSectionIds,
  } = useExpandableTableStoreState((state) => state)
  const { reorderRowsHandler } = useContext(ExpandableTableContext)
  const isDraggingSection = useExpandableTableInteractionStoreState(
    (state) => !!state.draggingSection,
  )

  if (!sectionConfig) {
    return null
  }

  const onSelectRow = (rowid: number) => {
    if (!selectedIds.includes(rowid)) {
      updateSelectedIds([...selectedIds, rowid])
    } else {
      updateSelectedIds(selectedIds.filter((id) => id !== rowid))
    }
  }

  const selected = selectedSectionIds.includes(section.id)

  const onSelectSection = () => {
    if (selected) {
      updateSelectedSectionIds(
        selectedSectionIds.filter((id) => id !== section.id),
      )
      updateSelectedIds(
        selectedIds.filter((id) => !sectionConfig.rows.includes(id)),
      )
    } else {
      updateSelectedSectionIds([...selectedSectionIds, section.id])
      const notSelectedRows = sectionConfig.rows.filter(
        (rowId) => !selectedIds.includes(rowId),
      )
      updateSelectedIds([...selectedIds, ...notSelectedRows])
    }
  }

  const onDragEnter = () => {
    if (!readonly) {
      isDraggingSection && reorderRowsHandler.setDragTargetSection(section)
    }
  }

  return (
    <div
      draggable={!readonly}
      onDragStart={() => reorderRowsHandler.setDraggingSection(sectionConfig)}
      onDragEnd={(e) => {
        e.nativeEvent.preventDefault()
        reorderRowsHandler.onSectionDraggingEnd()
      }}
      onDragOver={(e) => {
        e.dataTransfer.dropEffect = 'move'
        e.nativeEvent.preventDefault()
      }}
      onDragEnter={onDragEnter}
      data-rowid={section.id}
      onFocus={() =>
        interactionStore.setState({ focusedSectionId: section.id })
      }
    >
      <ExpandableRow
        section={section}
        onRowSelect={onSelectSection}
        selected={selected}
        initialItem={initialItem}
      />
      {sectionConfig.isExpanded &&
        sectionConfig.rows.map((rowId, i) => (
          <Row
            key={rowId}
            rowId={rowId}
            section={section}
            rowIndex={i}
            onRowSelect={() => onSelectRow(rowId)}
            initialItem={initialItem}
          />
        ))}
      {sectionConfig.isExpanded && (
        <AddRowBottom
          section={section}
          rowIndex={sectionConfig.rows.length - 1}
          initialItem={initialItem}
          isLastSection={isLastSection}
        />
      )}
      {section.children?.map((section) => (
        <ExpandableSection
          key={section.id}
          section={section}
          initialItem={initialItem}
        />
      ))}
    </div>
  )
}

export default ExpandableSection
