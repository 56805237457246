import Add from '@material-symbols/svg-500/rounded/add.svg'
import { capitalize } from 'lodash'
import { FC, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import Selector from 'src/components/selectors/Selector'
import { renderConnectionDirection } from 'src/components/system/system-connection-type/SystemConnectionTypeList'
import SystemSelectModal from 'src/components/system/system-table/SystemSelectModal'
import SystemTable from 'src/components/system/system-table/SystemTable'
import { useProjectSystemConnectionTypes } from 'src/query/systems/systemConnections'
import { ISystem, ISystemConnectionType } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import Textarea from 'src/ui-elements/textarea/Textarea'

export interface ICreateSystemsConnection {
  connected_system_ids: number[]
  system_id: number
  system_connection_type_id: number
  comment: string
  main_system_role: 'role_a' | 'role_b'
}

type Role = {
  id: 'role_a' | 'role_b'
  name: string
}

interface ISelectSystemConnectionsProps {
  onSelectSystemConnections: (data: ICreateSystemsConnection) => void
  closeModal: () => void
  system: ISystem
}

const SelectSystemConnections: FC<ISelectSystemConnectionsProps> = ({
  onSelectSystemConnections,
  closeModal,
  system,
}) => {
  const projectId = useProjectId()
  const [showSelectSystemModal, setShowSelectSystemModal] =
    useState<boolean>(false)
  const [connectionType, setConnectionType] = useState<ISystemConnectionType>()
  const [roles, setRoles] = useState<Role[]>([])

  const { handleSubmit, control, watch } = useForm<ICreateSystemsConnection>({
    defaultValues: { system_id: system.id },
  })

  const selectedSystemIds = watch('connected_system_ids')
  const role = watch('main_system_role')

  const { t } = useTranslation()
  const { data: systemConnectionTypes } = useProjectSystemConnectionTypes()

  const onSubmit = async (data: ICreateSystemsConnection) => {
    onSelectSystemConnections(data)
  }

  const onSystemConnectionTypeChange = (value: number) => {
    const selectedType = systemConnectionTypes?.find((c) => c.id === value)
    setConnectionType(selectedType)
    setRoles([
      { id: 'role_a', name: selectedType?.role_a ?? '' },
      { id: 'role_b', name: selectedType?.role_b ?? '' },
    ])
  }

  const selectButton = (
    <Button
      key={'add-system-connection'}
      onClick={(e) => {
        e.preventDefault()
        setShowSelectSystemModal(true)
      }}
      type={Button.ButtonType.PRIMARY}
      className={'h-8 pl-2'}
    >
      <Add className={'mr-1 text-xl fill-white'} />
      {t('select_w_param', { param: t('systems') })}
    </Button>
  )
  return (
    <Modal
      show={true}
      title={t('select_items_you_want_to_add_w_param', {
        param: t('systems').toLowerCase(),
      })}
      closeModal={closeModal}
      size={'w-9/10'}
      minHeight={'300px'}
      maxWidth={1800}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex gap-4">
          <div className={'w-1/2 my-2'}>
            <Controller
              name="system_connection_type_id"
              control={control}
              rules={{
                required: t('fill_out_w_param', {
                  param: t('system_connection_type').toLowerCase(),
                }),
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Selector
                  items={systemConnectionTypes ?? []}
                  selectedItemId={value || 0}
                  onSelect={(value) => {
                    onChange(value)
                    onSystemConnectionTypeChange(value)
                  }}
                  label={t('system_connection_type')}
                  dataFields={['name']}
                  required={true}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  userSelector={false}
                  errorMessage={error?.message}
                />
              )}
            />
            <Controller
              name="main_system_role"
              control={control}
              rules={{
                required: t('fill_out_w_param', {
                  param: t('role'),
                }),
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Selector
                  items={roles}
                  selectedItemId={value}
                  onSelect={onChange}
                  label={t('role')}
                  dataFields={['name']}
                  required={true}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  userSelector={false}
                  errorMessage={error?.message}
                />
              )}
            />
          </div>
        </div>
        <div className={'w-1/2 mb-4'}>
          <Controller
            name="comment"
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Textarea
                label={t('comment')}
                value={value}
                onChange={onChange}
                errorMessage={error?.message}
                block
              />
            )}
          />
        </div>
        <>
          {connectionType && (
            <div className={'mb-2 flex flex-row items-end'}>
              <Input
                label={connectionType?.role_a ?? t('role_a')}
                name={t('role_a')}
                type={'text'}
                placeholder={''}
                value={
                  role === 'role_a'
                    ? `${system.record_id} ${system.name}`
                    : `${selectedSystemIds?.length ?? 0} ${t('systems')}`
                }
                disabled
              />
              <div className={'m-2'}>
                {renderConnectionDirection(connectionType.direction).icon}
              </div>
              <Input
                placeholder={''}
                label={connectionType?.role_b ?? t('role_b')}
                name={t('role_b')}
                defaultValue={''}
                type={'text'}
                disabled
                value={
                  role === 'role_b'
                    ? `${system.record_id} ${system.name}`
                    : `${selectedSystemIds?.length ?? 0} ${t('systems')}`
                }
              />
            </div>
          )}
          <Controller
            name="connected_system_ids"
            control={control}
            rules={{
              required: `${capitalize(t('select'))} ${t('systems').toLowerCase()}`,
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <div className={'flex flex-col'}>
                <div className={'text-sm text-red-600 ml-2'}>
                  {error?.message}
                </div>
                <div className={'overflow-auto min-h-[400px] pb-4'}>
                  {selectedSystemIds?.length > 0 ? (
                    <SystemTable
                      removeSystem={(system) => {
                        onChange(
                          selectedSystemIds.filter((sys) => sys !== system.id),
                        )
                      }}
                      massRemoveSystems={(systems) => {
                        onChange(
                          selectedSystemIds.filter(
                            (sys) => !systems.includes(sys),
                          ),
                        )
                      }}
                      projectId={projectId}
                      tableName={'addsSystemConnections'}
                      systemIds={value}
                      customButtons={[selectButton]}
                    />
                  ) : (
                    <div className={'max-w-5'}>{selectButton}</div>
                  )}
                </div>
                {showSelectSystemModal && (
                  <SystemSelectModal
                    initialSelection={selectedSystemIds ?? []}
                    targetName={t('system_connection')}
                    tableName={'addSystemsToConnection'}
                    onSubmit={(ids) => {
                      onChange(ids)
                      setShowSelectSystemModal(false)
                    }}
                    closeModal={() => setShowSelectSystemModal(false)}
                  />
                )}
              </div>
            )}
          />
        </>
        <ModalFooter className={'self-end'}>
          <Button onClick={closeModal}>{t('cancel')}</Button>
          <Button type={Button.ButtonType.PRIMARY} buttonType={'submit'}>
            {t('save')}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  )
}

export default SelectSystemConnections
