import { useContext } from 'react'
import { StoreApi, useStore } from 'zustand'
import { ExpandableTableContext } from '../context/ExpandableTableContext'
import { IExpandableTableStore } from '../stores/ExpandableTableStore'

export const useExpandableTableStoreState = <U,>(
  selector: (state: IExpandableTableStore) => U,
  equalityFn?: (a: U, b: U) => boolean,
) => {
  const { store } = useContext(ExpandableTableContext)

  return useStore<StoreApi<IExpandableTableStore>, U>(
    store,
    selector,
    equalityFn,
  )
}

export const useExpandableTableStore = () => {
  const { store } = useContext(ExpandableTableContext)

  return store
}
