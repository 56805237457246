import { FC, useMemo } from 'react'
import Loader from 'src/ui-elements/loader/Loader'
import { IUploadResponse } from '../types'
import DocUploadSummery from './DocUploadSummery'
import ImportedFilesList, { UploadedFile } from './ImportedFilesList'

interface IUploadSummary {
  folderId: number
  uploadedFiles?: UploadedFiles
}

export interface UploadedFiles {
  [key: string]: IUploadResponse
}

const UploadSummary: FC<IUploadSummary> = ({ uploadedFiles }) => {
  const { successFiles, rejectedFiles } = useMemo(() => {
    if (!uploadedFiles) return { successFiles: [], rejectedFiles: [] }

    return Object.entries(uploadedFiles).reduce(
      (acc, [fileName, file]) => {
        const fileData = { ...file, fileName }
        file.status === 'error'
          ? acc.rejectedFiles.push(fileData)
          : acc.successFiles.push(fileData)
        return acc
      },
      {
        successFiles: [] as UploadedFile[],
        rejectedFiles: [] as UploadedFile[],
      },
    )
  }, [uploadedFiles])

  if (!uploadedFiles) return <Loader />

  return (
    <div className="min-h-70vh w-fit my-4">
      <DocUploadSummery reject={false} quantity={successFiles.length}>
        <ImportedFilesList uploadedFiles={successFiles} />
      </DocUploadSummery>
      <DocUploadSummery reject={true} quantity={rejectedFiles.length}>
        <ImportedFilesList uploadedFiles={rejectedFiles} />
      </DocUploadSummery>
    </div>
  )
}

export default UploadSummary
