
import {IEnvironmentConfig} from "./IEnvironmentConfig";

export const akhConfig: IEnvironmentConfig = {
  host: "akh.bygg.io",
  exportBaseUrl: "https://export-akh.bygg.io/",
  frontendBaseUrl: "https://akh.bygg.io/",
  organizationBaseUrl: "https://org-akh.bygg.io/",
  proxyBaseUrl: "https://proxy-akh.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-akh.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-akh.bygg.io/",
  bimServiceBaseUrl: "not yet set",
  kpiBaseUrl: "https://kpi-akh.bygg.io/"
}
