import {
  IChecklist,
  IChecklistGroup,
  IChecklistItem,
  IPaginateResult,
} from 'src/service/OrgTypes'
import { request } from 'src/service/service-utils'
import { TableFilter } from 'src/ui-elements/Table/useTable'

export const filteredChecklists = (
  projectId: number,
  filter: TableFilter,
  currentPage: number,
  itemsPerPage: number,
): Promise<IPaginateResult<IChecklist>> => {
  return request(
    `projects/${projectId}/checklists_filtered/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const updateCheckList = (
  projectId: number,
  checklistId: number,
  checklist: Partial<IChecklist>,
): Promise<IChecklist> => {
  return request(
    `projects/${projectId}/checklists/${checklistId}/`,
    'PUT',
    checklist,
  )
}

export const getCheckList = (
  projectId: number,
  checklistId: number,
): Promise<IChecklist> => {
  return request(`projects/${projectId}/checklists/${checklistId}/`, 'GET')
}

export const createCheckList = (
  projectId: number,
  checklist: Partial<IChecklist>,
) => {
  return request(`/projects/${projectId}/checklists`, 'POST', checklist)
}

export const createCheckListItem = (
  projectId: number,
  checkListId: number,
  checklistItem: IChecklistItem,
): Promise<IChecklistItem> => {
  return request(
    `/projects/${projectId}/checklists/${checkListId}/checklist_items`,
    'POST',
    checklistItem,
  )
}

export const updateCheckListItem = (
  checklistItemId: number,
  updatedItem: Partial<IChecklistItem>,
) => {
  return request(`checklist_items/${checklistItemId}/`, 'PUT', updatedItem)
}

export const deleteChecklist = (
  projectId: number,
  checklistId: number,
): Promise<boolean> => {
  return request(
    `/projects/${projectId}/checklists/${checklistId}`,
    'Delete',
    {},
    true,
  )
}

export const deleteMultipleChecklists = (
  projectId: number,
  checklistIds: number[],
): Promise<number> => {
  return request(
    `/projects/${projectId}/checklists/remove_multiple`,
    'POST',
    { checklist_ids: checklistIds },
    true,
  )
}

export const deleteChecklistItem = (
  checklistItemId: number,
): Promise<boolean> => {
  return request(`/checklist_items/${checklistItemId}`, 'Delete', {}, true)
}

export const deleteMultipleChecklistItems = (
  projectId: number,
  checklistId: number,
  checklistItemIds: number[],
): Promise<boolean> => {
  return request(
    `projects/${projectId}/checklist/${checklistId}/checklist_items/remove_multiple`,
    'POST',
    {
      checklist_item_ids: checklistItemIds,
    },
  )
}

export const updateChecklistItemOrder = (
  checklistItemId: string | number,
  sequence: number,
  checklistGroupId: number,
) => {
  return request(`checklist_items/${checklistItemId}/order`, 'PUT', {
    sequence,
    checklist_group_id: checklistGroupId,
  })
}

export const getChecklistTree = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/checklist_tree`, 'GET')
}

export const createCheckListGroup = (
  projectId: number,
  checkListId: number,
  checklistGroup: Partial<IChecklistGroup>,
): Promise<IChecklistGroup> => {
  return request(
    `/projects/${projectId}/checklists/${checkListId}/checklist_groups`,
    'POST',
    checklistGroup,
  )
}

export const updateCheckListGroup = (
  checklistGroupId: number,
  header: string,
  value: string,
): Promise<IChecklistGroup> => {
  return request(`checklist_groups/${checklistGroupId}/`, 'PUT', {
    [header]: value,
  })
}

export const deleteChecklistGroup = (
  checklistGroupId: number,
): Promise<boolean> => {
  return request(`/checklist_groups/${checklistGroupId}`, 'Delete', {}, true)
}

export const deleteMultipleChecklistGroups = (
  projectId: number,
  checklistId: number,
  checklistGroupIds: number[],
): Promise<boolean> => {
  return request(
    `projects/${projectId}/checklist/${checklistId}/checklist_groups/remove_multiple`,
    'POST',
    {
      checklist_group_ids: checklistGroupIds,
    },
  )
}

export const updateChecklistGroupOrder = (
  checklistGroupId: string | number,
  sequence: number,
) => {
  return request(`checklist_groups/${checklistGroupId}/order`, 'PUT', {
    sequence,
  })
}
