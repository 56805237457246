import * as React from 'react'
import { IInlineBaseProps } from './IDropDown'
import InlineBaseComponent from './InlineBaseComponent'
import InlineBorderComponent from './InlineBorderComponent'
import InlineErrorMessageComponent from './InlineErrorMessageComponent'
import InlineInputLabelComponent from './InlineInputLabelComponent'

interface IInLineComponent extends IInlineBaseProps {
  label: string
  content?: JSX.Element
  labelWidth?: string
  errorMessage?: string
}

const InlineCompontent: React.FC<IInLineComponent> = ({
  label,
  content,
  labelWidth,
  disabled = false,
  disableTooltip,
  errorMessage,
}) => {
  return (
    <InlineBaseComponent>
      <InlineInputLabelComponent
        label={label}
        labelWidth={labelWidth}
        disableTooltip={disableTooltip}
      />
      <InlineErrorMessageComponent errorMessage={errorMessage} />
      <InlineBorderComponent disabled={disabled}>
        {content}
      </InlineBorderComponent>
    </InlineBaseComponent>
  )
}

export default InlineCompontent
