import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getMainProcesses } from 'src/service/ProcessService'
import { getProjectTags } from 'src/service/TagService'
import { getProjectTeams, getMainProcessTeams } from 'src/service/TeamService'

export const useMainProcesses = (options: { enabled?: boolean } = {}) => {
  const projectId = useProjectId()

  return useQuery({
    ...options,
    queryKey: ['process', projectId],
    queryFn: () => getMainProcesses(projectId),
  })
}

export const useTags = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['tags', projectId],
    queryFn: () => getProjectTags(projectId),
  })
}

export const useTeams = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['teams', projectId],
    queryFn: () => getProjectTeams(projectId),
  })
}

export const useMainProcessTeams = (
  mainProcessId: number,
  options?: { enabled?: boolean },
) => {
  return useQuery({
    queryKey: ['teams', mainProcessId],
    queryFn: () => getMainProcessTeams(mainProcessId),
    ...options,
  })
}
