import { Fragment, createRef, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Badge from 'src/ui-elements/badge/Badge'
import { badgeColor } from '../../page/systematic-completion/tests/TestExecutionList'
import { capFirstLetter } from '../../utility/utils'
import { Direction } from '../multi-selector/MultiSelector'
import TableStatusDropdown, { IStatusTypes } from './TableStatusDropdown'

interface ITestTableStatusLabelProps {
  cell: any
  rowId: number
  onStatusSelect: (statusTypes: IStatusTypes, key: any, cell: any) => void
  statusTypes: IStatusTypes[]
  showDropdownAlways?: boolean
  disableEdit?: boolean
}

const TestTableStatusLabel = ({
  cell,
  rowId,
  onStatusSelect,
  statusTypes,
  showDropdownAlways = false,
  disableEdit,
}: ITestTableStatusLabelProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const ref = useRef(createRef<HTMLDivElement>())
  const hasOpenChildren = cell?.open_children && cell?.open_children > 0
  const { t } = useTranslation()
  const [openDirection, setOpenDirection] = useState<Direction>(Direction.DOWN)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const onClickRow = (e: any) => {
    e.stopPropagation()
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  const handleClickOutside = (event: any) => {
    if (
      ref &&
      ref.current &&
      ref.current.current &&
      !ref.current.current.contains(event.target)
    ) {
      setOpen(false)
    }
  }

  const onStatusSelectRow = (status: IStatusTypes) => {
    setOpen(false)
    onStatusSelect(status, rowId, cell)
  }

  const handleClick = (e: any) => {
    const target = e.target.getBoundingClientRect()
    const closeToBottom = window.innerHeight - target.top < 380
    setOpenDirection(closeToBottom ? Direction.UP : Direction.DOWN)
    if (!disableEdit) {
      if (!hasOpenChildren) {
        onClickRow(e)
      } else {
        if (showDropdownAlways) {
          onClickRow(e)
        }
      }
    }
  }

  return (
    <Fragment>
      {open ? (
        <Fragment>
          <div
            ref={ref.current}
            className={'w-full'}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
            }}
          />
          <div className={'w-full'} ref={ref.current}>
            <TableStatusDropdown
              selectedStatus={cell?.status}
              onSelectClick={onStatusSelectRow}
              statusTypes={statusTypes}
              onClose={onClose}
              openDirection={openDirection}
            />
          </div>
        </Fragment>
      ) : (
        <div>
          {cell && cell?.status !== '' ? (
            <div
              style={{ minWidth: '98px', maxWidth: '150px' }}
              className={'w-full h-full cursor-pointer'}
              onClick={(e) => handleClick(e)}
            >
              <Badge
                size={Badge.BadgeSize.LARGE}
                text={capFirstLetter(t(cell.status))}
                color={
                  cell.is_expired
                    ? Badge.BadgeColor.YELLOW
                    : badgeColor(cell.status)
                }
              />
            </div>
          ) : null}
        </div>
      )}
    </Fragment>
  )
}
export default TestTableStatusLabel
