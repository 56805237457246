import { IImprovement, IPaginateResult } from './OrgTypes'
import { request } from './service-utils'

export const createImprovement = (
  improvement: Omit<IImprovement, 'id' | 'priority'>,
  projectId: number,
): Promise<any> => {
  return request(`projects/${projectId}/improvements`, 'POST', improvement)
}

export const editImprovement = (
  improvement: Partial<IImprovement>,
): Promise<any> => {
  return request(`improvements/${improvement.id}`, 'PUT', improvement)
}

export const deleteImprovement = (improvementId: number): Promise<any> => {
  return request(`improvements/${improvementId}`, 'DELETE', {}, true)
}

export const getImprovement = (
  improvementId: number,
): Promise<IImprovement> => {
  return request(`/improvements/${improvementId}`, 'GET')
}

export const getProjectImprovements = (
  projectId: number,
): Promise<IImprovement[]> => {
  return request(`projects/${projectId}/improvements`, 'GET')
}

export const getImprovementBoard = (
  projectId: number,
  filter: any,
): Promise<{ [status: string]: IImprovement[] }> => {
  return request(`/projects/${projectId}/improvements_filtered`, 'POST', filter)
}

export const getFilteredProjectImprovementsWithPagination = (
  projectId: number,
  filter: any = {},
  currentPage: number = 0,
  itemsPerPage: number = 0,
): Promise<IPaginateResult<IImprovement>> => {
  return request(
    `/projects/${projectId}/improvements_filter/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}
