import {IEnvironmentConfig} from './IEnvironmentConfig'

export const caverionConfig: IEnvironmentConfig = {
  host: "caverion.bygg.io",
  exportBaseUrl: "https://export-caverion.bygg.io/",
  frontendBaseUrl: "https://caverion.bygg.io/",
  organizationBaseUrl: "https://org-caverion.bygg.io/",
  proxyBaseUrl: "https://proxy-caverion.bygg.io/api/",
  tableKeeperBaseUrl: "https://tablekeeper-caverion.bygg.io/api/",
  fdvuBaseUrl: "https://fdvu-caverion.bygg.io/",
  bimServiceBaseUrl: "not yet set",
  kpiBaseUrl: "https://kpi-caverion.bygg.io/"
}
