import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteConstructionPlanDraft } from 'src/query/construction/mutations'
import { useConfirmModal } from './useConfirmModal'

const useDiscardDraftMode = (reload?: () => void) => {
  const { t } = useTranslation()
  const removeMutation = useDeleteConstructionPlanDraft(reload)
  const { confirm } = useConfirmModal()
  const closeSaveModal = useRef<() => void>()

  const discardDraftMode = async () => {
    const confirmed = await confirm({
      title: t('discard_changes'),
      message: t('discard_all_unsaved_changes'),
      confirmText: t('confirm'),
      controlClose: (c) => {
        closeSaveModal.current = c
      },
    })
    if (confirmed) {
      await removeMutation.mutateAsync()
      closeSaveModal.current?.()
      return Promise.resolve()
    } else return Promise.reject()
  }

  return discardDraftMode
}

export default useDiscardDraftMode
