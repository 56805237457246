import AddCircle from '@material-symbols/svg-500/rounded/add_circle.svg'
import * as React from 'react'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import IconButton from 'src/ui-elements/button/IconButton'
import { ExpandableTableContext } from '../../context/ExpandableTableContext'
import { useExpandableTableInteractionStoreState } from '../../hooks/useExpandableTableInteractionStore'
import { useExpandableTableStoreState } from '../../hooks/useExpandableTableStore'
import { IExpandableSection } from '../../types/IExpandableSection'

const AddRow: React.FC<{
  rowId: number
  section: IExpandableSection
  rowIndex: number
  readonly: boolean
  initialItem?: Record<string, unknown>
}> = ({ rowId, section, rowIndex, readonly, initialItem }) => {
  const [loading, setLoading] = useState(false)
  const { t } = useTranslation()

  const createRow = useExpandableTableStoreState((state) => state.createRow)
  const currentSectionConfig = useExpandableTableStoreState(
    (state) => state.expandableSectionsConfig[section.id],
  )

  const {
    reorderRowsHandler: { setHoveringRow },
  } = useContext(ExpandableTableContext)

  const rowIsHovered = useExpandableTableInteractionStoreState(
    (state) => state.hoveringRow?.rowId === rowId,
  )

  const [rowCount, setRowCount] = useState<number>(
    currentSectionConfig?.rows.length + 1,
  )

  useEffect(() => {
    const numRows = currentSectionConfig.rows.length + 1
    if (numRows !== rowCount) {
      setRowCount(numRows)
    }
  }, [currentSectionConfig, rowCount])

  const onAddRow = () => {
    setLoading(true)
    createRow(section.id, initialItem ?? {}, rowIndex).then(() =>
      setLoading(false),
    )
  }

  if (readonly || !rowIsHovered) return null

  return (
    <div
      tabIndex={-1}
      onMouseEnter={() => setHoveringRow({ rowId, section })}
      onMouseLeave={() => setHoveringRow(undefined)}
    >
      <IconButton
        icon={<AddCircle className={'fill-blue-root'} />}
        onClick={() => !loading && onAddRow()}
        tooltipText={t('add_row')}
        tooltipDirection={'bottom'}
      />
    </div>
  )
}

export default AddRow
