export const fileContainerImportTemplate = (omegaEnabled: boolean) => ({
  name: 'file_container_import_template',
  displayName: 'file_container_import_template',
  columns: [
    'document_number',
    'document_name',
    'document_category',
    'document_deadline',
    'responsible',
    'discipline',
    'document_type',
    ...(omegaEnabled ? ['omega_release_code', 'omega_revision_date'] : []),
  ],
  templateColumns: [
    'name',
    'required',
    'value',
    'description',
    'example',
    ...(omegaEnabled ? ['omega_release_code', 'omega_revision_date'] : []),
  ],
  template: [
    {
      name: 'document_number',
      required: false,
      value: 'text',
      description: 'document_number',
      example: 'XXX.XXXX.XXXX',
    },
    {
      name: 'document_name',
      required: true,
      value: 'text',
      description: 'document_name',
      example: '',
    },
    {
      name: 'document_category',
      required: true,
      value: 'text',
      description: 'document_category',
      example: 'Tekn.dok',
    },
    {
      name: 'deadline',
      required: false,
      value: 'date_mm_dd_yyyy',
      description: 'deadline',
      example: '',
    },
    {
      name: 'responsible',
      required: false,
      value: 'text',
      description: 'responsible',
      example: 'Ola Nordmann',
    },
    {
      name: 'discipline',
      required: false,
      value: 'text',
      description: 'discipline',
      example: 'RIV',
    },
    {
      name: 'test_document_type',
      required: false,
      value: 'text',
      description: 'test_document_type',
      example: 'Systemskjema',
    },
    ...(omegaEnabled
      ? [
          {
            name: 'omega_release_code',
            required: false,
            value: 'text',
            description: 'omega_release_code',
            example: 'SB',
          },
          {
            name: 'omega_revision_date',
            required: false,
            value: 'date',
            description: 'omega_revision_date',
            example: '2025.02.05',
          },
        ]
      : []),
  ],
})
