import { FC, useState, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

interface TreeNodeProps {
  title: string
  defaultContent: ReactNode
  children: ReactNode
  info?: string
}

const TreeNode: FC<TreeNodeProps> = ({
  title,
  defaultContent,
  children,
  info,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <div
      className={twMerge('border-b py-1 my-1 w-full', !isExpanded && 'py-2')}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <button
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setIsExpanded((prev) => !prev)
            }}
            className="mr-2 capitalize hover:text-blue-600"
          >
            <span className="text-xs">{isExpanded ? '▼' : '▶'}</span> {title}
          </button>
          {!isExpanded && (
            <span className="italic text-gray-500 capitalize">
              {defaultContent}
            </span>
          )}
        </div>
        {info && (
          <div className="text-sm text-gray-400 hover:text-gray-600 cursor-pointer">
            ℹ {info}
          </div>
        )}
      </div>
      {isExpanded && <div className="mt-2">{children}</div>}
    </div>
  )
}

export default TreeNode
