import { isBoolean } from 'lodash'
import {
  createRef,
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import Spinner from 'src/ui-elements/loader/Spinner'
import { capFirstLetter, classNames } from 'src/utility/utils'
import Icon, { Icons } from '../../ui-elements/icon/Icon'
import MaterialIcon from '../../ui-elements/icon/materialIcon'
import Input from '../../ui-elements/input/Input'
import useKeyPress from '../hooks/UseKeyEvent'
import OptionItem from './OptionItem'

export enum Direction {
  UP = 'up',
  DOWN = 'down',
}

interface ISelectProps {
  onSelect: (id: number[] | string[] | any[]) => void
  onOpenList?: (open: boolean) => Promise<any>
  items: any[]
  dataFields: any[]
  disabled?: boolean
  label: string
  selectedItems: any[]
  fontSize?: string
  fontWeight?: string
  required?: boolean
  errorMessage?: string
  loading?: boolean
  noBorder?: boolean
  showIcon?: boolean
  hidelabel?: boolean
  bgColor?: string
  inMobile?: boolean
  direction?: Direction
  submitOnBlur?: boolean
  disableSelectAll?: boolean
  lineHeight?: string
  // Deprecated props. Remove when using
  scroll?: boolean
  sortBySelected?: boolean
}

interface IDropDownPosition {
  top: number
  right: number
  left: number
  bottom: number
  width: number
  height: number
}

interface IMultiSelectorItem {
  id: string | number
  name: string
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'w-full'),
  label: (_fontSize: string, _fontWeight: string) =>
    classNames(
      'block',
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'my-2',
      'font-roboto',
    ),
  filterBox: (isMobile: boolean, direction: string) =>
    classNames(
      'list-reset',
      'list-floating-dropDown',
      'shadow',
      'bg-white',
      'border-gray-300',
      'border',
      'absolute',
      'z-20',
      'pt-2',
      'mb-2',
      direction === 'up' ? 'flex flex-col-reverse pb-2 bottom-[-10px]' : '',
      isMobile ? 'rounded-md' : '',
    ),
  selected: (hasError: boolean, isDisabled: boolean, noBorder?: boolean) =>
    classNames(
      'text-gray-700',
      'hover:bg-gray-100',
      'hover:text-gray-900',
      'focus:bg-gray-100',
      'focus:text-gray-900',
      'cursor-pointer',
      hasError && 'border-red-100',
      hasError && 'border',
      !noBorder && 'border',
      !noBorder && 'border-gray-300',
      isDisabled ? 'opacity-50 cursor-not-allowed' : '',
    ),
  selectedContainer: (bg: string, isMobile: boolean) =>
    classNames(
      'flex',
      'flex-row',
      !isMobile ? 'form-selector' : 'h-10',
      'rounded-sm',
      'items-center',
      'overflow-hidden',
      'whitespace-nowrap',
      'p-2',
      'text-sm',
      'leading-5',
      'border',
      'border-gray-300',
      bg ? `bg-${bg}` : '',
    ),
  nameContainerUnselected: (hasError: boolean, bg: string, isMobile: boolean) =>
    classNames(
      'flex',
      'text-gray-400',
      'font-normal',
      'flex-row',
      'items-center',
      'text-sm',
      'p-2',
      isMobile ? '' : 'border border-gray-300 focus:border-blue-seven',
      hasError && 'border-red-300 text-red-900 focus:border-red-300',
      bg ? `bg-${bg}` : '',
    ),
  errorMessage: classNames('text-red-600', 'my-2', 'ml-2', 'text-sm'),
  cover: classNames('fixed', 'inset-x-0', 'inset-y-0', 'z-20'),
  selectIcon: classNames(
    'h-6',
    'w-auto',
    'inline-flex',
    'mr-0',
    'ml-auto',
    'pl-1',
    'mt-1',
  ),
  placeholder: (hasError: boolean, lineHeight: string) =>
    classNames(
      hasError ? 'text-red-300' : 'text-gray-400',
      'font-normal',
      'text-sm',
      `leading-${lineHeight}`,
    ),
}

const MultiSelector = ({
  onSelect,
  onOpenList,
  items,
  dataFields,
  disabled = false,
  label,
  selectedItems,
  fontSize,
  fontWeight,
  required,
  errorMessage,
  loading,
  noBorder,
  showIcon,
  hidelabel,
  bgColor,
  inMobile = false,
  direction,
  submitOnBlur,
  disableSelectAll,
  lineHeight,
  sortBySelected = true,
}: ISelectProps) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [searchableData, setSearchableData] = useState<IMultiSelectorItem[]>([])
  const allDataRef = useRef<IMultiSelectorItem[]>([])
  const [selectAll, setSelectAll] = useState<boolean>(false)
  const [dropdownPosition, setDropdownPosition] = useState<IDropDownPosition>({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    width: 0,
    height: 0,
  })
  const [, updateState] = useState<any>()
  const forceUpdate = useCallback(() => updateState({}), [])
  const elementsRef = useRef(createRef<HTMLDivElement>())
  const hasError: boolean = errorMessage && errorMessage !== '' ? true : false
  const hideLabel: boolean = hidelabel === true ? true : false
  const bg: string = bgColor ? bgColor : ''
  const { t } = useTranslation()
  const [selectedItemsTemp, setSelectedItemsTemp] =
    useState<any[]>(selectedItems)
  const selectedItemsSet = new Set(
    submitOnBlur ? selectedItemsTemp : selectedItems,
  )
  useEffect(() => {
    setSelectedItemsTemp(selectedItems)
  }, [selectedItems])

  useDidMountEffect(() => {
    if (!edit && submitOnBlur) {
      onSelect(selectedItemsTemp)
    }
  }, [edit])

  useEffect(() => {
    setPosition()
    prepareData(false)
  }, [items])

  const getRect = (element: any) => {
    if (!element) {
      return {
        bottom: 0,
        height: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
      }
    }
    return element.getBoundingClientRect()
  }

  const prepareData = (closeEdit: boolean) => {
    const FilteredData: IMultiSelectorItem[] = items.map((item: any) => {
      let itemName = ''

      dataFields.map((d: any) => {
        itemName += isBoolean(item[d])
          ? item[d]
            ? `(${t(d)}) `
            : ` `
          : `${item[d]} `
      })
      return {
        id: item.id,
        name: itemName.trim(),
      }
    })

    if (sortBySelected) {
      const activeItems = FilteredData.filter((item) =>
        selectedItemsSet.has(item.id),
      )
      const nonActiveItems = FilteredData.filter(
        (item) => !selectedItemsSet.has(item.id),
      )

      allDataRef.current = [...activeItems, ...nonActiveItems]
      setSearchableData([...activeItems, ...nonActiveItems])
    } else {
      allDataRef.current = FilteredData
      setSearchableData(FilteredData)
    }

    if (closeEdit) {
      setEdit(false)
    }
  }

  useKeyPress((keyType: string) => {
    switch (keyType) {
      case 'Tab':
        setEdit(false)
        break
    }
  })

  const toggleEdit = async () => {
    if (disabled) {
      return
    }

    if (edit) {
      prepareData(false)
      setSearchValue('')
    }

    if (onOpenList) {
      await onOpenList(!edit)
    }
    setEdit((n) => !n)
  }

  const toggleSelectAll = () => {
    let thisSelectedItems: (string | number)[] = []
    const tempsearchableData = searchableData
    if (selectAll) {
      thisSelectedItems = []
    } else {
      thisSelectedItems = tempsearchableData.map((item) => item.id)
    }
    setSelectAll((n) => !n)
    if (submitOnBlur) {
      setSelectedItemsTemp(thisSelectedItems)
    } else {
      onSelect(thisSelectedItems)
    }
  }

  const onFilterSearch = (e: any) => {
    const value = e.target.value
    const searchData = allDataRef.current.filter((item) => {
      return item.name
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(value.toLowerCase().replace(/\s+/g, ''))
    })
    setSearchValue(value)
    const activeItems = (
      value.length > 0 ? searchData : allDataRef.current
    ).filter((item) => selectedItemsSet.has(item.id))
    const nonActiveItems = (
      value.length > 0 ? searchData : allDataRef.current
    ).filter((item) => !selectedItemsSet.has(item.id))

    setSearchableData([...activeItems, ...nonActiveItems])
    setSelectAll(false)
    forceUpdate()
  }

  const onItemClick = (id: any) => {
    const tempSelectedItems = submitOnBlur ? selectedItemsTemp : selectedItems
    const index = tempSelectedItems.indexOf(id)
    if (index === -1) {
      tempSelectedItems.push(id)
    } else {
      tempSelectedItems.splice(index, 1)
    }
    if (submitOnBlur) {
      setSelectedItemsTemp(tempSelectedItems)
    } else {
      onSelect(tempSelectedItems)
    }
    forceUpdate()
  }

  const setPosition = () => {
    const clientBound = getRect(elementsRef.current.current)
    const tmpState = { ...dropdownPosition }
    tmpState.bottom = clientBound.bottom
    tmpState.top = clientBound.top
    tmpState.left = clientBound.left
    tmpState.right = clientBound.right
    tmpState.width = clientBound.width
    tmpState.height = clientBound.height

    setDropdownPosition(tmpState)
  }

  const getItem = () => {
    const Disabled: boolean = disabled ? disabled : false
    const ShowIcon: boolean = showIcon ? showIcon : true

    if (
      (submitOnBlur ? selectedItemsTemp : selectedItems) &&
      (submitOnBlur ? selectedItemsTemp : selectedItems).length > items.length
    ) {
      return (
        <span
          style={inMobile ? mobileStyles : { height: 34 }}
          className={styleClass.selectedContainer(bg, inMobile)}
        >
          <span className={'w-full flex justify-between items-center'}>
            {
              <span className={'truncate w-full'}>{`${
                (submitOnBlur ? selectedItemsTemp : selectedItems).length
              } ${t('items_selected')}`}</span>
            }
            {ShowIcon && (
              <Icon
                icon={Icons.ARROW_DOWN_GRAY}
                className={styleClass.selectIcon}
              />
            )}
          </span>
        </span>
      )
    }
    let hasValidSelectedItems = false
    if (
      (submitOnBlur ? selectedItemsTemp : selectedItems) &&
      Array.isArray(submitOnBlur ? selectedItemsTemp : selectedItems)
    ) {
      for (const selectedItem of submitOnBlur
        ? selectedItemsTemp
        : selectedItems) {
        for (const item of items) {
          if (selectedItem === item.id) {
            hasValidSelectedItems = true
          }
        }
      }
    }
    if (hasValidSelectedItems) {
      const selectedNames = allDataRef.current
        .filter((item: any) => selectedItemsSet.has(item.id))
        .map((item) => item.name)
        .join(', ')
      return (
        <span
          className={styleClass.selectedContainer(bg, inMobile)}
          style={inMobile ? mobileStyles : { height: 34 }}
        >
          <span className={'w-full flex justify-between items-center'}>
            <span className={'truncate w-full'}>{selectedNames}</span>
            {ShowIcon && (
              <Icon
                icon={Icons.ARROW_DOWN_GRAY}
                className={styleClass.selectIcon}
              />
            )}
          </span>
        </span>
      )
    }

    return (
      <div>
        {!Disabled ? (
          <span
            className={styleClass.nameContainerUnselected(
              hasError,
              bg,
              inMobile,
            )}
            style={inMobile ? mobileStyles : { height: 34 }}
          >
            {label && !hasError && (
              <span
                className={styleClass.placeholder(
                  hasError,
                  lineHeight ? lineHeight : '5',
                )}
              >
                {capFirstLetter(t('select'))} {label.toLowerCase()}
              </span>
            )}
            {ShowIcon && (
              <Icon
                icon={Icons.ARROW_DOWN_GRAY}
                className={styleClass.selectIcon}
              />
            )}
          </span>
        ) : (
          <span
            className={styleClass.nameContainerUnselected(
              hasError,
              bg,
              inMobile,
            )}
            style={inMobile ? mobileStyles : { height: 34 }}
          />
        )}{' '}
      </div>
    )
  }

  const mobileStyles = {
    maxHeight: 34,
    fontSize: 13,
    borderRadius: 8,
    borderColor: '#E7ECF3',
    borderWidth: 1,
  }

  const onClearSelected = () => {
    setSelectAll(false)
    if (submitOnBlur) {
      setSelectedItemsTemp([])
    } else {
      onSelect([])
    }
  }

  return (
    <Fragment>
      <div className={styleClass.root} ref={elementsRef.current}>
        <div className={'flex flex-row w-full'}>
          {!hideLabel && (
            <p
              className={styleClass.label(
                fontSize ? fontSize : 'sm',
                fontWeight ? fontWeight : 'normal',
              )}
            >
              {capFirstLetter(label)}
              {required ? ' *' : ''}
            </p>
          )}
          {hasError && (
            <span className={styleClass.errorMessage}>{errorMessage}</span>
          )}
        </div>
        {!edit || disabled ? (
          <div
            className={styleClass.selected(!!errorMessage, disabled, noBorder)}
            onClick={toggleEdit}
            tabIndex={0}
            onFocus={toggleEdit}
          >
            {loading ? (
              <div
                className={'flex justify-center items-center'}
                style={{ maxHeight: 34 }}
              >
                {' '}
                <Spinner />{' '}
              </div>
            ) : (
              getItem()
            )}
          </div>
        ) : items.length > 0 ? (
          <div>
            <div className={styleClass.cover} onClick={toggleEdit} />
            <div className={'relative'}>
              <div style={{ height: 34 }} />
              <div
                onClick={(e) => e.stopPropagation()}
                className={styleClass.filterBox(inMobile, direction ?? '')}
                style={{ marginTop: -38, maxWidth: '-webkit-fill-available' }}
              >
                <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
                  <Input
                    type="text"
                    autoFocus={true}
                    value={searchValue}
                    onChange={onFilterSearch}
                    block={true}
                    noPadding={true}
                    placeholder={t('search')}
                    style={{ height: 34 }}
                    inMobile={inMobile}
                  />
                </div>
                <div
                  className={'overflow-y-auto selectorScroll py-1'}
                  style={{
                    width: dropdownPosition.width,
                    maxWidth: '100%',
                    maxHeight: '300px',
                  }}
                >
                  {!disableSelectAll && (
                    <span className="flex flex-row justify-between pr-2">
                      <OptionItem
                        key={-1}
                        item={{
                          id: -1,
                          name: t('select_all'),
                          active: selectAll,
                        }}
                        onItemClick={toggleSelectAll}
                      />
                      <MaterialIcon
                        icon={'cancel'}
                        className={
                          'cursor-pointer text-red-500 text-sm ml-0.5 self-center'
                        }
                        onClick={onClearSelected}
                      />
                    </span>
                  )}
                  {searchableData.map((item: any, key: number) => {
                    return (
                      <OptionItem
                        key={key}
                        item={{
                          ...item,
                          active: selectedItemsSet.has(item.id),
                        }}
                        onItemClick={onItemClick}
                      />
                    )
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Input
            style={{ height: 34 }}
            type="search"
            autoFocus={true}
            value={searchValue}
            onChange={onFilterSearch}
            block={true}
            noPadding={true}
            placeholder={t('nothing')}
            inMobile={inMobile}
            onBlur={() => toggleEdit()}
          />
        )}
      </div>
    </Fragment>
  )
}

export default MultiSelector
