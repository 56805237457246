import { useQuery } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getProjectUsers,
  getProjectSingleUsers,
  getDisciplineUsers,
  getContractUsersData,
} from 'src/service/UserService'

export const useProjectUsers = (options?: { enabled?: boolean }) => {
  const projectId = useProjectId()

  return useQuery({
    ...options,
    queryKey: ['users', projectId],
    queryFn: () => getProjectUsers(projectId),
  })
}

export const useProjectSingleUsers = () => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['singleUsers', projectId],
    queryFn: () => getProjectSingleUsers(projectId),
  })
}

export const useDisciplineUsers = (
  disciplineId: number,
  options?: { enabled?: boolean },
) =>
  useQuery({
    ...options,
    queryKey: ['disciplineUsers', disciplineId],
    queryFn: () => getDisciplineUsers(disciplineId),
  })

export const useContractUsers = (contractId: number) =>
  useQuery({
    queryKey: ['contract', contractId, 'users'],
    queryFn: () => getContractUsersData(contractId),
  })
