import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useProjectId from 'src/components/hooks/useProjectId'
import SelectFileContainersModal from 'src/page/system/SelectFileContainerModal'
import { massUpdateSystemFileContainers } from 'src/service/SystemService'
import CheckboxWithLabel from 'src/ui-elements/checkbox/CheckboxWithLabel'

interface SystemObjectInlineProps {
  systemIds: number[]
  fileContainerIds?: number[]
  reload: () => void
}

const FileContainerInline: FC<SystemObjectInlineProps> = ({
  systemIds,
  fileContainerIds = [],
  reload,
}) => {
  const { t } = useTranslation()
  const projectId = useProjectId()
  const [overwrite, setOverwrite] = useState(false)

  const updateFileContainers = async (fileContainerIds: number[]) => {
    const removedFileContainers = fileContainerIds.filter(
      (i) => !fileContainerIds.includes(i),
    )
    await massUpdateSystemFileContainers(
      systemIds,
      fileContainerIds,
      removedFileContainers,
      overwrite,
    )
    reload()
  }

  return (
    <>
      <div className="pr-4">
        <SelectFileContainersModal
          key={projectId}
          onSelectFileContainers={updateFileContainers}
          closeModal={reload}
          selectedFileContainerIds={fileContainerIds}
        >
          <div className={'flex items-center gap-2 text-sm text-gray-700 mt-2'}>
            <CheckboxWithLabel
              labelRight
              name={t('overwrite_existing_connections')}
              onChange={() => setOverwrite(!overwrite)}
              value={overwrite}
            />
          </div>
        </SelectFileContainersModal>
      </div>
    </>
  )
}

export default FileContainerInline
