import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SimpleActivitiesTable from 'src/components/construction/wagon/SimpleActivitiesTable'
import SignatureInput from 'src/components/signature-pad/SignatureCanvas'
import CreateTaskForm from 'src/components/task/CreateTaskForm'
import { getConstructionWagonTasks } from 'src/service/ConstructionTaskService'
import {
  createCheckInWagon,
  editCheckInOut,
  getConstructionWagon,
  getWagonCheckInOuts,
} from 'src/service/ConstructionWagonService'
import {
  ICheckInOut,
  IConstructionTask,
  IConstructionWagon,
  IDiscipline,
  ITask,
  ITaskFormData,
} from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import RadioItems, { IRadioItems } from 'src/ui-elements/radio/RadioItems'
import Input from '../../../ui-elements/input/Input'
import { classNames } from '../../../utility/utils'

interface IWagonCheck {
  isCheckIn: boolean
  show: boolean
  wagonId: number
  close: () => void
  onSubmit?: () => void
  isEditing?: boolean
  discipline: IDiscipline
}

export const statusTypes = (_disable: boolean): IRadioItems[] => {
  return [
    {
      id: 'green',
      name: <div className={'h-25'} />,
      classNames: 'green_radio',
    },
    {
      id: 'yellow',
      name: <div className={'h-25'} />,
      classNames: 'yellow_radio',
    },
    {
      id: 'red',
      name: <div className={'h-25'} />,
      classNames: 'red_radio',
    },
  ]
}

export const checkOutText = (t: any) => [
  t(
    'carriage_that_now_leaves_the_roof_area_has_no_outstanding_work_to_prevent_the_next_carriages',
  ),
  t(
    'carriage_that_now_leaves_the_area_has_no_material_or_equipment_left_in_the_area',
  ),
  t('carriage_that_is_now_leaving_the_area_has_cleared_and_vacuumed_the_area'),
  t(
    'carriage_that_is_now_leaving_the_area_has_checked_that_the_area_is_safe_for_others_to_move_in',
  ),
  t(
    'carriage_that_is_now_leaving_the_area_has_registered_all_damage_that_has_occurred_to_the_floor_walls_roof_and_any_installations_or_equipment',
  ),
]

export const checkInText = (t: any) => [
  t(
    'area_in_which_the_wagon_will_now_start_has_no_outstanding_work_to_prevent_the_work_to_be_carried_out_in_this_wagon',
  ),
  t('area_has_no_material_or_equipment_left_in_the_area_that_hinders_the_work'),
  t('area_has_been_cleared_and_vacuumed'),
  t('area_is_safe_to_work_in'),
  t(
    'all_damage_that_has_occurred_to_floors_walls_ceilings_and_any_installations_or_equipment_is_registered_before_start_up',
  ),
]

const WagonCheckInOut = ({
  isCheckIn,
  show,
  wagonId,
  close,
  onSubmit,
  isEditing = false,
  discipline,
}: IWagonCheck) => {
  const styleClass = {
    root: classNames('bg-white', 'w-screen', 'h-screen', 'relative'),
    container: classNames('pl-6', 'pr-2', 'py-2', 'overflow-y-auto'),
    errorMessage: classNames(
      'flex',
      'items-center',
      'text-red-600',
      'ml-2',
      'my-2',
      'text-sm',
      'font-light',
    ),
    label: classNames(
      'flex',
      'items-center',
      'text-sm',
      'text-gray-700',
      'p-2 pl-0',
    ),
    row: classNames(
      'flex',
      'flex-nowrap',
      'pl-1',
      'justify-between',
      'items-start',
      'mb-2',
    ),
    inputGroup: classNames('w-full', 'flex', 'flex-row'),
  }

  const { t } = useTranslation()
  const checkInParameterTypes = [
    'no_outstanding_work',
    'no_equipment_left',
    'cleared_and_vacuumed',
    'safe_to_work_in',
    'damage_is_registered',
  ] as const

  type checkInParamType = (typeof checkInParameterTypes)[number]
  const disciplineId = discipline.id

  const getLabel = (index: number): string => {
    return isCheckIn ? checkInText(t)[index] : checkOutText(t)[index]
  }

  const [checkId, setCheckId] = useState<number>(0)
  const [wagon, setWagon] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [constructionTasks, setConstructionTasks] = useState<
    IConstructionTask[]
  >([])
  const [checkInParameterValues, setCheckInParameterValues] = useState<{
    [Key in checkInParamType]: string
  }>({
    no_equipment_left: '',
    cleared_and_vacuumed: '',
    no_outstanding_work: '',
    safe_to_work_in: '',
    damage_is_registered: '',
  })
  const [checkInParameterTasks, setCheckInParameterTasks] = useState<{
    [Key in checkInParamType]: ITask[]
  }>({
    no_equipment_left: [],
    cleared_and_vacuumed: [],
    no_outstanding_work: [],
    safe_to_work_in: [],
    damage_is_registered: [],
  })
  const [checkInParameterErrors, setCheckInParameterErrors] = useState<{
    [Key in checkInParamType]: string
  }>({
    no_equipment_left: '',
    cleared_and_vacuumed: '',
    no_outstanding_work: '',
    safe_to_work_in: '',
    damage_is_registered: '',
  })
  const [signature, setSignature] = useState<any>()
  const [signatureError, setSignatureError] = useState<string>('')
  const [actualStaffingError, setActualStaffingError] = useState('')
  const [actualWorkHoursError, setActualWorkHoursError] = useState('')
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false)
  const [actualStaffing, setActualStaffing] = useState<number>()
  const [actualWorkHours, setActualWorkHours] = useState<number>()
  const [selectedCheckInParam, setSelectedCheckInParam] = useState<
    checkInParamType | undefined
  >(undefined)

  const fetchExistingCheckInfo = () => {
    getWagonCheckInOuts(wagonId).then((res: ICheckInOut[]) => {
      const checkData = res.find(
        (check_in_out) =>
          check_in_out.is_check_in === isCheckIn &&
          check_in_out.discipline_id === disciplineId,
      )
      if (checkData && checkData.id) {
        setCheckId(checkData?.id)
        const values: typeof checkInParameterValues = {
          no_equipment_left: '',
          cleared_and_vacuumed: '',
          no_outstanding_work: '',
          safe_to_work_in: '',
          damage_is_registered: '',
        }
        const tasks: typeof checkInParameterTasks = {
          no_equipment_left: [],
          cleared_and_vacuumed: [],
          no_outstanding_work: [],
          safe_to_work_in: [],
          damage_is_registered: [],
        }
        checkInParameterTypes.forEach((type) => {
          const parameterData = checkData.check_in_parameters.find(
            (param) => param.name === type,
          )
          if (parameterData) {
            values[type] = parameterData.color
            tasks[type] = parameterData.tasks
          }
        })
        setCheckInParameterValues({
          ...checkInParameterValues,
          ...values,
        })
        setCheckInParameterTasks({
          ...checkInParameterTasks,
          ...tasks,
        })
      }
    })
  }

  useEffect(() => {
    getConstructionWagon(wagonId).then((res) => {
      setWagon(res)
    })
    getConstructionWagonTasks(wagonId).then((tasks) => {
      const tasksInDiscipline = tasks.filter(
        (task) => task.discipline_id === disciplineId,
      )
      setConstructionTasks(tasksInDiscipline)
    })

    if (isEditing && wagonId) {
      fetchExistingCheckInfo()
    }
  }, [wagonId, isCheckIn, isEditing])

  const changeCheckInParameter = (
    type: keyof typeof checkInParameterValues,
    value: string,
  ) => {
    setCheckInParameterValues({ ...checkInParameterValues, [type]: value })
    const isError =
      value !== 'green' && checkInParameterTasks[type].length === 0
    setCheckInParameterErrors({
      ...checkInParameterErrors,
      [type]: isError
        ? t('issues_must_be_created_to_select_yellow_or_red_level')
        : '',
    })
  }
  const total_expected_hours = constructionTasks.reduce(
    (prev, task) => prev + (task.total_work_hours ?? 0),
    0,
  )
  const total_expected_staffing = constructionTasks.reduce(
    (prev, task) => prev + (task.number_of_workers ?? 0),
    0,
  )

  const addTask = (type: checkInParamType) => {
    setShowTaskModal(true)
    setSelectedCheckInParam(type)
  }

  const onChangeActualStaffing = (e: any) => {
    setActualStaffing(e.target.value)
    if (e.target.value >= 0) setActualStaffingError('')
  }

  const onChangeActualWorkingHours = (e: any) => {
    setActualWorkHours(e.target.value)
    if (e.target.value >= 0) setActualWorkHoursError('')
  }

  const submitCheckIn = (e: any) => {
    e.preventDefault()

    let error = false
    checkInParameterTypes.forEach((type) => {
      const value = checkInParameterValues[type]
      if (!value) {
        setCheckInParameterErrors({
          ...checkInParameterErrors,
          [type]: t('add_status'),
        })
        error = true
      }
      if (value !== 'green' && checkInParameterTasks[type].length === 0) {
        error = true
      }
    })
    if (!signature) {
      setSignatureError(t('add_your_signature_here'))
      error = true
    }

    if (!isCheckIn && !(actualStaffing && actualStaffing >= 0)) {
      setActualStaffingError(t('required'))
      error = true
    }

    if (!isCheckIn && !(actualWorkHours && actualWorkHours >= 0)) {
      setActualWorkHoursError(t('required'))
      error = true
    }

    if (!error) {
      const createData = {
        signature,
        no_outstanding_work_tasks: checkInParameterTasks['no_outstanding_work'],
        no_equipment_left_tasks: checkInParameterTasks['no_equipment_left'],
        cleared_and_vacuumed_tasks:
          checkInParameterTasks['cleared_and_vacuumed'],
        safe_to_work_in_tasks: checkInParameterTasks['safe_to_work_in'],
        damage_is_registered_tasks:
          checkInParameterTasks['damage_is_registered'],
        actual_staffing: actualStaffing,
        actual_work_hours: actualWorkHours,
        is_check_in: isCheckIn,
        ...checkInParameterValues,
        discipline_id: disciplineId,
      }
      const editData = {
        id: checkId,
        ...checkInParameterValues,
        signature,
        no_outstanding_work_tasks: checkInParameterTasks['no_outstanding_work'],
        no_equipment_left_tasks: checkInParameterTasks['no_equipment_left'],
        cleared_and_vacuumed_tasks:
          checkInParameterTasks['cleared_and_vacuumed'],
        safe_to_work_in_tasks: checkInParameterTasks['safe_to_work_in'],
        damage_is_registered_tasks:
          checkInParameterTasks['damage_is_registered'],
        actual_staffing: actualStaffing,
        actual_work_hours: actualWorkHours,
        is_check_in: isCheckIn,
        discipline_id: disciplineId,
      }

      if (isEditing) {
        editCheckInOut(checkId, editData).then(() => {
          if (onSubmit) {
            onSubmit()
          } else {
            close()
          }
        })
      } else {
        createCheckInWagon(wagonId, createData).then(() => {
          if (onSubmit) {
            onSubmit()
          } else {
            close()
          }
        })
      }
    }
  }

  const setNewTasks = (
    type: (typeof checkInParameterTypes)[number],
    task: Partial<ITaskFormData>,
  ) => {
    setCheckInParameterTasks({
      ...checkInParameterTasks,
      [type]: [...checkInParameterTasks[type], task],
    })
  }

  return (
    <Modal
      show={show}
      closeModal={close}
      title={
        isCheckIn
          ? t('check_in_checklist_for_discipline_in_wagon', {
              wagon: wagon?.title,
              discipline: discipline?.name,
            })
          : t('check_out_checklist_for_discipline_in_wagon', {
              wagon: wagon?.title,
              discipline: discipline.name,
            })
      }
      size={'w-2/3'}
      maxWidth={800}
    >
      <div className={styleClass.container}>
        <h2 className="first-capitalize font-bold">
          {t('activities_in_discipline')}
        </h2>
        <SimpleActivitiesTable
          constructionTasks={constructionTasks}
          setConstructionTasks={setConstructionTasks}
        />
        <h2 className="first-capitalize font-bold mt-2">{t('checklist')}</h2>
        {checkInParameterTypes.map((parameterType, i) => {
          return (
            <>
              {checkInParameterErrors[parameterType] && (
                <p className={styleClass.errorMessage}>
                  {checkInParameterErrors[parameterType]}
                </p>
              )}
              <div className={styleClass.row}>
                <label className={styleClass.label}>{getLabel(i)}</label>
                <div
                  className={
                    'flex items-center w-full justify-between w-mx-content'
                  }
                >
                  <RadioItems
                    disableTitle={t(
                      'please_create_one_or_more_issues_to_select_medium_or_high',
                    )}
                    items={statusTypes(false)}
                    onClick={(e: any) =>
                      changeCheckInParameter(parameterType, e.target.value)
                    }
                    value={checkInParameterValues[parameterType]}
                    noMargin={true}
                  />
                  {
                    <div className={'flex h-full items-center'}>
                      <Icon
                        icon={Icons.PLUS}
                        className={
                          'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                        }
                        onClick={() => addTask(parameterType)}
                      />
                      <span
                        className={
                          'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border text-center'
                        }
                      >
                        {checkInParameterTasks[parameterType].length}
                      </span>
                    </div>
                  }
                </div>
              </div>
            </>
          )
        })}

        {!isCheckIn && (
          <div className={`${styleClass.inputGroup} my-2 flex-col gap-y-2`}>
            <h2 className="first-capitalize font-bold">
              {t('check_out_details')}
            </h2>
            <div className="flex flex-row gap-2 px-1">
              <div className="flex flex-col w-1/2">
                <p className="first-capitalize italic text-sm">{`${t('expected_actual_staffing')}: ${total_expected_staffing}`}</p>
                <Input
                  required={true}
                  block={true}
                  label={t('actual_staffing')}
                  type={'number'}
                  onChange={onChangeActualStaffing}
                  value={actualStaffing}
                  errorMessage={actualStaffingError}
                  noPadding
                />
              </div>
              <div className="flex flex-col w-1/2">
                <p className="first-capitalize italic text-sm">{`${t('expected_actual_total_hours_worked')}: ${total_expected_hours}`}</p>
                <Input
                  required={true}
                  block={true}
                  label={t('actual_total_hours_worked')}
                  type={'number'}
                  onChange={onChangeActualWorkingHours}
                  value={actualWorkHours}
                  errorMessage={actualWorkHoursError}
                  noPadding
                />
              </div>
            </div>
          </div>
        )}

        <h2 className="first-capitalize font-bold">{t('signature')}</h2>
        <div className={'pl-1'}>
          {signatureError && (
            <p className={styleClass.errorMessage}>{signatureError}</p>
          )}
          <SignatureInput
            width={715}
            height={225}
            errorMessage={''}
            label={t('add_your_signature_here')}
            required={true}
            data={(d: any) => {
              setSignature(d)
              setSignatureError('')
            }}
          />
        </div>
      </div>

      {showTaskModal && selectedCheckInParam && (
        <CreateTaskForm
          closeModal={() => setShowTaskModal(false)}
          customCreateTask={(task) => setNewTasks(selectedCheckInParam, task)}
          defaultTask={{ parent_type: 'ChecklistParameter' }}
        />
      )}

      <ModalFooter>
        <Button type={Button.ButtonType.DEFAULT} onClick={() => close()}>
          {t('cancel')}
        </Button>
        <Button type={Button.ButtonType.PRIMARY} onClick={submitCheckIn}>
          {isEditing ? t('update') : t('add')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default WagonCheckInOut
