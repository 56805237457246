import moment from 'moment'
import { ITestExecution } from 'src/service/OrgTypes'
import { request } from 'src/service/service-utils'

class SystematicCompletionService {
  private readonly projectId
  private readonly testId

  constructor(projectId: number, testId: number | undefined) {
    this.testId = testId
    this.projectId = projectId
  }

  readonly createTestCase = async (testCaseGroupId: number, order: number) => {
    return request(`projects/${this.projectId}/test_cases`, 'POST', {
      test_id: this.testId,
      test_case_group_id: testCaseGroupId,
      order: order ? order + 1 : 1,
    })
  }

  readonly updateAtomicTestCase = async (
    testCaseId: string | number,
    key: string,
    value: string | number | undefined | boolean,
  ) => {
    return request(`test_cases/${testCaseId}`, 'PUT', {
      [key]: value,
    })
  }

  readonly updateTestCase = async (
    testCaseId: string | number,
    props: { [key: string]: string | number },
  ) => {
    return request(`test_cases/${testCaseId}`, 'PUT', {
      ...props,
    })
  }

  readonly updateTestCaseOrder = async (
    testCaseId: string | number,
    order: number,
    newTestCaseGroupId?: number,
  ) => {
    return request(`test_cases/${testCaseId}/order`, 'PUT', {
      order,
      new_test_case_group_id: newTestCaseGroupId,
    })
  }

  readonly updateTestGroupOrder = async (
    testGroupId: string | number,
    order: number,
  ) => {
    return request(`test_case_groups/${testGroupId}/order`, 'PUT', {
      order,
    })
  }

  readonly createTestGroup = async (sectionTitle: string) => {
    return request(`projects/${this.projectId}/test_case_groups`, 'POST', {
      name: sectionTitle,
      project_id: this.projectId,
      test_id: this.testId,
      order: 1000,
    })
  }

  readonly updateTestGroup = async (
    testGroupId: number,
    sectionTitle: string,
  ) => {
    return request(`test_case_groups/${testGroupId}`, 'PUT', {
      name: sectionTitle,
      project_id: this.projectId,
      test_id: this.testId,
    })
  }

  readonly copyTestScript = async (
    fromTestScriptId: number,
    testCaseIds: number[],
    testCaseGroupIds: number[],
  ) => {
    return request(
      `tests/${this.testId}/test_cases/copy/${fromTestScriptId}`,
      'POST',
      {
        test_case_ids: testCaseIds,
        test_case_group_ids: testCaseGroupIds,
      },
    )
  }
  readonly getTestGroups = async () => {
    return request(`tests/${this.testId}/test_case_groups`, 'GET')
  }

  readonly deleteTestCase = async (testCaseId: string) => {
    return request(`test_cases/${testCaseId}`, 'DELETE', {}, true)
  }

  readonly deleteTestGroup = async (testGroupId: number) => {
    return request(`test_case_groups/${testGroupId}`, 'DELETE', {}, true)
  }

  readonly deleteTestGroupTestCases = async (testGroupId: number) => {
    return request(
      `test_case_groups/${testGroupId}/test_cases`,
      'DELETE',
      {},
      true,
    )
  }

  readonly getTestCases = async () => {
    return request(`tests/${this.testId}/test_cases/pages/0/0`, 'POST', {
      sort: {
        field: 'order',
        direction: true,
        active: false,
      },
    })
  }

  readonly getTestCaseExecutions = async (testExecutionId: number) => {
    return request(
      `projects/${this.projectId}/test_executions/${testExecutionId}/test_case_executions`,
      'GET',
    )
  }

  readonly getTestCaseGroupExecutions = async (testExecutionId: number) => {
    return request(
      `projects/${this.projectId}/test_executions/${testExecutionId}/test_case_group_executions`,
      'GET',
    )
  }

  readonly updateTestCaseExecutionsOrder = async (
    testCaseExecutionId: string | number,
    order: number,
  ) => {
    return request(`test_case_execution/${testCaseExecutionId}/order`, 'PUT', {
      order,
    })
  }

  readonly updateTestCaseGroupExecution = async (
    testCaseGroupExecutionId: number,
    name: string,
  ) => {
    return request(
      `test_case_group_executions/${testCaseGroupExecutionId}`,
      'PUT',
      {
        name,
      },
    )
  }

  readonly updateTestExecution = async (
    testExecutionId: number,
    executedDate: moment.Moment,
    status: string,
  ) => {
    return request(
      `projects/${this.projectId}/test_case_executions/${testExecutionId}`,
      'PUT',
      {
        executed_date: executedDate,
        status,
      },
    )
  }

  readonly updateTestExecutionForAll = async (
    testExecutionId: number,
    data: Partial<ITestExecution>,
  ) => {
    return request(
      `projects/${this.projectId}/test_case_executions/${testExecutionId}`,
      'PUT',
      data,
    )
  }
}

export default SystematicCompletionService
