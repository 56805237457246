import { arrayInsert } from 'src/utility/arrayInsert'
import { IExpandableTableInteractionStore } from '../stores/ExpandableTableInteractionStore'
import { IExpandableTableStore } from '../stores/ExpandableTableStore'

export class ReorderColumnsHandler {
  private draggingId?: string
  private hoveringId?: string
  private getState: () => IExpandableTableStore
  private setState: (store: Partial<IExpandableTableStore>) => void
  private setInteractionState: (
    store: Partial<IExpandableTableInteractionStore>,
  ) => void

  constructor(
    getState: () => IExpandableTableStore,
    setState: (store: Partial<IExpandableTableStore>) => void,
    setInteractionState: (
      store: Partial<IExpandableTableInteractionStore>,
    ) => void,
  ) {
    this.getState = getState
    this.setState = setState
    this.setInteractionState = setInteractionState
  }

  readonly setHoveringColumn = (colId?: string) => {
    this.hoveringId = colId
    this.setInteractionState({ hoveringColId: colId })
  }

  readonly setDraggingColumn = (colId?: string) => {
    this.draggingId = colId
    this.setInteractionState({ draggingColId: colId })
  }

  readonly onDraggingEnd = () => {
    if (!this.draggingId || !this.hoveringId) return
    const headers = [...this.getState().headers]
    const headerToMoveIndex = headers.findIndex(
      (header) => header.id === this.draggingId,
    )
    const destinationIndex = headers.findIndex(
      (header) => header.id === this.hoveringId,
    )
    const headerToMove = headers[headerToMoveIndex]

    headers.splice(headerToMoveIndex, 1)

    arrayInsert(headers, destinationIndex, headerToMove)

    const reorderCallback = this.getState().onReorderColumns
    if (reorderCallback) {
      reorderCallback(headerToMove, headerToMoveIndex, destinationIndex)
    }

    this.setState({ headers })
    this.setHoveringColumn(undefined)
    this.setDraggingColumn(undefined)
  }
}
