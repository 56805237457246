import { useQuery, keepPreviousData } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import { getImages } from 'src/service/ImageService'
import { getTestApprovals } from 'src/service/TestApproval'
import {
  getAllProjectTestTypes,
  getCandidateTestExecutionParticipants,
  getProjectTestDocumentTypes,
  getTestExecutionParticipants,
  getTest,
  getCurrentTestExecution,
  getTestExecution,
  getSystemsUnderTest,
  getDocumentStatisticsForTest,
  getProjectTests,
} from 'src/service/TestService'

export const queryKeyTestDocumentTypes = 'testDocumentTypes'
export const useProjectTestTypes = (options?: { enabled: boolean }) => {
  const projectId = useProjectId()
  return useQuery({
    ...options,
    queryKey: ['testTypes', projectId],
    queryFn: () => getAllProjectTestTypes(projectId),
  })
}

export const useFilteredTests = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: Parameters<typeof getProjectTests>[2]['sort']
  filters: Omit<Parameters<typeof getProjectTests>[2], 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['filteredTests', projectId, filters, sort, page, pageSize],
    queryFn: () =>
      getProjectTests(projectId, page, { ...filters, sort }, pageSize),
    placeholderData: keepPreviousData,
    enabled,
  })
}

export const useTest = (testId: number) => {
  return useQuery({
    queryKey: ['test', testId],
    queryFn: () => getTest(testId),
  })
}

export const useCurrentTestExecution = (testId: number) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['currentTestExecution', testId, projectId],
    queryFn: () => getCurrentTestExecution(projectId, testId),
  })
}

export const useTestExecution = (
  testExecutionId: number,
  options?: { enabled: boolean },
) => {
  return useQuery({
    ...options,
    queryKey: ['testExecution', testExecutionId],
    queryFn: () => getTestExecution(testExecutionId),
  })
}

export const useTestExecutionParticipants = (testExecutionId: number) =>
  useQuery({
    queryKey: ['testExecutionParticipants', testExecutionId],
    queryFn: () => getTestExecutionParticipants(testExecutionId),
  })

export const useTestSystems = (testId: number) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['testSystems', projectId, testId],
    queryFn: () => getSystemsUnderTest(projectId, testId),
  })
}

export const useTestApprovals = (
  testExecutionId: number,
  options?: { enabled: boolean },
) => {
  const projectId = useProjectId()
  return useQuery({
    ...options,
    queryKey: ['testSystems', projectId, testExecutionId],
    queryFn: () => getTestApprovals(projectId, testExecutionId),
  })
}

export const useCandidateTestExecutionParticipants = (
  testExecutionId: number,
) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: [
      'candidateTestExecutionParticipants',
      projectId,
      testExecutionId,
    ],
    queryFn: () =>
      getCandidateTestExecutionParticipants(projectId, testExecutionId),
  })
}

export const useTestAttachments = (testId?: number) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['testAttachments', projectId, testId],
    queryFn: () => getImages('Test', testId ?? -1, projectId),
    enabled: !!testId,
  })
}

export const useTestDocumentTypes = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: [queryKeyTestDocumentTypes, projectId],
    queryFn: () => getProjectTestDocumentTypes(projectId),
  })
}

export const useDocumentStatisticsForTest = (testId: number) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: [queryKeyTestDocumentTypes, testId, projectId],
    queryFn: () => getDocumentStatisticsForTest(projectId, testId),
  })
}
