import { capitalize } from 'lodash'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  ChecklistDomains,
  ChecklistStatus,
  checklistStatus,
} from 'src/components/checklist/utils'
import Selector from 'src/components/selectors/Selector'
import { useMutateChecklist } from 'src/query/checklist'
import { IChecklist } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Input from 'src/ui-elements/input/Input'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

interface IChecklistProps {
  onCloseModal: (close: boolean) => void
  show: boolean
  domain: ChecklistDomains
}
const CreateChecklistModal = ({
  onCloseModal,
  show,
  domain,
}: IChecklistProps) => {
  const { t } = useTranslation()
  const { control, setError, handleSubmit } = useForm<Partial<IChecklist>>({
    defaultValues: {
      status: ChecklistStatus.DRAFT,
    },
  })

  const { createList } = useMutateChecklist({
    setError: setError,
    onSuccess: () => onCloseModal(false),
  })
  const create = (data: IChecklist) => {
    createList.mutate({
      ...data,
      domain: domain,
    })
  }

  return (
    <div>
      <Modal
        title={t('new_checklist')}
        show={show}
        maxWidth={500}
        closeModal={() => onCloseModal(false)}
      >
        <form>
          <Controller
            name={'name'}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <Input
                label={capitalize(t('name'))}
                value={value}
                onChange={onChange}
                placeholder={t('name')}
                errorMessage={error?.message ? t(error.message) : ''}
                required
                block
              />
            )}
          />
          <Controller
            name={'status'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Selector
                items={checklistStatus(t)}
                selectedItemId={value}
                onSelect={onChange}
                label={capitalize(t('status'))}
                dataFields={['name']}
                fontSize={'sm'}
                fontWeight={'bold'}
                required={true}
              />
            )}
          />
          <Controller
            name={'description'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                label={capitalize(t('description'))}
                value={value}
                onChange={onChange}
                placeholder={t('description')}
                block
              />
            )}
          />
          <Controller
            name={'source'}
            control={control}
            render={({ field: { value, onChange } }) => (
              <Input
                label={capitalize(t('source'))}
                value={value}
                onChange={onChange}
                placeholder={t('source')}
                block
              />
            )}
          />
          <ModalFooter>
            <Button onClick={() => onCloseModal(false)}>{t('cancel')}</Button>
            <Button onClick={handleSubmit(create)} type={ButtonType.PRIMARY}>
              {t('save')}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    </div>
  )
}

export default CreateChecklistModal
