import { mapValues } from 'lodash'
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom'
import KeypointDetailsPage from 'src/components/key-point/KeypointDetailsPage'
import MilestoneDetailsPage from 'src/components/milestones/MilestoneDetailsPage'
import DeliveryPage from 'src/components/process/delivery/DeliveryPage'
import TaskDetailPage from 'src/components/task/TaskDetailPage'
import useTree from 'src/hooks/useTree'
import { getMilestonesTree } from 'src/service/MilestoneService'
import { useMultipleKeysTranslation } from 'src/service/useMultipleKeysTranslation'
import BreadCrumb from 'src/ui-elements/breadcrumb/BreadCrumb'
import { TreePageStyleClass } from 'src/ui-elements/tabs/Utils'
import { numberOrUndefined } from 'src/utility/utils'
import DropDownExplorer from '../explorer/DropDownExplorer'
import MilestoneList from '../milestones/MilestoneList'

const MilestoneTree: React.FC = () => {
  const history = useHistory()

  const { t } = useMultipleKeysTranslation()

  const { path: basePath } = useRouteMatch()

  const {
    projectId,
    onItemClick,
    data,
    reloadTree,
    breadCrumbsList,
    activeKey,
    openNodes,
    loading,
  } = useTree(
    ['milestone', 'keypoint', 'delivery', 'tasks'],
    getMilestonesTree,
    'MileStone',
  )

  return (
    <div className={TreePageStyleClass.root}>
      <DropDownExplorer
        data={data}
        header={{
          title: t('milestones'),
        }}
        onItemClick={onItemClick}
        initialActiveKey={activeKey}
        initialOpenNodes={openNodes}
        currentPage={1}
        totalPages={1}
        pagination
        loading={loading}
        keyName={'MileStone'}
      />

      <div className={TreePageStyleClass.content}>
        <BreadCrumb breadCrumbs={breadCrumbsList} />
        <Switch>
          <Route
            path={basePath}
            exact
            render={() => (
              <MilestoneList
                onOpenItem={(id) => history.push(`${basePath}/milestone/${id}`)}
                reloadTree={reloadTree}
              />
            )}
          />
          <Route
            path={`${basePath}/milestone/:milestoneId`}
            exact
            render={({ match }) => {
              const { milestoneId } = mapValues(match.params, numberOrUndefined)
              if (!milestoneId) return
              return (
                <MilestoneDetailsPage
                  milestoneId={milestoneId}
                  projectId={projectId}
                  onOpenItem={(id) =>
                    history.push(
                      `${basePath}/milestone/${milestoneId}/keypoint/${id}`,
                    )
                  }
                  reloadTree={reloadTree}
                />
              )
            }}
          />
          <Route
            path={`${basePath}/milestone/:milestoneId/keypoint/:keypointId`}
            exact
            render={({ match }) => {
              const { milestoneId, keypointId } = mapValues(
                match.params,
                numberOrUndefined,
              )
              if (!keypointId) return
              return (
                <KeypointDetailsPage
                  keypointId={keypointId}
                  projectId={projectId}
                  onOpenItem={(id) =>
                    history.push(
                      `${basePath}/milestone/${milestoneId}/keypoint/${keypointId}/delivery/${id}`,
                    )
                  }
                  origin={{
                    name: t('milestone'),
                    url: '/milestone',
                  }}
                  reloadTree={reloadTree}
                />
              )
            }}
          />
          <Route
            path={`${basePath}/milestone/:milestoneId/keypoint/:keypointId/delivery/:deliveryId`}
            exact
            render={({ match }) => {
              const { milestoneId, keypointId, deliveryId } = mapValues(
                match.params,
                numberOrUndefined,
              )
              if (!deliveryId) return
              return (
                <DeliveryPage
                  deliveryId={deliveryId}
                  onOpenItem={(id: number) =>
                    history.push(
                      `${basePath}/milestone/${milestoneId}/keypoint/${keypointId}/delivery/${deliveryId}/tasks/${id}`,
                    )
                  }
                  reloadTree={reloadTree}
                />
              )
            }}
          />

          <Route
            path={`${basePath}/milestone/:milestoneId/keypoint/:keypointId/delivery/:deliveryId/tasks/:taskId`}
            exact
            render={({ match }) => {
              const { taskId } = mapValues(match.params, numberOrUndefined)
              if (!taskId) return
              return (
                <TaskDetailPage
                  taskId={taskId}
                  projectId={projectId}
                  reloadTree={reloadTree}
                />
              )
            }}
          />
        </Switch>
      </div>
    </div>
  )
}

export default MilestoneTree
