import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router-dom'
import MilestoneTree from 'src/components/projectPlanningTree/MilestoneTree'
import TabView, { ITabs } from 'src/components/tab-view/TabView'
import useUserAccess from 'src/hooks/useUserAccess'
import MilestoneTimelineTabs from 'src/page/milestone/MilestoneTimelineTabs'
import PlanningSettingsPage from 'src/page/process/PlanningSettingsPage'

interface IMilestonePage {
  projectId: number
  routeProps?: RouteComponentProps<{
    deliveryId?: string
    keypointId?: string
    milestoneId?: string
    taskId?: string
  }>
}

const MilestonePage = ({
  projectId: _projectId,
  routeProps: _routeProps,
}: IMilestonePage) => {
  const { t } = useTranslation()
  const { adminAccess } = useUserAccess('planning')

  const tabs: ITabs = {
    overview: {
      title: t('table_overview'),
      show: true,
      content: <MilestoneTree />,
    },
    timelines: {
      title: t('timelines'),
      show: true,
      content: <MilestoneTimelineTabs />,
      path: 'timelines',
    },
    settings: {
      title: t('settings'),
      show: adminAccess,
      content: <PlanningSettingsPage parentType={'Milestone'} />,
      path: 'settings',
    },
  }

  return <TabView tabs={tabs} />
}

export default MilestonePage
