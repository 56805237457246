import { isEmpty } from 'lodash'
import { ICategory } from 'src/components/grid/Grid/types'
import { ISystem } from 'src/service/OrgTypes'
import { ISystemConfig } from '../types/ISystem'

export const last = (array: any[]) => {
  return array[array.length - 1]
}

const getIndexes = (
  ids: string[] | number[],
  id1: string | number,
  id2: string | number,
) => {
  const index1 = ids.findIndex((id) => id === id1)
  if (id1 === id2) return [index1, index1]
  const index2 = ids.findIndex((id) => id === id2)
  return index1 < index2 ? [index1, index2] : [index2, index1]
}

export const getSortedIdsFromSystemsConfig = (
  systemsConfig: Record<number, ISystemConfig>,
  systems: ISystem[],
) => {
  const systemIds: number[] = []
  systems.forEach((item) => {
    systemIds.push(item.id)
    if (
      !isEmpty(item.children_systems) &&
      systemsConfig[item.id]?.is_expanded
    ) {
      systemIds.push(
        ...getSortedIdsFromSystemsConfig(
          systemsConfig,
          item.children_systems ?? [],
        ),
      )
    }
  })
  return systemIds
}

export const getSystemConfigSubArray = (
  sortedSystemIds: number[],
  systemId1: number,
  systemId2: number,
) => {
  const [systemStartIndex, systemEndIndex] = getIndexes(
    sortedSystemIds,
    systemId1,
    systemId2,
  )
  return sortedSystemIds.slice(systemStartIndex, systemEndIndex + 1)
}

export const getCategoriesSubArray = (
  categoryIds: string[],
  categoryId1: string,
  categoryId2: string,
) => {
  const [categoryStartIndex, categoryEndIndex] = getIndexes(
    categoryIds,
    categoryId1,
    categoryId2,
  )
  return categoryIds.slice(categoryStartIndex, categoryEndIndex + 1)
}
export const getSelectedCategoriesAndGroups = (
  multipleSelectedCells: Record<number, string[]>,
  categories: ICategory[],
): [string[], ICategory[]] => {
  const uniqueSelectedCategoryIds = [
    ...new Set(Object.values(multipleSelectedCells).flat()),
  ]
  const selectedCategories = categories.filter((category) =>
    uniqueSelectedCategoryIds.includes(category.id),
  )
  const selectedCategoryGroups = selectedCategories
    .map((category) => category.group)
    .filter((val): val is string => !!val)
  return [[...new Set(selectedCategoryGroups)], selectedCategories]
}

export const addSystemsToParentSystem = (
  systemList: ISystem[],
  systemsByParent: Record<number, ISystem[]>,
): { updatedSystem: ISystem[]; childrenSystemIds: number[] } => {
  let childrenSystemIds: number[] = []
  const updatedSystem = systemList.map((system) => {
    let childrenSystems = system.children_systems ?? []

    const parentSystemIds = Object.keys(systemsByParent).map(Number)
    if (parentSystemIds.includes(system.id)) {
      childrenSystems = [...childrenSystems, ...systemsByParent[system.id]]
      childrenSystemIds = [
        ...childrenSystemIds,
        ...systemsByParent[system.id].map((s) => s.id),
      ]
    }

    // If the system has children, recursively add sub-systems to them
    if (childrenSystems?.length) {
      const recursionResponse = addSystemsToParentSystem(
        childrenSystems,
        systemsByParent,
      )
      childrenSystems = recursionResponse.updatedSystem
      childrenSystemIds = [
        ...childrenSystemIds,
        ...recursionResponse.childrenSystemIds,
      ]
    }

    return { ...system, children_systems: childrenSystems }
  })
  return {
    updatedSystem,
    childrenSystemIds,
  }
}

export const getAllChildrenIdsForSystem = (system: ISystem) => {
  const childrenIds: number[] = []
  system.children_systems?.forEach((subSystem) => {
    childrenIds.push(subSystem.id)
    if (subSystem.children_systems?.length) {
      childrenIds.push(...getAllChildrenIdsForSystem(subSystem))
    }
  })
  return childrenIds
}
