import { useQueryClient } from '@tanstack/react-query'
import { parse } from 'query-string'
import React, { useState, useEffect } from 'react'
import {
  initialState,
  IProjectContextProps,
  IProjectContextState,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import { SplashScreenUtil } from 'src/page/splash/splashScreenStore'
import AppCommunicator from 'src/service/AppCommunicator'
import { IProjectData } from 'src/service/OrgTypes'
import { getMyProjects } from 'src/service/ProjectService'

const ProjectContextProvider: React.FC<IProjectContextProps> = (props) => {
  const [state, setState] = useState<IProjectContextState>(initialState)
  const splashScreenUtil = SplashScreenUtil()

  const queryClient = useQueryClient()

  const getProject = (projects: IProjectData[], projectId: string | null) =>
    projects.find((project) => project.project.id === Number(projectId))

  const getCurrentProject = (projects: IProjectData[]) => {
    if (!projects || projects.length === 0) return
    const queryProject = getProject(
      projects,
      parse(location.search)['project'] as string,
    )
    const localProject = getProject(
      projects,
      localStorage.getItem('currentProject'),
    )
    const sessionProject = getProject(
      projects,
      sessionStorage.getItem('currentProject'),
    )
    return queryProject || sessionProject || localProject || projects[0]
  }

  const reload = () => {
    getMyProjects(props.userId)
      .then((res: IProjectData[]) => {
        const p = getCurrentProject(res)
        if (!p) return
        setState({
          ...state,
          projects: res,
          ...p,
          currentProject: p.project,
        })
        setStorage(
          sessionStorage,
          `${p.project.id}`,
          `${p.project.organization_id}`,
        )
        splashScreenUtil.removeSplashScreen()
      })
      .catch(console.error)
  }

  const setStorage = (
    storage: Storage,
    projectId: string,
    organizationId: string,
  ) => {
    storage.setItem('currentProject', projectId)
    storage.setItem('currentOrganization', organizationId)
  }

  const setProject = (updatedProjectId: number): void => {
    const { projects } = state
    const selectedProject = projects.find(
      (p: IProjectData) => p.project.id === updatedProjectId,
    )
    if (!selectedProject) return
    const projectId = String(updatedProjectId)
    const organizationId = String(selectedProject.project.organization_id)
    setStorage(sessionStorage, projectId, organizationId)
    setStorage(localStorage, projectId, organizationId)
    setState({
      ...state,
      ...selectedProject,
      currentProject: selectedProject.project,
    })
    queryClient.invalidateQueries()
  }

  useEffect(() => {
    AppCommunicator.addProjectChangedCallback(setProject)
    reload()
  }, [])

  const context = {
    actions: {
      setProject: setProject,
      reload: reload,
    },
    state,
  }

  return (
    <ProjectContext.Provider value={context}>
      {state && state.currentProject && props.children}
    </ProjectContext.Provider>
  )
}

export default ProjectContextProvider
