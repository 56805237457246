import { lazy, Suspense, useEffect, useContext } from 'react'
import { I18nextProvider } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import MenuHOC from 'src/components/menu/MenuHOC'
import { ImprovedRedirect } from 'src/components/tab-view/ImprovedRedirect'
import BreadCrumbContextProvider from 'src/context/BreadCrumbContext/BreadCrumbProvider'
import CheckProjectAcess from 'src/context/CheckProjectAcessHOC'
import { ConfirmModalContextProvider } from 'src/context/ConfirmModalContext'
import ExportContextProvider from 'src/context/ExportContext/ExportContextProvider'
import ImportContextProvider from 'src/context/ImportContext/ImportContextProvider'
import { InspectorContextProvider } from 'src/context/InspectorContext/InspectorContext'
import ItemSelectContextProvider from 'src/context/ItemSelectContext/ItemSelectProvider'
import MenuContextProvider from 'src/context/MenuContextProvider/MenuProvider'
import MilestoneContextHOC from 'src/context/MilestoneContextHOC'
import ModalContextProvider from 'src/context/ModalContextProvider/ModalProvider'
import MultiTouchContextProvider from 'src/context/MultiTouchContext/MultiTouchProvider'
import ProjectContextHOC from 'src/context/ProjectContextHOC'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import ProjectContextProvider from 'src/context/ProjectContextProvider/ProjectProvider'
import TableInputProvider from 'src/context/TableInputContext/TableInputProvider'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import UserContextProvider from 'src/context/UserContextProvider/UserProvider'
import i18n from 'src/i18n'
import { Module } from 'src/service/OrgTypes'
import Loader from 'src/ui-elements/loader/Loader'
import { redirectSystemRecordIdToDetailPage } from 'src/utility/systemRedirect'

const BimsyncPage = lazy(() => import('src/page/bimsync/pages/BimPage'))
const ControlAreasPage = lazy(
  () => import('src/page/construction/ControlAreasPage'),
)
const DashboardPage = lazy(() => import('src/page/dashboard/DashboardPage'))
const DeliverGridRoute = lazy(() => import('src/page/process/DeliverGridRoute'))
const DeliveryPage = lazy(() => import('src/page/delivery/DeliveryPage'))
const DocumentMainPage = lazy(
  () => import('src/document/pages/DocumentMainPage'),
)
const ErrorPage = lazy(() => import('src/page/error/ErrorPage'))
const FdvuPage = lazy(() => import('src/fdvu/pages/FdvuPage'))
const FloorCrumPage = lazy(
  () => import('src/components/construction/floor/FloorCrumPage'),
)
const GanttPage = lazy(() => import('src/components/gantt/GanttPage'))
const ImprovementPage = lazy(
  () => import('src/page/improvements/ImprovementPage'),
)
const KeyPointPage = lazy(() => import('src/page/key-point/KeyPointPage'))
const KeypointGridRoute = lazy(
  () => import('src/page/process/KeypointGridRoute'),
)
const KPIPage = lazy(() => import('src/page/kpi/KPIPage'))
const MeetingSeriesPage = lazy(
  () => import('src/page/meetings/MeetingSeriesPage'),
)
const MiroConsentPage = lazy(() => import('src/page/miro/ConsentPage'))
const DownloadFilesPage = lazy(() => import('src/page/download/DownloadFiles'))
const NotFound = lazy(() => import('src/page/dashboard/error/NotFound'))

const OrganizationPage = lazy(
  () => import('src/page/organization/OrganizationPage'),
)
const PersonalOverviewListPage = lazy(
  () => import('src/page/dashboard/PersonalOverviewListPage'),
)
const ProcessTimeline = lazy(
  () => import('src/components/process-timeline/ProcessTimeline'),
)
const ReleaseNote = lazy(() => import('src/page/organization/RealeaseNote'))
const RisksPage = lazy(() => import('src/page/risk/RisksPage'))
const RoomsPage = lazy(() => import('src/page/construction/RoomsPage'))
const SearchPage = lazy(() => import('src/page/search/SearchPage'))
const SectionCrumPage = lazy(
  () => import('src/components/construction/section/SectionCrumPage'),
)
const SuperTablePage = lazy(() => import('src/page/super_table/SuperTablePage'))
const SystematicCompletionPage = lazy(
  () => import('src/page/systematic-completion/SystematicCompletionPage'),
)
const SystemsPage = lazy(() => import('src/page/system/SystemsPage'))
const TasksPage = lazy(() => import('src/page/task/TasksPage'))
const TaskTypesPage = lazy(() => import('src/page/task/TaskTypesPage'))
const TestTypeCrumPage = lazy(
  () => import('src/page/systematic-completion/test_type/TestTypeCrumPage'),
)
const UiElements = lazy(() => import('src/ui-elements/uiElements'))
const UserPage = lazy(() => import('src/page/user/UserPage'))
const RevisionHighlight = lazy(
  () => import('src/page/revision-highlight/RevisionHighlight'),
)

const RouteAccess = ({
  path,
  component,
  module,
}: {
  path: string
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>
    | undefined
  module: Module
}) => {
  const userContext = useContext(UserContext)
  const hasReadAccess = userContext.actions.hasReadAccess(module)

  return hasReadAccess && <Route path={path} component={component} />
}

const MainRouter = () => {
  const history = useHistory()
  useEffect(() => {
    const urlParams = history.location.search
    const strippedLocation = history.location.pathname.replace(/\/\/+/g, '/')
    history.replace(`${strippedLocation}${urlParams}`)
  }, [history.location.pathname])
  return (
    <UserContextProvider>
      <UserContext.Consumer>
        {(userContext: IUserContext) =>
          userContext.state.user?.id ? (
            <CheckProjectAcess userId={userContext.state.user.id}>
              <ProjectContextProvider userId={userContext.state.user.id}>
                <MenuContextProvider
                  isSystemAdmin={userContext.state.user.isSystemAdmin}
                >
                  <ExportContextProvider>
                    <ImportContextProvider>
                      <TableInputProvider>
                        <MultiTouchContextProvider>
                          <ProjectContext.Consumer>
                            {(projectContext: IProjectContext) =>
                              projectContext.state.currentProject?.id ? (
                                <I18nextProvider
                                  i18n={i18n}
                                  defaultNS={
                                    projectContext.state.currentProject
                                      .is_infrastructure_project
                                      ? 'infrastructure'
                                      : ''
                                  }
                                >
                                  <ModalContextProvider>
                                    <ConfirmModalContextProvider>
                                      <InspectorContextProvider>
                                        <MenuHOC>
                                          <ItemSelectContextProvider>
                                            <Suspense fallback={<Loader />}>
                                              <Switch>
                                                <Route
                                                  exact={true}
                                                  path="/"
                                                  component={DashboardPage}
                                                />
                                                <Route
                                                  exact={true}
                                                  path="/404"
                                                  component={ErrorPage}
                                                />
                                                <Route
                                                  path="/kpi"
                                                  component={KPIPage}
                                                />
                                                <Route
                                                  exact={true}
                                                  path="/search"
                                                  component={SearchPage}
                                                />
                                                <Route
                                                  exact={true}
                                                  path="/release-note"
                                                  component={ReleaseNote}
                                                />
                                                <Route
                                                  exact={true}
                                                  path="/ui-elements"
                                                  component={UiElements}
                                                />
                                                <Route
                                                  path="/milestone"
                                                  component={
                                                    MilestoneContextHOC
                                                  }
                                                />
                                                <Route
                                                  exact={true}
                                                  path="/task/flows/:flowid"
                                                  component={TaskTypesPage}
                                                />
                                                <RouteAccess
                                                  path="/systematiccompletion"
                                                  component={
                                                    SystematicCompletionPage
                                                  }
                                                  module={
                                                    'systematic_completion'
                                                  }
                                                />
                                                <Route
                                                  path="/keypoint"
                                                  component={KeyPointPage}
                                                />
                                                <Route
                                                  path="/delivery"
                                                  component={DeliveryPage}
                                                />

                                                <Route
                                                  path="/gantt"
                                                  component={GanttPage}
                                                />

                                                <Route
                                                  path="/main-process-canvas"
                                                  component={ProcessTimeline}
                                                />

                                                <Route
                                                  path="/main-table"
                                                  component={SuperTablePage}
                                                />

                                                <Route
                                                  exact={true}
                                                  path="/main-process/keypoint/:id"
                                                  render={(routeProps) => (
                                                    <DeliverGridRoute
                                                      match={routeProps.match}
                                                    />
                                                  )}
                                                />
                                                <Route
                                                  exact={true}
                                                  path="/main-process/milestone/:id"
                                                  render={(routeProps) => (
                                                    <KeypointGridRoute
                                                      match={routeProps.match}
                                                    />
                                                  )}
                                                />
                                                <Route
                                                  path="/meetings"
                                                  component={MeetingSeriesPage}
                                                />
                                                <Route
                                                  exact={true}
                                                  path="/search"
                                                  component={SearchPage}
                                                />
                                                <RouteAccess
                                                  path={'/systems'}
                                                  component={SystemsPage}
                                                  module={'object'}
                                                />
                                                {redirectSystemRecordIdToDetailPage(
                                                  'projects/:projectId/system-number/:systemNumber',
                                                )}
                                                <RouteAccess
                                                  path="/documents"
                                                  component={DocumentMainPage}
                                                  module={'document'}
                                                />
                                                <Route
                                                  path="/rooms"
                                                  component={RoomsPage}
                                                />

                                                <Route
                                                  exact={true}
                                                  path="/users/:id"
                                                  component={UserPage}
                                                />

                                                <Route
                                                  path="/project"
                                                  component={ProjectContextHOC}
                                                />
                                                <Route
                                                  path="/actions"
                                                  component={TasksPage}
                                                />
                                                <Route
                                                  path="/tasks"
                                                  component={TaskTypesPage}
                                                />
                                                <Route
                                                  path="/risks"
                                                  component={RisksPage}
                                                />
                                                <Route
                                                  path="/improvements"
                                                  component={ImprovementPage}
                                                />
                                                <Route
                                                  path="/organization"
                                                  render={() => (
                                                    <OrganizationPage
                                                      isSystemAdmin={
                                                        userContext.state.user
                                                          .isSystemAdmin
                                                      }
                                                    />
                                                  )}
                                                />
                                                <Route
                                                  path="/constructions"
                                                  component={ControlAreasPage}
                                                />
                                                <Route
                                                  path="/test_types"
                                                  render={(routeProps) => {
                                                    return (
                                                      <BreadCrumbContextProvider
                                                        module="testType"
                                                        maxDepth={10}
                                                      >
                                                        <TestTypeCrumPage
                                                          props={routeProps}
                                                        />
                                                      </BreadCrumbContextProvider>
                                                    )
                                                  }}
                                                />

                                                <Route
                                                  path="/sections"
                                                  render={(routeProps) => {
                                                    return (
                                                      <BreadCrumbContextProvider
                                                        module="section"
                                                        maxDepth={4}
                                                      >
                                                        <SectionCrumPage
                                                          props={routeProps}
                                                        />
                                                      </BreadCrumbContextProvider>
                                                    )
                                                  }}
                                                />

                                                <Route
                                                  path="/fdvu"
                                                  component={FdvuPage}
                                                />
                                                <Route
                                                  path={'/bimsync'}
                                                  exact={true}
                                                  component={BimsyncPage}
                                                />

                                                <Route
                                                  path={'/miro'}
                                                  exact={true}
                                                  render={() => (
                                                    <MiroConsentPage />
                                                  )}
                                                />
                                                <Route
                                                  path={'/files'}
                                                  exact={true}
                                                  render={() => (
                                                    <DownloadFilesPage />
                                                  )}
                                                />
                                                <Route
                                                  exact={true}
                                                  path="/personalProjectOverview/:componentToRender/:status"
                                                  render={(routeProps) => (
                                                    <PersonalOverviewListPage
                                                      routeProps={routeProps}
                                                    />
                                                  )}
                                                />
                                                <Route
                                                  exact={true}
                                                  path="/personalOrgOverview/:componentToRender/:status"
                                                  render={(routeProps) => (
                                                    <PersonalOverviewListPage
                                                      routeProps={routeProps}
                                                    />
                                                  )}
                                                />
                                                <Route
                                                  exact={true}
                                                  path="/fileRevisionHighlight/:docId/:fileId"
                                                  render={(routeProps) => (
                                                    <RevisionHighlight
                                                      routeProps={routeProps}
                                                    />
                                                  )}
                                                />
                                                {ImprovedRedirect(
                                                  '',
                                                  'meeting_series',
                                                  'meetings/meeting_series/meeting_series',
                                                )}
                                                <Redirect
                                                  path="/locomotive_type/:wagon_id"
                                                  to="/constructions/templates/wagon_templates/locomotive_types/:wagon_id"
                                                />
                                                {ImprovedRedirect(
                                                  '',
                                                  'folders',
                                                  'documents/documents',
                                                )}
                                                {ImprovedRedirect(
                                                  '',
                                                  'testsystemgroups',
                                                  'systems/test_system_groups',
                                                )}
                                                <Route
                                                  path="/floors"
                                                  render={(routeProps) => {
                                                    return (
                                                      <BreadCrumbContextProvider
                                                        module="floor"
                                                        maxDepth={4}
                                                      >
                                                        <FloorCrumPage
                                                          props={routeProps}
                                                        />
                                                      </BreadCrumbContextProvider>
                                                    )
                                                  }}
                                                />
                                                <Route component={NotFound} />
                                              </Switch>
                                            </Suspense>
                                          </ItemSelectContextProvider>
                                        </MenuHOC>
                                      </InspectorContextProvider>
                                    </ConfirmModalContextProvider>
                                  </ModalContextProvider>
                                </I18nextProvider>
                              ) : null
                            }
                          </ProjectContext.Consumer>
                        </MultiTouchContextProvider>
                      </TableInputProvider>
                    </ImportContextProvider>
                  </ExportContextProvider>
                </MenuContextProvider>
              </ProjectContextProvider>
            </CheckProjectAcess>
          ) : null
        }
      </UserContext.Consumer>
    </UserContextProvider>
  )
}

export default MainRouter
