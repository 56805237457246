import * as React from 'react'
import { useEffect, useState } from 'react'
import { getRandomId } from 'src/utility/getRandomId'
import { useExpandableTableInteractionStoreState } from '../../hooks/useExpandableTableInteractionStore'
import { useExpandableTableStoreState } from '../../hooks/useExpandableTableStore'
import Header from './Header'

const Headers: React.FC = () => {
  const headers = useExpandableTableStoreState((state) => state.headers)
  const readonly = useExpandableTableStoreState((state) => state.readonly)
  const draggingId = useExpandableTableInteractionStoreState(
    (state) => state.draggingColId,
  )

  const [headerKey, setHeaderKey] = useState(() => getRandomId())

  useEffect(() => {
    if (!draggingId) setHeaderKey(getRandomId())
  }, [draggingId])

  return (
    <div key={headerKey} className="bg-white sticky z-20 flex w-max">
      {headers.map((header, index) => (
        <Header
          key={header.id}
          index={index}
          header={header}
          readonly={readonly}
        />
      ))}
    </div>
  )
}

export default Headers
