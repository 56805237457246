import { afConfig } from 'config/environments/af'
import { akhConfig } from 'config/environments/akh'
import { e6bvConfig } from 'config/environments/e6bv'
import { qaConfig } from 'config/environments/qa'
import { risaConfig } from 'config/environments/risa'
import { test2Config } from 'config/environments/test2'
import {
  baseConfig,
  IEnvironmentConfig,
} from '../../config/environments/IEnvironmentConfig'
import { caverionConfig } from '../../config/environments/caverion'
import { demoConfig } from '../../config/environments/demo'
import { impleniaConfig } from '../../config/environments/implenia'
import { localHostConfig } from '../../config/environments/localhost'
import { lvbConfig } from '../../config/environments/lvb'
import { ncsConfig } from '../../config/environments/ncs'
import { osloConfig } from '../../config/environments/oslo'
import { taskctrlConfig } from '../../config/environments/taskctrl'
import { testConfig } from '../../config/environments/test'

const envMap: { [host: string]: IEnvironmentConfig } = {
  [localHostConfig.host]: localHostConfig,
  [demoConfig.host]: demoConfig,
  [testConfig.host]: testConfig,
  [test2Config.host]: test2Config,
  [lvbConfig.host]: lvbConfig,
  [afConfig.host]: afConfig,
  [e6bvConfig.host]: e6bvConfig,
  [caverionConfig.host]: caverionConfig,
  [akhConfig.host]: akhConfig,
  [risaConfig.host]: risaConfig,
  [osloConfig.host]: osloConfig,
  [taskctrlConfig.host]: taskctrlConfig,
  [ncsConfig.host]: ncsConfig,
  [impleniaConfig.host]: impleniaConfig,
  [qaConfig.host]: qaConfig,
  ['storybook.bygg.io']: qaConfig,
}

class EnvironmentLoader {
  private config: IEnvironmentConfig

  constructor() {
    this.initializeEnvironment(window.location.host)
  }

  public readonly initializeEnvironment = (host: string) => {
    const isLocal = window.location.hostname === 'localhost'
    const selectedEnvironmentConfig =
      envMap[host] ?? (isLocal ? localHostConfig : undefined)

    if (!selectedEnvironmentConfig) {
      throw new Error(`No config found for host: ${host}`)
    }
    for (const key of Object.keys(baseConfig)) {
      if (!selectedEnvironmentConfig[key]) {
        throw Error(`Loaded environment is missing key: ${key}`)
      }
    }
    this.config = selectedEnvironmentConfig
  }

  public readonly isTest = () => {
    const testEnvs = [
      localHostConfig.host,
      testConfig.host,
      test2Config.host,
      qaConfig.host,
    ]
    return testEnvs.includes(this.config.host)
  }

  public readonly getConfig = () => {
    return this.config
  }

  public readonly environmentName = () => {
    if (this.config.host.includes('localhost')) return 'development'
    return this.config.host.split('.')[0]
  }

  public get isProd() {
    return import.meta.env.PROD
  }
}

export default new EnvironmentLoader()
