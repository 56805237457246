import {
  RowSelectionState,
  VisibilityState,
  ColumnFiltersState,
} from '@tanstack/react-table'
import { useEffect, useMemo, useState } from 'react'
import { FilteredPaginatedQuery } from 'src/service/OrgTypes'
import BackgroundLoader from '../loader/BackgroundLoader'
import Loader from '../loader/Loader'
import TableBody from './TableBody'
import TableButtons, { TableButtonProps } from './TableButtons'
import useTable, {
  NonColumnFilters,
  Sorting,
  TableFilter,
  UseTableProps,
} from './useTable'

type TableProps<T> = Omit<UseTableProps<T>, 'data'> & {
  useDataQuery: FilteredPaginatedQuery<T>
  initialFilter?: Omit<TableFilter, 'sort'>
  tableButtons?: (
    selectedItems: T[],
  ) => Omit<TableButtonProps<T>, 'table' | 'filters'>
  setSelectedRowsParent?: (rows: RowSelectionState) => void
  initialSelectedRowIds?: number[]
  selectable?: boolean
  adjustableHeight?: boolean
  initialColumnVisibility?: VisibilityState
  defaultFilter?: ColumnFiltersState
  disableSaveTableConfig?: boolean
  disableColumnSelector?: boolean
}

// NB! Note on export in the new table
// Export data is based on column definitions, NOT columns/rows in export files
// Specify export fields in `CustomMeta.field` if it differs from the column field used in the web view
// For legacy column definitions, use `filterDataField`

/**
 * @param useDataQuery
 * @param tableButtons
 * @param initialFilter Added as part of the column filters. Can be removed and changed
 * @param setSelectedRowsParent
 * @param initialSelectedRowIds
 * @param selectable
 * @param adjustableHeight
 * @param initialColumnVisibility
 * @param defaultFilter Invisible filter that is added as part of the fetch. Cannot be reset in the column filters
 * @param disableSaveTableConfig
 * @param disableColumnSelector
 * @param useTableProps
 */

const Table = <T extends { id: string | number }>({
  useDataQuery,
  tableButtons,
  initialFilter,
  setSelectedRowsParent,
  initialSelectedRowIds,
  selectable = true,
  adjustableHeight = false,
  initialColumnVisibility,
  defaultFilter,
  disableSaveTableConfig = false,
  disableColumnSelector = false,
  ...useTableProps
}: TableProps<T>) => {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>({})
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(30)
  const [columnFilters, setColumnFilters] = useState<TableFilter>({})
  const [nonColumnFilters, setNonColumnFilters] = useState<NonColumnFilters>({})
  const [sort, setSort] = useState<Sorting>()
  const [configFetched, setConfigFetched] = useState(false)

  const {
    data: res = {
      pages: 0,
      total_items: 0,
      items: [],
    },
    isFetching,
  } = useDataQuery({
    sort,
    filters: { ...columnFilters, ...nonColumnFilters, ...initialFilter },
    pageSize,
    page,
    enabled: configFetched,
  })

  useEffect(() => {
    setSelectedRowsParent?.(selectedRows)
  }, [selectedRows])

  useEffect(() => {
    setSelectedRows(
      initialSelectedRowIds?.reduce((acc, b) => {
        acc[b] = true
        return acc
      }, {}) ?? {},
    )
  }, [initialSelectedRowIds])

  const selectedItems =
    useMemo(() => {
      const ids = Object.keys(selectedRows)
      return res.items?.filter((item) => ids.includes(`${item.id}`))
    }, [res, selectedRows]) ?? []

  const onRowSelection = (state: RowSelectionState) => {
    setSelectedRows(state)
  }

  const table = useTable({
    data: res.items,
    selectionState: selectedRows,
    onSelect: onRowSelection,
    selectable,
    initialColumnVisibility,
    defaultFilter,
    ...useTableProps,
  })

  useEffect(() => {
    if (!configFetched) {
      setConfigFetched(!table.tableConfigPending)
    }
  }, [table.tableConfigPending])

  useEffect(() => {
    setPage(table.page)
    setPageSize(table.pageSize)
    setColumnFilters(table.filters)
    setSort(table.sorting)
    setNonColumnFilters(table.nonColumnFilters)
  }, [
    table.filters,
    table.page,
    table.pageSize,
    table.sorting,
    table.nonColumnFilters,
  ])

  if (table.tableConfigPending) {
    return <Loader />
  }

  return (
    <>
      <TableButtons
        savedTableConfigs={table.savedTableConfigs}
        tableName={disableSaveTableConfig ? undefined : useTableProps.name}
        disableColumnSelector={disableColumnSelector}
        table={table.table}
        filters={{
          ...table.filters,
          ...table.nonColumnFilters,
          ...initialFilter,
        }}
        nonColumnFilters={table.nonColumnFilters}
        setNonColumnFilters={table.setNonColumnFilters}
        {...(tableButtons && { ...tableButtons(selectedItems) })}
      />
      <TableBody
        table={table.table}
        pageCount={res.pages}
        totalItems={res.total_items}
        adjustableHeight={adjustableHeight}
      />
      <BackgroundLoader active={isFetching} />
    </>
  )
}

export default Table
