import { FC } from 'react'

interface IInlineSkeletonComponent {
  inputWidth?: string
  labelWidth?: string
}

const InlineSkeletonComponent: FC<IInlineSkeletonComponent> = ({
  inputWidth,
  labelWidth,
}) => {
  return (
    <div className="flex animate-pulse cursor-wait h-9 py-1">
      <div className={`${labelWidth} bg-gray-200 mr-2 rounded`}></div>
      <div className={`${inputWidth} bg-gray-200 rounded`}></div>
    </div>
  )
}

export default InlineSkeletonComponent
