import { useEffect } from 'react'
import { Route, useHistory, useRouteMatch } from 'react-router-dom'
import { useSystemByRecordId } from 'src/query/systems/systems'

const SystemRedirectHelper = () => {
  const history = useHistory()
  const matchRoute = useRouteMatch()

  const projectId = matchRoute.params['projectId']
  const matches = history.location.pathname.match(/system-number\/(.*)/)
  const recordId = matches ? matches[1] : undefined

  const { data: system, isFetched } = useSystemByRecordId(projectId, recordId)

  useEffect(() => {
    if (isFetched) {
      if (system) {
        const to = `/systems/systems?systemPath=${system.path}&systemSettingId=${system.system_setting_id}`
        history.replace(to)
      } else {
        history.replace(`/404`)
      }
    }
  }, [history, system, isFetched])
  return <></>
}

export const redirectSystemRecordIdToDetailPage = (from: string) => {
  return (
    <Route
      path={`/${from}`}
      key={'from'}
      exact={true}
      render={() => <SystemRedirectHelper />}
    />
  )
}
