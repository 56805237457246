import moment from 'moment'
import { useEffect, useState, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import {
  getConstructionWagon,
  getWagonCheckListsWithValues,
} from 'src/service/ConstructionWagonService'
import {
  IConstructionChecklist,
  IConstructionWagon,
  ITask,
} from 'src/service/OrgTypes'
import SpinnerMobile from 'src/ui-elements/loader/SpinnerMobile'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { classNames } from '../../utility/utils'
import { contentStyle } from '../MUtils'
import MCard from '../components/MCard'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MVognInfo from './MVognInfo'

const MTavlamoteDetail = () => {
  const styleClass = {
    root: classNames('bg-mobile', 'w-screen', 'h-screen'),
    container: classNames('p-4', 'overflow-y-auto'),
    row: classNames('flex', 'flex-nowrap', 'items-center'),
    label: classNames(
      'text-xs',
      'text-gray-600',
      'font-light',
      'p-2 pl-0',
      'min-w-24 ',
    ),
    errorMessage: classNames(
      'flex',
      'items-center',
      'text-red-600',
      'my-2',
      'text-xs',
      'font-light',
    ),
  }

  const { t } = useTranslation()
  const history = useHistory()
  const { tid } = useParams<{ tid: string }>()
  const location = useLocation<{ wagonId: string }>()
  const wagonId = location.state.wagonId
  const userContext: IUserContext = useContext(UserContext)
  const projectContext: IProjectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const [loading, setLoading] = useState<boolean>(false)
  const [wagon, setWagon] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )
  const [tavlamote, setTavlamote] = useState<IConstructionChecklist>(
    {} as IConstructionChecklist,
  )

  const onBackClick = () => {
    history.goBack()
  }

  useEffect(() => {
    if (wagonId && tid) {
      setLoading(true)
      getConstructionWagon(+wagonId).then((res) => {
        setWagon(res)
      })
      getWagonCheckListsWithValues(+wagonId).then((res) => {
        setTavlamote(res.filter((item: any) => item.id === +tid)[0])
        setLoading(false)
      })
    }
  }, [tid, wagonId, projectId])

  const handleShowTasks = (type: string) => {
    let showTasks: ITask[] = []
    switch (type) {
      case 'sha':
        showTasks = tavlamote?.sha_tasks
        break
      case 'quality':
        showTasks = tavlamote?.quality_tasks
        break
      case 'neatness':
        showTasks = tavlamote?.neatness_tasks
        break
      case 'progress':
        showTasks = tavlamote?.progress_tasks
        break
      case 'staffing':
        showTasks = tavlamote?.staffing_tasks
        break
    }
    if (showTasks?.length > 0) {
      showTasks.map((item) => (item.category = t('board_meeting')))
      history.push({
        pathname: `/mobile/${projectId}/issues/tavlamote`,
        state: { tasks: showTasks },
      })
    }
  }

  const getItem = (color: string) => (
    <div
      className={`rounded-full flex w-6 h-6 bg-${color} hover:border-white focus:border-white mr-2`}
    />
  )
  const getVal = (open: string, total: string, type: string) => {
    const done = Number(total) - Number(open)
    return (
      <div
        onClick={() => handleShowTasks(type)}
        className={`flex justify-center items-center rounded-full mr-1 w-10 h-6 bg-gray-mobile border border-gray-300`}
      >
        {`${done}`}/{total}
      </div>
    )
  }

  return (
    <MPage bgColor={'mobile'}>
      <>
        <MHeader
          showHomeBtn={true}
          showUser={false}
          onBackClick={onBackClick}
          user={userContext.state.user}
        />
        <div style={contentStyle} className={styleClass.container}>
          {loading ? (
            <div className={'w-full h-full grid place-items-center'}>
              <SpinnerMobile />
            </div>
          ) : (
            <>
              {wagon && !isEmpty(wagon) && (
                <MCard noPadding={true} className={'overflow-hidden mb-2'}>
                  <div className={'w-full md:w-1/2'}>
                    <MVognInfo isCard={false} wagon={wagon} />
                  </div>
                </MCard>
              )}
              <MCard>
                <div className={'flex flex-col w-full'}>
                  <div className={`flex mb-3 w-full`}>
                    <div
                      className={
                        'flex text-xs text-gray-500 justify-between w-full pr-2'
                      }
                    >
                      {!isEmpty(tavlamote) && (
                        <p>
                          {moment(tavlamote.created_at).format(
                            'ddd MMM DD YYYY',
                          )}
                        </p>
                      )}
                      <p>
                        {t('done')}/{t('total')}
                      </p>
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-center text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>{t('sha')}</div>

                    <div className={'flex transform scale-75'}>
                      {!isEmpty(tavlamote) && (
                        <>
                          {getItem(`m-${tavlamote?.sha_color}`)}
                          {getVal(
                            `${tavlamote?.check_list_task_count?.sha_checklist}`,
                            `${tavlamote?.check_list_task_count?.sha_checklist_total}`,
                            'sha',
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-center text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>{t('quality')}</div>

                    <div className={'flex transform scale-75'}>
                      {!isEmpty(tavlamote) && (
                        <>
                          {getItem(`m-${tavlamote?.quality_color}`)}
                          {getVal(
                            `${tavlamote?.check_list_task_count?.quality_checklist}`,
                            `${tavlamote?.check_list_task_count?.quality_checklist_total}`,
                            'quality',
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-center text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>{t('neatness')}</div>

                    <div className={'flex transform scale-75'}>
                      {!isEmpty(tavlamote) && (
                        <>
                          {getItem(`m-${tavlamote?.neatness_color}`)}
                          {getVal(
                            `${tavlamote?.check_list_task_count?.neatness_checklist}`,
                            `${tavlamote?.check_list_task_count?.neatness_checklist_total}`,
                            'neatness',
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-center text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>{t('progress')}</div>

                    <div className={'flex transform scale-75'}>
                      {!isEmpty(tavlamote) && (
                        <>
                          {getItem(`m-${tavlamote?.progress_color}`)}
                          {getVal(
                            `${tavlamote?.check_list_task_count?.progress_checklist}`,
                            `${tavlamote?.check_list_task_count?.progress_checklist_total}`,
                            'progress',
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-center text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>
                      {t('number_of_workers')}
                    </div>

                    <div className={'flex transform scale-75'}>
                      {!isEmpty(tavlamote) && (
                        <>
                          {getItem(`m-${tavlamote?.staffing_color}`)}
                          {getVal(
                            `${tavlamote?.check_list_task_count?.staffing_checklist}`,
                            `${tavlamote?.check_list_task_count?.staffing_checklist_total}`,
                            'staffing',
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-center text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>
                      {t('staffing_in_the_area')}
                    </div>

                    <div className={'mr-4 text-gray-500'}>
                      {wagon?.planned_staffing}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-center text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>
                      {t('number_of_workers_in_the_area')}
                    </div>

                    <div className={'mr-4 text-gray-500'}>
                      {tavlamote.number_of_workers}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-center text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>{t('created_by')}</div>

                    <div className={'mr-4 text-gray-500'}>
                      {tavlamote.signed_by}
                    </div>
                  </div>

                  <div
                    className={
                      'flex items-center text-xs font-medium text-gray-600 mb-1 justify-between'
                    }
                  >
                    <div className={'mr-1 min-w-18'}>
                      {t('were_all_disciplines_in_the_control_area_present')}
                    </div>

                    <div className={'mr-4 text-gray-500'}>
                      {tavlamote?.all_disciplines_present ? t('yes') : t('no')}
                    </div>
                  </div>
                </div>

                <div
                  className={'font-medium text-gray-600 text-xs block my-1'}
                >{`${t('comment_to_board_meeting')} :`}</div>
                <div className={'capitalize text-gray-500 text-xs my-1'}>
                  {tavlamote.comment}
                </div>
              </MCard>
            </>
          )}
        </div>
      </>
    </MPage>
  )
}

export default MTavlamoteDetail
