import { createContext } from 'react'
import { StoreApi } from 'zustand'
import { ReorderColumnsHandler } from '../services/ReorderColumnsHandler'
import { ReorderRowsHandler } from '../services/ReorderRowsHandler'
import { RowCounter } from '../services/RowCounter'
import { IExpandableTableInteractionStore } from '../stores/ExpandableTableInteractionStore'
import { IExpandableTableStore } from '../stores/ExpandableTableStore'

export interface ExpandableTableContext {
  store: StoreApi<IExpandableTableStore>
  interactionStore: StoreApi<IExpandableTableInteractionStore>
  rowCounter: RowCounter
  setRowCounter: (newCounter: RowCounter) => void
  reorderColumnsHandler: ReorderColumnsHandler
  reorderRowsHandler: ReorderRowsHandler
  canReorderRows: boolean
}

export const ExpandableTableContext = createContext<ExpandableTableContext>({
  store: undefined as unknown as StoreApi<IExpandableTableStore>,
  interactionStore:
    undefined as unknown as StoreApi<IExpandableTableInteractionStore>,
  rowCounter: undefined as unknown as RowCounter,
  setRowCounter: () => null,
  reorderColumnsHandler: undefined as unknown as ReorderColumnsHandler,
  reorderRowsHandler: undefined as unknown as ReorderRowsHandler,
  canReorderRows: false,
})
