import * as React from 'react'
import { useState, useMemo } from 'react'

import { useMultipleKeysTranslation } from '../../../../service/useMultipleKeysTranslation'
import { classNames } from '../../../../utility/utils'
import {
  BASE_METADATA_TYPES,
  IMetadataBaseType,
  IMetaDataType,
} from '../../../types/FolderMetadataTypes'
import FolderMetadataIconWrapper from './FolderMetadataIconWrapper'

interface IFolderMetadataFieldDropdown {
  value: IMetaDataType
  possibleMetadataTypes: Record<string, IMetadataBaseType>
  onClick: (newType: IMetaDataType) => void
  menuOnTheLeft?: boolean
  closeMenu: () => void
}

const FolderMetadataFieldDropdown: React.FC<IFolderMetadataFieldDropdown> = ({
  value,
  onClick,
  menuOnTheLeft,
  possibleMetadataTypes,
  closeMenu,
}) => {
  const [selectedType, setSelectedType] = useState<BASE_METADATA_TYPES>(
    value.data_type === 'multi_enum'
      ? BASE_METADATA_TYPES.enum
      : value.data_type,
  )
  const { t } = useMultipleKeysTranslation()

  const styleClass = {
    root: (menuOnTheLeft: boolean) =>
      classNames(
        'bg-white',
        'absolute',
        'top-0',
        menuOnTheLeft && '-right-0',
        'rounded-sm',
        'py-2',
        'z-50',
        'text-sm',
        'border border-gray-300',
      ),
    topMenu: classNames('flex', 'justify-between', 'pb-2', 'px-2'),
    topMenuItem: (isActive: boolean) =>
      classNames(
        'flex',
        'items-center',
        'cursor-pointer',
        'px-2',
        'py-1',
        'w-mx-content',
        'hover:bg-blue-50',
        isActive ? 'bg-d-lightblue text-sm text-blue-root' : '',
      ),
    bottomMenu: classNames('rounded-b-lg', 'pt-1', 'px-2'),
    bottomMenuItem: (isActive: boolean) =>
      classNames(
        'flex',
        'items-center',
        'cursor-pointer',
        'hover:bg-blue-50',
        'p-1',
        'rounded',
        isActive ? 'bg-d-lightblue' : '',
      ),
  }

  const isActiveTopMenu = (typeToCheck: BASE_METADATA_TYPES) =>
    typeToCheck === selectedType

  const isActiveBottomMenu = (typeToCheck: string | string[]) =>
    typeToCheck === value.pattern

  const changeType = (type: BASE_METADATA_TYPES) => {
    setSelectedType(type)
    const options = Object.values(possibleMetadataTypes[type].children)
    if (options.length === 1) selectMetadatatype(options[0])
  }

  const selectMetadatatype = (metaDataType: IMetaDataType) => {
    onClick(metaDataType)
  }

  const activeTypes = useMemo(
    () =>
      Object.values(possibleMetadataTypes).filter((baseType) => !baseType.hide),
    [possibleMetadataTypes],
  )

  const activeTypeOptions = useMemo(
    () =>
      possibleMetadataTypes[selectedType] &&
      Object.values(possibleMetadataTypes[selectedType].children).filter(
        (metaDataType) => !metaDataType.exclude,
      ),
    [possibleMetadataTypes, selectedType],
  )

  return (
    <div className={styleClass.root(!!menuOnTheLeft)}>
      <div className={styleClass.topMenu}>
        {activeTypes.map((baseType) => (
          <div
            key={baseType.type}
            className={styleClass.topMenuItem(isActiveTopMenu(baseType.type))}
            onClick={() => changeType(baseType.type)}
            tabIndex={0}
            onKeyDown={(e) => {
              if (e.key === 'Enter') changeType(baseType.type)
            }}
          >
            <div className="pr-1">
              <FolderMetadataIconWrapper type={baseType.type} />
            </div>
            {isActiveTopMenu(baseType.type) && (
              <div>
                {baseType.readableNameKey
                  ? t(baseType.readableNameKey)
                  : baseType.pattern}
              </div>
            )}
          </div>
        ))}
      </div>
      <hr />
      <div className={styleClass.bottomMenu}>
        {activeTypeOptions?.map((metaDataType: IMetaDataType, index) => (
          <div
            className={styleClass.bottomMenuItem(
              isActiveBottomMenu(metaDataType.pattern),
            )}
            key={index}
            tabIndex={0}
            onClick={() => selectMetadatatype(metaDataType)}
            onBlur={() => {
              if (activeTypeOptions.length - 1 === index) closeMenu()
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') selectMetadatatype(metaDataType)
            }}
          >
            <div className="pr-2">
              <FolderMetadataIconWrapper type={metaDataType.data_type} />
            </div>
            {metaDataType.readableNameKey
              ? t(metaDataType.readableNameKey)
              : metaDataType.pattern}
          </div>
        ))}
      </div>
    </div>
  )
}

export default FolderMetadataFieldDropdown
