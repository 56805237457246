import Delete from '@icons/delete.svg'
import * as React from 'react'
import { useContext } from 'react'
import IconButton from 'src/ui-elements/button/IconButton'
import { ExpandableTableContext } from '../../context/ExpandableTableContext'
import { useExpandableTableInteractionStoreState } from '../../hooks/useExpandableTableInteractionStore'
import { useExpandableTableStoreState } from '../../hooks/useExpandableTableStore'
import { IExpandableSection } from '../../types/IExpandableSection'

const DeleteRow: React.FC<{
  rowId: number
  section: IExpandableSection
}> = ({ rowId, section }) => {
  const { readonly } = useExpandableTableStoreState((state) => state)
  const deleteRow = useExpandableTableStoreState((state) => state.deleteRow)
  const {
    reorderRowsHandler: { setHoveringRow },
  } = useContext(ExpandableTableContext)

  const rowIsHovered = useExpandableTableInteractionStoreState(
    (state) => state.hoveringRow?.rowId === rowId,
  )

  const onDeleteClick = async () => {
    deleteRow(rowId, section.id)
  }

  return (
    <div
      tabIndex={-1}
      onMouseEnter={() => setHoveringRow({ rowId, section })}
      onMouseLeave={() => setHoveringRow(undefined)}
      className={'text-sm relative align-text-middle bg-transparent'}
    >
      {rowIsHovered && !readonly && (
        <IconButton
          icon={<Delete className={'text-lg fill-red'} />}
          onClick={onDeleteClick}
        />
      )}
    </div>
  )
}
export default DeleteRow
