import { useCallback, useEffect, useState } from 'react'

export const useKeyDown = (key: string) => {
  const [keyDown, setKeyDown] = useState(false)

  const handleKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === key) {
        setKeyDown(true)
      }
    },
    [key],
  )

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === key) {
        setKeyDown(false)
      }
    },
    [key],
  )

  const handleWindowBlur = useCallback(() => {
    setKeyDown(false)
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)
    window.addEventListener('blur', handleWindowBlur)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
      window.removeEventListener('blur', handleWindowBlur)
    }
  }, [handleKeyDown, handleKeyUp, handleWindowBlur])

  return keyDown
}
