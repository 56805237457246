import * as React from 'react'
import { useEffect, useRef } from 'react'
import Draggable from 'react-draggable'
import {
  useExpandableTableInteractionStore,
  useExpandableTableInteractionStoreState,
} from '../../hooks/useExpandableTableInteractionStore'

const ColumnDragger: React.FC = () => {
  const timeout = useRef<ReturnType<typeof setTimeout>>()

  const [draggingSource, isResizing] = useExpandableTableInteractionStoreState(
    (state) => [state.resizingColSource, state.isResizingCol],
  )

  const deltaX = useRef(0)

  const interactionStore = useExpandableTableInteractionStore()

  const startResizeHandleCountdown = () => {
    if (isResizing) return
    timeout.current = setTimeout(() => {
      if (isResizing) {
        startResizeHandleCountdown()
      } else {
        interactionStore.setState({ resizingColSource: undefined })
      }
    }, 200)
  }

  useEffect(() => {
    return () => {
      if (timeout.current) {
        clearTimeout(timeout.current)
      }
    }
  }, [])

  if (!draggingSource) return <></>

  return (
    <Draggable
      axis="x"
      offsetParent={draggingSource.target}
      position={{ x: draggingSource.x, y: draggingSource.y }}
      enableUserSelectHack
      onDrag={(_, d) => {
        deltaX.current = deltaX.current + d.deltaX
      }}
      onStart={() => {
        interactionStore.setState({ isResizingCol: true })
      }}
      onStop={() => {
        const currentWidth =
          interactionStore.getState().widthPerCol[draggingSource.header.id]
        let newWidth = currentWidth + deltaX.current
        deltaX.current = 0
        if (
          draggingSource.header.minWidth &&
          newWidth < draggingSource.header.minWidth
        )
          newWidth = draggingSource.header.minWidth
        else if (
          draggingSource.header.maxWidth &&
          newWidth > draggingSource.header.maxWidth
        )
          newWidth = draggingSource.header.maxWidth
        interactionStore
          .getState()
          .resizeColumn(draggingSource.header.id, newWidth)
        interactionStore.setState({
          isResizingCol: false,
          resizingColSource: undefined,
        })
      }}
    >
      <div
        className={'absolute top-0 left-0.5 z-50'}
        onMouseLeave={startResizeHandleCountdown}
      >
        <div className="relative h-full">
          <div className="absolute cursor-col-resize bg-blue-root -left-0.5 top-0 w-1 h-[28px]"></div>
          {isResizing && (
            <div className="absolute top-0 bg-blue-root bottom-0 w-px ml-px" />
          )}
        </div>
      </div>
    </Draggable>
  )
}

export default ColumnDragger
