import { useContext } from 'react'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { Module } from 'src/service/OrgTypes'

const useUserAccess = (module?: Module) => {
  const { hasWriteAccess, hasAdminAccess, hasModuleAdminAccess } =
    useContext(UserContext).actions
  const { isProjectAdmin, isSystemAdmin } = useContext(UserContext).state.user
  return {
    writeAccess: !!module && hasWriteAccess(module),
    adminAccess: !!module && hasAdminAccess(module),
    isProjectAdmin: isSystemAdmin || isProjectAdmin,
    hasModuleAdminAccess: !!module && hasModuleAdminAccess(module),
  }
}

export default useUserAccess
