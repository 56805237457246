import { PropsWithChildren, useState } from 'react'
import * as React from 'react'
import { ReorderColumnsHandler } from '../services/ReorderColumnsHandler'
import { ReorderRowsHandler } from '../services/ReorderRowsHandler'
import { RowCounter } from '../services/RowCounter'
import { ExpandableTableContext } from './ExpandableTableContext'

const ExpandableTableProvider: React.FC<
  PropsWithChildren<
    Pick<
      ExpandableTableContext,
      'store' | 'interactionStore' | 'canReorderRows'
    >
  >
> = ({ store, interactionStore, canReorderRows, children }) => {
  const [rowCounter, setRowCounter] = useState(() => new RowCounter())
  const [reorderColumnsHandler] = useState(
    () =>
      new ReorderColumnsHandler(
        store.getState,
        store.setState,
        interactionStore.setState,
      ),
  )

  const [reorderRowsHandler] = useState(
    () =>
      new ReorderRowsHandler(
        store.getState,
        store.setState,
        interactionStore.setState,
      ),
  )

  if (!store || !interactionStore) return null

  return (
    <ExpandableTableContext.Provider
      value={{
        store,
        interactionStore,
        rowCounter,
        setRowCounter,
        reorderColumnsHandler,
        reorderRowsHandler,
        canReorderRows,
      }}
    >
      {children}
    </ExpandableTableContext.Provider>
  )
}

export default ExpandableTableProvider
