export const arrayInsert = <T,>(
  originalArray: T[],
  index: number,
  ...items: T[]
) => {
  originalArray.splice(index, 0, ...items)
}

export const arrayInsertNoMutation = <T,>(
  originalArray: T[],
  index: number,
  ...items: T[]
) => [...originalArray.slice(0, index), ...items, ...originalArray.slice(index)]
