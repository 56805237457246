import {
  ExportTemplate,
  ExportData,
} from 'src/context/ExportContext/ExportTypes'
import { addColumnsToExportTemplate } from 'src/utility/exportUtils'

export const milestoneExportData: ExportData = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/mile_stones/pages/0/0',
    method: 'POST',
  },
  name: 'milestones',
}

export const milestoneListExportTemplate = {
  geniusApiRequest: {
    url: '{organizationApiBaseUrl}projects/{projectId}/mile_stones/pages/0/0',
    method: 'POST',
  },
  name: 'Milestones',
  displayName: 'milestones',
  columns: [
    'id',
    'title',
    'mainprocess',
    'theme',
    'type',
    'status',
    'deadline',
    'baseline',
    'duration',
    'delay',
    'children',
    'responsible',
    'discipline',
    'contract',
    'created_at',
    'updated_at',
  ],
  rows: [
    'record_id',
    'name',
    'main_process.name',
    'team.name',
    'tag.name',
    'status',
    'deadline',
    'baseline',
    'duration',
    'delay',
    'done_children||open_children||expired_children',
    'responsible.firstName||responsible.lastName',
    'discipline.shortName||discipline.name',
    'contract.contractNumber||contract.contractName',
    'created_at',
    'updated_at',
  ],
}

export const milestoneForPlanning = {
  ...milestoneListExportTemplate,
  ...addColumnsToExportTemplate(milestoneListExportTemplate as ExportTemplate, [
    {
      column: 'keypoints',
      row: 'taskCtrl_child_0',
      index: 1,
    },
    {
      column: 'comments',
      row: 'taskCtrl_child_1',
      index: 4,
    },
  ]),
}
