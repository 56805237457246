import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { userListExportTemplate } from 'src/export-templates/ProjectUsersExports'
import { forgotPassword } from 'src/service/TheWallService'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import {
  IListFilter,
  ResourceType,
} from 'src/ui-elements/list/ListContextProvider'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'
import { TabPageStyleClass } from 'src/ui-elements/tabs/Utils'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import history from '../../history'
import { IUserContractMapper, IUserData } from '../../service/OrgTypes'
import {
  getPaginatedFilterProjectUsersData,
  getProjectUsers,
  removeProjectAccess,
  removeUser,
} from '../../service/UserService'
import Button from '../../ui-elements/button/Button'
import List from '../../ui-elements/list/List'
import {
  filterCase,
  filterType,
  IListColumns,
  ISorting,
} from '../../ui-elements/list/ListTypes'
import Loader from '../../ui-elements/loader/Loader'
import Modal from '../../ui-elements/modal/Modal'
import {
  concatinateValuesForTable,
  userComparator,
  userEmailComparator,
} from '../../utility/Utility'
import {
  classNames,
  constructFilterJson,
  IActiveFilter,
} from '../../utility/utils'
import DeleteModal from '../delete-modal/DeleteModal'
import { setColorForIcon } from '../discipline/disciplineUtils'
import Selector from '../selectors/Selector'
import Checkbox from '../switchHoc/CheckBox'
import AddUserForm from './AddUserForm'
import EditUserForm from './EditUserForm'
import UserIcon from './UserIcon'

interface IProjectUsersListProps {
  projectId: number
  projectAdmin: boolean
  reload: () => void
}

const ProjectUsersList = ({
  projectId,
  projectAdmin,
  reload: _Reload,
}: IProjectUsersListProps) => {
  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-row'),
    rootCol: classNames('w-full', 'flex', 'flex-col', 'pr-4'),
    inputGroup: classNames('w-full', 'flex', 'row', 'my-2'),
  }

  const { t } = useTranslation()
  const [users, setUsers] = useState<any[]>([])
  const [projectAcess, setProjectAcess] = useState<any>([])
  const [selectedUser, setSelectedUser] = useState<any>(null)
  const [selectedUserContractAccess, setSelectedUserContractAccess] =
    useState<IUserContractMapper | null>(null)
  const [selectedUserProjectAccess, setSelectedUserProjectAccess] =
    useState<any>(null)
  const [showCreateModal, setShowCreateModal] = useState<boolean>(false)
  const [showEditModal, setShowEditModal] = useState<boolean>(false)
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
  const [showDeleteAccessModal, setShowDeleteAccessModal] =
    useState<boolean>(false)
  const [showResetPasswordModal, setShowResetPassword] =
    useState<boolean>(false)
  const [showEditUserModal, setShowEditUserModal] = useState<boolean>(false)

  const [isProjectAccess, setIsProjectAccess] = useState<boolean>(false)
  const [replacementUserId, setReplacementUserId] = useState<number>(0)
  const [replacementEmail, setReplacementEmail] = useState<string>()
  const [contractId, setContractId] = useState<number>(0)
  const [totalPages, setTotalPages] = useState<number>(0)
  const pageSize = 30

  const [reloadTable, setReloadTable] = useState<boolean>(false)
  const projectRef = useRef(projectId)
  const [loading, setLoading] = useState<boolean>(true)

  const { addAlert } = useAlert()
  const showAlert = (type: IAlertType, title: string, desc: string) => {
    addAlert({ type, title, description: desc })
  }

  const {
    getUserFilter,
    getDisciplineFilter,
    getContractFilter,
    getRoleFilter,
  } = useListHelper()

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['user_id'] = activeFilters['user']
    delete activeFilters['user']
    activeFilters['sort'] = currentSorting
    userFilteredData(activeFilters, page)
  }

  const userFilteredData = (activeFilters: IActiveFilter, page: number) => {
    getPaginatedFilterProjectUsersData(
      projectId,
      activeFilters,
      page,
      pageSize,
    ).then((res) => {
      setProjectAcess(res.user_access)
      setTotalPages(res.pages)
      setLoading(false)
      if (selectedUserProjectAccess) {
        const seletedUserCopy = res.user_access.filter(
          (user: any) => user.id === selectedUserProjectAccess.id,
        )
        seletedUserCopy.length > 0
          ? setSelectedUserProjectAccess(seletedUserCopy[0])
          : setSelectedUserProjectAccess(null)
      }
    })
  }

  useEffect(() => {
    reload()
    setLoading(true)
    setUsers([])
    setProjectAcess([])
  }, [projectId])

  useEffect(() => {
    projectRef.current = projectId
  })

  const reload = () => {
    setReloadTable((n) => !n)
  }

  const onDeleteProjectAccess = (row: any) => {
    setShowDeleteAccessModal((n) => !n)
    setSelectedUser(row.user)
    setSelectedUserProjectAccess(row)
    setIsProjectAccess(true)

    getProjectUsers(projectId).then((u: IUserData[]) => {
      setUsers(u)
    })
  }

  const onOpenResetPasswordModal = (row: any) => {
    closeDetailUser()
    setSelectedUserProjectAccess(null)
    setShowResetPassword(true)
    setSelectedUser(row.user)
  }

  const onOpenEditUserModal = (row: any) => {
    setShowEditUserModal(true)
    setSelectedUser(row.user)
  }

  const onCloseEditUserModal = () => {
    setShowEditUserModal(false)
    setSelectedUser(null)
    reload()
  }

  const onPasswordResetEmail = () => {
    forgotPassword(selectedUser?.email)
      .then(() => {
        showAlert(
          'success',
          t('success'),
          t(
            'we_have_sent_the_user_an_email_with_information_about_the_new_password',
          ),
        )
        setShowResetPassword(false)
      })
      .catch((err) => {
        showAlert('error', t('an_error_occurred'), err.body.message.text)
      })
  }

  const onDeleteContractAccess = (row: any) => {
    setShowDeleteAccessModal((n) => !n)
    setContractId(row.contract.id)
    setIsProjectAccess(true)
    getProjectUsers(projectId).then((u: IUserData[]) => {
      setUsers(u)
    })
    setShowEditModal(false)
  }

  const openCreateModal = () => {
    setShowCreateModal((n) => !n)
  }

  const closeCreateModal = () => {
    setShowCreateModal((n) => !n)
    reload()
  }

  const closeDeleteModal = () => {
    setShowDeleteModal((n) => !n)
    setSelectedUser(null)
  }

  const closeEditModal = () => {
    setShowEditModal((n) => !n)
    reload()
  }

  const onRowClick = (row: any) => {
    setSelectedUser(row.user)
    setSelectedUserProjectAccess(row)
  }

  const onAccessRowClick = (row: any) => {
    setSelectedUserContractAccess(row)
    setShowEditModal((n) => !n)
  }

  const onAccessChildRowClick = (
    row: IUserContractMapper,
    selectedUser: IUserData,
  ) => {
    setSelectedUser(selectedUser)
    setSelectedUserContractAccess(row)
    setShowEditModal((n) => !n)
  }

  const openCreateAccessModal = () => {
    setShowEditModal((n) => !n)
    setSelectedUserContractAccess(null)
  }

  const deleteUser = () => {
    const newUserId = replacementUserId > 0 ? replacementUserId : null
    removeUser(selectedUser, newUserId)
      .then(() => {
        setShowDeleteModal((n) => !n)
        reload()
      })
      .catch((err) => console.error(err))
  }

  const closeDetailUser = () => {
    setSelectedUser(null)
  }

  const closeDeleteAccessModal = () => {
    setShowDeleteAccessModal((n) => !n)
    setSelectedUser(null)
  }

  const deleteAccess = () => {
    const id = isProjectAccess ? projectId : contractId
    const newUserId = replacementUserId > 0 ? replacementUserId : null
    removeProjectAccess(id, selectedUser.id, newUserId, isProjectAccess)
      .then(() => {
        setShowDeleteAccessModal((n) => !n)
        reload()
      })
      .catch((err) => console.error(err))
  }

  const goToUser = (row: any) => {
    history.push(`users/${row.user_id}`)
  }

  const onRplacementUserChange = (value: number) => {
    setReplacementUserId(value)
    setReplacementEmail(users.filter((user) => user?.id === value)[0]?.email)
  }

  const onSystemAdminChange = () => {}

  const columns: IListColumns[] = [
    {
      name: 'name',
      size: '250',
      id: 'name',
      dataField: 'user',
      sortingField: 'user',
      filterType: filterType.DEFAULT,
      filterDataField: 'user.firstName||user.lastName',
      filterDataValue: 'user_id',
      filter: [],
      getFilter: getUserFilter,
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? userComparator(a, b, direction) : 0) : 0,
      cell: (user: any) =>
        user ? (
          <UserIcon
            userId={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            image_url={user.image_url}
          />
        ) : (
          <span />
        ),
    },
    {
      name: 'contract',
      size: '200',
      id: 'contract',
      sortingField: 'contract',
      dataField: 'user_contract_mappers',
      filterFiled: 'contract',
      filterType: filterType.DEFAULT,
      filterDataField: 'contract.contracttName',
      filterDataValue: 'contract.id',
      filter: [],
      getFilter: getContractFilter,
      cell: (contracts: any) => (
        <span className="truncate">
          {contracts
            .map((contract: any) =>
              contract.contract ? contract.contract.contractName : '',
            )
            .join(', ')}
        </span>
      ),
    },
    {
      name: 'discipline',
      size: '200',
      sortingField: 'discipline',
      filterFiled: 'discipline',
      filterType: filterType.DEFAULT,
      filterDataField: 'discipline.shortName',
      filterDataValue: 'discipline.id',
      getFilter: getDisciplineFilter,
      filter: [],
      id: 'disiplin',
      dataField: 'user_contract_mappers',
      cell: (access: any) => (
        <span className="truncate">
          {access
            ? access
                .filter((ds: any) => ds.discipline)
                .map((ds: any) => ds.discipline.name)
                .join(', ')
            : ''}
        </span>
      ),
    },
    {
      name: 'role',
      size: '200',
      id: 'role',
      filterFiled: 'role',
      dataField: 'user_contract_mappers',
      filterDataField: 'role.roleName',
      filterDataValue: 'role.id',
      getFilter: getRoleFilter,
      filter: [],
      filterType: filterType.DEFAULT,
      sortingField: 'role',
      cell: (access: any) => (
        <span className="truncate">
          {access
            ? access
                .filter((ds: any) => ds.role)
                .map((ds: any) => ds.role.roleName)
                .join(', ')
            : ''}
        </span>
      ),
    },
    {
      name: 'email',
      size: '300',
      id: 'projectEmail',
      dataField: 'email||user',
      sortingField: 'email',
      filterType: filterType.TEXT,
      filter: [],
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? userEmailComparator(a, b, direction) : 0) : 0,
      cell: (data: any) => (
        <span>{data.user.email ? data.user.email : ''}</span>
      ),
    },
    {
      name: 'contract_admin',
      size: '200',
      id: 'is_contract_admin',
      dataField: 'user_contract_mappers',
      filterFiled: 'is_contract_admin',
      filterType: filterType.DEFAULT,
      filterCase: filterCase.ACTION_YES_NO,
      filter: [
        {
          name: 'yes',
          value: 'Ja',
          active: false,
        },
        {
          name: 'no',
          value: 'Nei',
          active: false,
        },
      ],
      cell: (accesses: IUserContractMapper[], key: number) => (
        <div
          className="flex flex-row items-center pointer-events-none"
          key={key}
        >
          <Checkbox
            disableTab={true}
            onChange={onSystemAdminChange}
            valueProp={
              accesses.find((it) => it.isContractAdmin)?.isContractAdmin ??
              false
            }
          />
        </div>
      ),
    },
    {
      name: 'project_admin',
      size: '200',
      id: 'isProjectAdmin',
      dataField: 'isProjectAdmin',
      sortingField: 'isProjectAdmin',
      filterType: filterType.DEFAULT,
      filterCase: filterCase.ACTION_YES_NO,
      filterDataField: 'isProjectAdmin',
      filterDataValue: 'isProjectAdmin',
      filter: [
        {
          name: 'yes',
          value: 'Ja',
          active: false,
        },
        {
          name: 'no',
          value: 'Nei',
          active: false,
        },
      ],
      cell: (isProjectAdmin: any, key: number) => (
        <div
          className="flex flex-row items-center pointer-events-none"
          key={key}
        >
          <Checkbox
            disableTab={true}
            onChange={onSystemAdminChange}
            valueProp={isProjectAdmin}
          />
        </div>
      ),
    },
    {
      name: 'access_to_project_risk',
      size: '250',
      id: 'riskAdmin',
      dataField: 'isProjectRiskAdmin||user||project_id',
      sortingField: 'isProjectRiskAdmin',
      filterType: filterType.DEFAULT,
      filterCase: filterCase.ACTION_YES_NO,
      filterDataField: 'isProjectRiskAdmin',
      filterDataValue: 'isProjectRiskAdmin',
      filter: [
        {
          name: 'yes',
          value: 'Ja',
          active: false,
        },
        {
          name: 'no',
          value: 'Nei',
          active: false,
        },
      ],
      cell: (cell: any, key: number) => (
        <div
          className="flex flex-row items-center pointer-events-none"
          key={key}
        >
          <Checkbox
            disableTab={true}
            onChange={onSystemAdminChange}
            valueProp={cell?.isProjectRiskAdmin}
          />
        </div>
      ),
    },
    {
      name: 'access_to_meeting_module',
      size: '250',
      id: 'meetingAdmin',
      dataField: 'meetingModuleAccess',
      sortingField: 'meetingModuleAccess',
      filterType: filterType.DEFAULT,
      filterCase: filterCase.ACTION_YES_NO,
      filterDataField: 'meetingModuleAccess',
      filterDataValue: 'meetingModuleAccess',
      filter: [
        {
          name: 'yes',
          value: 'Ja',
          active: false,
        },
        {
          name: 'no',
          value: 'Nei',
          active: false,
        },
      ],
      cell: (meetingModuleAccess: any, key: number) => (
        <div
          className="flex flex-row items-center pointer-events-none"
          key={key}
        >
          <Checkbox
            disableTab={true}
            onChange={onSystemAdminChange}
            valueProp={meetingModuleAccess}
          />
        </div>
      ),
    },
    {
      name: 'access_to_suggestions_for_improvement',
      size: '250',
      id: 'isQualityAdmin',
      dataField: 'isQualityAdmin',
      sortingField: 'isQualityAdmin',
      filterType: filterType.DEFAULT,
      filterCase: filterCase.ACTION_YES_NO,
      filterDataField: 'isQualityAdmin',
      filterDataValue: 'isQualityAdmin',
      filter: [
        {
          name: 'yes',
          value: 'Ja',
          active: false,
        },
        {
          name: 'no',
          value: 'Nei',
          active: false,
        },
      ],
      cell: (isQualityAdmin: any, key: number) => (
        <div
          className="flex flex-row items-center pointer-events-none"
          key={key}
        >
          <Checkbox
            disableTab={true}
            onChange={onSystemAdminChange}
            valueProp={isQualityAdmin}
          />
        </div>
      ),
    },
    {
      name: 'status_manager',
      size: '250',
      id: 'statusManager',
      dataField: 'status_manager',
      sortingField: 'status_manager',
      filterType: filterType.DEFAULT,
      filterCase: filterCase.ACTION_YES_NO,
      filterDataField: 'status_manager',
      filterDataValue: 'status_manager',
      filter: [
        {
          name: 'yes',
          value: 'Ja',
          active: false,
        },
        {
          name: 'no',
          value: 'Nei',
          active: false,
        },
      ],
      cell: (statusManager: any, key: number) => (
        <div
          className="flex flex-row items-center pointer-events-none"
          key={key}
        >
          <Checkbox
            disableTab={true}
            onChange={onSystemAdminChange}
            valueProp={statusManager}
          />
        </div>
      ),
    },
    {
      name: 'construction_manager',
      size: '250',
      id: 'constructionManager',
      dataField: 'isConstructionManager',
      sortingField: 'isConstructionManager',
      filterType: filterType.DEFAULT,
      filterCase: filterCase.ACTION_YES_NO,
      filterDataField: 'isConstructionManager',
      filterDataValue: 'isConstructionManager',
      filter: [
        {
          name: 'yes',
          value: 'Ja',
          active: false,
        },
        {
          name: 'no',
          value: 'Nei',
          active: false,
        },
      ],
      cell: (constructionManager: any, key: number) => (
        <div
          className="flex flex-row items-center pointer-events-none"
          key={key}
        >
          <Checkbox
            disableTab={true}
            onChange={onSystemAdminChange}
            valueProp={constructionManager}
          />
        </div>
      ),
    },
    {
      name: 'created_at',
      size: '200',
      id: 'createdAt',
      dataField: 'created_at',
      sortingField: 'created_at',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '200',
      id: 'updatedAt',
      dataField: 'updated_at',
      sortingField: 'updated_at',
      filterType: filterType.RANGEDATE,
      filter: [],
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
    {
      name: 'last_active',
      size: '250',
      id: 'last_logged_in',
      dataField: 'user',
      cell: (user: any) =>
        user && user.last_logged_in ? (
          <span>{moment(user.last_logged_in).calendar()}</span>
        ) : (
          <span />
        ),
    },
  ]

  const basicColumns: IListColumns[] = [
    {
      name: 'name',
      size: '250',
      id: 'name',
      dataField: 'user',
      sortingField: 'user',
      filterType: filterType.DEFAULT,
      filterDataField: 'user.firstName||user.lastName',
      filterDataValue: 'user_id',
      filter: [],
      getFilter: getUserFilter,
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? userComparator(a, b, direction) : 0) : 0,
      cell: (user: any) =>
        user ? (
          <UserIcon
            userId={user.id}
            firstName={user.firstName}
            lastName={user.lastName}
            image_url={user.image_url}
          />
        ) : (
          <span />
        ),
    },
    {
      name: 'email',
      size: '200',
      id: 'projectBasicUserEmail',
      dataField: 'email||user',
      sortingField: 'email',
      filterType: filterType.TEXT,
      filter: [],
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? userEmailComparator(a, b, direction) : 0) : 0,
      cell: (data: any) => (
        <span>{data.user.email ? data.user.email : ''}</span>
      ),
    },
    {
      name: 'telephone',
      size: '200',
      id: 'projectBasicUserPhone',
      dataField: 'user.telephoneNumber',
      sortingField: 'telephoneNumber',
      filterType: filterType.TEXT,
      filter: [],
    },
    {
      name: 'contract',
      size: '200',
      id: 'contract',
      dataField: 'user_contract_mappers',
      filterType: filterType.TEXT,
      cell: (contracts: any) => (
        <span>
          {contracts
            .map((contract: any) =>
              contract.contract
                ? concatinateValuesForTable(
                    contract.contract.contractNumber,
                    contract.contract.contractName,
                  )
                : '',
            )
            .join()}
        </span>
      ),
    },
    {
      name: 'created_at',
      size: '200',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '200',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
  ]

  const subcolumns: IListColumns[] = [
    {
      name: 'contract',
      size: '200',
      id: 'contract',
      dataField: 'contract',
      cell: (contract: any) =>
        contract ? (
          <span>
            {concatinateValuesForTable(
              contract.contractNumber,
              contract.contractName,
            )}
          </span>
        ) : (
          <span />
        ),
    },
    {
      name: 'contract_admin',
      size: '200',
      id: 'contractAdmin',
      dataField: 'isContractAdmin',
      cell: (isContractAdmin: any) => (
        <span>{isContractAdmin ? 'Ja' : 'Nei'}</span>
      ),
    },
    {
      name: 'discipline',
      size: '150',
      id: 'disiplin',
      dataField: 'discipline',
      cell: (discipline: any) =>
        discipline ? (
          <span className={'flex flex-row'}>
            <span className={'mr-2'}>
              {setColorForIcon({
                color: discipline.color,
                shape: discipline.shape,
              })}
            </span>
            <span>
              {discipline
                ? concatinateValuesForTable(
                    discipline.shortName,
                    discipline.name,
                  )
                : ''}
            </span>
          </span>
        ) : (
          <span />
        ),
    },
    {
      name: 'role',
      size: '150',
      id: 'role',
      dataField: 'role',
      cell: (role: any) => <span>{role ? role.roleName : ''}</span>,
    },
    {
      name: 'active',
      size: '100',
      id: 'active',
      dataField: 'isActive',
      cell: (isActive: any) => <span>{isActive ? 'Ja' : 'Nei'}</span>,
    },
    {
      name: 'created_at',
      size: '150',
      id: 'createdAt',
      dataField: 'created_at',
      cell: (created: any) => (
        <span>{created ? moment(created).format('L') : ''}</span>
      ),
    },
    {
      name: 'updated_at',
      size: '150',
      id: 'updatedAt',
      dataField: 'updated_at',
      cell: (updated: any) => (
        <span>{updated ? moment(updated).format('L') : ''}</span>
      ),
    },
    {
      name: 'modified_by',
      size: '200',
      id: 'grantedBy',
      dataField: 'granted_by',
      cell: (grantedby: any) =>
        grantedby ? (
          <UserIcon
            userId={grantedby.id}
            firstName={grantedby.firstName}
            lastName={grantedby.lastName}
            image_url={grantedby.image_url}
          />
        ) : (
          <span />
        ),
    },
  ]

  return (
    <div className={TabPageStyleClass.root}>
      {projectAdmin ? (
        <List
          actionButton={
            <Button onClick={openCreateModal} type={Button.ButtonType.PRIMARY}>
              {t('new_user')}
            </Button>
          }
          columns={columns}
          data={projectAcess}
          exportTemplate={userListExportTemplate}
          tableName={'projectUsersList'}
          filterParent={'user'}
          onRowClick={onRowClick}
          itemsPerPage={pageSize}
          resourceType={ResourceType.PROJECT_USERS}
          pagination={true}
          getFilteredData={getFilteredData}
          reload={reloadTable}
          totalPages={totalPages}
          sortBackend={true}
          filterResetOption={true}
          actionMenu={[
            {
              name: t('remove_project_access'),
              action: onDeleteProjectAccess,
              type: 'delete',
            },
            {
              name: t('reset_password'),
              action: onOpenResetPasswordModal,
            },
            {
              name: t('edit'),
              action: onOpenEditUserModal,
            },
          ]}
          expandedElement={(selectedUserProjectAccessExpand: any) => {
            return (
              <div className="p-4">
                <List
                  actionButton={
                    <Icon
                      icon={Icons.PLUS}
                      className={'py-1 text-center w-6'}
                      onClick={openCreateModal}
                    />
                  }
                  columns={subcolumns}
                  tableName={'projectUsersSubList'}
                  data={selectedUserProjectAccessExpand.user_contract_mappers}
                  onRowClick={(row: IUserContractMapper) =>
                    onAccessChildRowClick(
                      row,
                      selectedUserProjectAccessExpand.user,
                    )
                  }
                  itemsPerPage={0}
                  isExpandedElement={true}
                  actionMenu={[
                    {
                      name: t('remove_contract_access'),
                      action: onDeleteContractAccess,
                      type: 'delete',
                    },
                  ]}
                />
              </div>
            )
          }}
        />
      ) : (
        <List
          columns={basicColumns}
          data={projectAcess}
          tableName={'projectUsersList'}
          filterParent={'user'}
          onRowClick={goToUser}
          itemsPerPage={pageSize}
          getFilteredData={getFilteredData}
          reload={reloadTable}
          totalPages={totalPages}
          sortBackend={true}
          filterResetOption={true}
          resourceType={ResourceType.PROJECT_USERS}
          pagination={true}
          expandedElement={(selectedUserProjectAccessExpand: any) => {
            return (
              <div className="p-4">
                <List
                  columns={subcolumns}
                  tableName={'projectUsersSubList'}
                  data={selectedUserProjectAccessExpand.user_contract_mappers}
                  onRowClick={onAccessRowClick}
                  itemsPerPage={0}
                  isExpandedElement={true}
                />
              </div>
            )
          }}
        />
      )}
      {loading ? <Loader /> : null}

      {selectedUserProjectAccess !== null && (
        <Modal
          show={selectedUser !== null && selectedUserProjectAccess !== null}
          closeModal={closeDetailUser}
          size={'w-9/10'}
          title={t('user_access_list')}
          maxWidth={1450}
          minHeight={'400px'}
        >
          <div className="p-4">
            <List
              actionButton={
                <Button
                  onClick={openCreateAccessModal}
                  type={Button.ButtonType.PRIMARY}
                >
                  {t('new_access')}
                </Button>
              }
              columns={subcolumns}
              tableName={'projectUsersSubList'}
              data={selectedUserProjectAccess.user_contract_mappers}
              onRowClick={onAccessRowClick}
              itemsPerPage={0}
              actionMenu={[
                {
                  name: t('remove_contract_access'),
                  action: onDeleteContractAccess,
                  type: 'delete',
                },
              ]}
            />
          </div>
        </Modal>
      )}

      {selectedUser !== null && (
        <DeleteModal
          show={showDeleteAccessModal}
          closeModal={closeDeleteAccessModal}
          customTitle={t('are_you_sure_you_want_to_remove_access', {
            username: `${
              selectedUser
                ? selectedUser.firstName + ' ' + selectedUser.lastName
                : ''
            }`,
          })}
          onDelete={deleteAccess}
          itemName={t('access')}
        >
          <div className={styleClass.rootCol}>
            <div className={styleClass.inputGroup}>
              <Selector
                items={users.filter((users) => users.id !== selectedUser.id)}
                selectedItemId={replacementUserId}
                onSelect={onRplacementUserChange}
                label={t('new_responsible')}
                dataFields={['firstName', 'lastName']}
                required={false}
                fontSize={'sm'}
                userSelector={true}
                fontWeight={'bold'}
              />
            </div>
            {replacementEmail ? (
              <div className="text-gray-500 text-sm">{`${t(
                'new_responsible',
              )}: ${replacementEmail}`}</div>
            ) : (
              ''
            )}
          </div>
        </DeleteModal>
      )}

      {selectedUser !== null && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={closeDeleteModal}
          customTitle={t('are_you_sure_you_want_to_delete_the_user', {
            username: `${
              selectedUser
                ? selectedUser.firstName + ' ' + selectedUser.lastName
                : ''
            }`,
          })}
          onDelete={deleteUser}
          itemName={t('the_user')}
        >
          <div className={styleClass.rootCol}>
            <div className={styleClass.inputGroup}>
              <Selector
                items={users}
                selectedItemId={replacementUserId || 0}
                onSelect={onRplacementUserChange}
                label={t('new_responsible')}
                dataFields={['firstName', 'lastName']}
                required={false}
                fontSize={'sm'}
                userSelector={true}
                fontWeight={'bold'}
              />
            </div>
          </div>
        </DeleteModal>
      )}

      <Modal
        show={showCreateModal}
        closeModal={openCreateModal}
        title={t('add_new_user')}
        size={'w-9/10'}
        maxWidth={800}
      >
        <div className={''}>
          <AddUserForm
            projectId={projectId}
            organizationId={0}
            organizationLevel={false}
            isEditMode={false}
            closeModal={closeCreateModal}
          />
        </div>
      </Modal>

      {selectedUser && (
        <Modal
          show={showEditModal}
          closeModal={() => {
            setShowEditModal(false)
            setSelectedUser(null)
          }}
          title={t('edit_user_access')}
          size={'w-9/10'}
          maxWidth={800}
        >
          <div className={''}>
            <AddUserForm
              projectId={projectId}
              organizationId={0}
              organizationLevel={false}
              isEditMode={true}
              user={selectedUser}
              contractAccess={selectedUserContractAccess}
              projectAccess={selectedUserProjectAccess}
              closeModal={closeEditModal}
            />
          </div>
        </Modal>
      )}

      <Modal
        show={showResetPasswordModal}
        closeModal={() => setShowResetPassword(false)}
        title={t('reset_password')}
        size={'w-1/2'}
        maxWidth={650}
      >
        <div>
          <div className={'flex flex-row items-center p-2'}>
            <Icon
              className={'flex flex-grow-0 flex-shrink-0 h-10 w-10 mr-4'}
              styleClass={'w-full'}
              icon={Icons.INFO_BLUE}
            />
            <div className={'flex flex-col w-8p7/10'}>
              <div className={'text-gray-800 mr-4 w-full text-sm truncate'}>
                {t('you_are_about_to_send_password_reset_email', {
                  firstName: selectedUser?.firstName,
                  lastName: selectedUser?.lastName,
                })}
              </div>
            </div>
          </div>
          <ModalFooter>
            <Button
              type={Button.ButtonType.DEFAULT}
              onClick={() => setShowResetPassword(false)}
            >
              {t('cancel')}
            </Button>
            <Button
              type={Button.ButtonType.PRIMARY}
              onClick={onPasswordResetEmail}
            >
              {t('confirm')}
            </Button>
          </ModalFooter>
        </div>
      </Modal>

      {showEditUserModal && selectedUser && (
        <Modal
          size={'w-1/3'}
          show={showEditUserModal}
          closeModal={onCloseEditUserModal}
          title={t('edit_user_info')}
          minHeight={'720px'}
          maxWidth={600}
        >
          <EditUserForm user={selectedUser} closeModal={onCloseEditUserModal} />
        </Modal>
      )}
    </div>
  )
}

export default ProjectUsersList
