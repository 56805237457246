import { useEffect, useMemo, useState } from 'react'
import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import SystematicCompletionService from '../../systematic-completion/services/SystematicCompletionService'
import Button from '../../ui-elements/button/Button'
import { ButtonSize, ButtonType } from '../../ui-elements/button/ButtonEnums'
import Modal from '../../ui-elements/modal/Modal'
import ModalFooter from '../../ui-elements/modal/ModalFooter'
import CopyTestyScriptList from './CopyTestyScriptList'
import TestCaseTable from './TestCaseTable'

const CopyTestScript: React.FC<{
  projectId: number
  testId: number
  reload: () => void
  copyModal?: () => void
}> = ({
  projectId,
  testId,
  reload,
  copyModal,
}: {
  projectId: number
  testId: number
  reload: () => void
  copyModal?: () => void
}) => {
  const { t } = useTranslation()
  const [showCopyModal, setShowCopyModal] = useState(false)
  const [selectedTest, setSelectedTest] = useState(0)
  const [selectedTestCases, setSelectedTestCases] = useState<number[]>([])
  const [selectedTestCaseGroups, setSelectedTestCaseGroups] = useState<
    number[]
  >([])

  const systematicCompletionService = useMemo(
    () => new SystematicCompletionService(projectId, testId),
    [projectId, testId],
  )
  useEffect(() => {
    setSelectedTestCases([])
    setSelectedTestCaseGroups([])
  }, [selectedTest])

  return (
    <>
      <Modal
        show={showCopyModal}
        title={t('copy_test_procedure_from_existing_test')}
        closeModal={() => setShowCopyModal(false)}
        size={'w-9/10'}
        maxWidth={1320}
      >
        <div className={'mb-4 ml-1'}>
          <CopyTestyScriptList
            projectId={projectId}
            onTestClick={(r: any) => {
              setSelectedTest(r.id)
            }}
            testId={testId}
          />
        </div>
        <div className="mb-4 ml-1">
          {!!selectedTest && (
            <TestCaseTable
              readonly={true}
              test={{ id: selectedTest }}
              projectId={projectId}
              onSelector={true}
              selectAble={true}
              selectCallback={(
                selectedTestCaseIds: number[],
                selectedTestCaseGroupIds: number[],
              ) => {
                setSelectedTestCases(selectedTestCaseIds)
                setSelectedTestCaseGroups(selectedTestCaseGroupIds)
              }}
              selectedTestCases={selectedTestCases}
              selectedTestCaseGroups={selectedTestCaseGroups}
            />
          )}
        </div>
        <ModalFooter>
          <Button onClick={() => setShowCopyModal(false)}>{t('cancel')}</Button>
          <Button
            type={ButtonType.PRIMARY}
            disabled={
              selectedTest === 0 ||
              (selectedTestCases.length === 0 &&
                selectedTestCaseGroups.length === 0)
            }
            onClick={async () => {
              await systematicCompletionService.copyTestScript(
                selectedTest,
                selectedTestCases,
                selectedTestCaseGroups,
              )
              await reload()
              setShowCopyModal(false)
            }}
          >
            {t('copy_test_procedure')}
          </Button>
        </ModalFooter>
      </Modal>
      <Button
        className={'h-8'}
        type={ButtonType.SECONDARY}
        size={ButtonSize.XSMALL}
        onClick={() => (copyModal ? copyModal() : setShowCopyModal(true))}
      >
        <div className="flex">
          <Icon icon={Icons.COPY_BLUE} className={'w-4 h-4'} />
        </div>
      </Button>
    </>
  )
}

export default CopyTestScript
