import { RefObject, useMemo, FC } from 'react'
import { ICell } from 'src/systematic-completion/components/expandable-table/components/cell/Cell'
import { useExpandableTableStoreState } from 'src/systematic-completion/components/expandable-table/hooks/useExpandableTableStore'
import { COLUMN_TYPES } from 'src/systematic-completion/components/expandable-table/types/COLUMN_TYPES'
import ExpandableCellDisplay from './ExpandableCellDisplay'
import ExpandableTextArea from './ExpandableTextArea'

interface IExpandableCell extends ICell {
  isEditing: boolean
  finishEditing: (newValue: string) => void
  displayRef: RefObject<HTMLDivElement>
  placeholder?: string
  setEditing?: () => void
}

const ExpandableCell: FC<IExpandableCell> = ({
  finishEditing,
  displayRef,
  isEditing,
  placeholder,
  setEditing,
  rowId,
  getRowIndex,
  section,
  colIndex,
  initialItem,
}) => {
  const sectionIndex = useExpandableTableStoreState((state) =>
    state.expandableSections.findIndex((value) => value.id === section.id),
  )

  const header = useExpandableTableStoreState(
    (state) => state.headers[colIndex],
  )

  const row = useExpandableTableStoreState((state) => state.rows[rowId])
  const value = useMemo(() => row?.[header.field], [row, header])
  if (header.type === COLUMN_TYPES.CUSTOM && header.cell)
    return (
      <header.cell
        isEditing={isEditing}
        finishEditing={finishEditing}
        value={value}
        rowId={rowId}
        row={row}
        rowIdx={getRowIndex()}
        sectionIdx={sectionIndex}
      />
    )

  if (header.type === COLUMN_TYPES.TEXT) {
    return (
      <ExpandableTextArea
        placeholder={placeholder}
        finishEditing={finishEditing}
        value={value}
        onClick={setEditing}
        sectionId={section.id}
        rowId={rowId}
        initialItem={initialItem}
      />
    )
  }

  return (
    <ExpandableCellDisplay
      displayRef={displayRef}
      value={value}
      placeholder={placeholder}
      setEditing={setEditing}
    />
  )
}

export default ExpandableCell
