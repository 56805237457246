import { useQueryClient } from '@tanstack/react-query'
import { createColumnHelper } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useBoolean from 'src/hooks/useBoolean'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import { useContractsFiltered } from 'src/query/contract'
import {
  deleteContract,
  getFilteredContracts,
} from 'src/service/ContractService'
import { IContract } from 'src/service/OrgTypes'
import {
  defaultDateColumnObject,
  defaultTextColumn,
} from 'src/ui-elements/Table/Columns'
import Table from 'src/ui-elements/Table/Table'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Modal from 'src/ui-elements/modal/Modal'
import useProjectId from '../hooks/useProjectId'
import ContractForm from './ContractForm'
import ContractInspectorPanel from './contractInspectorPanel'

const ContractTable = () => {
  const [selectedContract, setSelectedContract] = useState<
    IContract | undefined
  >(undefined)
  const { value, toggle } = useBoolean(['edit', 'inspect', 'new'])

  const columnHelper = createColumnHelper<IContract>()

  const queryClient = useQueryClient()
  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['contractsFiltered'] })
  }

  const { t } = useTranslation()
  const projectId = useProjectId()

  const ct = (s: string) => {
    return capitalize(t(s))
  }

  const columns = [
    columnHelper.accessor(
      'contractNumber',
      defaultTextColumn('contractNumber', { name: ct('contract_number') }),
    ),
    columnHelper.accessor(
      'contractName',
      defaultTextColumn('contractName', { name: ct('contract_name') }),
    ),
    columnHelper.accessor(
      'contractSize',
      defaultTextColumn('contractSize', { name: ct('size') }),
    ),
    columnHelper.accessor(
      'company_name',
      defaultTextColumn('company_name', { name: ct('company') }),
    ),
    columnHelper.accessor(
      'updated_at',
      defaultDateColumnObject('updated_at', { name: ct('updated_at') }),
    ),
    columnHelper.accessor(
      'created_at',
      defaultDateColumnObject('created_at', { name: ct('created_at') }),
    ),
  ]

  const useData = (filterProps: {
    sort: unknown
    filters: Omit<Parameters<typeof getFilteredContracts>[3], 'sort'>
    page: number
    pageSize: number
    enabled?: boolean
  }) => useContractsFiltered(filterProps)

  const { confirmDelete } = useDeleteModal()

  const onDeleteClick = async (contract: IContract) => {
    const result = await confirmDelete({
      itemIdnType: `${contract.contractNumber} (${t('contract')})`,
      itemName: `${contract?.contractNumber} ${contract?.contractName}`,
    })
    if (result) {
      await deleteContract(contract)
      reload()
    }
  }

  return (
    <div>
      <Table
        tableButtons={() => ({
          customButtons: [
            <Button
              key="new"
              type={ButtonType.PRIMARY}
              onClick={() => toggle('new')}
            >
              {t('new_contract')}
            </Button>,
          ],
        })}
        name={'contractList'}
        columns={columns}
        useDataQuery={useData}
        onDeleteClick={onDeleteClick}
        selectable={false}
        onRowClick={(c) => {
          setSelectedContract(c)
          toggle('edit')
        }}
        onPreviewClick={(c) => {
          setSelectedContract(c)
          toggle('inspect')
        }}
      />
      {selectedContract && (
        <ContractInspectorPanel
          contractId={selectedContract.id}
          open={value('inspect')}
          onClose={() => setSelectedContract(undefined)}
          onUpdate={reload}
        />
      )}

      {selectedContract && (
        <Modal
          show={value('edit')}
          closeModal={() => toggle('edit')}
          title={t('edit_contract')}
          maxWidth={600}
        >
          <ContractForm
            editingMode={true}
            contract={selectedContract}
            projectId={projectId}
            closeModal={() => {
              toggle('edit')
              reload()
            }}
          />
        </Modal>
      )}
      <Modal
        size={'w-1/3'}
        show={value('new')}
        closeModal={() => toggle('new')}
        title={t('add_contract')}
        maxWidth={600}
      >
        <ContractForm
          editingMode={false}
          contract={{
            id: 0,
            contractName: '',
            contractNumber: '',
            company_name: '',
            contractSize: '',
          }}
          projectId={projectId}
          closeModal={() => {
            toggle('new')
            reload()
          }}
        />
      </Modal>
    </div>
  )
}

export default ContractTable
