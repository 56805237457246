import { ColumnDef, VisibilityState } from '@tanstack/react-table'
import { t } from 'i18next'
import { capitalize } from 'lodash'
import moment, { Moment } from 'moment/moment'
import { useRef } from 'react'
import {
  DisciplineAndResponsibleColumn,
  ResponsibleAndDisciplineColumn,
} from 'src/components/TableColumns/DisciplineResponsibleColumns'
import { MainProcessAndThemeColumn } from 'src/components/TableColumns/MainProcessTemaColumns'
import useProjectId from 'src/components/hooks/useProjectId'
import { IStatusCell } from 'src/components/status-dropdown/TableStatusLabel'
import { useFreezePlan } from 'src/query/planning/freezePlan'
import { updateDelivery } from 'src/service/DeliveryService'
import { getFilteredProjectImprovementsWithPagination } from 'src/service/ImprovementsService'
import {
  IDelivery,
  IDiscipline,
  IMainProcess,
  IStatusTypes,
  ITeam,
  IUserData,
} from 'src/service/OrgTypes'
import { getProjectKeypoints } from 'src/service/ProcessService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import {
  getDeliveryErrorMessage,
  StructureValidationError,
} from 'src/service/ValidationErrors'
import {
  dateColumn,
  editableDateColumn,
  editableTextColumn,
  multiFilterOptionsColumn,
  multiFilterOptionsColumnEditable,
  numberColumn,
  statusColumn,
  taskCountColumn,
  textColumn,
  userColumn,
} from 'src/ui-elements/Table/Columns'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import useAlert from 'src/ui-elements/toast/useAlert'

interface IDeliveryColumnType {
  onDateChange?: (delivery: IDelivery, date: string) => void
  disabled?: boolean
  reload?: () => void
}

export function useDeliveryColumns({
  onDateChange,
  disabled = false,
  reload,
}: IDeliveryColumnType) {
  const {
    getContractFilter,
    getMilestoneFilter,
    getKeypointFilter,
    getTagFilter,
    getImprovementFilter,
    getReporterFilter,
    getTeamFilter,
  } = useListHelper()

  const projectId = useProjectId()
  const { addAlert } = useAlert()
  const projectUsers = useRef<IUserData[]>([])
  const projectDiscipline = useRef<IDiscipline[]>([])
  const mainProcesses = useRef<IMainProcess[]>([])
  const themes = useRef<ITeam[]>([])
  const { data: freezePlan } = useFreezePlan('delivery')

  const showAlert = (text: string) => {
    addAlert({
      type: 'error',
      title: t('something_went_wrong'),
      description: text,
    })
  }

  const deliveryUpdate = async (newDelivery: Partial<IDelivery>) => {
    if (newDelivery.id) {
      await updateDelivery(newDelivery, newDelivery.id)
      reload?.()
    }
  }

  const onStatusSelect = (
    status: IStatusTypes,
    key: number,
    delivery: IStatusCell,
  ) => {
    if (
      delivery.open_children &&
      delivery.open_children > 0 &&
      status.id === 'done'
    ) {
      showAlert(
        getDeliveryErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return
    }
    deliveryUpdate({ id: key, status: status.id })
  }

  const validateDateChange = (delivery: IDelivery, date?: Moment): string => {
    if (!date) return ''
    const backInTime = moment(date).isBefore(delivery.endTime, 'day')
    if (backInTime) return ''
    if (
      delivery.key_point &&
      moment(delivery.key_point.endTime).isBefore(date, 'day')
    ) {
      showAlert(
        getDeliveryErrorMessage(
          StructureValidationError.PAST_PARENT_DEADLINE,
          t,
          moment(delivery.key_point.endTime).format('L'),
        ),
      )
      return 'past_keypoint_deadline'
    }
    if (
      delivery.improvement &&
      moment(delivery.improvement.deadline).isBefore(date, 'day')
    ) {
      showAlert(
        getDeliveryErrorMessage(
          StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
          t,
          moment(delivery.improvement.deadline).format('L'),
        ),
      )
      return 'past_improvement_deadline'
    }
    return ''
  }

  const onDateUpdate = (date: string, delivery: IDelivery) => {
    const forwardInTime = moment(date).isAfter(delivery.endTime, 'day')
    const freezeUntil =
      freezePlan?.plan_freeze_period && freezePlan?.plan_freeze_period > 0
        ? moment()
            .add(freezePlan.plan_freeze_period - 1, 'weeks')
            .endOf('week')
        : moment().startOf('day')
    if (
      forwardInTime &&
      moment(delivery.endTime).isBefore(freezeUntil, 'day')
    ) {
      onDateChange?.(delivery, date)
    } else deliveryUpdate({ id: delivery.id, endTime: date })
  }

  const onFieldChange = (field: string, key: number, value: string) => {
    const delivery = { id: key, [field]: value }
    deliveryUpdate(delivery)
  }

  const updateResponsible = (
    id: number,
    responsibleId: number,
    disciplineId: number,
  ) => {
    const delivery = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    } as IDelivery
    deliveryUpdate(delivery)
  }

  const updateDiscipline = (
    id: number,
    disciplineId: number,
    responsibleId: number,
  ) => {
    const delivery = {
      id,
      responsible_id: responsibleId,
      discipline_id: disciplineId,
    } as IDelivery
    deliveryUpdate(delivery)
  }

  const updateMainProcess = (
    id: number,
    mainProcessId: number,
    teamId: number,
  ) => {
    const delivery = {
      id,
      main_process_id: mainProcessId,
      team_id: teamId,
    } as IDelivery
    deliveryUpdate(delivery)
  }

  const c = (s: string) => capitalize(t(s))

  const columns = [
    textColumn('record_id', { name: t('id') }),
    editableTextColumn(
      'name',
      { name: c('title') },
      (key, value) => onFieldChange('name', +key, value),
      disabled,
    ),
    dateColumn('created_at', { name: c('created_at') }),
    dateColumn('updated_at', { name: c('updated_at') }),
    editableDateColumn(
      'endTime',
      { name: c('deadline') },
      onDateUpdate,
      validateDateChange,
      disabled,
    ),
    dateColumn('baseline', { name: c('baseline_date') }),
    dateColumn('closed_date', { name: c('closed_date') }),
    multiFilterOptionsColumn(
      'contract',
      {
        name: c('contract'),
        getFilter: getContractFilter,
        field: 'contract.contractNumber||contract.contractName',
      },
      ['contractNumber', 'contractName'],
    ),
    multiFilterOptionsColumn(
      'mile_stone',
      {
        name: c('milestone'),
        getFilter: getMilestoneFilter,
        field: 'mile_stone.record_id||mile_stone.name',
      },
      ['record_id', 'name'],
    ),
    multiFilterOptionsColumnEditable(
      'key_point',
      {
        name: c('key_point'),
        getFilter: getKeypointFilter,
        field: 'key_point.record_id||key_point.name',
        disabled,
      },
      ['record_id', 'name'],
      () => getProjectKeypoints(projectId),
      (key, value) => onFieldChange('key_point_id', key, value),
      disabled,
    ),
    multiFilterOptionsColumnEditable(
      'tag',
      { name: c('type'), getFilter: getTagFilter, field: 'tag.name' },
      ['name'],
      () => getProjectTags(projectId),
      (key, value) => onFieldChange('tag_id', key, value),
      disabled,
    ),
    multiFilterOptionsColumnEditable(
      'improvement',
      {
        name: c('improvement'),
        getFilter: getImprovementFilter,
        field: 'improvement.title',
      },
      ['title'],
      () =>
        getFilteredProjectImprovementsWithPagination(projectId).then(
          (res) => res.items,
        ),
      (key, value) => onFieldChange('improvement_id', key, value),
      disabled,
    ),
    multiFilterOptionsColumn(
      'team',
      { name: c('team'), getFilter: getTeamFilter, field: 'team.name' },
      ['name'],
    ),
    statusColumn(
      'status',
      ['status', 'endTime', 'expired_children', 'open_children'],
      { name: c('status') },
      statusTypes(t),
      !disabled ? onStatusSelect : undefined,
    ),
    userColumn('user', {
      name: c('reporter'),
      getFilter: getReporterFilter,
      field: 'reporter.firstName||reporter.lastName',
    }),
    ResponsibleAndDisciplineColumn(
      projectId,
      projectUsers,
      projectDiscipline,
      updateResponsible,
      disabled,
    ),
    DisciplineAndResponsibleColumn(
      projectId,
      projectDiscipline,
      projectUsers,
      updateDiscipline,
      disabled,
    ),
    MainProcessAndThemeColumn(
      projectId,
      mainProcesses,
      themes,
      updateMainProcess,
      disabled,
    ),
    numberColumn('delay', { name: c('delay_days') }),
    numberColumn('duration', { name: c('duration_days') }),
    taskCountColumn({ name: c('tasks_done_total') }),
  ] as ColumnDef<IDelivery>[]

  const defaultOrdering = [
    'select',
    'record_id',
    'name',
    'status',
    'responsible',
    'endTime',
    'duration',
    'delay',
    'closed_date',
    'open_children',
    'key_point',
    'mile_stone',
    'main_process',
    'team',
    'tag',
    'improvement',
    'discipline',
    'contract',
    'user',
    'baseline',
    'created_at',
    'updated_at',
  ]

  const visibleColumns = [
    'select',
    'record_id',
    'name',
    'status',
    'responsible',
    'endTime',
  ]

  const inspectorPanelColumnVisibility = {} as VisibilityState
  defaultOrdering?.map((field) => {
    inspectorPanelColumnVisibility[field] = visibleColumns.includes(field)
  })

  return { columns, defaultOrdering, inspectorPanelColumnVisibility }
}
