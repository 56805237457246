import 'react-toggle/style.css'
import moment from 'moment'
import { PureComponent } from 'react'
import { SingleDatePicker } from 'react-dates'
import { withTranslation, WithTranslation } from 'react-i18next'
import TextDisplay from 'src/ui-elements/input-group/TextDisplay'
import { IUserContext } from '../../context/UserContextProvider/UserContext'
import { withUserContext } from '../../context/WithUserContext'
import {
  IIwithAlartContext,
  withAlartContext,
} from '../../context/withAlartContext'
import { getContractDisciplines } from '../../service/DisciplineService'
import {
  IContract,
  IDiscipline,
  IProject,
  IRole,
  IUserData,
} from '../../service/OrgTypes'
import { getAllProjects } from '../../service/ProjectService'
import { createUser, deleteUser } from '../../service/TheWallService'
import {
  createNewUser,
  createProjectUser,
  getAllUsers,
  getUserAssignData,
  giveProjectAccess,
} from '../../service/UserService'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import ModalFooter from '../../ui-elements/modal/ModalFooter'
import TypeHead from '../../ui-elements/type-head/TypeHead'
import { renderDayContents } from '../../utility/Utility'
import {
  capFirstLetter,
  classNames,
  generateRandomString,
  validation,
} from '../../utility/utils'
import Selector from '../selectors/Selector'
import CheckBox from '../switchHoc/CheckBox'

interface IAddUserFormProps
  extends IUserContext,
    IIwithAlartContext,
    WithTranslation {
  user?: any
  contractAccess?: any
  projectAccess?: any
  isEditMode: boolean
  projectId: number
  organizationId: number
  organizationLevel: boolean
  closeModal: () => void
  t: any
}

interface IAddUserFormState {
  user_id: number
  firstName: string
  lastName: string
  email: string
  phone: string
  isSystemAdmin: boolean
  isProjectAdmin: boolean
  isProjectRiskAdmin: boolean
  isQualityAdmin: boolean
  meetingModuleAccess: boolean
  statusManager: boolean
  isConstructionManager: boolean
  isContractAdmin: boolean
  accessExpire: moment.Moment | null
  roles: IRole[]
  contracts: IContract[]
  disciplines: IDiscipline[]
  users: IUserData[]
  projects: IProject[]
  contract_id: number
  discipline_id: number
  project_id: number
  role_id: number
  firstNameErrorMessage: string
  lastNameErrorMessage: string
  emailErrorMessage: string
  phoneErrorMessage: string
  roleErrorMessage: string
  contractErrorMessage: string
  disciplineErrorMessage: string
  loading: boolean
  existingUser: boolean
  accessExpirePickerFocused: boolean
  createMultiple: boolean
  firstPage: boolean
}

class AddUserForm extends PureComponent<IAddUserFormProps, IAddUserFormState> {
  private static styleClass = {
    root: classNames('flex', 'flex-row', 'flex-wrap'),
    wrapper: classNames('flex-1'),
    column: classNames('w-full', 'lg:w-1/2', 'flex', 'flex-col', 'gap-4'),
    label: classNames(
      'block',
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'my-2',
    ),
  }

  public constructor(props: IAddUserFormProps) {
    super(props)
    this.state = {
      user_id: props.isEditMode && props.user ? props.user.id : 0,
      firstName: props.isEditMode && props.user ? props.user.firstName : '',
      lastName: props.isEditMode && props.user ? props.user.lastName : '',
      email: props.isEditMode && props.user ? props.user.email : '',
      phone: props.isEditMode && props.user ? props.user.telephoneNumber : '',
      isContractAdmin:
        props.isEditMode && props.contractAccess
          ? props.contractAccess.isContractAdmin
          : false,
      statusManager:
        props.isEditMode && props.projectAccess
          ? props.projectAccess.status_manager
          : false,
      isConstructionManager:
        props.isEditMode && props.projectAccess
          ? props.projectAccess.isConstructionManager
          : false,
      isProjectAdmin:
        props.isEditMode && props.projectAccess
          ? props.projectAccess.isProjectAdmin
          : false,
      isProjectRiskAdmin:
        props.isEditMode && props.projectAccess
          ? props.projectAccess.isProjectRiskAdmin
          : false,
      isQualityAdmin:
        props.isEditMode && props.projectAccess
          ? props.projectAccess.isQualityAdmin
          : false,
      meetingModuleAccess:
        props.isEditMode && props.projectAccess
          ? props.projectAccess.meetingModuleAccess
          : false,
      isSystemAdmin:
        props.isEditMode && props.user ? props.user.isSystemAdmin : false,
      accessExpire:
        props.isEditMode && props.contractAccess
          ? props.contractAccess.accessExpire
            ? moment(props.contractAccess.accessExpire)
            : null
          : null,
      contract_id: props.contractAccess ? props.contractAccess.contract_id : 0,
      discipline_id: props.contractAccess
        ? props.contractAccess.discipline_id
        : 0,
      role_id:
        props.isEditMode && props.contractAccess
          ? props.contractAccess.role_id
          : 0,
      project_id:
        props.isEditMode && props.projectAccess
          ? props.projectAccess.project_id
          : 0,
      roles: [],
      contracts: [],
      disciplines: [],
      users: [],
      projects: [],
      firstNameErrorMessage: '',
      lastNameErrorMessage: '',
      emailErrorMessage: '',
      phoneErrorMessage: '',
      roleErrorMessage: '',
      contractErrorMessage: '',
      disciplineErrorMessage: '',
      loading: false,
      existingUser: props.isEditMode,
      accessExpirePickerFocused: false,
      createMultiple: false,
      firstPage: true,
    }
  }

  public reload() {
    this.setState((_prevState) => ({
      loading: true,
    }))

    if (this.props.organizationLevel) {
      getAllProjects(this.props.organizationId)
        .then((res) => {
          this.setState({
            projects: res,
          })
        })
        .catch((err) => console.error(err))

      this.loadUserAssignData(this.props.projectId)
    } else {
      this.loadUserAssignData(this.props.projectId)
    }
  }

  public componentDidMount() {
    this.reload()
  }

  public loadUserAssignData(projectId: number) {
    if (!this.props.isEditMode) {
      getAllUsers()
        .then((res) => {
          this.setState({
            users: res,
          })
        })
        .catch((err) => console.error(err))
    }

    getUserAssignData(projectId)
      .then((res) => {
        this.setState((_prevState) => ({
          roles: res.roles,
          contracts: res.contracts,
          disciplines: res.disciplines,
          loading: false,
        }))
      })
      .catch((err) => console.error(err))
  }

  private onSingleCreate = () => {
    this.setState({
      createMultiple: false,
    })
  }

  private onMultipleCreate = () => {
    this.setState({
      createMultiple: true,
    })
  }

  private onSubmit = (e: any) => {
    e.preventDefault()
    const { t } = this.props
    let error = false
    this.setState({
      firstNameErrorMessage: '',
      lastNameErrorMessage: '',
      emailErrorMessage: '',
      phoneErrorMessage: '',
      roleErrorMessage: '',
      contractErrorMessage: '',
      disciplineErrorMessage: '',
      loading: true,
    })
    // User validation
    if (!this.state.firstName) {
      this.setState({
        firstNameErrorMessage: t('fill_in_first_name'),
      })
      error = true
    }
    if (!this.state.lastName) {
      this.setState({
        lastNameErrorMessage: t('fill_in_last_name'),
      })
      error = true
    }
    if (!this.state.email) {
      this.setState({
        emailErrorMessage: t('fill_in_the_email'),
      })
      error = true
    }
    if (this.state.email && !validation.isEmailAddress(this.state.email)) {
      this.setState({
        emailErrorMessage: t('is_not_an_email_address', {
          val: this.state.email,
        }),
      })
      error = true
    }
    if (!this.state.phone) {
      this.setState({
        phoneErrorMessage: t('fill_in_the_phone'),
      })
      error = true
    }

    if (!this.props.organizationLevel) {
      if (!this.state.role_id) {
        this.setState({
          roleErrorMessage: t('select_role'),
        })
        error = true
      }

      if (!this.state.contract_id) {
        this.setState({
          contractErrorMessage: t('select_contract'),
        })
        error = true
      }

      if (!this.state.discipline_id) {
        this.setState({
          disciplineErrorMessage: t('select_discipline'),
        })
        error = true
      }
    }
    if (!error) {
      if (this.state.existingUser) {
        giveProjectAccess(
          {
            user_id: this.state.user_id,
            contract_id: this.state.contract_id,
            discipline_id: this.state.discipline_id,
            role_id: this.state.role_id,
            isContractAdmin: this.state.isContractAdmin,
            isProjectAdmin: this.state.isProjectAdmin,
            isProjectRiskAdmin: this.state.isProjectRiskAdmin,
            isQualityAdmin: this.state.isQualityAdmin,
            meetingModuleAccess: this.state.meetingModuleAccess,
            isSystemAdmin: this.state.isSystemAdmin,
            status_manager: this.state.statusManager,
            isConstructionManager: this.state.isConstructionManager,
            project_mapper_id: this.props.projectAccess
              ? this.props.projectAccess.id
              : undefined,
            contract_mapper_id: this.props.contractAccess
              ? this.props.contractAccess.id
              : undefined,
            accessExpire: this.state.accessExpire
              ? this.state.accessExpire
              : undefined,
          },
          this.props.projectId,
        )
          .then(() => {
            if (this.state.createMultiple) {
              this.setState({
                firstName: '',
                lastName: '',
                email: '',
                phone: '',
                loading: false,
                existingUser: false,
              })
            } else {
              this.props.closeModal()
            }
          })
          .catch(() => {
            this.setState({
              loading: false,
            })
          })
      } else {
        createUser({
          email: this.state.email,
          password: generateRandomString(),
        })
          .then((res) => {
            if (!this.props.organizationLevel) {
              const projectId = this.props.organizationLevel
                ? this.state.project_id
                : this.props.projectId
              createProjectUser(
                {
                  id: 0,
                  firstName: this.state.firstName,
                  lastName: this.state.lastName,
                  email: this.state.email,
                  telephoneNumber: this.state.phone,
                  key: res.body,
                  project_id: projectId,
                  contract_id: this.state.contract_id,
                  discipline_id: this.state.discipline_id,
                  role_id: this.state.role_id,
                  isContractAdmin: this.state.isContractAdmin,
                  isProjectAdmin: this.state.isProjectAdmin,
                  isProjectRiskAdmin: this.state.isProjectRiskAdmin,
                  isQualityAdmin: this.state.isQualityAdmin,
                  meetingModuleAccess: this.state.meetingModuleAccess,
                  isSystemAdmin: false,
                  accessExpire: this.state.accessExpire
                    ? this.state.accessExpire
                    : undefined,
                },
                projectId,
              )
                .then(() => {
                  if (this.state.createMultiple) {
                    this.setState({
                      firstName: '',
                      lastName: '',
                      email: '',
                      phone: '',
                      loading: false,
                    })
                  } else {
                    this.props.closeModal()
                  }
                })
                .catch(() => {
                  this.revertUser(res.body)
                })
            } else {
              createNewUser({
                id: 0,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                telephoneNumber: this.state.phone,
                key: res.body,
                isContractAdmin: false,
                isProjectAdmin: false,
                isProjectRiskAdmin: false,
                isQualityAdmin: false,
                meetingModuleAccess: false,
                isSystemAdmin: this.state.isSystemAdmin,
              })
                .then(() => {
                  if (this.state.createMultiple) {
                    this.setState({
                      firstName: '',
                      lastName: '',
                      email: '',
                      phone: '',
                      loading: false,
                    })
                  } else {
                    this.props.closeModal()
                  }
                })
                .catch(() => {
                  this.revertUser(res.body)
                })
            }
          })
          .catch((err) => {
            const errorMessages =
              err && err.body && err.body.message ? err.body.message : {}
            const errorKeys: string[] = errorMessages.error_keys
              ? errorMessages.error_keys
              : []
            if (errorKeys.indexOf('email_has_already_been_taken') > -1) {
              this.setState({
                emailErrorMessage: t('the_email_is_already_in_use'),
              })
            } else {
              this.showErrorAlert(
                'Fail',
                errorMessages.text ? errorMessages.text : '',
              )
            }
            this.setState({
              loading: false,
            })
          })
      }
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  private revertUser = (body: any) => {
    deleteUser(body)
      .then(() => {
        this.setState({
          loading: false,
        })
      })
      .catch(() => {
        this.setState({
          loading: false,
        })
      })
  }

  private onEndDateChange = (date: moment.Moment) => {
    this.setState({
      accessExpire: date,
    })
  }

  private setEndDatepickerFocus = (focused: any) => {
    this.setState({
      accessExpirePickerFocused: focused.focused,
    })
  }

  private onFirstNameChange = (e: any) => {
    const firstNameVal = e.target.value

    this.setState({
      firstName: firstNameVal,
      firstNameErrorMessage: '',
    })
  }

  private onLastnameChange = (e: any) => {
    const lastNameVal = e.target.value

    this.setState({
      lastName: lastNameVal,
      lastNameErrorMessage: '',
    })
  }

  private onEmailChange = (e: any) => {
    const emailVal = e.target.value

    this.setState({
      email: emailVal,
      emailErrorMessage: '',
    })
  }

  private onPhoneChange = (e: any) => {
    const phoneVal = e.target.value

    this.setState({
      phone: phoneVal,
      phoneErrorMessage: '',
    })
  }

  private changeProject = (value: number) => {
    this.loadUserAssignData(value)
    this.setState({
      project_id: value,
    })
  }

  private changeContract = (value: number) => {
    getContractDisciplines(value)
      .then((res) => {
        this.setState({
          disciplines: res,
        })
      })
      .catch((err) => console.error(err))
    this.setState({
      contract_id: value,
      contractErrorMessage: '',
    })
  }

  private changeDiscipline = (value: number) => {
    this.setState({
      discipline_id: value,
      disciplineErrorMessage: '',
    })
  }

  private onRoleChange = (value: number) => {
    this.setState({
      role_id: value,
      roleErrorMessage: '',
    })
  }

  private onContractAdminChange = () => {
    this.setState((prevState) => ({
      isContractAdmin: !prevState.isContractAdmin,
    }))
  }

  private onProjectAdminChange = () => {
    this.setState((prevState) => ({
      isProjectAdmin: !prevState.isProjectAdmin,
    }))
  }

  private onProjectRiskAdminChange = () => {
    this.setState((prevState) => ({
      isProjectRiskAdmin: !prevState.isProjectRiskAdmin,
    }))
  }

  private onProjectQualityAdminChange = () => {
    this.setState((prevState) => ({
      isQualityAdmin: !prevState.isQualityAdmin,
    }))
  }

  private onMeetingModuleAccessChange = () => {
    this.setState((prevState) => ({
      meetingModuleAccess: !prevState.meetingModuleAccess,
    }))
  }

  private onStatusManagerChange = () => {
    this.setState((prevState) => ({
      statusManager: !prevState.statusManager,
    }))
  }

  private onConstructionManagerChange = () => {
    this.setState((prevState) => ({
      isConstructionManager: !prevState.isConstructionManager,
    }))
  }

  private onSystemAdminChange = () => {
    this.setState((prevState) => ({
      isSystemAdmin: !prevState.isSystemAdmin,
    }))
  }

  private outSideRangeDate = () => {
    return false
  }

  private onSelectItem = (user: IUserData) => {
    if (this.state.createMultiple) {
      this.setState({
        user_id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.telephoneNumber,
        isSystemAdmin: user.isSystemAdmin,
        existingUser: true,
      })
    } else {
      this.setState({
        user_id: user.id,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phone: user.telephoneNumber,
        isSystemAdmin: user.isSystemAdmin,
        isContractAdmin: false,
        isProjectAdmin: false,
        isProjectRiskAdmin: false,
        isQualityAdmin: false,
        meetingModuleAccess: false,
        contract_id: 0,
        discipline_id: 0,
        role_id: 0,
        existingUser: true,
      })
    }
  }

  private showErrorAlert = (title: string, description: string) => {
    const { addAlert } = this.props.alartContext.actions
    addAlert({ type: 'error', title, description })
  }

  public render() {
    const { t } = this.props
    const {
      firstName,
      lastName,
      email,
      phone,
      project_id,
      isContractAdmin,
      isProjectAdmin,
      isProjectRiskAdmin,
      isQualityAdmin,
      meetingModuleAccess,
      statusManager,
      isSystemAdmin,
      accessExpire,
      accessExpirePickerFocused,
      contract_id,
      discipline_id,
      role_id,
      projects,
      users,
      roles,
      contracts,
      disciplines,
      firstNameErrorMessage,
      lastNameErrorMessage,
      emailErrorMessage,
      phoneErrorMessage,
      roleErrorMessage,
      contractErrorMessage,
      disciplineErrorMessage,
      loading,
      existingUser,
      createMultiple,
      isConstructionManager,
    } = this.state

    return (
      <form onSubmit={this.onSubmit}>
        <div className={AddUserForm.styleClass.root}>
          {!this.props.organizationLevel && !this.props.isEditMode && (
            <div className={'w-full flex flex-col px-2 mt-2'}>
              <label className={`${AddUserForm.styleClass.label}`}>
                {t('existing_user')}
              </label>
              <TypeHead
                data={users}
                onSelect={this.onSelectItem}
                isLoading={loading}
                placeholder={t('search_for_user')}
                autoFocus
                userSelector
                dataFields={['firstName', 'lastName']}
              />
              <div
                className={
                  'w-full text-gray-700 my-2 text-sm flex justify-center'
                }
              >
                {t('or')}:
              </div>
            </div>
          )}
          <div className={AddUserForm.styleClass.column}>
            <Input
              label={t('first_name')}
              block={true}
              value={firstName}
              disabled={existingUser}
              onChange={this.onFirstNameChange}
              errorMessage={firstNameErrorMessage}
              required={true}
              focus={createMultiple}
            />
            <Input
              label={t('surname')}
              block={true}
              value={lastName}
              disabled={existingUser}
              errorMessage={lastNameErrorMessage}
              required={true}
              onChange={this.onLastnameChange}
            />
            <Input
              label={t('email')}
              block={true}
              value={email}
              disabled={existingUser}
              errorMessage={emailErrorMessage}
              required={true}
              onChange={this.onEmailChange}
            />
            <Input
              label={t('telephone')}
              block={true}
              value={phone}
              disabled={existingUser}
              errorMessage={phoneErrorMessage}
              required={true}
              onChange={this.onPhoneChange}
            />
            <div>
              {contract_id !== 0 && (
                <div className={'w-full px-2'}>
                  <TextDisplay
                    noPaddingY={true}
                    noBorder={true}
                    labelIsWide={true}
                    noPaddingX={true}
                    renderChildern={true}
                    label={t('contract_administrator')}
                  >
                    <div className={'w-full flex justify-end'}>
                      <div
                        className={
                          'pl-2 h-full inline-flex flex-row justify-between items-center flex-nowrap'
                        }
                      >
                        <CheckBox
                          valueProp={isContractAdmin}
                          onChange={this.onContractAdminChange}
                        />
                      </div>
                    </div>
                  </TextDisplay>
                </div>
              )}

              {this.props.state.user.isSystemAdmin && (
                <div className={'w-full px-2'}>
                  <TextDisplay
                    noPaddingY={true}
                    noBorder={true}
                    labelIsWide={true}
                    noPaddingX={true}
                    renderChildern={true}
                    label={t('system_adminstrator')}
                  >
                    <div className={'w-full flex justify-end'}>
                      <div
                        className={
                          'pl-2 h-full inline-flex flex-row justify-between items-center flex-nowrap'
                        }
                      >
                        <CheckBox
                          valueProp={isSystemAdmin}
                          onChange={this.onSystemAdminChange}
                        />
                      </div>
                    </div>
                  </TextDisplay>
                </div>
              )}

              <div className={'w-full px-2'}>
                <TextDisplay
                  noPaddingY={true}
                  noBorder={true}
                  labelIsWide={true}
                  noPaddingX={true}
                  renderChildern={true}
                  label={t('project_administrator')}
                >
                  <div className={'w-full flex justify-end'}>
                    <div
                      className={
                        'pl-2 h-full inline-flex flex-row justify-between items-center flex-nowrap'
                      }
                    >
                      <CheckBox
                        valueProp={isProjectAdmin}
                        onChange={this.onProjectAdminChange}
                      />
                    </div>
                  </div>
                </TextDisplay>
              </div>
            </div>
          </div>
          <div className={AddUserForm.styleClass.column}>
            <div className={'w-full flex flex-col'}>
              <label className={`${AddUserForm.styleClass.label} pl-2`}>
                {t('end_date')}
              </label>
              <div style={{ zIndex: 500 }} className={'pl-2 w-full pr-2'}>
                <SingleDatePicker
                  firstDayOfWeek={1}
                  date={accessExpire}
                  onDateChange={this.onEndDateChange}
                  renderDayContents={renderDayContents}
                  focused={accessExpirePickerFocused}
                  onFocusChange={this.setEndDatepickerFocus}
                  placeholder={t('end_date')}
                  id="sluttdato"
                  small={true}
                  isOutsideRange={this.outSideRangeDate}
                  showDefaultInputIcon={true}
                  noBorder={true}
                  numberOfMonths={1}
                  displayFormat={() =>
                    moment.localeData('no').postformat('DD.MM.YY')
                  }
                  hideKeyboardShortcutsPanel={true}
                  showClearDate={true}
                />
              </div>
            </div>

            {this.props.organizationLevel ? (
              <Selector
                items={projects}
                selectedItemId={project_id}
                onSelect={this.changeProject}
                label={t('project')}
                dataFields={['projectNumber', 'projectName']}
                required={true}
                fontSize={'sm'}
                fontWeight={'bold'}
              />
            ) : (
              <>
                <Selector
                  items={contracts}
                  selectedItemId={contract_id}
                  onSelect={this.changeContract}
                  label={t('contract')}
                  dataFields={['contractNumber', 'contractName']}
                  required={true}
                  fontSize={'sm'}
                  fontWeight={'bold'}
                  errorMessage={contractErrorMessage}
                />
                {contract_id !== 0 && (
                  <Selector
                    items={disciplines}
                    selectedItemId={discipline_id}
                    onSelect={this.changeDiscipline}
                    label={t('discipline')}
                    dataFields={['shortName', 'name']}
                    required={true}
                    fontSize={'sm'}
                    fontWeight={'bold'}
                    errorMessage={disciplineErrorMessage}
                  />
                )}

                {discipline_id !== 0 && (
                  <Selector
                    items={roles}
                    selectedItemId={role_id}
                    onSelect={this.onRoleChange}
                    label={t('role')}
                    dataFields={['roleName']}
                    required={true}
                    fontSize={'sm'}
                    fontWeight={'bold'}
                    errorMessage={roleErrorMessage}
                  />
                )}
              </>
            )}

            <div className="mt-auto">
              <div className={'w-full px-2'}>
                <TextDisplay
                  noPaddingY={true}
                  noBorder={true}
                  labelIsWide={true}
                  noPaddingX={true}
                  renderChildern={true}
                  label={t('access_to_project_risk')}
                >
                  <div className={'w-full flex justify-end'}>
                    <div
                      className={
                        'pl-2 h-full inline-flex flex-row justify-between items-center flex-nowrap'
                      }
                    >
                      <CheckBox
                        valueProp={isProjectRiskAdmin}
                        onChange={this.onProjectRiskAdminChange}
                      />
                    </div>
                  </div>
                </TextDisplay>
              </div>

              <div className={'w-full px-2'}>
                <TextDisplay
                  noPaddingY={true}
                  noBorder={true}
                  labelIsWide={true}
                  noPaddingX={true}
                  renderChildern={true}
                  label={t('access_to_suggestions_for_improvement')}
                >
                  <div className={'w-full flex justify-end'}>
                    <div
                      className={
                        'pl-2 h-full inline-flex flex-row justify-between items-center flex-nowrap'
                      }
                    >
                      <CheckBox
                        valueProp={isQualityAdmin}
                        onChange={this.onProjectQualityAdminChange}
                      />
                    </div>
                  </div>
                </TextDisplay>
              </div>

              <div className={'w-full px-2'}>
                <TextDisplay
                  noPaddingY={true}
                  noBorder={true}
                  labelIsWide={true}
                  noPaddingX={true}
                  renderChildern={true}
                  label={t('access_to_meeting_module')}
                >
                  <div className={'w-full flex justify-end'}>
                    <div
                      className={
                        'pl-2 h-full inline-flex flex-row justify-between items-center flex-nowrap'
                      }
                    >
                      <CheckBox
                        valueProp={meetingModuleAccess}
                        onChange={this.onMeetingModuleAccessChange}
                      />
                    </div>
                  </div>
                </TextDisplay>
              </div>

              <div className={'w-full px-2'}>
                <TextDisplay
                  noPaddingY={true}
                  noBorder={true}
                  noPaddingX={true}
                  renderChildern={true}
                  label={t('status_manager')}
                >
                  <div className={'w-full flex justify-end'}>
                    <div
                      className={
                        'pl-2 h-full inline-flex flex-row justify-between items-center flex-no-wrap'
                      }
                    >
                      <CheckBox
                        valueProp={statusManager}
                        onChange={this.onStatusManagerChange}
                      />
                    </div>
                  </div>
                </TextDisplay>
              </div>

              <div className={'w-full px-2'}>
                <TextDisplay
                  noPaddingY={true}
                  noBorder={true}
                  labelIsWide={true}
                  noPaddingX={true}
                  renderChildern={true}
                  label={t('construction_manager')}
                >
                  <div className={'w-full flex justify-end'}>
                    <div
                      className={
                        'pl-2 h-full inline-flex flex-row justify-between items-center flex-no-wrap'
                      }
                    >
                      <CheckBox
                        valueProp={isConstructionManager}
                        onChange={this.onConstructionManagerChange}
                      />
                    </div>
                  </div>
                </TextDisplay>
              </div>
            </div>
          </div>
        </div>

        <ModalFooter>
          <Button
            type={Button.ButtonType.DEFAULT}
            onClick={this.props.closeModal}
          >
            {capFirstLetter(t('cancel'))}
          </Button>
          {this.props.isEditMode ? (
            <Button
              type={Button.ButtonType.PRIMARY}
              onClick={this.onSingleCreate}
              disabled={loading}
            >
              {capFirstLetter(t('update'))}
            </Button>
          ) : (
            <>
              <Button
                type={Button.ButtonType.SECONDARY}
                onClick={this.onMultipleCreate}
                disabled={loading}
              >
                {capFirstLetter(t('add_multiple'))}
              </Button>
              <Button
                type={Button.ButtonType.PRIMARY}
                onClick={this.onSingleCreate}
                disabled={loading}
              >
                {capFirstLetter(t('add'))}
              </Button>
            </>
          )}
        </ModalFooter>
      </form>
    )
  }
}

export default withTranslation()(withAlartContext(withUserContext(AddUserForm)))
