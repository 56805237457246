import { ColumnDef, createColumnHelper } from '@tanstack/react-table'
import { t } from 'i18next'
import { capitalize, round } from 'lodash'
import moment from 'moment/moment'
import React from 'react'
import { twMerge } from 'tailwind-merge'
import { Changeset, IDraft } from 'src/service/OrgTypes'
import {
  CustomMeta,
  dateColumn,
  enumColumn,
  styleClass,
  textColumn,
  userColumn,
} from 'src/ui-elements/Table/Columns'
import TableColumn from 'src/ui-elements/Table/TableColumn'
import useListHelper from 'src/ui-elements/list/UseListHelper'

const useDraftChangesTableColumns = () => {
  const formatChangeset = (
    changeset: Changeset,
  ): { textAreaText: string; length: number } => {
    const attributes = Object.entries(changeset).map(([attr, val]) => ({
      attribute: attr,
      before: val[0],
      after: val[1],
    }))

    let textAreaText = ''
    let length = 0

    attributes.forEach(({ attribute, before, after }) => {
      const isBeforeNumber = typeof before === 'number'
      const isAfterNumber = typeof after === 'number'

      const beforeText = isBeforeNumber
        ? round(before, 2)
        : moment(before).isValid()
          ? moment(before).format('L')
          : before

      const afterText = isAfterNumber
        ? round(after, 2)
        : moment(after).isValid()
          ? moment(after).format('L')
          : after

      const valuesNotEqual = afterText !== beforeText
      if (valuesNotEqual) {
        length += 1
        textAreaText += `${capitalize(t(attribute))}: ${beforeText} --> ${afterText} \n`
      }
    })

    length = length === 0 ? 1 : length
    return { textAreaText, length }
  }

  type ColumnTypeChangeset = {
    changeset: Changeset
  }

  const colHelperChangeset = createColumnHelper<ColumnTypeChangeset>()

  const changeSetColumn = (
    field: keyof ColumnTypeChangeset,
    meta: CustomMeta,
  ) =>
    colHelperChangeset.accessor(field, {
      id: field,
      size: 250,
      enableSorting: false,
      enableColumnFilter: false,
      cell: ({ cell, row }) => {
        const isUpdateEvent = row.original['event'] === 'update'
        const { textAreaText, length } = formatChangeset(cell.getValue())

        return (
          <textarea
            className={twMerge(
              styleClass.cell,
              'h-full w-full overflow-auto bg-transparent align-text-top first-capitalize',
            )}
            value={isUpdateEvent ? textAreaText : ''}
            rows={isUpdateEvent ? length : 1}
            onChange={() => {}}
          ></textarea>
        )
      },
      header: (props) => (
        <TableColumn column={props.column} table={props.table} />
      ),
      meta: {
        field: field,
        ...meta,
      },
    })

  const displayItemType = (type?: string): string => {
    switch (type) {
      case 'ConstructionLocomotive':
        return 'wagon'
      case 'ConstructionTrain':
        return 'train'
      case 'ConstructionTask':
        return 'activity'
      default:
        return ''
    }
  }

  const displayEventType = (type?: string): string => {
    switch (type) {
      case 'create':
        return 'created_draft'
      default:
        return 'updated_draft'
    }
  }

  const itemTypes = [
    {
      id: 'ConstructionTrain',
      name: t('train'),
    },
    {
      id: 'ConstructionTask',
      name: t('activity'),
    },
    {
      id: 'ConstructionLocomotive',
      name: t('wagon'),
    },
  ]

  const eventTypes = [
    {
      id: 'update',
      name: t('updated_draft'),
    },
    {
      id: 'create',
      name: t('created_draft'),
    },
  ]

  const { getReporterFilter } = useListHelper()

  return [
    enumColumn(
      'item_type',
      {
        name: t('type'),
      },
      itemTypes,
      displayItemType,
    ),
    enumColumn(
      'event',
      {
        name: t('event'),
      },
      eventTypes,
      displayEventType,
    ),
    textColumn('title', { name: t('title') }),
    textColumn('record_id', { name: t('record_id') }),
    textColumn('control_area_title', { name: t('control_area') }),
    userColumn('whodunnit', {
      name: t('responsible'),
      getFilter: getReporterFilter,
    }),
    dateColumn('created_at', { name: t('created_at') }, false, true),
    changeSetColumn('changeset', { name: t('changes') }),
  ] as ColumnDef<IDraft>[]
}

export default useDraftChangesTableColumns
