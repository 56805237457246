import Cancel from '@icons/cancel.svg'
import Check from '@icons/check.svg'
import HorizontalRule from '@icons/horizontal_rule.svg'
import { useIsFetching, useQueryClient } from '@tanstack/react-query'
import moment from 'moment/moment'

import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import UploadProvider from 'src/document/components/FileUpload/context/FileUploadContext'
import { IDocumentHistory } from 'src/document/types/IFileContainer'
import { IFdvuFileContainer } from 'src/fdvu/types/IFdvuFileContainer'
import { useFileContainerHistorySubContractor } from 'src/query/documents/fileContainerHistory'
import { useCurrentSubcontractor } from 'src/query/fdvu/subcontractor'
import FileList from 'src/subcontractor/components/requirements/FileList'
import FileUpload from 'src/subcontractor/components/requirements/FileUpload'
import FdvuSubcontractorService from 'src/subcontractor/service/SubcontractorService'
import { getAccessToken } from 'src/subcontractor/utils/AccessToken'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Spinner from 'src/ui-elements/loader/Spinner'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { StatusLEnum } from 'src/utility/statusEnums'

interface IFileSubmissionPanel {
  selectedFileContainer: IFdvuFileContainer
  hideInReview: boolean
}

const FileSubmissionPanel = ({
  selectedFileContainer,
  hideInReview,
}: IFileSubmissionPanel) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { data: currentSubcontractor } = useCurrentSubcontractor()

  const refresh = () => {
    queryClient.invalidateQueries({
      queryKey: ['fdvu', 'subcontractor', 'fileContainers', hideInReview],
    })
  }

  const isFetching = useIsFetching({
    queryKey: ['fdvu', 'subcontractor', 'fileContainers', hideInReview],
  })

  const { data: history, refetch: historyRefetch } =
    useFileContainerHistorySubContractor(selectedFileContainer.id, {
      enabled: !!currentSubcontractor,
    })

  const withOutFlow = selectedFileContainer.current_step === undefined
  const canDeliver = selectedFileContainer.current_step?.sequence === 1
  const inReview = (selectedFileContainer.current_step?.sequence ?? -1) > 2

  const deliverDocuments = async () => {
    const status = canDeliver ? StatusLEnum.DELIVERED : StatusLEnum.OPEN
    await FdvuSubcontractorService.updateDocumentStatus(
      selectedFileContainer.id,
      status,
    )
    refresh()
    await historyRefetch()
  }

  const statusIcon = (history: IDocumentHistory) => {
    const archivedStep = history.archived_step
    const step = history?.step
    if (step) {
      return (
        <>
          <HorizontalRule className="text-lg" />
          <p className="font-bold">{t(selectedFileContainer.status)}</p>
        </>
      )
    } else if (archivedStep?.sequence === 1) {
      return (
        <>
          <Check className="text-lg fill-task-blue" />
          <p className="font-bold text-task-blue">{t('delivered')}</p>
        </>
      )
    }
    if (history.closed_by_subcontractor) {
      return (
        <>
          <HorizontalRule className="fill-yellow text-lg" />
          <p className="font-bold text-yellow-500">{t('re_opened')}</p>
        </>
      )
    } else if (
      !history.closed_by_subcontractor &&
      archivedStep?.sequence === 2 &&
      selectedFileContainer.status !== StatusLEnum.REVIEWED
    ) {
      return (
        <>
          <Cancel className="text-xl fill-red" />
          <p className="font-bold text-red">{t('returned')}</p>
        </>
      )
    }
    return (
      <>
        <HorizontalRule className="fill-gray-500 text-lg" />
        <p className="font-bold text-gray-500">{t('in_review')}</p>
      </>
    )
  }

  return (
    <div className="w-1/3">
      <div className="bg-white border rounded-sm pb-3 w-full flex flex-col">
        <div className="flex flex-col bg-grey-light p-1 rounded-t-sm text-sm font-bold">
          {`${selectedFileContainer?.record_id ?? ''} ${selectedFileContainer?.name ?? ''}`}
        </div>
        <InlineComponentsWrapper
          padding="left"
          labelWidth="w-32"
          inputWidth="w-full"
        >
          <TextInlineInputCompontent
            label="covers_requirements"
            value={selectedFileContainer.requirements
              .map((req) => req.requirement_info.title)
              .join(', ')}
            disabled
          />
          <TextInlineInputCompontent
            label="systems"
            value={selectedFileContainer.systems
              .map((system) => system.name)
              .join(', ')}
            disabled
          />
        </InlineComponentsWrapper>
        <div className="z-0">
          <UploadProvider>
            <FileUpload
              multiple
              uploadUrl={`{SUBCONTRACTOR}file_containers/${selectedFileContainer?.id}/project_files`}
              accessToken={getAccessToken()}
              onUploadComplete={refresh}
              disabled={!canDeliver && !withOutFlow}
            />
          </UploadProvider>
        </div>
        {selectedFileContainer && (
          <FileList
            files={selectedFileContainer.project_files}
            refreshFileContainer={refresh}
            disableDeleteFile={!canDeliver && !withOutFlow}
            fileContainerId={selectedFileContainer.id}
          />
        )}
        <Button
          className="mx-4"
          type={canDeliver ? ButtonType.SUCCESS : ButtonType.WARNING}
          onClick={deliverDocuments}
          visible={!withOutFlow}
          disabled={isFetching === 1 || inReview}
        >
          {isFetching ? (
            <Spinner />
          ) : canDeliver ? (
            t('deliver')
          ) : (
            t('re_open_document')
          )}
        </Button>
        {history && history.length > 1 && (
          <div className="my-6 flex flex-col gap-2 justify-center">
            <div className="mx-auto font-bold text-sm">{t('history')}</div>
            <hr />
          </div>
        )}
        <div
          style={{ maxHeight: '50vh' }}
          className="overflow-scroll py-4 gap-2 flex flex-col px-4"
        >
          {history?.map((history, index) => (
            <div
              key={history.id}
              className={twMerge(
                'font-medium border border-gray-100 p-1 rounded text-sm w-full flex items-center gap-4',
                index === 0 && 'rounded-t-sm bg-blue-50',
              )}
            >
              <div className="flex gap-2">{statusIcon(history)}</div>
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <p>{moment(history.created_at).format('L')}</p>
                  {!history.closed_by_subcontractor ? (
                    <p>{`${history.closed_by?.firstName ?? ''} ${history.closed_by?.lastName ?? ''}`}</p>
                  ) : (
                    <p>{history.subcontractor?.name ?? ''}</p>
                  )}
                </div>
                {!history.closed_by_subcontractor && (
                  <div className="text-xs italic min-h">
                    <p>{history.reason}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default FileSubmissionPanel
