import * as React from 'react'
import { useContext, useRef, useState } from 'react'
import CheckBox from 'src/components/switchHoc/CheckBox'
import { classNames } from 'src/utility/utils'
import { ExpandableTableContext } from '../../context/ExpandableTableContext'
import {
  useExpandableTableInteractionStore,
  useExpandableTableInteractionStoreState,
} from '../../hooks/useExpandableTableInteractionStore'
import {
  useExpandableTableStore,
  useExpandableTableStoreState,
} from '../../hooks/useExpandableTableStore'
import { ICellUpdate } from '../../types/ICellUpdate'
import { IExpandableSection } from '../../types/IExpandableSection'
import ExpandableCell from '../expandable-cell/ExpandableCell'

export interface ICell {
  rowId: number
  getRowIndex: () => number
  colIndex: number
  section: IExpandableSection
  onRowSelect?: () => void
  initialItem?: Record<string, unknown>
}

const Cell: React.FC<ICell> = ({
  rowId,
  colIndex,
  getRowIndex,
  section,
  onRowSelect,
  initialItem,
}) => {
  const ref = useRef<HTMLTableCellElement>(null)
  const childRef = useRef<HTMLDivElement>(null)
  const [isEditingCell, setIsEditingCell] = useState(false)

  const header = useExpandableTableStoreState(
    (state) => state.headers[colIndex],
  )

  const isLastElement = useExpandableTableStoreState((state) => {
    const sectionRows = state.expandableSectionsConfig[section.id].rows
    return sectionRows.indexOf(rowId) === sectionRows.length - 1
  })

  const readonly = useExpandableTableStoreState((state) => state.readonly)
  const isRowSelectable = useExpandableTableStoreState(
    (state) => state.isRowSelectable,
  )
  const isSelected = useExpandableTableStoreState((state) =>
    state.selectedIds.includes(rowId),
  )

  const {
    reorderRowsHandler: { setHoveringRow },
  } = useContext(ExpandableTableContext)

  const getHeaderWidth = useExpandableTableInteractionStoreState(
    (state) => state.getHeaderWidth,
  )
  useExpandableTableInteractionStoreState((state) => state.widthPerCol)

  const interactionStore = useExpandableTableInteractionStore()

  const store = useExpandableTableStore()

  const startEditing = () => {
    if (header.readonly) return
    setIsEditingCell(true)
  }

  const finishEditing = (newValue: ICellUpdate['newValue']) => {
    setIsEditingCell(false)
    ref.current?.focus()
    store.getState().updateCell({ newValue, header, rowId })
  }

  const style = {
    td: classNames(
      isLastElement && 'border-b',
      'border-l border-t last:border-r',
      'text-sm',
    ),
  }

  return (
    <div
      ref={ref}
      onMouseEnter={() => setHoveringRow({ rowId, section })}
      onMouseLeave={() => setHoveringRow(undefined)}
      onMouseDownCapture={(e) => {
        if (readonly) return

        interactionStore.setState({
          selectedRowIndex: getRowIndex(),
          selectedColumn: header.id,
        })

        if (ref.current === document.activeElement) {
          startEditing()
        } else if (!isEditingCell) {
          startEditing()
          ;(e.target as HTMLTableCellElement).focus()
          e.nativeEvent.stopImmediatePropagation()
        }
      }}
      className={style.td}
      style={{ width: getHeaderWidth(header.id) }}
    >
      {isRowSelectable && colIndex === 0 && (
        <div className="z-10  h-full border-gray">
          <CheckBox
            valueProp={isSelected}
            onChange={() => {
              if (onRowSelect) {
                onRowSelect()
              }
            }}
          />
        </div>
      )}
      <ExpandableCell
        isEditing={isEditingCell}
        displayRef={childRef}
        setEditing={startEditing}
        finishEditing={finishEditing}
        placeholder={header.placeholder}
        colIndex={colIndex}
        rowId={rowId}
        getRowIndex={getRowIndex}
        section={section}
        initialItem={initialItem}
      />
    </div>
  )
}

export default Cell
