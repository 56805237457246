import { useQuery, keepPreviousData } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  getContract,
  getFilteredContracts,
  getProjectContracts,
} from 'src/service/ContractService'

export const useContract = (
  contractId: number,
  options?: { enabled?: boolean },
) =>
  useQuery({
    ...options,
    queryKey: ['contract', contractId],
    queryFn: () => getContract(contractId),
  })

export const useProjectContracts = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['contracts', 'project', projectId],
    queryFn: () => getProjectContracts(projectId),
  })
}

export const useContractsFiltered = ({
  sort,
  filters,
  page,
  pageSize,
  enabled = true,
}: {
  sort: unknown
  filters: Omit<Parameters<typeof getFilteredContracts>[3], 'sort'>
  page: number
  pageSize: number
  enabled?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [
      'contractsFiltered',
      { projectId, filters, sort, page, pageSize },
    ],
    queryFn: () =>
      getFilteredContracts(projectId, page, pageSize, {
        ...filters,
        sort,
      }),
    placeholderData: keepPreviousData,
    enabled,
  })
}
