import * as React from 'react'
import { PropsWithChildren } from 'react'
import { twMerge } from 'tailwind-merge'

interface IInlineBorderComponent {
  errorMessage?: string
  isFocusing?: boolean
  cursor?: string
  disabled?: boolean
  fullWidth?: boolean
  showBorder?: boolean
}

const InlineBorderComponent: React.FC<
  PropsWithChildren<IInlineBorderComponent>
> = ({
  errorMessage,
  isFocusing,
  cursor = 'cursor-text',
  disabled,
  children,
  fullWidth = true,
  showBorder,
}) => {
  return (
    <div
      className={twMerge(
        showBorder ? 'border border-gray-300' : 'border-transparent',
        'p-1',
        disabled
          ? 'border cursor-not-allowed'
          : `group ${
              errorMessage?.length
                ? ' border-red'
                : isFocusing
                  ? 'border-gray-300'
                  : 'hover:border-gray-300'
            } border rounded ${cursor} w-max`,
        fullWidth && 'w-full',
      )}
    >
      {children}
    </div>
  )
}

export default InlineBorderComponent
