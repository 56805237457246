import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TestStatusColumn } from 'src/components/TableColumns/Columns'
import { testStatus } from 'src/service/SystemValues'
import { IDiscipline, ITest } from '../../service/OrgTypes'
import { getProjectTests } from '../../service/TestService'
import List from '../../ui-elements/list/List'
import { IListFilter } from '../../ui-elements/list/ListContextProvider'
import {
  filterType,
  IListColumns,
  ISorting,
} from '../../ui-elements/list/ListTypes'
import useListHelper from '../../ui-elements/list/UseListHelper'
import { displineComparator, userComparator } from '../../utility/Utility'

import { constructFilterJson } from '../../utility/utils'

interface ICopyTestCaseProps {
  projectId: number
  onTestClick: (r: any) => void
  testId: number
}

const CopyTestyScriptList = ({
  projectId,
  onTestClick,
  testId,
}: ICopyTestCaseProps) => {
  const { t } = useTranslation()
  const { getTestTypeFilter, getUserFilter, getDisciplineFilter } =
    useListHelper()
  const [tests, setTests] = useState<ITest[]>([])
  const [totalPages, setTotalPages] = useState(0)
  const [selectedRow, setSelectedRow] = useState(0)
  const perPage = 15

  const getFilteredData = (
    currentFilters: IListFilter[],
    currentSorting: ISorting,
    page: number,
  ) => {
    const activeFilters = constructFilterJson(currentFilters)
    activeFilters['sort'] = currentSorting
    activeFilters['has_test_cases'] = true
    activeFilters['not_ids'] = [testId]
    getProjectTests(projectId, page, activeFilters, perPage).then((res) => {
      setTests(res.items)
      setTotalPages(res.pages)
    })
  }

  const testListCols: IListColumns[] = [
    {
      name: 'id',
      size: '100',
      id: 'recordId',
      sortingField: 'record_id',
      dataField: 'record_id',
      filter: [],
      filterType: filterType.TEXT,
    },
    {
      name: 'test_type',
      size: '200',
      id: 'testTypeId',
      sortingField: 'test_type_id',
      dataField: 'test_type',
      filterDataField: 'test_type.name',
      filterDataValue: 'test_type.id',
      filter: [],
      filterType: filterType.DEFAULT,
      getFilter: getTestTypeFilter,
      cell: (testtype: any) => <span>{testtype?.name} </span>,
    },
    {
      name: 'test_name',
      size: '200',
      id: 'name',
      sortingField: 'name',
      dataField: 'name',
      filter: [],
      filterType: filterType.TEXT,
    },
    {
      name: 'responsible',
      size: '175',
      id: 'responsible',
      dataField: 'responsible',
      sortingField: 'responsible',
      filterType: filterType.DEFAULT,
      filterDataField: 'responsible.firstName||responsible.lastName',
      filterDataValue: 'responsible.id',
      filter: [],
      getFilter: getUserFilter,
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? userComparator(a, b, direction) : 0) : 0,
      cell: (responsible: any) => (
        <span>
          {responsible?.firstName} {responsible?.lastName}
        </span>
      ),
    },
    {
      name: 'discipline',
      size: '200',
      id: 'discipline',
      dataField: 'discipline',
      sortingField: 'discipline',
      filterType: filterType.DEFAULT,
      filterDataField: 'discipline.shortName',
      filterDataValue: 'discipline.id',
      filter: [],
      getFilter: getDisciplineFilter,
      comparator: (a: any, b: any, direction: boolean) =>
        a ? (b ? displineComparator(a, b, direction) : 0) : 0,
      cell: (discipline: IDiscipline) => (
        <span>
          {discipline?.shortName} - {discipline?.name}
        </span>
      ),
    },
    TestStatusColumn(false, testStatus(t), () => {}, true),
    {
      name: 'test_procedures_count',
      size: '200',
      id: 'test_cases_count',
      sortingField: 'test_cases_count',
      dataField: 'test_cases_count',
    },
  ]

  return (
    <>
      <List
        columns={testListCols}
        getFilteredData={getFilteredData}
        totalPages={totalPages}
        itemsPerPage={perPage}
        tableName={'copy_test_case_table'}
        data={tests}
        pagination={true}
        disableColumnSelector={true}
        disableConfigStore={true}
        onRowClick={(r) => {
          onTestClick(r)
          setSelectedRow(r.id)
        }}
        sortBackend={true}
        selectedRows={[selectedRow]}
      />
    </>
  )
}

export default CopyTestyScriptList
