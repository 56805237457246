import { IContract } from './OrgTypes'
import { request } from './service-utils'

export const createContract = (
  contract: IContract,
  projectId: number,
): Promise<any> => {
  return request(`projects/${projectId}/contracts`, 'POST', contract)
}

export const editContract = (contract: Partial<IContract>): Promise<any> => {
  return request(`contracts/${contract.id}`, 'PUT', contract)
}

export const deleteContract = (contract: IContract): Promise<any> => {
  return request(`contracts/${contract.id}`, 'DELETE', {}, true)
}

export const getProjectContracts = (
  projectId: number,
): Promise<IContract[]> => {
  return request(`projects/${projectId}/contracts`, 'GET')
}

export const getContractsTree = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/contracts/tree`, 'GET')
}

export const getContract = (id: number): Promise<IContract> => {
  return request(`/contracts/${id}`, 'GET')
}

export const getFilteredContracts = (
  projectId: number,
  page: number,
  perPage: number,
  filter?: Record<string, unknown>,
) => {
  return request(
    `projects/${projectId}/contracts_filtered/pages/${page}/${perPage}`,
    'POST',
    filter,
  )
}

export const getUserProjectContracts = (
  userId: number,
  projectId: number,
): Promise<IContract[]> => {
  return request(`projects/${projectId}/users/${userId}/contracts`, 'GET')
}
