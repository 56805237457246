import Attachment from '@icons/attachment.svg'
import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import { useContext, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import ImprovementPanel from 'src/components/improvement/improvement-panel/ImprovementPanel'
import AttachmentsInspectorSectionTableWithUpload from 'src/components/inspector-section-tables/AttachmentsInspectorSectionTableWithUpload'
import RelatedKeypointsList from 'src/components/key-point/RelatedKeypointsList'
import { useKeypointUpdateValidation } from 'src/components/key-point/useKeypointUpdateValidation'
import MilestoneInspectorPanel from 'src/components/milestones/MilestoneInspectorPanel'
import DeliveryInspectorPanel from 'src/components/process/delivery/DeliveryInspectorPanel'
import DeliveryTable from 'src/components/process/delivery/DeliveryTable'
import KeypointChangeLogModal from 'src/components/process/main-process/KeypointChangeLogModal'
import {
  getMetaDataValues,
  loadMetaValues,
} from 'src/components/system/SystemUtil'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import MetaDataFieldsForPage from 'src/document/components/DocumentCreateModal/MetaDataFieldsForPage'
import InspectorSections, {
  IInspectorSection,
} from 'src/document/components/Inspector/InspectorSections'
import { IMetaValue } from 'src/document/types/IMetaData'
import history from 'src/history'
import useUserAccess from 'src/hooks/useUserAccess'
import { useSystemTypeGroupForDomain } from 'src/query/systemTypeGroups'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { getFilteredProjectImprovementsWithPagination } from 'src/service/ImprovementsService'
import { getProjectMilestones } from 'src/service/MilestoneService'
import {
  IKeypoint,
  UpdateDependency,
  DependencyTypeUpdate,
} from 'src/service/OrgTypes'
import {
  getKeypointDetails,
  getProjectProcesses,
  IModalOrigin,
  removeKeypointDependency,
  updateKeypoint,
  addKeypointDependency,
} from 'src/service/ProcessService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getMainprocessTeams, getProjectTeams } from 'src/service/TeamService'
import {
  getDisciplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import {
  getErrorMessage,
  getKeyPointErrorMessage,
  StructureValidationError,
  ValidationError,
} from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import { Icons } from 'src/ui-elements/icon/Icon'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import { classNames } from 'src/utility/utils'

interface IKeypointInspectorPanel {
  keyPointId: number
  open: boolean
  onClose: () => void
  origin: IModalOrigin
  notFound?: (id: number) => void
  onUpdate?: () => void
  defaultIndex?: number
  updateDependency?: (type: UpdateDependency) => void
}

const styleClass = {
  root: classNames('flex', 'flex-col', 'min-h-full'),
}

const KeypointInspectorPanel: React.FC<IKeypointInspectorPanel> = ({
  keyPointId,
  open,
  onClose,
  origin,
  onUpdate,
  defaultIndex = 0,
  updateDependency,
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const [keypointData, setKeypointData] = useState<IKeypoint>()
  const { getChangesForUpdate } = useInlineDependencyUpdate<IKeypoint>(
    setKeypointData,
    keypointData,
  )
  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id
  const { addAlert } = useAlert()
  const [showImprovementPanel, setShowImprovementPanel] = useState(false)
  const [changeLogModal, setChangeLogModal] = useState(false)
  const [changeLogData, setChangeLogData] = useState<any>('')
  const [showDeliveryModal, setShowDeliveryModal] = useState(false)
  const [selectedDeliveryId, setSelectedDeliveryId] = useState<
    number | undefined
  >(undefined)
  const endDateRef = useRef(moment())
  const [optionalFieldValues, setOptionalFieldValues] = useState<IMetaValue[]>(
    [],
  )
  const [showMilestoneInspector, setShowMilestoneInspector] = useState(false)
  const readonly = !keypointData?.can_edit
  const { hasModuleAdminAccess } = useUserAccess('planning')

  const parentMilestoneId = useMemo(() => {
    if (keypointData?.mile_stone_id) {
      return keypointData.mile_stone_id
    }
    return null
  }, [keypointData])

  const { data: systemTypeGroup } = useSystemTypeGroupForDomain('KeyPoint')
  const { isUpdatingDateInFreeze, validateDate, validateStatus } =
    useKeypointUpdateValidation()

  const loadKeypoint = () => {
    getKeypointDetails(keyPointId, projectId).then((res) => {
      setKeypointData(res)
      if (systemTypeGroup) {
        const metaData = getMetaDataValues(res.meta_data)
        setOptionalFieldValues(
          loadMetaValues(
            keyPointId,
            'KeyPoint',
            systemTypeGroup?.optional_fields,
            metaData,
          ),
        )
      }
      setLoading(false)
    })
  }

  const addDependency = async (ids: number[], ingoing?: boolean) => {
    await addKeypointDependency(keyPointId, {
      dependent_on_key_point: ingoing ? ids : undefined,
      dependent_key_point: !ingoing ? ids : undefined,
    })
    updateDependency?.({
      type: DependencyTypeUpdate.Add,
      source_ids: ingoing ? ids : [keyPointId],
      target_ids: !ingoing ? ids : [keyPointId],
    })
  }

  const removeDependency = async (ids: number[], ingoing?: boolean) => {
    await removeKeypointDependency(keyPointId, {
      dependent_on_key_point: ingoing ? ids : undefined,
      dependent_key_point: !ingoing ? ids : undefined,
    })
    updateDependency?.({
      type: DependencyTypeUpdate.Remove,
      source_ids: ingoing ? ids : [keyPointId],
      target_ids: !ingoing ? ids : [keyPointId],
    })
  }

  const getRows = (): IInspectorSection[] => [
    {
      name: t('details'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: getMainContent(),
      onClick: loadKeypoint,
    },
    {
      name: t('additional_information'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      hidden: optionalFieldValues?.length === 0,
      content: getMetaDataContent(),
      onClick: loadKeypoint,
      overflowVisible: true,
    },
    {
      name: t('incoming_dependencies'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <RelatedKeypointsList
          readonly={readonly}
          origin={origin}
          parentFilter={{ dependent: [keyPointId] }}
          tableName={'incoming-dependencies'}
          addKeypoints={(ids) => addDependency(ids, true)}
          removeKeypoints={(ids) => removeDependency(ids, true)}
        />
      ),
    },
    {
      name: t('outgoing_dependencies'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <RelatedKeypointsList
          readonly={readonly}
          origin={origin}
          parentFilter={{ dependent_on: [keyPointId] }}
          tableName={'outgoing-dependencies'}
          addKeypoints={(ids) => addDependency(ids, false)}
          removeKeypoints={(ids) => removeDependency(ids, false)}
        />
      ),
    },
    {
      name: t('deliveries'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <DeliveryTable
          inInspector
          keypoint={keypointData}
          readOnly={keypointData?.status === 'done' || readonly}
          tableName={'keypointDeliveriesTableInspectorPanel'}
        />
      ),
    },
    {
      name: t('attachments'),
      icon: <Attachment />,
      content: (
        <AttachmentsInspectorSectionTableWithUpload
          parentId={keyPointId}
          parentType="KeyPoint"
          readonly={readonly}
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: <Comments parentId={keyPointId} parentType="KeyPoint" />,
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentId={keyPointId} parentType="KeyPoint" />,
    },
  ]

  const onChangeInput = (update: Partial<IKeypoint>) => {
    if (!keypointData) return
    if (
      !!update.endTime &&
      isUpdatingDateInFreeze(update.endTime, keypointData?.endTime)
    ) {
      setChangeLogModal(true)
      endDateRef.current = moment(update.endTime)
    } else {
      const allUpdates = getChangesForUpdate(update)
      updateKeypoint(keyPointId, {
        ...convertUndefinedToNull(allUpdates),
        id: keypointData?.id,
      }).then(() => {
        onKeypointUpdate()
      })
    }
  }

  const onKeypointUpdate = () => {
    loadKeypoint()
    onUpdate?.()
  }

  const onChangeLogSubmit = () => {
    toggleChangelogModal()
    updateKeypoint(keyPointId, {
      id: keypointData?.id,
      endTime: endDateRef.current.toISOString(),
      change_reason: changeLogData,
    }).then(() => {
      loadKeypoint()
      onUpdate?.()
    })
  }

  const onChangelogReasonChange = (comment: string) => {
    setChangeLogData(comment)
  }

  const toggleChangelogModal = () => {
    setChangeLogModal(!changeLogModal)
  }

  const showAlart = (
    type: IAlertType,
    alertTitle: string,
    description: string,
  ) => {
    addAlert({ type, title: alertTitle, description })
  }

  const onStatusSelect = () => {
    if (!keypointData?.can_edit) {
      showAlart('error', t('access_limited'), t('do_not_have_access_to_edit'))
      return
    }

    const isValid = validateStatus(keypointData, 'done')
    if (!isValid) return

    const updatedKeypoint = { ...keypointData, status: 'done' }
    updateKeypoint(updatedKeypoint.id, updatedKeypoint).then(() => {
      loadKeypoint()
    })
  }

  const toggleImprovementPanel = () => {
    setShowImprovementPanel(!showImprovementPanel)
  }

  const extraButtons = () => {
    return (
      <div className="flex flex-row justify-end items-center">
        {parentMilestoneId && (
          <Button
            type={ButtonType.DEFAULT}
            size={Button.ButtonSize.SMALL}
            onClick={() => setShowMilestoneInspector((prev) => !prev)}
          >
            {t('show_milestone_details')}
          </Button>
        )}
        {keypointData?.status && keypointData?.status !== 'done' && (
          <Button
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {t('done')}
          </Button>
        )}
        {origin && (
          <Link
            to={`/main-process/keypoint/${keyPointId}?rootUrl=${
              origin.url
            }&rootName=${
              origin.name
            }&showHome=${!!origin.showHome}${addProjectToUrl()}`}
          >
            <Button size={Button.ButtonSize.SMALL}>
              {t('view_or_plan_deliveries')}
            </Button>
          </Link>
        )}
        {keypointData?.improvement_id ? (
          <Button
            size={Button.ButtonSize.SMALL}
            onClick={toggleImprovementPanel}
          >
            {t('see_improvement_measures')}
          </Button>
        ) : null}
      </div>
    )
  }

  const addProjectToUrl = (): string => {
    const params = new URLSearchParams(history.location.search)
    if (params.has('project')) {
      return `&project=${params.get('project')}`
    }
    return ''
  }

  const getMetaDataContent = (): JSX.Element =>
    optionalFieldValues.length > 0 ? (
      <MetaDataFieldsForPage
        disabled={readonly}
        onFieldsUpdate={onKeypointUpdate}
        values={optionalFieldValues}
        fields={systemTypeGroup?.optional_fields ?? []}
        required={false}
        editMode={false}
        inspectorPanel={true}
        padding={false}
      />
    ) : (
      <></>
    )

  const getMainContent = () => {
    return (
      <InlineComponentsWrapper border={undefined} loading={loading}>
        <TextInlineInputCompontent
          disabled={readonly}
          label={t('title')}
          value={keypointData?.name}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ name: newValue })
          }}
          validate={(value) => {
            if (value === undefined || value === '')
              return getErrorMessage(ValidationError.MISSING_TITLE, t)
            return
          }}
        />

        <SelectorInlineInputComponent
          disabled={readonly}
          items={statusTypes(t)}
          label={t('status')}
          getItemLabel={(stat) => stat?.name}
          initialItem={{
            id: keypointData?.status ?? '',
            name:
              statusTypes(t).find(
                (statData) => statData.id === keypointData?.status,
              )?.name ?? '',
          }}
          validate={(value) => {
            if (value === undefined)
              return getErrorMessage(ValidationError.MISSING_STATUS, t)

            if (keypointData?.open_children && value === 'done') {
              return getKeyPointErrorMessage(
                StructureValidationError.HAS_OPEN_CHILDREN,
                t,
              )
            }
            if (
              value &&
              keypointData?.mile_stone &&
              keypointData.mile_stone.status === 'done' &&
              value !== 'done'
            ) {
              return getKeyPointErrorMessage(
                StructureValidationError.PARENT_IS_CLOSED,
                t,
              )
            }
            return
          }}
          selectedId={keypointData?.status}
          onValueSubmitted={(stat) => {
            onChangeInput({ status: stat })
          }}
          inspectorPanel={true}
        />

        <DateTimeInlineInputComponent
          disabled={readonly}
          label={t('deadline')}
          selectedTime={keypointData?.endTime}
          onValueSubmitted={(deadline) => {
            onChangeInput({ endTime: deadline })
          }}
          validate={(value) => {
            if (!keypointData) return
            return validateDate(keypointData, value)
          }}
          inspectorPanel={true}
        />
        <DateTimeInlineInputComponent
          label="closed_date"
          selectedTime={keypointData?.closed_date}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
        <Tooltip
          show={!hasModuleAdminAccess}
          message={t('only_planning_admin_can_edit_baseline')}
        >
          <DateTimeInlineInputComponent
            disabled={!hasModuleAdminAccess}
            label={t('baseline_date')}
            selectedTime={keypointData?.baseline}
            onValueSubmitted={(baselinedate) => {
              onChangeInput({ baseline: baselinedate })
            }}
            inspectorPanel={true}
          />
        </Tooltip>
        <TextInlineInputCompontent
          disabled={readonly}
          label={t('duration_days')}
          value={`${keypointData?.duration ?? 0}`}
          onValueSubmitted={(newValue) => {
            if (newValue)
              onChangeInput({ duration: parseInt(`${newValue}`, 10) })
          }}
          validate={(newValue) => {
            if (newValue?.length && isNaN(+newValue)) {
              return t('must_be_a_number')
            }
            return undefined
          }}
        />
        <TextInlineInputCompontent
          label={t('delay_days')}
          disabled={true}
          value={`${keypointData?.delay ?? 0}`}
        />
        <TextInlineInputCompontent
          label={t('deliveries')}
          disabled={true}
          value={`${keypointData?.done_children || 0}/${
            (keypointData?.open_children || 0) +
            (keypointData?.done_children || 0)
          }`}
        />
        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() => getProjectMilestones(projectId)}
          label={t('milestone')}
          getItemLabel={(milestone) =>
            `${milestone?.record_id} - ${milestone?.name}`
          }
          initialItem={keypointData?.mile_stone}
          selectedId={keypointData?.mile_stone_id ?? 0}
          onValueSubmitted={(val) => {
            onChangeInput({ mile_stone_id: val })
          }}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() => getProjectDisciplines(projectId)}
          label="discipline"
          initialItem={keypointData?.discipline}
          getItemLabel={(discipline) =>
            `${discipline?.shortName} - ${discipline?.name}`
          }
          validate={(value) => {
            if (value === undefined)
              return t('fill_out_w_param', {
                param: t('discipline'),
              })
            return
          }}
          selectedId={keypointData?.discipline_id}
          onValueSubmitted={(discipline_id) => {
            onChangeInput({ discipline_id, responsible_id: null })
          }}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() =>
            keypointData?.discipline_id
              ? getDisciplineUsers(keypointData?.discipline_id)
              : getProjectUsersWithDisciplines(projectId)
          }
          label="responsible"
          getItemLabel={(responsible) =>
            `${responsible?.firstName} ${responsible?.lastName}`
          }
          initialItem={keypointData?.responsible}
          validate={(value) => {
            if (value === undefined)
              return t('fill_out_w_param', {
                param: t('responsible'),
              })
            return
          }}
          selectedId={keypointData?.responsible_id ?? 0}
          onValueSubmitted={(responsible_id) => {
            onChangeInput({ responsible_id })
          }}
          dependencies={[keypointData?.discipline_id]}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          label={'contract'}
          disabled={true}
          selectedId={keypointData?.contract_id ?? ''}
          getItemLabel={(contract) =>
            `${contract?.contractNumber} - ${contract?.contractName}`
          }
          initialItem={keypointData?.contract}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() => getProjectProcesses(projectId)}
          label={t('main_process')}
          getItemLabel={(process) => `${process?.record_id} - ${process?.name}`}
          initialItem={keypointData?.main_process}
          validate={(value) => {
            if (!value || parseInt(`${value}`, 10) === 0) {
              return getErrorMessage(ValidationError.MISSING_MAIN_PROCESS, t)
            }
            return
          }}
          selectedId={keypointData?.main_process_id}
          onValueSubmitted={(val) => {
            onChangeInput({ main_process_id: val, team_id: undefined })
          }}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() =>
            keypointData?.main_process_id
              ? getMainprocessTeams(keypointData?.main_process_id)
              : getProjectTeams(projectId)
          }
          label={t('team')}
          getItemLabel={(team) => team?.name}
          initialItem={keypointData?.team}
          selectedId={keypointData?.team_id ?? 0}
          onValueSubmitted={(val) => {
            onChangeInput({ team_id: val })
          }}
          cancelButton={true}
          dependencies={[keypointData?.main_process_id]}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() => getProjectTags(projectId)}
          label={t('type')}
          getItemLabel={(tag) => tag?.name}
          initialItem={keypointData?.tag}
          selectedId={keypointData?.tag_id ?? 0}
          onValueSubmitted={(val) => {
            onChangeInput({ tag_id: val })
          }}
          cancelButton={true}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          disabled={readonly}
          getItems={() =>
            getFilteredProjectImprovementsWithPagination(projectId).then(
              (res) => res.items,
            )
          }
          label={t('improvement')}
          getItemLabel={(improvement) => improvement?.title}
          initialItem={keypointData?.improvement}
          selectedId={keypointData?.improvement_id ?? 0}
          onValueSubmitted={(val) => {
            onChangeInput({ improvement_id: val })
          }}
          cancelButton={true}
          inspectorPanel={true}
        />

        <TextInlineInputCompontent
          disabled={readonly}
          label={t('description')}
          textArea={true}
          value={keypointData?.description}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ description: newValue })
          }}
        />

        <TextInlineInputCompontent
          disabled={readonly}
          label={t('assumptions')}
          textArea={true}
          value={keypointData?.assumptions}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ assumptions: newValue })
          }}
        />
        <TextInlineInputCompontent
          label={t('reporter')}
          value={`${keypointData?.user?.firstName} ${keypointData?.user?.lastName}`}
          disabled={true}
        />
        <DateTimeInlineInputComponent
          label="created_at"
          selectedTime={`${keypointData?.created_at}`}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
        <DateTimeInlineInputComponent
          label="updated_at"
          selectedTime={`${keypointData?.updated_at}`}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
      </InlineComponentsWrapper>
    )
  }
  return (
    <>
      <FixedPane
        disableOutsideClose={true}
        title={`${keypointData?.record_id} - ${keypointData?.name}`}
        show={open}
        onClose={() => onClose()}
        className={'w-[700px]'}
        detailPageData={{
          key: DetailPageKeys.KEYPOINT,
          ids: { keypointId: keyPointId },
        }}
      >
        <div className={styleClass.root}>
          {!readonly && extraButtons()}
          <InspectorSections defaultIndex={defaultIndex} sections={getRows()} />
          {showImprovementPanel && keypointData?.improvement_id && (
            <ImprovementPanel
              projectId={projectContext.state.currentProject.id}
              improvementId={keypointData?.improvement_id}
              onClose={toggleImprovementPanel}
              show={showImprovementPanel}
            />
          )}
          {changeLogModal && (
            <KeypointChangeLogModal
              show={changeLogModal}
              toggleChangeLogModal={toggleChangelogModal}
              onChangeLogSubmit={onChangeLogSubmit}
              onChangeReasonLog={onChangelogReasonChange}
            />
          )}
        </div>
      </FixedPane>
      {selectedDeliveryId && showDeliveryModal ? (
        <DeliveryInspectorPanel
          deliveryId={selectedDeliveryId}
          open={showDeliveryModal}
          onClose={() => {
            setSelectedDeliveryId(0)
            setShowDeliveryModal(false)
          }}
          projectId={projectId}
          onUpdate={onUpdate}
        />
      ) : null}
      {parentMilestoneId && showMilestoneInspector ? (
        <MilestoneInspectorPanel
          milestoneId={parentMilestoneId}
          origin={{
            name: t('visual_overview_by_discipline'),
            url: '/milestone?tabIndex=2',
          }}
          open={showMilestoneInspector}
          onClose={() => {
            setShowMilestoneInspector(false)
          }}
          onUpdate={onUpdate}
        />
      ) : null}
    </>
  )
}
export default KeypointInspectorPanel
