import * as React from 'react'
import { useEffect } from 'react'
import { useExpandableTableInteractionStoreState } from 'src/systematic-completion/components/expandable-table/hooks/useExpandableTableInteractionStore'
import { useExpandableTableStoreState } from '../../hooks/useExpandableTableStore'
import ExpandableSection from './ExpandableSection'

interface ExpandableSectionsProps {
  initialItem?: Record<string, unknown>
}

const ExpandableSections: React.FC<ExpandableSectionsProps> = ({
  initialItem,
}) => {
  const focusedSectionId = useExpandableTableInteractionStoreState(
    (state) => state.focusedSectionId,
  )
  const [internalExpandableSections, createRow, createSection] =
    useExpandableTableStoreState((state) => [
      state.expandableSections,
      state.createRow,
      state.createSection,
    ])

  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter' && e.shiftKey) {
        const lastSectionId =
          internalExpandableSections[internalExpandableSections.length - 1]?.id
        lastSectionId || focusedSectionId
          ? createRow(focusedSectionId ?? lastSectionId, initialItem ?? {})
          : createSection('')
      }
    }

    document.addEventListener('keydown', onKeyDown)
    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [
    createRow,
    createSection,
    focusedSectionId,
    initialItem,
    internalExpandableSections,
  ])

  return (
    <>
      {internalExpandableSections?.map((section, index) => (
        <ExpandableSection
          key={section.id}
          section={section}
          initialItem={initialItem}
          isLastSection={index === internalExpandableSections.length - 1}
        />
      ))}
    </>
  )
}

export default ExpandableSections
