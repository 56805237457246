import moment from 'moment'
import { PureComponent } from 'react'
import { SingleDatePicker } from 'react-dates'
import { withTranslation } from 'react-i18next'
import {
  IWithProjectContext,
  withProjectContext,
} from 'src/context/withProjectContext'
import DocumentMetaDataFields from 'src/document/components/DocumentCreateModal/DocumentMetaDataFields'
import { IMetaValue } from 'src/document/types/IMetaData'
import { getUploadDocURl } from 'src/service/FileUploadService'
import {
  IDiscipline,
  IDocument,
  IImprovement,
  IMilestone,
  ISystemTypeGroup,
  ITag,
  ITeam,
  IUserData,
  IKeypoint,
  IMainProcess,
} from 'src/service/OrgTypes'
import {
  addKeyPoint,
  addKeypointDependency,
  getKeypointDetails,
  getProjectKeypoints,
  getProjectPlanningData,
  updateKeypoint,
} from 'src/service/ProcessService'
import { getProjectSystemTypeGroupsPerDomain } from 'src/service/SystemTypeGroupService'
import { getProjectTags } from 'src/service/TagService'
import Modal from 'src/ui-elements/modal/Modal'
import Textarea from 'src/ui-elements/textarea/Textarea'
import { getDisciplines } from '../../service/DisciplineService'
import { deleteFile } from '../../service/DocumentService'
import { getMainProcessTeams } from '../../service/TeamService'
import {
  getProjectDisplineUsers,
  getProjectUsersWithDisciplines,
} from '../../service/UserService'
import {
  getErrorMessage,
  getKeyPointErrorMessage,
  StructureValidationError,
  ValidationError,
} from '../../service/ValidationErrors'
import Button from '../../ui-elements/button/Button'
import Input from '../../ui-elements/input/Input'
import Spinner from '../../ui-elements/loader/Spinner'
import ModalFooter from '../../ui-elements/modal/ModalFooter'
import { renderDayContents } from '../../utility/Utility'
import { capFirstLetter, classNames } from '../../utility/utils'
import UploadFile from '../document/UploadFile'
import UploadFileList from '../document/UploadFileList'
import { ICopiedKeypointData } from '../grid/Keypoint/KeypointTimeline'
import MultiSelector from '../multi-selector/MultiSelector'
import Selector from '../selectors/Selector'
import { initializeMetaValues, setExistingValues } from '../system/SystemUtil'

interface IEditKeyPointModalProps extends IWithProjectContext {
  keypoint: IKeypoint
  show: boolean
  createKeypoint?: boolean
  improvementId?: number
  createFromCanvas?: boolean
  copyCreateState?: ICopiedKeypointData
  onCreateKeypoint?: (keypoint: IKeypoint) => void
  close: () => void
  t: any
}

interface IEditKeyPointModalState {
  keypoint: IKeypoint
  allKeypoints: IKeypoint[]
  disciplines: IDiscipline[]
  mainProcess: IMainProcess[]
  themes: ITeam[]
  users: IUserData[]
  milestones: IMilestone[]
  responsible_id: number
  discipline_id: number
  contract_id: number | null
  mainProcess_id: number
  mile_stone_id: number
  theme_id: number
  dependencyLoading: boolean
  title: string
  description: string
  assumptions: string
  status: string
  endTime: any
  datePickerFocused: boolean
  loading: boolean
  createMultiple: boolean
  dependencyFirstTime: boolean
  selectedKeypoints: any[]
  duration: number
  keypointModalError: boolean
  keypointModalErrorMessage: string
  datoErrorMessage: string
  titleErrorMessage: string
  milestoneErrorMessage: string
  tags: ITag[]
  tag_id: number | null
  processErrorMessage: string
  disciplineErrorMessage: string
  theamErrorMessage: string
  responsibleErrorMessage: string

  documents: IDocument[]
  selectLoading: boolean
  improvements: IImprovement[]
  systemTypeGroup?: ISystemTypeGroup
  optionalFieldValues: IMetaValue[]
}

class KeypointModal extends PureComponent<
  IEditKeyPointModalProps,
  IEditKeyPointModalState
> {
  private static styleClass = {
    root: classNames('flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'pt-1'),
    label: classNames(
      'flex',
      'items-center',
      'w-full',
      'font-roboto',
      'block',
      'font-medium',
      'text-sm',
      'leading-5',
      'text-gray-700',
      'my-2',
    ),
    errorMessage: classNames(
      'flex',
      'items-center',
      'text-red-600',
      'ml-2',
      'text-sm',
      'font-normal',
    ),
    wrapper: (halfWidth?: boolean) =>
      classNames('px-2', !halfWidth ? 'w-full' : 'w-1/2'),
  }

  constructor(props: IEditKeyPointModalProps) {
    super(props)
    const keypoint = this.props.keypoint
    this.state = {
      keypoint: keypoint,
      allKeypoints: [],
      disciplines: [],
      mainProcess: [],
      themes: [],
      users: [],
      milestones: [],
      mainProcess_id: keypoint.main_process_id ?? 0,
      theme_id: keypoint.team_id ?? 0,
      mile_stone_id: keypoint.mile_stone_id ?? 0,
      responsible_id: keypoint.responsible_id ?? 0,
      discipline_id: keypoint.discipline_id ?? 0,
      tags: keypoint.tag ? [keypoint.tag] : [],
      tag_id: keypoint.tag_id ?? 0,
      contract_id: keypoint.contract_id ?? 0,
      title: keypoint.name,
      description: keypoint.description,
      status: keypoint.status ? keypoint.status : 'open',
      assumptions: keypoint.assumptions,
      endTime: this.props.createFromCanvas
        ? moment(this.props.keypoint.endTime)
        : null,
      datePickerFocused: false,
      loading: false,
      createMultiple: false,
      dependencyFirstTime: true,
      dependencyLoading: false,
      selectedKeypoints: [],
      duration: 0,
      keypointModalError: false,
      keypointModalErrorMessage: '',
      datoErrorMessage: '',
      titleErrorMessage: '',
      milestoneErrorMessage: '',

      processErrorMessage: '',
      disciplineErrorMessage: '',
      theamErrorMessage: '',
      responsibleErrorMessage: '',
      documents: [],
      selectLoading: false,
      improvements: [],
      optionalFieldValues: [],
      systemTypeGroup: undefined,
    }
  }

  public componentDidMount() {
    const { copyCreateState } = this.props
    const { currentProject } = this.props.projectContext.state
    const { keypoint } = this.props
    getProjectSystemTypeGroupsPerDomain(currentProject.id, 'KeyPoint').then(
      (res) => {
        this.setState({ systemTypeGroup: res[0] })
      },
    )
    if (copyCreateState) {
      this.getCopyKeypointInfo(copyCreateState, currentProject.id)
    } else {
      getProjectPlanningData(currentProject.id).then((res) => {
        this.setState({
          disciplines: res.disciplines ? res.disciplines : [],
          mainProcess: res.main_processes ? res.main_processes : [],
          themes: res.teams ? res.teams : [],
          milestones: res.mile_stones ? res.mile_stones : [],
          improvements: res.improvements,
        })
        if (keypoint.responsible_id) {
          this.getUsers()
        }
      })
    }
    this.fetchSystemGroupType()
  }

  private getCopyKeypointInfo = async (
    copyCreateKeypoint: ICopiedKeypointData,
    projectId: number,
  ) => {
    this.setState({ loading: true })
    const copyKeypoint = await getKeypointDetails(
      copyCreateKeypoint.id,
      projectId,
    )
    const res = await getProjectPlanningData(projectId)
    this.setState(
      {
        disciplines: res.disciplines ? res.disciplines : [],
        mainProcess: res.main_processes ? res.main_processes : [],
        themes: res.teams ? res.teams : [],
        milestones: res.mile_stones ? res.mile_stones : [],
        title: copyKeypoint.name,
        description: copyKeypoint.description,
        status: copyKeypoint.status ? copyKeypoint.status : 'open',
        assumptions: copyKeypoint.assumptions,
        endTime: copyCreateKeypoint.date,
        mainProcess_id:
          copyCreateKeypoint.mainProcess_id ??
          copyKeypoint.main_process_id ??
          0,
        theme_id: copyCreateKeypoint.theme_id
          ? copyCreateKeypoint.theme_id
          : copyKeypoint.team_id
            ? copyKeypoint.team_id
            : 0,
        mile_stone_id: copyKeypoint.mile_stone_id
          ? copyKeypoint.mile_stone_id
          : 0,
        responsible_id: copyKeypoint.responsible_id
          ? copyKeypoint.responsible_id
          : 0,
        discipline_id: copyCreateKeypoint.discipline_id
          ? copyCreateKeypoint.discipline_id
          : copyKeypoint.discipline_id
            ? copyKeypoint.discipline_id
            : 0,
        tags: copyKeypoint.tag ? [copyKeypoint.tag] : [],
        tag_id: copyKeypoint.tag_id ? copyKeypoint.tag_id : 0,
        loading: false,
      },
      () => {
        this.getUsers()
      },
    )
  }

  private getUsers = () => {
    const { currentProject } = this.props.projectContext.state
    const { discipline_id } = this.state
    this.setState({ selectLoading: true })
    if (discipline_id > 0) {
      getProjectDisplineUsers(currentProject.id, discipline_id).then((res) => {
        const disciplineUser =
          res.findIndex(
            (discipline) => this.state.responsible_id === discipline.id,
          ) > -1
        this.setState({
          users: res || [],
          selectLoading: false,
          responsible_id: disciplineUser ? this.state.responsible_id : 0,
        })
      })
    } else {
      getProjectUsersWithDisciplines(currentProject.id).then((res) => {
        this.setState({
          users: res,
          selectLoading: false,
        })
      })
    }
  }

  private onMainProcessChange = (value: number) => {
    getMainProcessTeams(value).then((res) => {
      this.setState({
        mainProcess_id: value,
        themes: res,
        processErrorMessage: '',
      })
    })
  }

  private onDisciplineChange = async (value: number) => {
    const { currentProject } = this.props.projectContext.state
    const selectedDiscipline = this.state.disciplines
      .filter((d) => d.id === value)
      .pop()
    if (this.state.responsible_id <= 0) {
      getProjectDisplineUsers(currentProject.id, value).then((res) => {
        this.setState({
          users: res || [],
          responsible_id: 0,
        })
      })
    }

    this.setState({
      discipline_id: value,
      contract_id:
        selectedDiscipline && selectedDiscipline.contract_id
          ? selectedDiscipline.contract_id
          : null,
      disciplineErrorMessage: '',
      responsibleErrorMessage: '',
    })
  }

  private onThemeChange = (value: number) => {
    const selectedTheam = this.state.themes
      .filter((team: ITeam) => team.id === value)
      .pop()
    this.setState((prevState) => ({
      theme_id: value,
      mainProcess_id: selectedTheam
        ? selectedTheam.main_process_id
          ? selectedTheam.main_process_id
          : prevState.mainProcess_id
        : prevState.mainProcess_id,
      theamErrorMessage: '',
    }))
  }

  private onNameChange = (e: any) => {
    this.setState({
      title: e.target.value,
      titleErrorMessage: '',
    })
  }

  private onDurationChange = (e: any) => {
    this.setState({
      duration: e.target.value,
    })
  }

  private onDescriptionChange = (e: any) => {
    this.setState({
      description: e.target.value,
    })
  }

  private onAssumptionsChange = (e: any) => {
    this.setState({
      assumptions: e.target.value,
    })
  }

  private onResponsibleChange = (userId: number) => {
    const { currentProject } = this.props.projectContext.state
    const selectedUser: IUserData | undefined = this.state.users
      .filter((u) => u.id === userId)
      .pop()
    const userDisciplines =
      selectedUser && selectedUser.disciplines
        ? selectedUser.disciplines.filter(
            (d) => d.project_id === currentProject.id,
          )
        : undefined
    const discipline =
      userDisciplines && userDisciplines.length === 1
        ? userDisciplines[0]
        : undefined
    if (discipline) {
      this.setState({
        discipline_id: discipline.id,
        contract_id: discipline.contract_id,
      })
    }
    this.setState({
      responsible_id: userId,
      disciplines: userDisciplines || [],
      disciplineErrorMessage: '',
      responsibleErrorMessage: '',
    })
  }

  private getTags = () => {
    const id = this.props.projectContext.state.currentProject.id
    this.setState({
      selectLoading: true,
    })
    getProjectTags(id).then((res) => {
      if (res) {
        this.setState({
          tags: res ? res : [],
          selectLoading: false,
        })
      }
    })
  }

  private onDisciplineUserClear = async () => {
    this.setState({
      discipline_id: 0,
      responsible_id: 0,
    })
    const { currentProject } = this.props.projectContext.state
    const allProjectDis = await getDisciplines(currentProject.id)
    const allUsers = await getProjectUsersWithDisciplines(currentProject.id)
    this.setState({
      disciplines: allProjectDis,
      users: allUsers,
    })
  }

  private onMilestoneChange = (value: number) => {
    this.setState({
      mile_stone_id: value,
    })
  }

  private onSubmit = (e: any) => {
    let error = false
    const { t } = this.props
    e.preventDefault()
    this.setState({
      loading: true,
      keypointModalError: false,
      keypointModalErrorMessage: '',
      datoErrorMessage: '',
      titleErrorMessage: '',
      processErrorMessage: '',
      disciplineErrorMessage: '',
      theamErrorMessage: '',
      responsibleErrorMessage: '',
    })

    if (!this.state.discipline_id || this.state.discipline_id === 0) {
      this.setState({
        disciplineErrorMessage: getErrorMessage(
          ValidationError.MISSING_DISCIPLINE,
          t,
        ),
      })
      error = true
    }

    if (!this.state.mainProcess_id || this.state.mainProcess_id === 0) {
      this.setState({
        processErrorMessage: getErrorMessage(
          ValidationError.MISSING_MAIN_PROCESS,
          t,
        ),
      })
      error = true
    }

    if (!this.state.responsible_id || this.state.responsible_id === 0) {
      this.setState({
        responsibleErrorMessage: getErrorMessage(
          ValidationError.MISSING_RESPONSIBLE,
          t,
        ),
      })
      error = true
    }

    if (!this.state.endTime || !moment(this.state.endTime).isValid()) {
      this.setState({
        datoErrorMessage: getErrorMessage(ValidationError.MISSING_DEADLINE, t),
      })
      error = true
    }

    const improvement = this.state.improvements.find(
      (u) => u.id === this.props.improvementId,
    )
    if (this.state.endTime && improvement && improvement.deadline) {
      if (
        moment(this.state.endTime).isAfter(moment(improvement.deadline), 'day')
      ) {
        this.setState({
          datoErrorMessage: getKeyPointErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
            t,
            moment(improvement.deadline).format('L'),
          ),
        })
        error = true
      }
    }

    const milestone = this.state.milestones.find(
      (u) => u.id === this.state.mile_stone_id,
    )
    if (this.state.endTime && milestone) {
      if (milestone) {
        if (
          moment(this.state.endTime).isAfter(moment(milestone.deadline), 'day')
        ) {
          this.setState({
            datoErrorMessage: getKeyPointErrorMessage(
              StructureValidationError.PAST_PARENT_DEADLINE,
              t,
              moment(milestone.deadline).format('L'),
            ),
          })
          error = true
        }
      }
    }
    if (milestone && milestone.status === 'done') {
      this.setState({
        milestoneErrorMessage: getKeyPointErrorMessage(
          StructureValidationError.PARENT_IS_CLOSED,
          t,
        ),
      })
      error = true
    }

    if (!this.state.title || this.state.title === '') {
      this.setState({
        titleErrorMessage: getErrorMessage(ValidationError.MISSING_TITLE, t),
      })
      error = true
    }

    if (!error) {
      const { createKeypoint } = this.props
      e.preventDefault()
      const { currentProject } = this.props.projectContext.state
      const updatedKeypoint: IKeypoint = {
        id: this.props.keypoint.id,
        name: this.state.title,
        record_id: this.props.keypoint.record_id,
        description: this.state.description,
        assumptions: this.state.assumptions,
        discipline_id:
          this.state.discipline_id > 0 ? this.state.discipline_id : undefined,
        contract_id:
          this.state.contract_id && this.state.contract_id > 0
            ? this.state.contract_id
            : undefined,
        team_id: this.state.theme_id > 0 ? this.state.theme_id : undefined,
        mile_stone_id:
          this.state.mile_stone_id > 0 ? this.state.mile_stone_id : undefined,
        main_process_id:
          this.state.mainProcess_id > 0 ? this.state.mainProcess_id : undefined,
        endTime: this.state.endTime ? this.state.endTime.toISOString() : '',
        status: this.state.status,
        responsible_id:
          this.state.responsible_id > 0 ? this.state.responsible_id : undefined,
        documents: this.state.documents,
        project_id: currentProject.id,
        copy_from_id: this.props.keypoint.copy_from_id,
        improvement_id: this.props.improvementId,
        duration: this.state.duration,
        tag_id: this.state.tag_id ? this.state.tag_id : undefined,
        system_type_group_id: this.state.systemTypeGroup?.id,
        optional_fields: this.state.optionalFieldValues,
      }

      if (createKeypoint) {
        if (this.state.mainProcess_id) {
          addKeyPoint(this.state.mainProcess_id, updatedKeypoint).then(
            (res) => {
              addKeypointDependency(res.id, {
                dependent_on_key_point: this.state.selectedKeypoints.concat(),
              })
              if (this.state.createMultiple) {
                this.setState({
                  title: '',
                  description: '',
                  assumptions: '',
                  documents: [],
                })
              } else {
                this.props.close()
              }

              if (this.props.onCreateKeypoint) {
                this.props.onCreateKeypoint(res)
              }
            },
          )
        }
      } else {
        updateKeypoint(this.props.keypoint.id, updatedKeypoint).then(() => {
          this.props.close()
        })
      }

      this.setState({
        loading: false,
      })
    } else {
      this.setState({
        loading: false,
      })
    }
  }

  private setKeypointDatePickerValue = (data: moment.Moment) => {
    this.setState({
      endTime: data,
      datoErrorMessage: '',
    })
  }

  private setDatePickerFocus = (focused: any) => {
    this.setState({
      datePickerFocused: focused.focused,
    })
  }

  private outSideRangeDate = () => {
    return false
  }

  private filesUploaded = (docs: IDocument[]) => {
    this.setState({
      documents: this.state.documents.concat(docs),
    })
  }

  private updateUploadedFiles = (docs: IDocument[]) => {
    this.setState({
      documents: docs,
    })
  }

  private onCloseModal = () => {
    this.state.documents.forEach((item) => {
      deleteFile({ file_url: item.fileUrl }).catch((err) => console.error(err))
    })

    this.setState({
      documents: [],
    })

    this.props.close()
  }

  private onSelectDependency = (selectedKeypoints: any[]) => {
    this.setState({ selectedKeypoints })
  }

  private onSingleCreate = () => {
    this.setState({
      createMultiple: false,
    })
  }

  private onMultipleCreate = () => {
    this.setState({
      createMultiple: true,
    })
  }

  private openDependencyKeypoint = async () => {
    const { currentProject } = this.props.projectContext.state
    if (this.state.dependencyFirstTime) {
      this.setState({
        dependencyLoading: true,
      })
      const allKeypoints = await getProjectKeypoints(currentProject.id)
      this.setState({
        allKeypoints,
        dependencyFirstTime: false,
        dependencyLoading: false,
      })
    }
  }

  private getModalTitle = (): string => {
    const { keypoint, t } = this.props
    return keypoint.name
      ? keypoint.record_id + ' - ' + keypoint.name
      : t('new_keypoint')
  }

  private onTagChange = (value: number | null) => {
    this.setState({
      tag_id: value,
    })
  }

  private fetchSystemGroupType = () => {
    getProjectSystemTypeGroupsPerDomain(
      this.props.projectContext.state.currentProject.id,
      'KeyPoint',
    ).then((res: ISystemTypeGroup[]) => {
      const value = res.pop()
      this.setState({
        systemTypeGroup: value,
      })
      if (value) {
        const metaFields = initializeMetaValues(
          value.optional_fields ?? [],
          'KeyPoint',
          this.props.keypoint?.id,
        )
        this.setState({
          optionalFieldValues: setExistingValues(
            this.props.keypoint?.optional_fields ?? [],
            metaFields,
          ),
        })
      }
    })
  }

  private onOptionalFieldsUpdate = (values: IMetaValue[]) => {
    this.setState({
      optionalFieldValues: values,
    })
  }

  public render() {
    const { close } = this.props
    const disable = false
    const {
      disciplines,
      mainProcess,
      themes,
      responsible_id,
      users,
      milestones,
      discipline_id,
      mainProcess_id,
      theme_id,
      mile_stone_id,
      tags,
      tag_id,
      title,
      description,
      assumptions,
      endTime,
      dependencyLoading,
      selectLoading,
      allKeypoints,
      documents,
      selectedKeypoints,
      datePickerFocused,
      loading,
      duration,
      titleErrorMessage,
      processErrorMessage,
      disciplineErrorMessage,
      theamErrorMessage,
      responsibleErrorMessage,
      datoErrorMessage,
      optionalFieldValues,
    } = this.state

    const { t } = this.props

    const themesCheck = themes.filter(
      (theme: any) => theme.main_process_id === mainProcess_id,
    )
    const { currentProject } = this.props.projectContext.state

    return (
      <Modal
        show={this.props.show}
        title={this.getModalTitle()}
        closeModal={close}
        maxWidth={800}
        confirmOutsideClick={true}
      >
        <form
          className={KeypointModal.styleClass.root}
          onSubmit={this.onSubmit}
        >
          <div
            className={`${KeypointModal.styleClass.inputGroup} flex-wrap items-center`}
          >
            <div className={'w-mx-content flex flex-col px-2 items-start'}>
              <div className={'flex'}>
                <label className={KeypointModal.styleClass.label}>
                  {capFirstLetter(t('deadline'))} *
                  {datoErrorMessage && (
                    <span
                      className={KeypointModal.styleClass.errorMessage}
                    >{`  ${datoErrorMessage}`}</span>
                  )}
                </label>
              </div>
              <div className={'pr-2 mb-1'}>
                <SingleDatePicker
                  firstDayOfWeek={1}
                  date={endTime}
                  onDateChange={this.setKeypointDatePickerValue}
                  renderDayContents={renderDayContents}
                  focused={datePickerFocused}
                  onFocusChange={this.setDatePickerFocus}
                  id="datePickerKeypoint"
                  small={true}
                  isOutsideRange={this.outSideRangeDate}
                  showDefaultInputIcon={true}
                  noBorder={true}
                  numberOfMonths={1}
                  displayFormat={() =>
                    moment.localeData('no').postformat('DD.MM.YY')
                  }
                  hideKeyboardShortcutsPanel={true}
                />
              </div>
            </div>
          </div>
          <div className={'mb-1 w-36'}>
            <Input
              label={t('duration_days')}
              value={duration}
              onChange={this.onDurationChange}
              block={true}
              required={false}
              type={'Number'}
              minValue={0}
              step={'any'}
            />
          </div>

          <div className={KeypointModal.styleClass.inputGroup}>
            <Input
              label={t('title')}
              value={title}
              onChange={this.onNameChange}
              block={true}
              required={true}
              errorMessage={titleErrorMessage}
              autoFocus={true}
            />
          </div>

          <div className={KeypointModal.styleClass.inputGroup}>
            <div className={' w-full md:w-1/2'}>
              <Selector
                items={mainProcess}
                selectedItemId={mainProcess_id}
                onSelect={this.onMainProcessChange}
                label={t('main_process')}
                dataFields={['name']}
                required={true}
                fontSize={'sm'}
                fontWeight={'bold'}
                disabled={disable}
                errorMessage={processErrorMessage}
              />
            </div>
            {themesCheck.length > 0 && (
              <div className={KeypointModal.styleClass.wrapper(true)}>
                <div className={' w-full md:w-1/2'}>
                  <Selector
                    items={themes.filter(
                      (theme: any) => theme.main_process_id === mainProcess_id,
                    )}
                    selectedItemId={theme_id}
                    onSelect={this.onThemeChange}
                    label={t('team')}
                    dataFields={['name']}
                    fontSize={'sm'}
                    fontWeight={'bold'}
                    errorMessage={theamErrorMessage}
                  />
                </div>
              </div>
            )}
          </div>

          <div className={`${KeypointModal.styleClass.inputGroup} flex-wrap`}>
            <div className={' w-full md:w-1/2'}>
              <Selector
                items={disciplines}
                selectedItemId={discipline_id}
                onSelect={this.onDisciplineChange}
                label={t('discipline')}
                dataFields={['shortName', 'name']}
                required={true}
                fontSize={'sm'}
                fontWeight={'bold'}
                disabled={disable}
                errorMessage={disciplineErrorMessage}
                onCancel={this.onDisciplineUserClear}
                cancelButton={true}
              />
            </div>
            <div className={' w-full md:w-1/2'}>
              <Selector
                items={users}
                selectedItemId={responsible_id}
                onSelect={this.onResponsibleChange}
                onOpenSelectFunction={this.getUsers}
                loading={this.state.selectLoading}
                label={t('responsible')}
                dataFields={['firstName', 'lastName']}
                required={true}
                fontSize={'sm'}
                userSelector={true}
                fontWeight={'bold'}
                errorMessage={responsibleErrorMessage}
                onCancel={this.onDisciplineUserClear}
                cancelButton={true}
              />
            </div>
          </div>

          <div className={KeypointModal.styleClass.inputGroup}>
            <div className={' w-full md:w-1/2'}>
              <Selector
                items={milestones}
                selectedItemId={mile_stone_id}
                onSelect={this.onMilestoneChange}
                label={t('milestone')}
                dataFields={['name']}
                fontSize={'sm'}
                errorMessage={this.state.milestoneErrorMessage}
                fontWeight={'bold'}
              />
            </div>
            <div className={' w-full md:w-1/2'}>
              <Selector
                items={tags}
                selectedItemId={tag_id ? tag_id : 0}
                loading={selectLoading}
                onSelect={this.onTagChange}
                onOpenSelectFunction={this.getTags}
                label={t('type')}
                dataFields={['name']}
                fontSize={'sm'}
                fontWeight={'bold'}
                cancelButton={true}
                onCancel={() => this.onTagChange(null)}
              />
            </div>
          </div>

          <div className={KeypointModal.styleClass.inputGroup}>
            <div className={'px-2 w-full md:w-1/2'}>
              <MultiSelector
                items={allKeypoints}
                onOpenList={this.openDependencyKeypoint}
                label={t('depends_on')}
                scroll={true}
                dataFields={['record_id', 'name']}
                selectedItems={selectedKeypoints}
                fontWeight={'bold'}
                onSelect={this.onSelectDependency}
                noBorder={true}
                loading={dependencyLoading}
                disableSelectAll={true}
              />
            </div>
          </div>

          <div className={KeypointModal.styleClass.inputGroup}>
            <div className={'mt-2 w-full'}>
              <Textarea
                isValid={false}
                label={t('description')}
                value={description}
                onChange={this.onDescriptionChange}
                block={true}
                required={false}
                placeholder={t('describe_what_the_key_point_includes')}
              />
            </div>
          </div>

          <div className={KeypointModal.styleClass.inputGroup}>
            <div className={'mt-2 w-full'}>
              <Textarea
                isValid={false}
                label={t('assumptions')}
                value={assumptions}
                onChange={this.onAssumptionsChange}
                block={true}
                required={false}
                placeholder={t(
                  'describe_the_assumptions_that_must_be_in_place_to_be_able_to_solve_the_task',
                )}
              />
            </div>
          </div>

          {optionalFieldValues.length > 0 && (
            <div className="pb-8">
              <DocumentMetaDataFields
                title={''}
                onFieldsUpdate={this.onOptionalFieldsUpdate}
                values={optionalFieldValues}
                fields={this.state.systemTypeGroup?.optional_fields ?? []}
                required={false}
              />
            </div>
          )}

          {documents && documents.length > 0 && (
            <UploadFileList
              documents={documents}
              updateDocuments={this.updateUploadedFiles}
            />
          )}

          <UploadFile
            uploadUrl={getUploadDocURl(currentProject.id, 'KeyPoint')}
            uploadedDocuments={this.filesUploaded}
          />

          <ModalFooter>
            <Button
              type={Button.ButtonType.DEFAULT}
              onClick={this.onCloseModal}
            >
              {capFirstLetter(t('cancel'))}
            </Button>
            <Button
              type={Button.ButtonType.SECONDARY}
              onClick={this.onMultipleCreate}
              disabled={loading}
            >
              {loading ? <Spinner /> : capFirstLetter(t('add_multiple'))}
            </Button>
            <Button
              type={Button.ButtonType.PRIMARY}
              onClick={this.onSingleCreate}
              disabled={loading}
            >
              {loading ? <Spinner /> : capFirstLetter(t('add'))}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    )
  }
}

export default withTranslation()(withProjectContext(KeypointModal))
