import 'react-toggle/style.css'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ConstructionChecklistTask from 'src/components/task/ConstructionChecklistTask'
import { editConstructionWagon } from 'src/service/ConstructionWagonService'
import Spinner from 'src/ui-elements/loader/Spinner'
import useAlert from 'src/ui-elements/toast/useAlert'
import {
  IWithProjectContext,
  withProjectContext,
} from '../../../context/withProjectContext'
import InspectorSections from '../../../document/components/Inspector/InspectorSections'
import { createConstructionChecklist } from '../../../service/ConstructionChecklistService'
import {
  IConstructionChecklist,
  IConstructionWagon,
  IInputErrorMessage,
  ITaskFormData,
} from '../../../service/OrgTypes'
import Button from '../../../ui-elements/button/Button'
import FixedPane from '../../../ui-elements/fixed-pane/FixedPane'
import { Icons } from '../../../ui-elements/icon/Icon'
import Input from '../../../ui-elements/input/Input'
import ModalFooter from '../../../ui-elements/modal/ModalFooter'
import InlineCompontent from '../../../ui-elements/page-display/inline-components/InlineComponent'
import InlineComponentsWrapper from '../../../ui-elements/page-display/inline-components/InlineComponentsWrapper'
import RadioItems, { IRadioItems } from '../../../ui-elements/radio/RadioItems'
import Textarea from '../../../ui-elements/textarea/Textarea'
import { capFirstLetter, classNames } from '../../../utility/utils'

interface IConstructionChecklistForm extends IWithProjectContext {
  open: boolean
  closeModal: () => void
  detailView: boolean
  wagon: IConstructionWagon
  constructionChecklist?: IConstructionChecklist
  controlAreaId?: number
}

const ConstructionChecklistForm = ({
  open,
  closeModal,
  detailView,
  constructionChecklist,
  wagon,
  projectContext,
  controlAreaId: _controlAreaId,
}: IConstructionChecklistForm) => {
  const styleClass = {
    root: classNames('flex', 'flex-col', 'min-h-full'),
    inputGroup: classNames('flex', 'flex-row', 'w-full'),
    text: classNames('text-sm', 'text-gray-600', 'my-0.5'),
    row: classNames('flex', 'flex-row', 'flex-nowrap', 'pl-1', 'items-center'),
  }

  useEffect(() => {
    setProjectId(projectContext.state.currentProject.id)
    if (detailView && constructionChecklist) {
      setShaColor(constructionChecklist.sha_color)
      setQualityColor(constructionChecklist.quality_color)
      setNeatnessColor(constructionChecklist.neatness_color)
      setProgressColor(constructionChecklist.progress_color)
      setStaffingColor(constructionChecklist.staffing_color)
      setComment(constructionChecklist.comment)
      setIsNextAreaClean(constructionChecklist.isNextAreaClean)
      setIsNextAreaReadyForStart(constructionChecklist.isNextAreaReadyForStart)
    }
  }, [constructionChecklist, detailView, projectContext])

  const getChecklistCount = (type: string): number => {
    if (constructionChecklist && constructionChecklist.check_list_task_count) {
      const count = constructionChecklist.check_list_task_count
      return count[`${type}_checklist`]
    } else {
      return 0
    }
  }

  const { t } = useTranslation()
  const [projectId, setProjectId] = useState<number>(0)
  const [shaColor, setShaColor] = useState<string>('')
  const [qualityColor, setQualityColor] = useState<string>('')
  const [neatnessColor, setNeatnessColor] = useState<string>('')
  const [progressColor, setProgressColor] = useState<string>('')
  const [staffingColor, setStaffingColor] = useState<string>('')

  const [numberOfWorkers, setNumberOfWorkers] = useState<number>(0)
  const [comment, setComment] = useState<string>('')
  const [isNextAreaClean, setIsNextAreaClean] = useState<boolean>(false)
  const [isNextAreaReadyForStart, setIsNextAreaReadyForStart] =
    useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [shaTasks, setShaTasks] = useState<number>(getChecklistCount('sha'))
  const [qualityTasks, setQualityTasks] = useState<number>(
    getChecklistCount('quality'),
  )
  const [neatnessTasks, setNeatnessTasks] = useState<number>(
    getChecklistCount('neatness'),
  )
  const [progressTasks, setProgressTasks] = useState<number>(
    getChecklistCount('progress'),
  )
  const [crewTasks, setCrewTasks] = useState<number>(
    getChecklistCount('staffing'),
  )
  const [errorMessages, setErroMessages] = useState<IInputErrorMessage[]>([])
  const [tasks, setTasks] = useState<any>({
    sha: [],
    quality: [],
    neatness: [],
    progress: [],
    staffing: [],
  })

  const { addAlert } = useAlert()

  const onShaColorChange = (e: any) => {
    setShaColor(e.target.value)
    checkError('sha', e.target.value, shaTasks)
  }

  const onQualityColorChange = (e: any) => {
    setQualityColor(e.target.value)
    checkError('quality', e.target.value, qualityTasks)
  }

  const onNeatnessColorChange = (e: any) => {
    setNeatnessColor(e.target.value)
    checkError('neatness', e.target.value, neatnessTasks)
  }

  const onProgressColorChange = (e: any) => {
    setProgressColor(e.target.value)
    checkError('progress', e.target.value, progressTasks)
  }

  const onStaffingColorChange = (e: any) => {
    setStaffingColor(e.target.value)
    checkError('crew', e.target.value, crewTasks)
  }

  const onChangeComment = (e: any) => {
    setComment(e.target.value)
  }

  const setNewTasks = (type: string, task: ITaskFormData) => {
    const cloneTasks = { ...tasks }
    if (cloneTasks[type]) {
      cloneTasks[type].push(task)
    }
    setTasks(cloneTasks)
  }

  const checkError = (it: string, value: string, size: number) => {
    if (value !== 'green' && size === 0) {
      const er = t('issue_must_be_added_to_select_yellow_or_red_level')
      makeErrorMessage(er, it)
    } else {
      clearAnErrorMessage(it)
    }
  }

  const shaListSize = (size: number) => {
    setShaTasks(size)
    if (size > 0) {
      clearAnErrorMessage('sha')
    }
  }

  const qualityListSize = (size: number) => {
    setQualityTasks(size)
    if (size > 0) {
      clearAnErrorMessage('quality')
    }
  }

  const neatnessListSize = (size: number) => {
    setNeatnessTasks(size)
    if (size > 0) {
      clearAnErrorMessage('neatness')
    }
  }

  const progressListSize = (size: number) => {
    setProgressTasks(size)
    if (size > 0) {
      clearAnErrorMessage('progress')
    }
  }

  const crewListSize = (size: number) => {
    setCrewTasks(size)
    if (size > 0) {
      clearAnErrorMessage('crew')
    }
  }

  const onSubmitForm = (e: any) => {
    if (!checkAllItemIsSelected()) {
      showAlart(t('select_an_item_from_each_row'))
      return
    }
    e.preventDefault()
    setIsLoading(true)
    const wagonId = wagon.id ? wagon.id : 0
    const newConstructionChecklist: IConstructionChecklist = {
      sha_color: shaColor,
      quality_color: qualityColor,
      neatness_color: neatnessColor,
      progress_color: progressColor,
      staffing_color: staffingColor,
      comment,
      isNextAreaClean,
      isNextAreaReadyForStart,
      construction_locomotive_id: wagonId,
      number_of_workers: numberOfWorkers,
      ...tasks,
    }

    const constructionConstructionChecklistApiCall =
      createConstructionChecklist(newConstructionChecklist, wagonId)
    constructionConstructionChecklistApiCall.then(() => {
      setIsLoading(false)
      if (isNextAreaClean && isNextAreaReadyForStart) {
        const updatedWagon = { ...wagon }
        updatedWagon.status = 'done'
        editConstructionWagon(updatedWagon)
      }
      closeModal()
    })
  }

  const showAlart = (text: string) => {
    addAlert({
      type: 'error',
      title: t('something_went_wrong'),
      description: text,
    })
  }

  const checkAllItemIsSelected = (): boolean => {
    return (
      shaColor !== '' &&
      qualityColor !== '' &&
      neatnessColor !== '' &&
      progressColor !== '' &&
      staffingColor !== ''
    )
  }

  const statusTypes = (_disable: boolean): IRadioItems[] => {
    return [
      {
        id: 'green',
        name: <div className={'h-25'} />,
        classNames: 'green_radio',
      },
      {
        id: 'yellow',
        name: <div className={'h-25'} />,
        classNames: 'yellow_radio',
      },
      {
        id: 'red',
        name: <div className={'h-25'} />,
        classNames: 'red_radio',
      },
    ]
  }

  const onChangeNumberOfWorkers = (e: any) => {
    setNumberOfWorkers(parseInt(e.target.value, 10))
  }

  const getErrorMessage = (input: string): string => {
    const error = errorMessages.find((err) => err.for === input)
    return error ? error.errorMessage : ''
  }

  const hasErrorMessage = (input: string): boolean => {
    const error = errorMessages.find((err) => err.for === input)
    return error !== undefined && error.errorMessage !== ''
  }

  const makeErrorMessage = (errorMessage: string, input: string) => {
    const err = { for: input, errorMessage }
    const cloneErr = [...errorMessages]
    if (hasErrorMessage(input)) {
      const ind = errorMessages.findIndex((er) => er.for === input)
      cloneErr.splice(ind, 1, err)
    } else {
      cloneErr.push(err)
    }
    setErroMessages(cloneErr)
  }

  const clearAnErrorMessage = (input: string) => {
    const copyErrMesg = [...errorMessages]
    const index = copyErrMesg.findIndex((er) => er.for === input)
    if (index > -1) {
      copyErrMesg.splice(index, 1)
    }
    setErroMessages(copyErrMesg)
  }

  const getRows = (): Array<{
    name: string
    icon: string
    activeIcon: string
    content: JSX.Element
    isMaterialIcon?: boolean
    onClick?: () => void
  }> => [
    {
      name: t('info'),
      icon: Icons.INFO_GRAY,
      activeIcon: Icons.INFO_BLUE,
      content: getInfoContent(),
    },
  ]

  const getInfoContent = () => {
    return (
      <>
        <b>
          {' '}
          <span className={'capitalize'}>{t('green')}</span>{' '}
        </b>
        <span className={styleClass.text}>
          {t(
            'the_work_can_continue_without_special_deviations_to_ensure_completion_of_the_wagon_until_the_end_of_the_controlarea_period',
          )}
        </span>
        <br />
        <b>
          <span className={'capitalize'}>{t('yellow')}</span>{' '}
        </b>{' '}
        <span className={styleClass.text}>
          {t(
            'there_is_a_risk_that_the_wagon_cannot_be_completed_within_the_time_period_deter_registered_measures_that_will_in_all_probability_ensure_completion',
          )}
        </span>
        <br />
        <b>
          {' '}
          <span className={'capitalize'}>{t('red')}</span>{' '}
        </b>{' '}
        <span className={styleClass.text}>
          {t(
            'there_is_a_high_probability_that_the_wagon_will_not_be_completed_within_the_time_period_measures_have_been_registered_that_can_improve_the_situation_but_special_attention_is_required_here',
          )}
        </span>
      </>
    )
  }

  return (
    <FixedPane
      show={open}
      title={
        detailView
          ? capFirstLetter(t('board_meeting'))
          : t('register_a_new_board_meeting')
      }
      onClose={closeModal}
      className={'w-[600px]'}
    >
      <div className={styleClass.root}>
        <InlineComponentsWrapper
          padding="left"
          border={undefined}
          inputWidth="w-[300px]"
          labelWidth={'w-[100px]'}
        >
          <InlineCompontent
            label="sha"
            content={
              <div className={styleClass.row}>
                <RadioItems
                  disable={detailView}
                  items={statusTypes(shaTasks === 0)}
                  onClick={onShaColorChange}
                  value={shaColor}
                />
                <div>
                  {
                    <ConstructionChecklistTask
                      listSize={shaListSize}
                      taskCount={shaTasks}
                      wagon={wagon}
                      projectId={projectId}
                      submitTask={(task) => setNewTasks('sha', task)}
                      disable={detailView}
                    />
                  }
                </div>
              </div>
            }
            errorMessage={getErrorMessage('sha')}
          />
          <InlineCompontent
            label="quality"
            content={
              <div className={styleClass.row}>
                <RadioItems
                  disable={detailView}
                  items={statusTypes(qualityTasks === 0)}
                  onClick={onQualityColorChange}
                  value={qualityColor}
                />
                <div>
                  {
                    <ConstructionChecklistTask
                      listSize={qualityListSize}
                      wagon={wagon}
                      projectId={projectId}
                      taskCount={qualityTasks}
                      submitTask={(task) => setNewTasks('quality', task)}
                      disable={detailView}
                    />
                  }
                </div>
              </div>
            }
            errorMessage={getErrorMessage('quality')}
          />
          <InlineCompontent
            label="neatness"
            content={
              <div className={styleClass.row}>
                <RadioItems
                  disable={detailView}
                  items={statusTypes(neatnessTasks === 0)}
                  onClick={onNeatnessColorChange}
                  value={neatnessColor}
                />
                <div>
                  {
                    <ConstructionChecklistTask
                      listSize={neatnessListSize}
                      wagon={wagon}
                      projectId={projectId}
                      taskCount={neatnessTasks}
                      submitTask={(task) => setNewTasks('neatness', task)}
                      disable={detailView}
                    />
                  }
                </div>
              </div>
            }
            errorMessage={getErrorMessage('neatness')}
          />
          <InlineCompontent
            label="progress"
            content={
              <div className={styleClass.row}>
                <RadioItems
                  disable={detailView}
                  items={statusTypes(progressTasks === 0)}
                  onClick={onProgressColorChange}
                  value={progressColor}
                />
                <div>
                  {
                    <ConstructionChecklistTask
                      listSize={progressListSize}
                      projectId={projectId}
                      wagon={wagon}
                      taskCount={progressTasks}
                      submitTask={(task) => setNewTasks('progress', task)}
                      disable={detailView}
                    />
                  }
                </div>
              </div>
            }
            errorMessage={getErrorMessage('progress')}
          />

          <InlineCompontent
            label="number_of_workers"
            content={
              <div className={styleClass.row}>
                <RadioItems
                  disable={detailView}
                  items={statusTypes(crewTasks === 0)}
                  onClick={onStaffingColorChange}
                  value={staffingColor}
                />
                <div>
                  {
                    <ConstructionChecklistTask
                      listSize={crewListSize}
                      wagon={wagon}
                      projectId={projectId}
                      taskCount={crewTasks}
                      submitTask={(task) => setNewTasks('staffing', task)}
                      disable={detailView}
                    />
                  }
                </div>
              </div>
            }
            errorMessage={getErrorMessage('crew')}
          />
        </InlineComponentsWrapper>
        <div className={`${styleClass.inputGroup} pl-4 px-2`}>
          <Input
            block={true}
            label={t(
              'number_of_workers_in_the_area_or_planned_staffing_is_param',
              {
                param: Math.round(Number(wagon.planned_staffing) * 100) / 100,
              },
            )}
            type={'number'}
            onChange={onChangeNumberOfWorkers}
            value={numberOfWorkers}
            disabled={detailView}
          />
        </div>
        <div className={`${styleClass.inputGroup} pl-4 px-2 pb-2`}>
          <Textarea
            label={t('comment')}
            value={comment}
            isValid={false}
            autoFocus={true}
            onChange={onChangeComment}
            block={true}
            disabled={detailView}
          />
        </div>
        <InspectorSections defaultIndex={0} sections={getRows()} />

        <ModalFooter>
          {!detailView && (
            <>
              <Button type={Button.ButtonType.DEFAULT} onClick={closeModal}>
                {capFirstLetter(t('cancel'))}
              </Button>
              <Button
                type={Button.ButtonType.PRIMARY}
                disabled={isLoading || errorMessages.length > 0}
                onClick={onSubmitForm}
              >
                {isLoading ? <Spinner /> : capFirstLetter(t('add'))}
              </Button>
            </>
          )}
        </ModalFooter>
      </div>
    </FixedPane>
  )
}

export default withProjectContext(ConstructionChecklistForm)
