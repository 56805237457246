import 'moment/dist/locale/nb'
import '@taskctrl/react-calendar-timeline/lib/Timeline.css'
import ArrowDropDown from '@icons/arrow_drop_down.svg'
import ArrowDown from '@icons/keyboard_arrow_down.svg'
import ArrowRight from '@icons/keyboard_arrow_right.svg'
import North from '@icons/north.svg'
import South from '@icons/south.svg'
import Timeline, {
  SidebarHeader,
  TimelineHeaders,
  TodayMarker,
} from '@taskctrl/react-calendar-timeline'
import lodash from 'lodash'
import moment from 'moment'
import { useContext, useEffect, useRef, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import GanttHeaderResize from 'src/components/gantt/GanttHeaderResize'
import GanttInfoRow from 'src/components/gantt/GanttInfoRow'
import GanttTimelineItem, {
  planningTypeKey,
} from 'src/components/gantt/GanttTimelineItem'
import {
  calculateIsOutOfRange,
  renderDependencyLines,
} from 'src/components/hooks/useCanvasDependencyLines'
import MainProcessModalHelper, {
  MainTableModalOrigin,
} from 'src/components/process/main-process/MainProcessModalHelper'
import TimeScale from 'src/components/timeline/TimeScale'
import TimeScaleTimelineHeaders from 'src/components/timeline/TimeScaleTimelineHeaders'
import {
  Dependent,
  ItemWithDependency,
  IGanttItem,
  typeHasDependencies,
  typeIsTopLevel,
} from 'src/components/timeline/types'
import { useTimelineZoomLevels } from 'src/components/timeline/useTimelineZoomLevels'
import UserIcon from 'src/components/user/UserIcon'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import {
  ICanvasLength,
  ISiblingDepType,
} from 'src/page/delivery/DeliveryTimeline'
import { getChildrenGanttTree } from 'src/service/ProjectService'
import { getGanttConfig, setGanttConfig } from 'src/service/TableKeeperService'
import Loader from 'src/ui-elements/loader/Loader'
import Spinner from 'src/ui-elements/loader/Spinner'
import useAlert from 'src/ui-elements/toast/useAlert'
import { capFirstLetter, classNames } from 'src/utility/utils'

const styleClass = {
  rowName: classNames(
    'bg-bg-grey',
    'flex',
    'flex-row',
    'items-center',
    'text-sm',
    'hover:bg-gray-200',
  ),
  dateItem: classNames(
    'sticky px-4 flex flex-col items-center justify-center bg-white',
  ),
  sideHeader: classNames(
    'absolute',
    'flex',
    'flex-row',
    'left-header-border',
    'justify-center',
    'items-center',
    'border-r-2',
    'border-gray-400',
  ),
  gridItem: classNames(
    'flex',
    'flex-1',
    'justify-center',
    'items-center',
    'min-w-19 ',
    'justify-between',
    'text-sm',
  ),
  item: classNames(
    'flex',
    'px-4',
    'overflow-visible',
    'whitespace-nowrap',
    'cursor-pointer',
    'relative',
  ),
  header: classNames('flex', 'w-full', 'flex-row'),
  dateHeader: classNames(
    'text-sm',
    'capitalize',
    'text-gray-500',
    'whitespace-nowrap',
  ),
  top: classNames(
    'flex',
    'justify-center',
    'items-center',
    'w-7',
    'h-7',
    'top-22',
    'right-40',
    'z-30',
    'absolute',
    'rounded-full',
  ),
  topItem: classNames(
    'flex',
    'h-full',
    'w-full',
    'items-center',
    'justify-center',
  ),
}

interface IGanttTimelineProps {
  ganttData: IGanttItem[]
  readonly?: boolean
}

interface IGanttColumns {
  size: number
  active: boolean
  name: string
  value: string
}

type GanttConfig = {
  sideBarSize?: IGanttColumns[]
}

const GanttTimeline = ({ ganttData, readonly }: IGanttTimelineProps) => {
  const { t } = useTranslation()
  const defaultTimeStart = moment().add(-30, 'days').toDate().getTime()
  const defaultTimeEnd = moment().toDate().getTime()
  const [canvasDuration, setCanvasDuration] = useState<ICanvasLength>({
    canvasTimeStart: defaultTimeStart,
    canvasTimeEnd: defaultTimeEnd,
  })
  const defaultActiveColumns = [
    {
      size: 350,
      active: true,
      name: capFirstLetter(t('main_process')),
      value: 'title',
    },
    {
      size: 100,
      active: true,
      name: capFirstLetter(t('discipline')),
      value: 'discipline',
    },
    {
      size: 150,
      active: true,
      name: capFirstLetter(t('responsible')),
      value: 'responsible',
    },
    {
      size: 100,
      active: true,
      name: capFirstLetter(t('deadline')),
      value: 'deadline',
    },
  ]

  const [items, setItems] = useState<IGanttItem[]>([])
  const [groups, setGroups] = useState<IGanttItem[]>([])
  const [showDetailsModal, setShowDetailsModal] = useState<boolean>(false)
  const [selectedSuperItem, setSelectedSuperItem] = useState<{
    data_type: string
    id: number
  }>()
  const [loading, setLoading] = useState<boolean>(true)
  const [openGroups, setOpenGroup] = useState<{ [key: string]: boolean }>({})
  const [showArrows, setShowArrows] = useState<boolean>(false)
  const [showDependencies, setShowDependencies] = useState<boolean>(false)
  const [activeColumns, setActiveColumns] =
    useState<IGanttColumns[]>(defaultActiveColumns)
  const [loadingGroups, setLoadingGroups] = useState<string[]>([])

  const [selectedSiblings, setSelectedSiblings] = useState<ISiblingDepType[]>(
    [],
  )

  const { addAlert } = useAlert()

  const projectContext = useContext(ProjectContext)
  const projectId = projectContext.state.currentProject.id

  const selectedItemRef = useRef<IGanttItem>()
  const groupRef = useRef(groups)
  const openGroupRef = useRef(openGroups)
  const activeColumnsRef = useRef(activeColumns)

  const sortingDirectionRef = useRef('ASC')

  useEffect(() => {
    if (ganttData && ganttData.length > 0) {
      reload(sortingDirectionRef.current)
    }
  }, [projectContext, ganttData])

  useEffect(() => {
    groupRef.current = groups
    activeColumnsRef.current = activeColumns
  }, [groups, activeColumns])

  const reload = async (sortDir: string) => {
    const config = await reviveStoredGantt()
    setActiveColumns(config)
    prepareData(ganttData, sortDir)
  }

  const sortDeadline = (data: IGanttItem[], sorDir: string) => {
    data.sort((x, y) => {
      const dateA = new Date(x.end_time)
      const dateB = new Date(y.end_time)
      return sorDir === 'ASC'
        ? dateA.getTime() - dateB.getTime()
        : dateB.getTime() - dateA.getTime()
    })

    return data
  }

  const prepareData = (ganttData: IGanttItem[], _sortDir: string) => {
    const sortedData = sortData(ganttData)
    const copyOpenGroups = { ...openGroupRef.current }
    sortedData.map((group: IGanttItem) => {
      if (typeIsTopLevel(group.type)) {
        copyOpenGroups[group.id] = false
      }
    })
    setOpenGroup(copyOpenGroups)
    setGroups(sortedData)
    setItems(
      sortedData.filter((item) => item.start_time > 0 && item.end_time > 0),
    )
    setLoading(false)
  }

  const sortData = (_ganttData: IGanttItem[]): IGanttItem[] => {
    const cloneGroup = [..._ganttData]
    let groupIndex = 0
    _ganttData.map((gr) => {
      const children = sortDeadline(gr.children, sortingDirectionRef.current)
      cloneGroup.splice(groupIndex + 1, 0, ...children)
      groupIndex += children.length + 1
    })

    return cloneGroup
  }

  const onSort = () => {
    const dir = sortingDirectionRef.current === 'DESC' ? 'ASC' : 'DESC'
    sortingDirectionRef.current = dir
    prepareData(ganttData, dir)
  }

  const showErrorAlert = (text: string) => {
    addAlert({
      type: 'error',
      title: t('something_went_wrong'),
      description: text,
    })
  }

  const itemRenderer = ({
    item,
    timelineContext,
    itemContext,
    getItemProps,
  }: any) => {
    return (
      <GanttTimelineItem
        getItemProps={getItemProps}
        item={item}
        openModal={openDetailsModal}
        timelineContext={timelineContext}
        itemContext={itemContext}
        showDependentOn={showDependentOn}
      />
    )
  }

  const toggleShowDependencies = () => {
    selectedItemRef.current = undefined
    setShowArrows(!showDependencies)
    setShowDependencies(!showDependencies)
  }

  const fetchAndSortChildren = async (
    groupId: string,
    tmpGroups: IGanttItem[],
  ): Promise<IGanttItem[]> => {
    const group = tmpGroups.find((g) => g.id === groupId)
    if (group === undefined || group.cached || group.root) return []
    const { id, type, item_id: itemId, hierarchy } = group
    const body = { hierarchy: `${hierarchy}/${id}` }
    return getChildrenGanttTree(projectId, type, itemId, body).then((res) => {
      const sortedChildData = sortDeadline(
        res.children,
        sortingDirectionRef.current,
      )
      group.cached = true
      const index = tmpGroups.findIndex((gr) => gr.id === group.id)
      tmpGroups.splice(index, 1, ...[group, ...sortedChildData])
      return sortedChildData
    })
  }

  const expandGroups = async (groupIds: string[]) => {
    if (groups?.length < 0) return
    setLoadingGroups(groupIds)
    const tmpGroups = [...groups]
    const newChildren = await groupIds.reduce(
      async (acc: Promise<IGanttItem[]>, groupId) => {
        const data = await acc
        const children = await fetchAndSortChildren(groupId, tmpGroups)
        return data.concat(children)
      },
      Promise.resolve([]),
    )
    setGroups(tmpGroups)
    toggleGroups(groupIds, groupIds.length > 1)
    const uniqueItems = lodash.uniqBy([...items, ...newChildren], 'id')
    setItems(uniqueItems)
    setLoadingGroups([])
  }

  const toggleGroups = (groupIds: string[], forceOpen?: boolean) => {
    const toggledValues = groupIds.reduce((newValues, group) => {
      return {
        ...newValues,
        [group]: forceOpen || !openGroups[group],
      }
    }, {})
    openGroupRef.current = {
      ...openGroupRef.current,
      ...toggledValues,
    }
    setOpenGroup({
      ...openGroups,
      ...toggledValues,
    })
  }

  const toggleActiveColumn = (value: string) => {
    if (value === 'title') {
      showErrorAlert(t('you_can_not_remove_main_Process'))
      return
    }
    const columnActiveColumn = [...activeColumnsRef.current]
    const columnIndex = columnActiveColumn.findIndex(
      (col) => col.value === value,
    )
    if (columnIndex > -1) {
      const column = columnActiveColumn[columnIndex]
      column.active = !column.active
      columnActiveColumn[columnIndex] = column
      setActiveColumns(columnActiveColumn)
      storeGanttConfig(activeColumnsRef.current)
    }
  }

  const isActive = (value: string): boolean => {
    const columnActiveColumn = [...activeColumnsRef.current]
    const columnIndex = columnActiveColumn.findIndex(
      (col) => col.value === value,
    )
    if (columnIndex > -1) {
      return columnActiveColumn[columnIndex].active
    }
    return false
  }

  const getSize = (value: string): number => {
    const columnActiveColumn = [...activeColumnsRef.current]
    const columnIndex = columnActiveColumn.findIndex(
      (col) => col.value === value,
    )
    if (columnIndex > -1) {
      return columnActiveColumn[columnIndex].size
    }
    return 100
  }

  const openDetailsModal = (data: IGanttItem) => {
    setShowDetailsModal((n) => !n)
    openModal(data)
  }

  const closeModal = () => {
    setShowDetailsModal((n) => !n)
  }

  const openModal = async (data: IGanttItem) => {
    if (data.type) {
      const currentData = { data_type: data.type, id: data.item_id }
      setSelectedSuperItem(currentData)
    }
  }

  const getPadding = (
    type: string,
    isParent?: boolean,
    parents?: string,
  ): number => {
    if (!parents) {
      return type === 'MainProcess' || type === 'Project' ? 5 : 30
    } else {
      const par = parents.split('/')
      const add = !isParent ? 29 : 5
      return par.length * 25 + add
    }
  }

  const checkParentsStats = (parents: string): boolean => {
    const par = parents.split('/')
    let ret = true
    par.map((pa: string) => {
      if (pa !== '') {
        ret = ret && openGroupRef.current[pa]
      }
    })
    return ret
  }

  const renderArrows = (groupId: string, type: string, open?: boolean) => {
    if (loadingGroups.includes(groupId)) return <Spinner />

    if (type === 'Project') return <ArrowDropDown className={'text-xxl'} />
    else if (open) return <ArrowDown className={'text-xl fill-blue-root'} />
    else return <ArrowRight className={'text-xl fill-blue-root'} />
  }

  const getGroups = () => {
    return groups
      .filter((g) => g.root || checkParentsStats(g.hierarchy))
      .map((group) => {
        return Object.assign({}, group, {
          title: (
            <div
              style={{ height: 'inherit', flexGrow: 3 }}
              className={styleClass.rowName}
            >
              <div className={styleClass.header}>
                <div
                  className="truncate"
                  onClick={() => {
                    group.isParent && expandGroups([group.id])
                  }}
                  style={{
                    minWidth: getSize('title'),
                    maxWidth: getSize('title'),
                  }}
                >
                  <span
                    className={`flex truncate ${
                      group.isParent && 'cursor-pointer'
                    } items-center`}
                    style={{
                      paddingLeft: `${getPadding(
                        group.type,
                        group.isParent,
                        group.hierarchy,
                      )}px`,
                    }}
                  >
                    {group.isParent &&
                      renderArrows(group.id, group.type, openGroups[group.id])}
                    <span className="px-0.5">{`${group.record_id} - ${group.title}`}</span>
                  </span>
                </div>
                {isActive('discipline') && (
                  <div
                    className="truncate px-1"
                    style={{
                      minWidth: getSize('discipline'),
                      maxWidth: getSize('discipline'),
                    }}
                  >
                    {group.discipline && (
                      <span>{group.discipline.shortName}</span>
                    )}
                  </div>
                )}
                {isActive('responsible') && (
                  <div
                    className="truncate px-1 flex items-center"
                    style={{
                      minWidth: getSize('responsible'),
                      maxWidth: getSize('responsible'),
                    }}
                  >
                    {group.responsible && (
                      <UserIcon
                        userId={group.responsible.id}
                        firstName={group.responsible.firstName}
                        lastName={group.responsible.lastName}
                      />
                    )}
                  </div>
                )}
                {isActive('deadline') && (
                  <div
                    className="truncate px-1"
                    style={{ minWidth: '105px', maxWidth: '105px' }}
                  >
                    {group.end_time ? moment(group.end_time).format('L') : ''}
                  </div>
                )}
              </div>
            </div>
          ),
        })
      })
  }

  const getSidebarSize = (): string => {
    return `${getSidebarWidth()}px`
  }

  const getSidebarWidth = (): number => {
    return (
      getSize('title') +
      (isActive('responsible') ? getSize('responsible') : 0) +
      (isActive('discipline') ? getSize('discipline') : 0) +
      (isActive('deadline') ? 105 : 0)
    )
  }

  const setSize = (value: string, size: number) => {
    const columnActiveColumn = [...activeColumnsRef.current]
    const columnIndex = columnActiveColumn.findIndex(
      (col) => col.value === value,
    )
    if (columnIndex > -1) {
      const column = columnActiveColumn[columnIndex]
      column.size = size
      columnActiveColumn[columnIndex] = column
      setActiveColumns(columnActiveColumn)
    }
  }

  const storeGanttConfig = (config: IGanttColumns[], noRemote?: boolean) => {
    const ganttConfigStored = localStorage.getItem('ganntSideBar')
    const ganttConfig = { projectId, sideBarSize: config, activeColumns }
    if (ganttConfigStored) {
      const configArray: any[] = JSON.parse(ganttConfigStored)
      const configIndex = configArray.findIndex(
        (con) => con.projectId === projectId,
      )
      configIndex > -1
        ? configArray.splice(configIndex, 1, ganttConfig)
        : configArray.push(ganttConfig)
      localStorage.setItem('ganntSideBar', JSON.stringify(configArray))
    } else {
      localStorage.setItem('ganntSideBar', JSON.stringify([ganttConfig]))
    }
    if (!noRemote) {
      setGanttConfig(projectId, { sideBarSize: config })
    }
  }

  const reviveStoredGantt = (): Promise<IGanttColumns[]> => {
    return new Promise(async (resolve) => {
      const ganttConfigStored = localStorage.getItem('ganntSideBar')
      if (ganttConfigStored) {
        const configArray: any[] = JSON.parse(ganttConfigStored)
        const currentConfig = configArray.find(
          (config) => config.projectId === projectId,
        )
        resolve(
          currentConfig ? currentConfig.sideBarSize : defaultActiveColumns,
        )
      } else if (!readonly) {
        const remoteConfig = await getGanttConfig<GanttConfig>(projectId)
        if (remoteConfig.sideBarSize) {
          storeGanttConfig(remoteConfig.sideBarSize, true)
          resolve(remoteConfig.sideBarSize)
        } else {
          resolve(defaultActiveColumns)
        }
      } else {
        resolve(defaultActiveColumns)
      }
    })
  }

  const getSideBar = () => {
    return (
      <SidebarHeader style={{ width: getSidebarSize() }}>
        {({ getRootProps }: any) => {
          return (
            <div {...getRootProps()}>
              <div
                className={styleClass.sideHeader}
                style={{
                  width: getSidebarSize(),
                  height: '100%',
                  backgroundColor: '#F4F5F7',
                }}
              >
                <div className={styleClass.header}>
                  <div
                    style={{
                      minWidth: getSize('title'),
                      maxWidth: getSize('title'),
                    }}
                    className={styleClass.gridItem}
                  >
                    <span className="pl-6">
                      {capFirstLetter(t('main_process'))}
                    </span>
                    <GanttHeaderResize
                      size={getSize('title')}
                      updateSize={(size) => setSize('title', size)}
                    />
                  </div>
                  {isActive('discipline') && (
                    <div
                      style={{
                        minWidth: getSize('discipline'),
                        maxWidth: getSize('discipline'),
                      }}
                      className={styleClass.gridItem}
                    >
                      <span className="pl-4">
                        {capFirstLetter(t('discipline'))}
                      </span>
                      <GanttHeaderResize
                        size={getSize('discipline')}
                        updateSize={(size) => setSize('discipline', size)}
                      />
                    </div>
                  )}
                  {isActive('responsible') && (
                    <div
                      style={{
                        minWidth: getSize('responsible'),
                        maxWidth: getSize('responsible'),
                      }}
                      className={styleClass.gridItem}
                    >
                      <span className="pl-4">
                        {capFirstLetter(t('responsible'))}
                      </span>
                      <GanttHeaderResize
                        size={getSize('responsible')}
                        updateSize={(size) => setSize('responsible', size)}
                      />
                    </div>
                  )}
                  {isActive('deadline') && (
                    <div
                      style={{ minWidth: '105px', maxWidth: '105px' }}
                      className={styleClass.gridItem}
                    >
                      <span className="pl-4">
                        {capFirstLetter(t('deadline'))} &nbsp;
                      </span>
                      <div className="pr-2">
                        <span
                          onClick={onSort}
                          className={
                            'text-base hover:fill-blue-root cursor-pointer'
                          }
                        >
                          {sortingDirectionRef.current === 'ASC' ? (
                            <North />
                          ) : (
                            <South />
                          )}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        }}
      </SidebarHeader>
    )
  }

  const onTimeChange = (
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: (start: number, end: number) => void,
  ) => {
    setCanvasDuration({
      canvasTimeStart: visibleTimeStart,
      canvasTimeEnd: visibleTimeEnd,
    })
    updateScrollCanvas(visibleTimeStart, visibleTimeEnd)
  }

  const [zoom, setZoom] = useState<'month' | 'week' | 'quarter' | 'project'>(
    'month',
  )

  const projectItem = useMemo((): IGanttItem => {
    // Dirty but very efficient optimization
    if (items.length > 0 && items[0].type === 'Project') return items[0]
    return items.find((i) => i.type === 'Project') as IGanttItem
  }, [items])

  const { zoomLevel } = useTimelineZoomLevels(zoom)

  const sidebarWidth = getSidebarWidth()

  const [key, setKey] = useState(true)
  const forceReRender = () => {
    setKey((k) => !k)
  }

  useEffect(() => {
    forceReRender()
  }, [sidebarWidth])

  const resetTimePosition = () => {
    forceReRender()
  }

  const toggleParentGroup = (typeStr: string, id?: number) => {
    if (groups?.length < 0 || !id) return
    const groupId = `${typeStr}${id}`
    return groupId
  }

  useEffect(() => {
    const toggleParents = () => {
      const groups: string[] = selectedSiblings
        .map((dependency: ISiblingDepType) => {
          return [
            toggleParentGroup('P', dependency.main_process_id),
            toggleParentGroup('M', dependency.mile_stone_id),
            toggleParentGroup('K', dependency.key_point_id),
          ]
        })
        .flat()
        .filter((group) => group !== undefined) as string[]
      expandGroups(groups)
      setShowArrows(true)
    }
    showDependencies && toggleParents()
  }, [showDependencies])

  const showDependentOn = (item: IGanttItem) => {
    selectedItemRef.current = item
    if (!item.type || !typeHasDependencies(item.type)) {
      return
    }
    const pastDeps: ISiblingDepType[] = item.dependent.map(
      (dep: Dependent) => ({
        ...dep,
        type: 'future',
        domain: item.type as ItemWithDependency,
      }),
    )
    const futureDeps: ISiblingDepType[] = item.dependent_on.map(
      (dep: Dependent) => ({
        ...dep,
        type: 'past',
        domain: item.type as ItemWithDependency,
      }),
    )
    const allSiblings = [...pastDeps, ...futureDeps]
    setSelectedSiblings(allSiblings)
    setShowDependencies(true)
  }

  const dependencyLines = () => {
    return selectedSiblings.map((dependency: ISiblingDepType) => {
      const isOutOfRange = calculateIsOutOfRange(
        dependency.type,
        dependency.endTime,
        { end_time: projectItem.start_time },
        projectItem,
        canvasDuration,
        selectedItemRef,
      )

      if (selectedItemRef.current && !isOutOfRange)
        return renderDependencyLines(
          selectedItemRef.current.id,
          `${planningTypeKey[dependency.domain ?? '']}${dependency.id}`,
          dependency.id,
          dependency.type,
        )
      return <></>
    })
  }

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <GanttInfoRow
            columns={activeColumns.filter((c) => c.value !== 'title')}
            toggleColumn={toggleActiveColumn}
            resetTimePosition={resetTimePosition}
            clearDependency={toggleShowDependencies}
            toggleShowArrows={() => setShowArrows((n) => !n)}
            showDependencies={showDependencies}
            showArrows={showArrows}
          />
          <TimeScale
            onScaleClick={(scale) => setZoom(scale)}
            activeScale={zoom}
          />
          <Timeline
            key={key}
            groups={getGroups()}
            items={items}
            itemsSorted={false}
            itemTouchSendsClick={false}
            stackItems={true}
            sidebarWidth={sidebarWidth}
            itemHeightRatio={0.99}
            showCursorLine={true}
            lineHeight={33}
            canMove={false}
            canResize={false}
            zoom={zoomLevel}
            defaultTimeStart={moment(canvasDuration.canvasTimeStart)}
            defaultTimeEnd={moment(canvasDuration.canvasTimeEnd)}
            itemRenderer={itemRenderer}
            buffer={7}
            onTimeChange={onTimeChange}
          >
            {showArrows && showDependencies && !loadingGroups?.length ? (
              dependencyLines()
            ) : (
              <></>
            )}
            <TodayMarker>
              {({ styles }: any) => {
                const newStyles = {
                  ...styles,
                  backgroundColor: '#FD7171',
                  width: '6px',
                }
                return <div className="z-20" style={newStyles} />
              }}
            </TodayMarker>
            <TimelineHeaders
              className="sticky"
              style={{ display: 'flex', width: '100%', top: 0 }}
            >
              {getSideBar()}
              <TimeScaleTimelineHeaders zoom={zoom} zoomLevel={zoomLevel} />
            </TimelineHeaders>
          </Timeline>
        </>
      )}
      {showDetailsModal ? (
        <MainProcessModalHelper
          readonly={readonly}
          closeModal={closeModal}
          showModal={showDetailsModal}
          projectId={projectId}
          origin={MainTableModalOrigin.GANTT}
          superItem={selectedSuperItem}
        />
      ) : null}
    </>
  )
}

export default GanttTimeline
