import { useContext } from 'react'
import { StoreApi, useStore } from 'zustand'
import { ExpandableTableContext } from '../context/ExpandableTableContext'
import { IExpandableTableInteractionStore } from '../stores/ExpandableTableInteractionStore'

export const useExpandableTableInteractionStoreState = <U,>(
  selector: (state: IExpandableTableInteractionStore) => U,
  equalityFn?: (a: U, b: U) => boolean,
) => {
  const { interactionStore } = useContext(ExpandableTableContext)

  return useStore<StoreApi<IExpandableTableInteractionStore>, U>(
    interactionStore,
    selector,
    equalityFn,
  )
}

export const useExpandableTableInteractionStore = () => {
  const { interactionStore } = useContext(ExpandableTableContext)

  return interactionStore
}
