import moment, { Moment } from 'moment'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { IMilestone } from 'src/service/OrgTypes'
import {
  StructureValidationError,
  getErrorMessage,
  ValidationError,
  getMileStoneErrorMessage,
} from 'src/service/ValidationErrors'
import useAlert from 'src/ui-elements/toast/useAlert'

export const useMilestoneUpdateValidation = () => {
  const { addAlert } = useAlert()
  const { t } = useTranslation()
  const projectContext = useContext(ProjectContext)

  const showAlert = (text: string) => {
    addAlert({
      type: 'warning',
      title: t('something_went_wrong'),
      description: text,
    })
  }

  const validateDate = (milestone: IMilestone, date?: Moment): string => {
    if (!date || !moment(date).isValid()) {
      return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
    }
    const backInTime = moment(date).isBefore(milestone.deadline, 'day')
    if (backInTime) return ''
    const projectEndDate = projectContext.state.currentProject.endDate
    if (projectEndDate.isBefore(date, 'day')) {
      showAlert(
        getMileStoneErrorMessage(
          StructureValidationError.PAST_PARENT_DEADLINE,
          t,
        ) +
          ' (' +
          projectEndDate.format('L') +
          ')',
      )
      return 'past_project_deadline'
    }
    return ''
  }

  const validateStatus = (data: { open_children?: number }, status: string) => {
    if (status !== 'done') return true
    if (data?.open_children && data.open_children > 0) {
      showAlert(
        getMileStoneErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return false
    }
    return true
  }

  return {
    validateDate,
    validateStatus,
  }
}
