import { createContext } from 'react'
import { IUser, Module } from 'src/service/OrgTypes'

export interface IUserContext {
  actions: {
    reload: () => void
    hasReadAccess: (module: Module) => boolean
    hasWriteAccess: (module: Module) => boolean
    hasAdminAccess: (module: Module) => boolean
    hasModuleAdminAccess: (module: Module) => boolean
  }
  state: IUser
}

export const initialState: IUserContext = {
  actions: {
    reload: () => null,
    hasReadAccess: () => true,
    hasWriteAccess: () => false,
    hasAdminAccess: () => false,
    hasModuleAdminAccess: () => false,
  },
  state: {} as IUser,
}

export type UserContext = IUserContext

export const UserContext = createContext<UserContext>(initialState)
