import * as React from 'react'
import { useContext, useRef, useState } from 'react'
import CheckBox from 'src/components/switchHoc/CheckBox'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { classNames } from 'src/utility/utils'
import { ExpandableTableContext } from '../../context/ExpandableTableContext'
import {
  useExpandableTableInteractionStore,
  useExpandableTableInteractionStoreState,
} from '../../hooks/useExpandableTableInteractionStore'
import {
  useExpandableTableStore,
  useExpandableTableStoreState,
} from '../../hooks/useExpandableTableStore'
import { IExpandableSection } from '../../types/IExpandableSection'
import { IRow } from '../../types/IRow'
import ExpandableCell from '../expandable-cell/ExpandableCell'

interface IDraggableCell {
  getRowIndex: () => number
  colIndex: number
  rowId: IRow['id']
  section: IExpandableSection
  onRowSelect?: () => void
  initialItem?: Record<string, unknown>
}

/**
 * This component contains much of the same logic as Cell, but for performance they are split up in two components to ensure that we don't
 * render unessecary logic in the majority of the cells (The non-draggable ones).
 */

const DraggableCell: React.FC<IDraggableCell> = ({
  getRowIndex,
  colIndex,
  rowId,
  section,
  onRowSelect,
  initialItem,
}) => {
  const ref = useRef<HTMLTableCellElement>(null)
  const childRef = useRef<HTMLDivElement>(null)
  const [isEditingCell, setIsEditingCell] = useState(false)

  const header = useExpandableTableStoreState(
    (state) => state.headers[colIndex],
  )

  const isLastElement = useExpandableTableStoreState((state) => {
    const sectionRows = state.expandableSectionsConfig[section.id]?.rows
    return sectionRows?.indexOf(rowId) === sectionRows.length - 1
  })

  const readonly = useExpandableTableStoreState((state) => state.readonly)
  const isRowSelectable = useExpandableTableStoreState(
    (state) => state.isRowSelectable,
  )
  const isSelected = useExpandableTableStoreState((state) =>
    state.selectedIds.includes(
      typeof rowId === 'number' ? rowId : parseInt(rowId),
    ),
  )

  const getHeaderWidth = useExpandableTableInteractionStoreState(
    (state) => state.getHeaderWidth,
  )

  useExpandableTableInteractionStoreState((state) => state.widthPerCol)

  const interactionStore = useExpandableTableInteractionStore()
  const store = useExpandableTableStore()

  const startEditing = () => {
    setIsEditingCell(true)
  }

  const { canReorderRows, reorderRowsHandler } = useContext(
    ExpandableTableContext,
  )

  const draggingRow = useExpandableTableInteractionStoreState(
    (state) => state.draggingRow,
  )

  return (
    <div
      ref={ref}
      onMouseEnter={() => {
        !readonly &&
          interactionStore.setState({ hoveringRow: { rowId, section } })
      }}
      onMouseLeave={() => interactionStore.setState({ hoveringRow: undefined })}
      onClick={(e) => {
        if (readonly) return
        interactionStore.setState({
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          selectedRowIndex: e.target.parentElement.parentElement.rowIndex,
          selectedColumn: header.id,
        })
        if (ref.current === document.activeElement) {
          startEditing()
        } else if (!isEditingCell) {
          ;(e.target as HTMLTableCellElement).focus()
          startEditing()
        }
      }}
      className={classNames(
        'border-l border-t',
        isLastElement && 'border-b',
        'sticky-without-z-index left-0',
        'focus:ring-0',
        'focus:outline-none',
        'text-sm',
        'pl-1',
        'align-text-top',
        'h-full',
        'min-h-7',
      )}
      style={{ width: getHeaderWidth(header.id) }}
    >
      <div
        draggable={!readonly}
        onDragStart={(e) => {
          e.stopPropagation()
          reorderRowsHandler.setDraggingRow({ section, rowId })
          reorderRowsHandler.setHoveringRow({ section, rowId })
        }}
        onDragEnd={(e) => {
          e.nativeEvent.preventDefault()
          e.stopPropagation()
          reorderRowsHandler.onDraggingEnd()
        }}
        onDragOver={(e) => {
          e.stopPropagation()
          e.dataTransfer.dropEffect = 'move'
          e.nativeEvent.preventDefault()
        }}
        onDragEnter={(e) => {
          e.stopPropagation()
          reorderRowsHandler.setHoveringRow({ section, rowId })
        }}
        className={`${
          draggingRow?.rowId === rowId ? 'bg-white shadow-lg cursor-move' : ''
        } flex w-full h-full gap-1 items-center`}
      >
        {isRowSelectable && (
          <div className="z-10 flex border-gray ">
            <CheckBox
              valueProp={isSelected}
              onChange={() => {
                if (onRowSelect) {
                  onRowSelect()
                }
              }}
              disableTab
            />
          </div>
        )}
        {canReorderRows && !readonly && (
          <div>
            <Icon
              icon={Icons.DRAG_NEW}
              className="cursor-grab min-w-2 mx-1"
              noPointerEvent
            />
          </div>
        )}
        <div className="max-w-[90%] w-full">
          <ExpandableCell
            isEditing={isEditingCell}
            displayRef={childRef}
            finishEditing={(newValue) => {
              setIsEditingCell(false)
              ref.current?.focus()
              store.getState().updateCell({ newValue, header, rowId })
            }}
            colIndex={colIndex}
            section={section}
            rowId={rowId}
            getRowIndex={getRowIndex}
            initialItem={initialItem}
          />
        </div>
      </div>
    </div>
  )
}

export default DraggableCell
