import Close from '@icons/close.svg'
import Delete from '@icons/delete.svg'
import Preview from '@icons/preview.svg'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import DraftChangesTableModal from 'src/components/DraftModeBanner/DraftChangesTableModal'
import useDiscardDraftMode from 'src/hooks/useDiscardDraftMode'
import useDraftMode from 'src/hooks/useDraftMode'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'

const DraftModeBanner = () => {
  const { draftMode, setDraftMode } = useDraftMode()
  const { t } = useTranslation()
  const [showDraftChanges, setShowDraftChanges] = useState(false)

  const queryClient = useQueryClient()
  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['construction-wagon-canvas'] })
  }

  const discardDraft = useDiscardDraftMode(reload)

  return (
    <>
      <div
        className={twMerge(
          'bg-[#374151] h-10 w-full text-white ',
          !draftMode && 'hidden',
        )}
      >
        <div className="flex justify-between items-center w-full h-10 relative">
          <div className="flex justify-center items-center gap-2 flex-grow">
            <div>{t('editing_mode')}</div>
            <Button
              toolTipText={t('discard_drafts')}
              type={ButtonType.PRIMARY}
              className={'h-6 m-0 bg-red-danger'}
              onClick={discardDraft}
            >
              <Delete className="text-xl fill-white" />
            </Button>
            <Button
              toolTipText={t('view_and_save_drafts')}
              type={ButtonType.PRIMARY}
              onClick={() => setShowDraftChanges(true)}
              className={'h-6 m-0 bg-green'}
            >
              <Preview className="text-xl fill-white" />
            </Button>
          </div>
          <div
            className="underline text-xl mr-2 text-dark-grey cursor-pointer"
            onClick={() => setDraftMode(false)}
          >
            <Close fontSize={16} className="text-xl fill-dark-grey" />
          </div>
        </div>
      </div>
      {showDraftChanges && (
        <DraftChangesTableModal
          closeModal={() => setShowDraftChanges(false)}
          show={showDraftChanges}
        />
      )}
    </>
  )
}

export default DraftModeBanner
