import { createColumnHelper } from '@tanstack/react-table'
import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SystemImportDetail from 'src/components/system/system-import-history/SystemImportDetail'
import { useSystemImportHistory } from 'src/query/systems/import'
import { ISystemImportHistory } from 'src/service/OrgTypes'
import { getSystemsWithPage } from 'src/service/SystemService'
import {
  defaultDateColumnObject,
  defaultUserColumn,
  defaultNumberColumn,
  defaultLinkColumn,
} from 'src/ui-elements/Table/Columns'
import Table from 'src/ui-elements/Table/Table'
import Button from 'src/ui-elements/button/Button'
import useListHelper from 'src/ui-elements/list/UseListHelper'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

interface ISystemImportHistoryListProps {
  systemSettingId: number
  closeModal: () => void
}

const SystemImportHistoryList: FC<ISystemImportHistoryListProps> = ({
  systemSettingId,
  closeModal,
}) => {
  const [showDetailModal, setShowDetailModal] = useState(false)
  const [systemsImportHistoryId, setSystemsImportHistoryId] = useState(0)

  const { t } = useTranslation()
  const { getUserFilter } = useListHelper()

  const closeDetailModal = () => {
    setShowDetailModal(false)
  }

  const onView = (row: ISystemImportHistory) => {
    setSystemsImportHistoryId(row.id)
    setShowDetailModal(true)
  }

  const columnHelper = createColumnHelper<ISystemImportHistory>()

  const columns = [
    columnHelper.accessor(
      'created_at',
      defaultDateColumnObject('created_at', { name: 'date' }),
    ),
    columnHelper.accessor(
      'imported_by',
      defaultUserColumn('imported_by', {
        name: 'author',
        getFilter: getUserFilter,
      }),
    ),
    columnHelper.accessor(
      'number_of_new_systems',
      defaultNumberColumn('number_of_new_systems', { name: t('new') }),
    ),
    columnHelper.accessor(
      'number_of_updated_systems',
      defaultNumberColumn('number_of_updated_systems', { name: t('updated') }),
    ),
    columnHelper.accessor(
      'total_systems',
      defaultNumberColumn('total_systems', { name: t('total') }),
    ),
    columnHelper.accessor(
      'import_file',
      defaultLinkColumn('import_file', { name: t('import_file') }),
    ),
  ]

  const useData = (filterProps: {
    sort: Parameters<typeof getSystemsWithPage>[3]['sort']
    filters: Omit<Parameters<typeof getSystemsWithPage>[3], 'sort'>
    page: number
    pageSize: number
    enabled?: boolean
  }) => useSystemImportHistory(systemSettingId, filterProps)

  return (
    <Modal
      show={true}
      title={t('system_import_history_list')}
      closeModal={closeModal}
      maxWidth={1340}
      size={'w-9/10'}
      minHeight={'400px'}
      notClipContent
    >
      <Table
        name={'systemImportHistoryList'}
        columns={columns}
        useDataQuery={useData}
        onRowClick={onView}
        selectable={false}
      />
      {showDetailModal && systemsImportHistoryId && (
        <SystemImportDetail
          show={showDetailModal}
          closeModal={closeDetailModal}
          systemImportId={systemsImportHistoryId}
        />
      )}
      <ModalFooter>
        <Button type={Button.ButtonType.PRIMARY} onClick={closeModal}>
          {t('done')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default SystemImportHistoryList
