import * as React from 'react'
import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import EnumMetaDataOptions from 'src/document/components/Folders/CreateFolderModal/EnumMetadataOptions'
import { IMetaData } from 'src/document/types/IMetaData'
import Input from '../../../../ui-elements/input/Input'
import { metaDataTypes } from '../../../types/FolderMetadataTypes'
import FolderMetadataField from '../FolderMetadataField/FolderMetadataField'
import RemoveIcon from '../icons/RemoveIcon'
import FolderMetadataDefault from './FolderMetadataDefault'

interface IFolderMetadataRowProps {
  field: IMetaData
  updateMetadata: (id: string, fieldUpdate: Partial<IMetaData>) => void
  onRemove?: () => void
  metaFieldMenuOnTheLeft?: boolean
  disabled?: boolean
}

export interface Option {
  label: string
  value: string
  id: string
}

const FolderMetadataRow: React.FC<IFolderMetadataRowProps> = ({
  field,
  updateMetadata,
  onRemove,
  metaFieldMenuOnTheLeft,
  disabled,
}) => {
  const { t } = useTranslation()
  const [enumError, setEnumError] = useState('')
  const [enumValue, setEnumValue] = useState<Option[]>([])

  useEffect(() => {
    if (
      (field.data_type === 'enum' || field.data_type === 'multi_enum') &&
      Array.isArray(field.pattern)
    ) {
      setEnumValue(field.pattern.map((value) => createOption(value)))
    }
  }, [field])

  const updateEnumValues = (newValue: Option[]) => {
    setEnumValue(newValue)
    updateMetadata(field.id, {
      pattern: newValue.map((value: Option) => value.value),
    })
  }

  const addEnumValue = (value: string) => {
    if (
      value.length === 0 ||
      !enumValue.find(
        (v) => v.value.toLowerCase() === value.trim().toLowerCase(),
      )
    ) {
      const newValue = [...enumValue, createOption(value.trim())]
      updateEnumValues(newValue)
      setEnumError('')
    } else {
      setEnumError(t('duplicate_value_not_allowed'))
    }
  }

  const removeEnumValue = (optionToDelete: Option) => {
    const newValue = enumValue.filter(
      (option) => option.id !== optionToDelete.id,
    )
    updateEnumValues(newValue)
  }

  const bulkRemoveEnumValues = (ids: string[]) => {
    const newValue = enumValue.filter((option) => !ids.includes(option.id))
    updateEnumValues(newValue)
  }

  const createOption = (label: string) => ({
    label,
    value: label,
    id: label,
  })

  const getPattern = (dataType: string, newPattern: string | string[]) => {
    if (
      (field.data_type === 'enum' || field.data_type === 'multi_enum') &&
      (dataType === 'enum' || dataType === 'multi_enum')
    ) {
      return field.pattern
    } else {
      return newPattern
    }
  }

  const getDefaultValueList = (dataType: string) => {
    if (
      field.data_type === 'enum' &&
      dataType === 'multi_enum' &&
      field.default_value
    ) {
      return [field.default_value?.toString()]
    } else {
      return []
    }
  }

  const getDefaultValue = (dataType: string) => {
    if (
      field.data_type === 'multi_enum' &&
      dataType === 'enum' &&
      field.default_value_list
    ) {
      return field.default_value_list.pop()
    } else {
      return null
    }
  }

  return (
    <>
      {onRemove && (
        <div className={`flex justify-end pt-2 `}>
          <RemoveIcon disabled={disabled} onClick={onRemove} />
        </div>
      )}
      <div className={`flex`}>
        <Input
          label={t('field_name')}
          placeholder={''}
          type={'text'}
          block={true}
          value={field.name}
          required={true}
          onChange={(e) =>
            updateMetadata(field.id, { ...field, name: e.target.value })
          }
          disabled={disabled}
        />
        <div className={'w-1/4 mr-2 h-9 pb-0.5'}>
          <label
            className={'block text-sm leading-5 font-medium text-gray-700 my-2'}
          >
            {t('data_type')} *
          </label>
          <FolderMetadataField
            possibleMetadataTypes={metaDataTypes}
            disabled={disabled}
            onTypeChange={(newMetadataType) => {
              updateMetadata(field.id, {
                ...newMetadataType,
                default_value_list: getDefaultValueList(
                  newMetadataType.data_type,
                ),
                default_value: getDefaultValue(newMetadataType.data_type),
                pattern: getPattern(
                  newMetadataType.data_type,
                  newMetadataType.pattern,
                ),
              })
            }}
            value={field}
            menuOnTheLeft={metaFieldMenuOnTheLeft}
          />
        </div>
      </div>

      <div className={`mx-2 mt-1`}>
        {(field.data_type === 'enum' || field.data_type === 'multi_enum') && (
          <>
            <label
              className={'text-sm leading-5 mb-1 font-medium text-gray-700'}
            >
              {t('options')}
            </label>
            {enumError && (
              <p className={'text-red-600 ml-2 text-sm font-normal'}>
                {enumError}
              </p>
            )}
            <EnumMetaDataOptions
              options={enumValue}
              addEnumValue={disabled ? undefined : addEnumValue}
              removeEnumValue={disabled ? undefined : removeEnumValue}
              bulkRemoveEnumValues={disabled ? undefined : bulkRemoveEnumValues}
            />
          </>
        )}
      </div>
      <FolderMetadataDefault
        field={field}
        updateMetadata={updateMetadata}
        enumValues={enumValue}
      />
    </>
  )
}

export default FolderMetadataRow
