import * as React from 'react'
import { useMemo, useContext, useRef } from 'react'
import { classNames } from '../../../../../utility/utils'
import { ExpandableTableContext } from '../../context/ExpandableTableContext'
import { useExpandableTableInteractionStoreState } from '../../hooks/useExpandableTableInteractionStore'
import { useExpandableTableStoreState } from '../../hooks/useExpandableTableStore'
import { IExpandableSection } from '../../types/IExpandableSection'
import AddRow from '../add-row/AddRow'
import Cell from '../cell/Cell'
import DraggableCell from '../cell/DraggableCell'
import DeleteRow from './DeleteRow'

interface RowProps {
  rowId: number
  section: IExpandableSection
  rowIndex: number
  onRowSelect?: () => void
  initialItem?: Record<string, unknown>
}

const Row: React.FC<RowProps> = ({
  rowId,
  section,
  rowIndex,
  onRowSelect,
  initialItem,
}) => {
  const { interactionStore } = useContext(ExpandableTableContext)
  useExpandableTableInteractionStoreState((state) => state.widthPerCol)
  const ref = useRef<HTMLTableRowElement>(null)

  const headers = useExpandableTableStoreState((state) => state.headers)

  const readonly = useExpandableTableStoreState((state) => state.readonly)

  const rowIsHovered = useExpandableTableInteractionStoreState(
    (state) => state.hoveringRow?.rowId === rowId,
  )

  const sectionIsDraggedOver = useExpandableTableInteractionStoreState(
    (state) =>
      state.dragTargetSection?.id === section.id &&
      state.draggingSection &&
      !state.draggingRow,
  )

  const trClass = classNames(
    rowIsHovered || sectionIsDraggedOver
      ? 'bg-gray-100 rounded-sm'
      : 'bg-white',
    'flex',
    'items-center',
  )

  const row = useMemo(() => {
    const getRowIndex = () => {
      return rowIndex ?? 0
    }

    if (!headers.length) return []

    return (
      <div className={'flex'}>
        {headers.map((_, i) => {
          if (i === 0 && !readonly) {
            return (
              <div key={`${rowId}-${i}}`}>
                <DraggableCell
                  colIndex={0}
                  getRowIndex={getRowIndex}
                  rowId={rowId}
                  section={section}
                  onRowSelect={onRowSelect}
                  initialItem={initialItem}
                />
              </div>
            )
          }
          return (
            <Cell
              key={`${rowId}-${i}`}
              colIndex={i}
              getRowIndex={getRowIndex}
              rowId={rowId}
              section={section}
              onRowSelect={onRowSelect}
              initialItem={initialItem}
            />
          )
        })}
      </div>
    )
  }, [headers, onRowSelect, rowId, rowIndex, section])

  const onMouseEnter = () => {
    if (!readonly) {
      interactionStore.setState({ hoveringRow: { rowId, section } })
    }
  }
  const onMouseLeave = () => {
    if (!readonly) {
      interactionStore.setState({ hoveringRow: undefined })
    }
  }

  return (
    <div
      className={'flex'}
      data-rowid={rowId}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div ref={ref} className={trClass}>
        {row}
        <DeleteRow rowId={rowId} section={section} />
        <AddRow
          rowId={rowId}
          rowIndex={rowIndex}
          section={section}
          readonly={readonly}
          initialItem={initialItem}
        />
      </div>
    </div>
  )
}

export default Row
