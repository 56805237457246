import { KeyboardEventHandler, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { createFilter } from 'react-select'
import CreatableSelect from 'react-select/creatable'

const components = {
  DropdownIndicator: null,
}

interface Option {
  readonly label: string
  readonly value: string
}

const createOption = (label: string) => ({
  label,
  value: label,
})

export const EnumPattern = ({
  pattern,
  onChange,
}: {
  pattern?: string[]
  onChange: (pattern: string[]) => void
}) => {
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<readonly Option[]>([])
  const [errorMessage, setErrorMessage] = useState<string>()

  useEffect(() => {
    if (!pattern) return
    setOptions(pattern?.map((item) => createOption(item)))
  }, [pattern])

  const handleChange = (updatedOptions: Option[]) => {
    setOptions(updatedOptions)
    setInputValue('')
    setErrorMessage('')
    onChange(updatedOptions.map((option) => option.value))
  }

  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (!inputValue) return
    if (e.key === 'Enter' && !e.shiftKey) {
      if (!!options?.find((option) => option.value === inputValue)) {
        setErrorMessage(t('duplicate_value_not_allowed'))
      } else {
        const updatedValues = [...options, createOption(inputValue)]
        handleChange(updatedValues)
        e.preventDefault()
      }
    }
  }

  return (
    <div className={'w-full'}>
      {errorMessage && (
        <p className={'text-red text-xs first-capitalize'}>{errorMessage}</p>
      )}
      <CreatableSelect
        tabIndex={0}
        isClearable
        isMulti
        inputValue={inputValue}
        value={options}
        menuIsOpen={false}
        components={components}
        onKeyDown={handleKeyDown}
        filterOption={createFilter({ ignoreAccents: false })}
        onChange={handleChange}
        onInputChange={setInputValue}
        placeholder={t('enum_place_holder')}
      />
    </div>
  )
}
