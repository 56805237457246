import { useQuery, keepPreviousData } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import useDraftMode from 'src/hooks/useDraftMode'
import {
  getCanvasConstructionWagons,
  getConstructionWagon,
} from 'src/service/ConstructionWagonService'
import {
  getPaginatedProjectControlAreas,
  getProjectControlAreaGroups,
  getControlAreaGroupsControlAreas,
} from 'src/service/ControlAreaService'
import { IConstructionTask } from 'src/service/OrgTypes'
import { request } from 'src/service/service-utils'
import { getCurrentUser } from 'src/utility/utils'

export const useControlAreas = ({
  filters,
  draftMode,
}: {
  filters: Parameters<typeof getPaginatedProjectControlAreas>[2]
  draftMode?: boolean
}) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: ['control-area', projectId, { filters, draftMode }],
    queryFn: () =>
      getPaginatedProjectControlAreas(projectId, 0, filters, 0, draftMode),
    placeholderData: keepPreviousData,
  })
}

export const useControlAreaGroups = () => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['control-area-groups', projectId],
    queryFn: () => getProjectControlAreaGroups(projectId),
    placeholderData: keepPreviousData,
  })
}

export const useControlAreaGroupControlAreas = (
  controlAreaGroups: number[],
) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: ['control-area-groups', projectId, { controlAreaGroups }],
    queryFn: () =>
      getControlAreaGroupsControlAreas(projectId, {
        control_area_group: controlAreaGroups,
      }),
    placeholderData: keepPreviousData,
  })
}

export const useConstructionWagonsCanvas = ({
  filters,
  draftMode,
  options,
}: {
  filters: Parameters<typeof getCanvasConstructionWagons>[1]
  draftMode?: boolean
  options?: { enabled: boolean }
}) => {
  const projectId = useProjectId()

  return useQuery({
    ...options,
    queryKey: ['construction-wagon-canvas', projectId, { filters, draftMode }],
    queryFn: () => getCanvasConstructionWagons(projectId, filters, draftMode),
    placeholderData: keepPreviousData,
  })
}

export const useConstructionWagon = (id: number) => {
  const projectId = useProjectId()
  const { draftMode } = useDraftMode()

  return useQuery({
    queryKey: ['construction-wagon', projectId, id, draftMode],
    queryFn: () => getConstructionWagon(id, draftMode),
  })
}

export const useUpcomingConstructionTasks = () => {
  const projectId = useProjectId()
  const user = getCurrentUser()

  return useQuery({
    queryKey: [
      'upcoming-construction-tasks',
      projectId,
      user?.disciplines?.map((discipline) => discipline.id),
    ],
    queryFn: () =>
      request<{
        this_weeks_construction_tasks: IConstructionTask[]
        next_weeks_construction_tasks: IConstructionTask[]
      }>(`/projects/${projectId}/construction_tasks/upcoming`, 'GET'),
  })
}
