import { IHeader } from '../types/IHeader'
import { IRow } from '../types/IRow'

interface ITableConfig {
  headers: Record<string, number>
  rows: Record<string, number>
}

class TableColumnWidthRowHeightHandler {
  readonly initializeTable = (
    headers: IHeader[],
    rows: Record<string, IRow>,
    readonly: boolean,
    tableName: string,
    defaultWidth = 40,
    defaultHeight = 10,
  ): ITableConfig => {
    const storedTable = readonly
      ? undefined
      : window.localStorage.getItem(tableName)

    const tableConfig = JSON.parse(
      storedTable ?? '{"rows": {}, "headers": {}}',
    ) as ITableConfig

    const widthPerCol = tableConfig.headers
    for (const header of headers ?? []) {
      widthPerCol[header.id] =
        widthPerCol[header.id] ?? header.initialWidth ?? defaultWidth
    }

    const heightPerRow = tableConfig.rows

    for (const row of Object.values(rows)) {
      heightPerRow[row.id] = heightPerRow[row.id] ?? defaultHeight
    }

    return { headers: widthPerCol, rows: heightPerRow }
  }

  readonly saveTable = (config: ITableConfig, tableName: string) => {
    window.localStorage.setItem(tableName, JSON.stringify(config))
  }
}

export default new TableColumnWidthRowHeightHandler()
