import {
  IFilteredRisksResponse,
  IMatrixCellConfig,
  IMatrixData,
  IRisk,
  IRiskArea,
  IRiskAssessmentData,
  IRiskData,
  IRiskGroup,
  IRiskPossibility,
  IRiskProbability,
  IRiskThreat,
} from 'src/service/OrgTypes'
import { request } from 'src/service/service-utils'

export const getProjectRiskGroups = (
  projectId: number,
): Promise<IRiskGroup[]> => {
  return request(`projects/${projectId}/risk_groups`, 'GET')
}

export const getRisksForRiskGroup = (riskGroupId: number): Promise<IRisk[]> => {
  return request(`risk_groups/${riskGroupId}/risks`, 'GET')
}

export const getFilteredRiskGroupRisks = (
  riskGroupId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
): Promise<IFilteredRisksResponse> => {
  return request(
    `risk_groups/${riskGroupId}/risks_filtered/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getFilteredRiskGroupRisksNoPage = (
  riskGroupId: number,
  filter: any,
): Promise<IRisk[]> => {
  return request(`risk_groups/${riskGroupId}/risks_filtered`, 'POST', filter)
}

export const deleteRiskGroup = (riskGroupId: number) => {
  return request(`risk_groups/${riskGroupId}`, 'DELETE', {}, true)
}

export const updateRiskGroup = (riskGroupId: number, riskGroup: IRiskGroup) => {
  return request(`risk_groups/${riskGroupId}`, 'PUT', riskGroup)
}

export const getRiskGroup = (riskGroupId: number) => {
  return request(`risk_groups/${riskGroupId}`, 'GET')
}

export const createRiskGroup = (
  projectId: number,
  riskGroup: Pick<IRiskGroup, 'project_id' | 'title' | 'is_project_risk_group'>,
) => {
  return request(`projects/${projectId}/risk_groups`, 'POST', riskGroup)
}

export const getRiskGroupMatrixData = (
  riskGroupId: number,
): Promise<IRiskArea[]> => {
  return request(`risk_groups/${riskGroupId}/risk_group_matrix_data`, 'GET')
}

export const createRiskGroupRisk = (
  riskGroupId: number,
  risk: IRiskData,
): Promise<IRisk> => {
  return request(`/risk_groups/${riskGroupId}/risks`, 'POST', risk)
}

export const getRisksWithId = (
  projectId: number,
  riskIds: any,
): Promise<IRisk[]> => {
  return request(`projects/${projectId}/risks_filtered`, 'POST', riskIds)
}

export const editRisk = (risk: any): Promise<IRisk> => {
  return request(`risks/${risk.id}`, 'PUT', risk)
}

export const getProjectRisksWithPagination = (
  projectId: number,
  filter: any,
  currentPage: number,
  itemsPerPage: number,
) => {
  return request(
    `/projects/${projectId}/risks_filtered/pages/${currentPage}/${itemsPerPage}`,
    'POST',
    filter,
  )
}

export const getProjectRiskList = (projectId: number): Promise<any[]> => {
  return request(`projects/${projectId}/risk_list`, 'GET')
}

export const getRiskGroupRiskList = (
  projectId: number,
  riskGroupId: number,
): Promise<any[]> => {
  return request(
    `projects/${projectId}/risk_groups/${riskGroupId}/risk_list`,
    'GET',
  )
}

export const getProjectRiskData = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/risk_data`, 'GET')
}

export const getRisk = (riskId: number): Promise<IRisk> => {
  return request(`risks/${riskId}`, 'GET')
}

export const deleteRisk = (riskId: number): Promise<any> => {
  return request(`risks/${riskId}`, 'DELETE', {}, true)
}

export const getRiskAssessments = (riskId: number): Promise<any> => {
  return request(`risks/${riskId}/assessments`, 'GET')
}

export const createRiskAssessment = (
  riskId: number,
  riskAssessment: IRiskAssessmentData,
): Promise<IRiskAssessmentData> => {
  return request(`risks/${riskId}/assessments`, 'POST', riskAssessment)
}

export const getRiskAssessment = (id: number): Promise<any> => {
  return request(`assessments/${id}`, 'GET')
}

export const deleteRiskAssessment = (id: number): Promise<any> => {
  return request(`assessments/${id}`, 'DELETE', {}, true)
}

export const createRiskArea = (
  riskArea: IRiskArea,
  projectId: number,
): Promise<IRiskArea> => {
  return request(`projects/${projectId}/risk_areas`, 'POST', riskArea)
}

export const editRiskArea = (riskArea: IRiskArea): Promise<IRiskArea> => {
  return request(`risk_areas/${riskArea.id}`, 'PUT', riskArea)
}

export const deleteRiskArea = (riskItemId: number): Promise<any> => {
  return request(`risk_areas/${riskItemId}`, 'DELETE', {}, true)
}

export const getRiskAreas = (projectId: number): Promise<IRiskArea[]> => {
  return request(`projects/${projectId}/risk_areas`, 'GET')
}

export const getRiskAreaMatrixData = (
  riskGroupId: number,
  riskAreaId: number,
): Promise<IRiskArea> => {
  return request(
    `risk_groups/${riskGroupId}/risk_areas/${riskAreaId}/risk_area_matrix_data`,
    'GET',
  )
}

export const editRiskPossibility = (
  riskPossibility: NullablePartial<IRiskPossibility> & { id: number },
): Promise<IRiskPossibility> => {
  return request(
    `risk_possibilities/${riskPossibility.id}`,
    'PUT',
    riskPossibility,
  )
}

export const editRiskProbability = (
  riskProbability: NullablePartial<IRiskProbability> & { id: number },
): Promise<IRiskProbability> => {
  return request(
    `risk_probabilities/${riskProbability.id}`,
    'PUT',
    riskProbability,
  )
}

export const editRiskThreat = (
  riskThreat: NullablePartial<IRiskThreat> & { id: number },
): Promise<IRiskThreat> => {
  return request(`risk_threats/${riskThreat.id}`, 'PUT', riskThreat)
}

export const getRiskMatrix = (projectId: number): Promise<IMatrixData> => {
  return request(`projects/${projectId}/risk_matrix`, 'GET')
}

export const createRiskMatrix = (
  matrixData: IMatrixData,
  projectId: number,
): Promise<IMatrixData> => {
  return request(`projects/${projectId}/risk_matrix`, 'POST', matrixData)
}

export const deleteRiskMatrix = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/risk_matrix`, 'DELETE', {}, true)
}

export const editRiskMatrixCell = (
  matrixCell: IMatrixCellConfig,
): Promise<IMatrixCellConfig> => {
  return request(`risk_configs/${matrixCell.id}`, 'PUT', matrixCell)
}

export const getRiskStat = (projectId: number): Promise<any> => {
  return request(`projects/${projectId}/risk_kpi`, 'GET')
}

export const getRiskGroupStat = (riskGroupId: number): Promise<any> => {
  return request(`risk_groups/${riskGroupId}/risk_kpi`, 'GET')
}

export const getRiskCumulative = (
  projectId: number,
  body?: { date: string[] },
): Promise<any> => {
  return request(`projects/${projectId}/risk_graph`, 'POST', body)
}

export const getRiskGroupCumulative = (
  riskGroupId: number,
  body?: { date: string[] },
): Promise<any> => {
  return request(`risk_groups/${riskGroupId}/risk_graph`, 'POST', body)
}

export const deleteBulkRisks = (
  projectId: number,
  data: number[],
): Promise<any> => {
  return request(`projects/${projectId}/risks/remove_multiple`, 'POST', {
    ids: data,
  })
}

export const getRiskTreeData = (projectId: number): Promise<any> => {
  return request(`/projects/${projectId}/risk_groups/tree`, 'GET')
}
