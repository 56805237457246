import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StoreApi } from 'zustand'
import UploadModal from 'src/components/upload-item/UploadModal'
import CopyTestScript from 'src/page/systematic-completion-v2/CopyTestScript'
import { IImportItemList } from 'src/service/OrgTypes'
import Button from 'src/ui-elements/button/Button'
import { ButtonSize, ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Icon from 'src/ui-elements/icon/Icon'
import AddExpandableSection from './components/expandable-sections/AddExpandableSection'
import BulkDelete from './components/expandable-sections/BulkDelete'
import Collapse from './components/expandable-sections/Collapse'
import ExpandableSections from './components/expandable-sections/ExpandableSections'
import ColumnDragger from './components/headers/ColumnDragger'
import Headers from './components/headers/Headers'
import ExpandableTableProvider from './context/ExpandableTableProvider'
import { IExpandableTableInteractionStore } from './stores/ExpandableTableInteractionStore'
import { IExpandableTableStore } from './stores/ExpandableTableStore'

interface IExpandableTable {
  store: StoreApi<IExpandableTableStore>
  interactionStore: StoreApi<IExpandableTableInteractionStore>
  canReorderRows: boolean
  testId?: number
  projectId?: number
  readonly?: boolean
  reload?: () => void
  copyModal?: () => void
  importItem?: IImportItemList
  dontCopyFromExisting?: boolean
  isRowSelectable?: boolean
  onBulkDelete?: (rowIds: number[], sectionIds: number[]) => Promise<boolean>
  initialItem?: Record<string, unknown>
}

export const padNumber = (n: number): string => {
  return n > 9 ? n.toString() : `0` + n
}

const ExpandableTable = ({
  store,
  interactionStore,
  canReorderRows = false,
  testId,
  projectId,
  readonly,
  reload,
  copyModal,
  importItem,
  dontCopyFromExisting,
  onBulkDelete,
  initialItem,
}: IExpandableTable) => {
  const { t } = useTranslation()
  const [uploadModal, toggleUploadModal] = useState(false)

  return (
    <ExpandableTableProvider
      store={store}
      interactionStore={interactionStore}
      canReorderRows={canReorderRows}
    >
      <div className="flex items-center -ml-1">
        <AddExpandableSection />
        {!readonly && onBulkDelete && (
          <BulkDelete onBulkDelete={onBulkDelete} />
        )}
        {!dontCopyFromExisting &&
          !readonly &&
          testId &&
          projectId &&
          reload && (
            <CopyTestScript
              projectId={projectId}
              testId={testId}
              reload={reload}
              copyModal={copyModal}
            />
          )}

        {!readonly && importItem ? (
          <Button
            type={ButtonType.SECONDARY}
            size={ButtonSize.XSMALL}
            className={'h-8'}
            onClick={() => toggleUploadModal(true)}
            title={t('upload_test_case')}
          >
            <Icon icon={Icon.IconType.UPLOAD} className={'w-4 h-4'} />
          </Button>
        ) : null}
        <Collapse />
      </div>
      <div>
        <Headers />
        <ColumnDragger />
        <ExpandableSections initialItem={initialItem} />
      </div>
      {uploadModal && importItem && (
        <UploadModal
          showModal={uploadModal}
          closeModal={() => toggleUploadModal(false)}
          importItem={importItem}
        />
      )}
    </ExpandableTableProvider>
  )
}

export default ExpandableTable
