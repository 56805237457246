import { useQueryClient } from '@tanstack/react-query'
import { capitalize } from 'lodash'
import { useContext, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import DraftChangesTable from 'src/components/DraftModeBanner/DraftChangesTable'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { useConfirmModal } from 'src/hooks/useConfirmModal'
import useDiscardDraftMode from 'src/hooks/useDiscardDraftMode'
import useDraftMode from 'src/hooks/useDraftMode'
import { useCreateConstructionPlan } from 'src/query/construction/mutations'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'

const DraftChangesTableModal = ({
  closeModal,
  show,
}: {
  closeModal: () => void
  show: boolean
}) => {
  const { t } = useTranslation()
  const { setDraftMode } = useDraftMode()

  const mutation = useCreateConstructionPlan()
  const projectContext = useContext(ProjectContext)
  const { isConstructionManager } = projectContext.state

  const { confirm } = useConfirmModal()
  const queryClient = useQueryClient()

  const closeSaveModal = useRef<() => void>()

  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['filteredDrafts'] })
  }

  const reloadCanvas = () => {
    setDraftMode(false)
    reload()
    closeModal()
  }

  const discardDraft = useDiscardDraftMode(reload)

  const discardAndReload = async () => {
    await discardDraft()
    reloadCanvas()
  }

  const publish = async () => {
    const confirmed = await confirm({
      title: t('publish_changes'),
      message: t('publish_changes_to_new_version'),
      confirmText: t('confirm'),
      controlClose: (c) => {
        closeSaveModal.current = c
      },
    })
    if (confirmed) {
      await mutation.mutateAsync()
      closeSaveModal.current?.()
      reloadCanvas()
    }
  }

  return (
    <Modal
      show={show}
      title={capitalize(t('changes'))}
      closeModal={closeModal}
      minHeight={'300px'}
    >
      <DraftChangesTable removeChange={discardAndReload} />
      {isConstructionManager && (
        <div className="pb-4 flex sticky-without-z-index justify-end left-0 w-full bottom-0">
          <Button onClick={closeModal}>{t('cancel')}</Button>
          <Button type={Button.ButtonType.PRIMARY} onClick={publish}>
            {t('publish')}
          </Button>
        </div>
      )}
    </Modal>
  )
}

export default DraftChangesTableModal
