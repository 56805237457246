import { useRef, useContext, MouseEvent } from 'react'
import * as React from 'react'
import Draggable from 'react-draggable'
import CheckBox from 'src/components/switchHoc/CheckBox'
import { useExpandableTableStoreState } from 'src/systematic-completion/components/expandable-table/hooks/useExpandableTableStore'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import { classNames } from 'src/utility/utils'
import { ExpandableTableContext } from '../../context/ExpandableTableContext'
import {
  useExpandableTableInteractionStore,
  useExpandableTableInteractionStoreState,
} from '../../hooks/useExpandableTableInteractionStore'
import { IHeader } from '../../types/IHeader'

interface HeaderProps {
  index: number
  header: IHeader
  readonly: boolean
}

const Header: React.FC<HeaderProps> = ({ index, header, readonly }) => {
  const thRef = useRef<HTMLTableHeaderCellElement>(null)

  const isResizing = useExpandableTableInteractionStoreState(
    (state) => state.isResizingCol,
  )
  const draggingColId = useExpandableTableInteractionStoreState(
    (state) => state.draggingColId,
  )
  const getHeaderWidth = useExpandableTableInteractionStoreState(
    (state) => state.getHeaderWidth,
  )

  const interactionState = useExpandableTableInteractionStore()
  const { reorderColumnsHandler } = useContext(ExpandableTableContext)
  const isRowSelectable = useExpandableTableStoreState(
    (state) => state.isRowSelectable,
  )

  const setResizeColumn = (e: MouseEvent<HTMLDivElement>) => {
    if (!isResizing && thRef.current)
      interactionState.setState({
        resizingColSource: {
          target: thRef.current,
          x: thRef.current?.offsetLeft + thRef.current?.offsetWidth - 2,
          y: e.currentTarget.offsetTop,
          header,
        },
      })
  }
  const {
    expandableSectionsConfig,
    selectedSectionIds,
    selectedIds,
    updateSelectedSectionIds,
    updateSelectedIds,
  } = useExpandableTableStoreState((state) => state)
  const allSelected = !Object.values(expandableSectionsConfig).some(
    (section) =>
      !selectedSectionIds.includes(section.id) ||
      section.rows.some((row) => !selectedIds.includes(row)),
  )
  const onSelectAll = () => {
    if (allSelected) {
      updateSelectedIds([])
      updateSelectedSectionIds([])
    } else {
      updateSelectedIds(
        Object.values(expandableSectionsConfig).reduce(
          (prev, curr) => [...prev, ...curr.rows],
          [],
        ),
      )
      updateSelectedSectionIds(
        Object.values(expandableSectionsConfig).map((section) => section.id),
      )
    }
  }

  const styleClass = {
    row: classNames(
      index === 0 && 'left-0 rounded-tl',
      draggingColId === header.id && 'z-40',
      'text-start text-d-fontchip text-sm relative w-full',
      'border-l border-t border-b last:border-r font-bold last:rounded-tr',
    ),
    colDragger: classNames(
      'z-50 absolute w-full opacity-0 cursor-grab hover:border-r-black',
    ),
    root: classNames(
      'w-full h-full bg-white h-8',
      index === 0 && 'rounded-tl',
      index === 2 && 'rounded-tr',
    ),
  }

  const Dragger = () => {
    if (draggingColId && draggingColId !== header.id && index !== 0) {
      return <div className={styleClass.colDragger} />
    }
    return null
  }

  return (
    <div
      style={{ width: getHeaderWidth(header.id) }}
      ref={thRef}
      className={styleClass.row}
      onMouseEnter={() => {
        if (index) reorderColumnsHandler.setHoveringColumn(header.id)
        if (!isResizing)
          interactionState.setState({ resizingColSource: undefined })
      }}
    >
      <Dragger />
      <Draggable
        defaultClassName="rounded-none"
        axis="x"
        handle=".handle"
        onStart={() => {
          reorderColumnsHandler.setDraggingColumn(header.id)
          reorderColumnsHandler.setHoveringColumn(header.id)
        }}
        onStop={reorderColumnsHandler.onDraggingEnd}
      >
        <div>
          <div
            className={classNames('w-full h-full flex p-1 gap-1 items-center')}
          >
            {index === 0 &&
              isRowSelectable &&
              Object.values(expandableSectionsConfig).length > 0 && (
                <div>
                  <CheckBox valueProp={allSelected} onChange={onSelectAll} />
                </div>
              )}
            {header.draggable && (
              <div className="handle h-6">
                <Icon
                  icon={Icons.DRAG_NEW}
                  className="cursor-grab"
                  noPointerEvent
                />
              </div>
            )}
            <div className="flex flex-row items-center">{header.label}</div>
            <div
              className={`z-50 ${
                readonly ? '' : 'cursor-col-resize'
              } -right-1 w-2`}
              onMouseEnter={setResizeColumn}
            />
          </div>
        </div>
      </Draggable>
    </div>
  )
}

export default Header
