import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { twMerge } from 'tailwind-merge'
import SignatureInput from 'src/components/signature-pad/SignatureCanvas'
import {
  IProjectContext,
  ProjectContext,
} from 'src/context/ProjectContextProvider/ProjectContext'
import {
  IUserContext,
  UserContext,
} from 'src/context/UserContextProvider/UserContext'
import MConstructionTaskItem from 'src/mobile/components/MConstructionTaskItem'
import { useUserDisciplines } from 'src/query/disciplines'
import { getConstructionWagonTasks } from 'src/service/ConstructionTaskService'
import {
  createCheckInWagon,
  editCheckInOut,
  getConstructionWagon,
} from 'src/service/ConstructionWagonService'
import {
  ICheckInOut,
  IConstructionTask,
  IConstructionWagon,
  IDiscipline,
  ITaskFormData,
} from 'src/service/OrgTypes'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'
import useWindowDimensions from 'src/ui-elements/page-display/UseWindowDimensions'
import RadioItems, { IRadioItems } from 'src/ui-elements/radio/RadioItems'
import Input from '../../ui-elements/input/Input'
import { classNames } from '../../utility/utils'
import { contentStyle, FullPageLoader } from '../MUtils'
import BottomActionBtns from '../components/BottomActionBtns'
import MHeader from '../components/MHeader'
import MPage from '../components/MPage'
import MAddIssue from './MAddIssue'

interface ILocation {
  isCheckIn: boolean
  checkData?: ICheckInOut & {
    checkInOutDiscipline: IDiscipline
  }
}

const MCheckInOut = () => {
  const styleClass = {
    root: classNames('bg-white', 'w-screen', 'h-screen', 'relative'),
    container: classNames('px-6', 'py-2', 'overflow-y-auto'),
    errorMessage: classNames(
      'flex',
      'items-center',
      'text-red-600',
      'my-2',
      'text-xs',
      'font-light',
    ),
    label: classNames(
      'text-xs',
      'text-gray-600',
      'font-light',
      'p-1 pl-0',
      'min-w-24 ',
    ),
    row: classNames(
      'flex',
      'flex-col',
      'flex-nowrap',
      'md:flex-row',
      'pl-1',
      'items-start',
      'mb-2',
      'border-b border-gray-300',
    ),
    radioWrapper: classNames(
      'flex',
      'items-center',
      'w-full',
      'justify-between',
      'md:justify-end',
    ),
  }

  const { t } = useTranslation()
  const checkInText = () => [
    t(
      'area_in_which_the_wagon_will_now_start_has_no_outstanding_work_to_prevent_the_work_to_be_carried_out_in_this_wagon',
    ),
    t(
      'area_has_no_material_or_equipment_left_in_the_area_that_hinders_the_work',
    ),
    t('area_has_been_cleared_and_vacuumed'),
    t('area_is_safe_to_work_in'),
    t(
      'all_damage_that_has_occurred_to_floors_walls_ceilings_and_any_installations_or_equipment_is_registered_before_start_up',
    ),
  ]

  const checkOutText = () => [
    t(
      'carriage_that_now_leaves_the_roof_area_has_no_outstanding_work_to_prevent_the_next_carriages',
    ),
    t(
      'carriage_that_now_leaves_the_area_has_no_material_or_equipment_left_in_the_area',
    ),
    t(
      'carriage_that_is_now_leaving_the_area_has_cleared_and_vacuumed_the_area',
    ),
    t(
      'carriage_that_is_now_leaving_the_area_has_checked_that_the_area_is_safe_for_others_to_move_in',
    ),
    t(
      'carriage_that_is_now_leaving_the_area_has_registered_all_damage_that_has_occurred_to_the_floor_walls_roof_and_any_installations_or_equipment',
    ),
  ]

  const getLabel = (index: number): string => {
    return isCheckIn ? checkInText()[index] : checkOutText()[index]
  }

  const { vid } = useParams<{ vid: string }>()
  const location = useLocation<ILocation>()
  const { isCheckIn, checkData } = location.state
  const userContext: IUserContext = useContext(UserContext)
  const history = useHistory()
  const projectContext: IProjectContext = useContext(ProjectContext)
  const { id: projectId } = projectContext.state.currentProject
  const [loading, setLoading] = useState<boolean>(false)
  const [actualStaffingError, setActualStaffingError] = useState('')
  const [actualWorkHoursError, setActualWorkHoursError] = useState('')

  const [wagon, setWagon] = useState<IConstructionWagon>(
    {} as IConstructionWagon,
  )

  const onBackClick = () => {
    history.goBack()
  }

  const { width } = useWindowDimensions()
  const getWidth = () => width - 75

  const [noOutstandingWork, setNoOutstandingWork] = useState<string>('')
  const [noEquipmentLeft, setNoEquipmentLeft] = useState<string>('')
  const [clearedAndVacuumed, setClearedAndVacuumed] = useState<string>('')
  const [safeToWorkIn, setSafeToWorkIn] = useState<string>('')
  const [damageIsRegistered, setDamageIsRegistered] = useState<string>('')
  const [signature, setSignature] = useState<any>()
  const [selectedDiscipline, setSelectedDiscipline] = useState<
    IDiscipline | undefined
  >()

  const [noOutstandingWorkError, setNoOutstandingWorkError] =
    useState<string>('')
  const [noEquipmentLeftError, setNoEquipmentLeftError] = useState<string>('')
  const [clearedAndVacuumedError, setClearedAndVacuumedError] =
    useState<string>('')
  const [safeToWorkInError, setSafeToWorkInError] = useState<string>('')
  const [damageIsRegisteredError, setDamageIsRegisteredError] =
    useState<string>('')
  const [signatureError, setSignatureError] = useState<string>('')
  const [showTaskModal, setShowTaskModal] = useState<boolean>(false)
  const [newTaskType, setNewTaskType] = useState<string>('')
  const [tasks, setTasks] = useState<any>({
    noOutstandingWork: [],
    noEquipmentLeft: [],
    clearedAndVacuumed: [],
    safeToWorkIn: [],
    damageIsRegistered: [],
  })
  const [actualStaffing, setActualStaffing] = useState<number>()
  const [actualWorkHours, setActualWorkHours] = useState<number>()
  const [disciplineCheckInOptions, setDisciplineCheckInOptions] = useState<
    IDiscipline[]
  >([])
  const [constructionTasks, setConstructionTasks] = useState<
    IConstructionTask[]
  >([])

  const user = userContext.state.user
  const { data: userDisciplines = [] } = useUserDisciplines(user.id)

  useEffect(() => {
    if (+vid) {
      getConstructionWagon(+vid).then((res) => {
        setWagon(res)
        setActualStaffing(
          res.actual_staffing && !isNaN(res.actual_staffing)
            ? +res.actual_staffing
            : 0,
        )
        setActualWorkHours(
          res.actual_work_hours && !isNaN(res.actual_work_hours)
            ? +res.actual_work_hours
            : 0,
        )
      })
    }

    if (checkData) {
      const { checkInOutDiscipline } = checkData
      setSelectedDiscipline(checkInOutDiscipline)
      setDisciplineCheckInOptions([checkInOutDiscipline])
      const noEquipmentLeft = checkData?.check_in_parameters?.find(
        (param) => param.name === 'no_equipment_left',
      )
      const noOutstandingWork = checkData?.check_in_parameters?.find(
        (param) => param.name === 'no_outstanding_work',
      )
      const clearedAndVacuumed = checkData?.check_in_parameters?.find(
        (param) => param.name === 'cleared_and_vacuumed',
      )
      const safeToWorkIn = checkData?.check_in_parameters?.find(
        (param) => param.name === 'safe_to_work_in',
      )
      const damageIsRegistered = checkData?.check_in_parameters?.find(
        (param) => param.name === 'damage_is_registered',
      )
      setNoEquipmentLeft(noEquipmentLeft?.color ?? '')
      setNoOutstandingWork(noOutstandingWork?.color ?? '')
      setClearedAndVacuumed(clearedAndVacuumed?.color ?? '')
      setSafeToWorkIn(safeToWorkIn?.color ?? '')
      setDamageIsRegistered(damageIsRegistered?.color ?? '')

      setTasks({
        noEquipmentLeft: noEquipmentLeft?.tasks ?? [],
        noOutstandingWork: noOutstandingWork?.tasks ?? [],
        clearedAndVacuumed: clearedAndVacuumed?.tasks ?? [],
        safeToWorkIn: safeToWorkIn?.tasks ?? [],
        damageIsRegistered: damageIsRegistered?.tasks ?? [],
      })
    }
  }, [vid, projectId, checkData])

  useEffect(() => {
    if (checkData) {
      return
    }
    const disciplineCheckInOptions = userDisciplines.filter((discipline) =>
      wagon.activity_disciplines?.some(
        (wagonDiscipline) =>
          wagonDiscipline.id === discipline.id &&
          !wagon.check_ins?.some(
            (checkIn) =>
              checkIn.discipline_id === discipline.id &&
              checkIn.is_check_in === isCheckIn,
          ),
      ),
    )
    setDisciplineCheckInOptions(disciplineCheckInOptions)
    if (disciplineCheckInOptions.length > 0) {
      setSelectedDiscipline(disciplineCheckInOptions[0])
    }
  }, [user.id, projectId, wagon.activity_disciplines, userDisciplines])

  useEffect(() => {
    getConstructionWagonTasks(+vid).then((tasks) => {
      setConstructionTasks(tasks)
    })
  }, [vid])

  const onOutstandingWorkChange = (e: any) => {
    setNoOutstandingWork(e.target.value)
    if (e.target.value !== 'green' && tasks?.noOutstandingWork?.length === 0) {
      setNoOutstandingWorkError(
        t('issue_must_be_added_to_select_yellow_or_red_level'),
      )
      return
    }
    setNoOutstandingWorkError('')
  }

  const onEquipmentChange = (e: any) => {
    setNoEquipmentLeft(e.target.value)
    if (e.target.value !== 'green' && tasks?.noEquipmentLeft?.length === 0) {
      setNoEquipmentLeftError(
        t('issue_must_be_added_to_select_yellow_or_red_level'),
      )
      return
    }
    setNoEquipmentLeftError('')
  }

  const onCleanlinessChange = (e: any) => {
    setClearedAndVacuumed(e.target.value)
    if (e.target.value !== 'green' && tasks?.clearedAndVacuumed?.length === 0) {
      setClearedAndVacuumedError(
        t('issue_must_be_added_to_select_yellow_or_red_level'),
      )
      return
    }
    setClearedAndVacuumedError('')
  }

  const onSafetyChange = (e: any) => {
    setSafeToWorkIn(e.target.value)
    if (e.target.value !== 'green' && tasks?.safeToWorkIn?.length === 0) {
      setSafeToWorkInError(
        t('issue_must_be_added_to_select_yellow_or_red_level'),
      )
      return
    }
    setSafeToWorkInError('')
  }

  const onDamageChange = (e: any) => {
    setDamageIsRegistered(e.target.value)
    if (e.target.value !== 'green' && tasks?.damageIsRegistered?.length === 0) {
      setDamageIsRegisteredError(
        t('issue_must_be_added_to_select_yellow_or_red_level'),
      )
      return
    }
    setDamageIsRegisteredError('')
  }

  const onChangeActualStaffing = (e: any) => {
    if (e.target.value >= 0) setActualStaffingError('')
    setActualStaffing(e.target.value)
  }

  const onChangeActualWorkingHours = (e: any) => {
    setActualWorkHours(e.target.value)
    if (e.target.value >= 0) setActualWorkHoursError('')
  }

  const statusTypes = (_disable: boolean): IRadioItems[] => {
    return [
      {
        id: 'green',
        name: <div className={'h-25'} />,
        classNames: 'green_radio',
      },
      {
        id: 'yellow',
        name: <div className={'h-25'} />,
        classNames: 'yellow_radio',
      },
      {
        id: 'red',
        name: <div className={'h-25'} />,
        classNames: 'red_radio',
      },
    ]
  }

  const addTask = (type: string) => {
    setShowTaskModal(true)
    setNewTaskType(type)
  }

  const hasValidationErrors = () => {
    const values = [
      noOutstandingWorkError,
      noEquipmentLeftError,
      clearedAndVacuumedError,
      safeToWorkInError,
      damageIsRegisteredError,
      signatureError,
    ]
    const found = values.find((item) => item !== '')
    return found ? true : false
  }

  const submitCheckIn = (e: any) => {
    e.preventDefault()
    if (loading) {
      return
    }
    let error = false

    if (!noOutstandingWork) {
      setNoOutstandingWorkError(t('add_status'))
      error = true
    }

    if (!noEquipmentLeft) {
      setNoEquipmentLeftError(t('add_status'))
      error = true
    }

    if (!clearedAndVacuumed) {
      setClearedAndVacuumedError(t('add_status'))
      error = true
    }

    if (!safeToWorkIn) {
      setSafeToWorkInError(t('add_status'))
      error = true
    }

    if (!damageIsRegistered) {
      setDamageIsRegisteredError(t('add_status'))
      error = true
    }
    if (!signature) {
      setSignatureError(t('add_your_signature'))
      error = true
    }

    if (!isCheckIn && !(actualStaffing && actualStaffing >= 0)) {
      setActualStaffingError(t('required'))
      error = true
    }

    if (!isCheckIn && !(actualWorkHours && actualWorkHours >= 0)) {
      setActualWorkHoursError(t('required'))
      error = true
    }

    if (!error && !hasValidationErrors()) {
      setLoading(true)
      const createData = {
        no_outstanding_work: noOutstandingWork,
        no_equipment_left: noEquipmentLeft,
        cleared_and_vacuumed: clearedAndVacuumed,
        safe_to_work_in: safeToWorkIn,
        damage_is_registered: damageIsRegistered,
        signature,
        no_outstanding_work_tasks: tasks.noOutstandingWork,
        no_equipment_left_tasks: tasks.noEquipmentLeft,
        cleared_and_vacuumed_tasks: tasks.clearedAndVacuumed,
        safe_to_work_in_tasks: tasks.safeToWorkIn,
        damage_is_registered_tasks: tasks.damageIsRegistered,
        actual_staffing: actualStaffing,
        actual_work_hours: actualWorkHours,
        is_check_in: isCheckIn,
        discipline_id: selectedDiscipline?.id,
      }
      const editData = {
        id: checkData?.id,
        no_outstanding_work: noOutstandingWork,
        no_equipment_left: noEquipmentLeft,
        cleared_and_vacuumed: clearedAndVacuumed,
        safe_to_work_in: safeToWorkIn,
        damage_is_registered: damageIsRegistered,
        signature,
        actual_staffing: actualStaffing,
        actual_work_hours: actualWorkHours,
        is_check_in: isCheckIn,
        no_outstanding_work_tasks: tasks.noOutstandingWork,
        no_equipment_left_tasks: tasks.noEquipmentLeft,
        cleared_and_vacuumed_tasks: tasks.clearedAndVacuumed,
        safe_to_work_in_tasks: tasks.safeToWorkIn,
        damage_is_registered_tasks: tasks.damageIsRegistered,
        discipline_id: selectedDiscipline?.id,
      }
      if (checkData && editData.id) {
        if (!isCheckIn) {
          Object.assign(editData, {
            actual_staffing: Number(actualStaffing),
            actual_work_hours: Number(actualWorkHours),
          })
        }
        editCheckInOut(editData.id, editData)
          .then(() => {
            setLoading(false)
            history.goBack()
          })
          .catch(() => {
            setLoading(false)
          })
      } else {
        createCheckInWagon(+vid, createData)
          .then(() => {
            setLoading(false)
            history.goBack()
          })
          .catch(() => {
            setLoading(false)
          })
      }
    }
  }

  const getWagonDeadline = () => {
    return moment(wagon.startTime).add(wagon.extended_duration, 'days')
  }

  const setNewTasks = (type: string, task: ITaskFormData) => {
    const cloneTasks = { ...tasks }
    if (cloneTasks[type]) {
      cloneTasks[type].push(task)
    }
    setTasks(cloneTasks)
    switch (type) {
      case 'noOutstandingWork':
        const e1 = { target: { value: noOutstandingWork } }
        onOutstandingWorkChange(e1)
        break
      case 'noEquipmentLeft':
        const e2 = { target: { value: noEquipmentLeft } }
        onEquipmentChange(e2)
        break
      case 'clearedAndVacuumed':
        const e3 = { target: { value: clearedAndVacuumed } }
        onCleanlinessChange(e3)
        break
      case 'safeToWorkIn':
        const e4 = { target: { value: safeToWorkIn } }
        onSafetyChange(e4)
        break
      case 'damageIsRegistered':
        const e5 = { target: { value: damageIsRegistered } }
        onDamageChange(e5)
        break
    }
  }

  const getTasksLength = (type: string) => {
    return tasks[type]?.length
  }

  const handleAddTask = (type: string) => {
    addTask(type)
  }

  const total_expected_hours = constructionTasks
    .filter((task) => task.discipline_id === selectedDiscipline?.id)
    .reduce((prev, task) => prev + (task.total_work_hours ?? 0), 0)
  const total_expected_staffing = constructionTasks
    .filter((task) => task.discipline_id === selectedDiscipline?.id)
    .reduce((prev, task) => prev + (task.number_of_workers ?? 0), 0)

  return (
    <MPage bgColor={'white'}>
      {!showTaskModal ? (
        <>
          <MHeader
            user={userContext.state.user}
            showUser={false}
            title={
              isCheckIn
                ? t('check_in_checklist_for_discipline_in_wagon', {
                    wagon: wagon.title,
                    discipline: selectedDiscipline?.name ?? '',
                  })
                : t('check_out_checklist_for_discipline_in_wagon', {
                    wagon: wagon.title,
                    discipline: selectedDiscipline?.name ?? '',
                  })
            }
          />
          <div style={contentStyle} className={styleClass.container}>
            {loading ? (
              <FullPageLoader />
            ) : (
              <>
                <div className={`${styleClass.row} p-2`}>
                  <p className="text-sm font-extralight first-capitalize">
                    {isCheckIn
                      ? t('choose_discipline_to_check_in_for')
                      : t('choose_discipline_to_check_out_for')}
                    :
                  </p>
                  {disciplineCheckInOptions.map((discipline) => {
                    return (
                      <span
                        key={discipline.id}
                        className={twMerge(
                          'rounded-full border text-sm p-2 cursor-pointer',
                          discipline.id === selectedDiscipline?.id &&
                            'bg-red-200',
                        )}
                        onClick={() => setSelectedDiscipline(discipline)}
                      >{`${discipline.shortName} - ${discipline.name}`}</span>
                    )
                  })}
                </div>
                <details className="border-2 border-grey rounded-md p-1">
                  <summary className="text-sm font-extralight first-capitalize ml-3">
                    {t('activities')}:
                  </summary>
                  {constructionTasks
                    .filter(
                      (task) => task.discipline_id === selectedDiscipline?.id,
                    )
                    .map((task) => (
                      <MConstructionTaskItem
                        key={task.id}
                        task={task}
                        wagon={wagon}
                      />
                    ))}
                </details>
                <div className={styleClass.row}>
                  <label className={styleClass.label}>{getLabel(0)}</label>
                  {noOutstandingWorkError && (
                    <p className={styleClass.errorMessage}>
                      {noOutstandingWorkError}
                    </p>
                  )}
                  <div className={styleClass.radioWrapper}>
                    <RadioItems
                      disableTitle={t(
                        'please_create_one_or_more_actions_to_select_medium_or_high',
                      )}
                      items={statusTypes(false)}
                      onClick={onOutstandingWorkChange}
                      value={noOutstandingWork}
                      noMargin={true}
                      inMobile={true}
                    />
                    {
                      <div className={'flex mt-1'}>
                        {
                          <Icon
                            icon={Icons.PLUS}
                            className={
                              'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                            }
                            onClick={() => handleAddTask('noOutstandingWork')}
                          />
                        }
                        <span
                          className={
                            'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border text-center'
                          }
                        >
                          {getTasksLength('noOutstandingWork')}
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <div className={styleClass.row}>
                  <label className={styleClass.label}>{getLabel(1)}</label>
                  {noEquipmentLeftError && (
                    <p className={styleClass.errorMessage}>
                      {noEquipmentLeftError}
                    </p>
                  )}
                  <div className={styleClass.radioWrapper}>
                    <RadioItems
                      disableTitle={t(
                        'please_create_one_or_more_actions_to_select_medium_or_high',
                      )}
                      items={statusTypes(false)}
                      onClick={onEquipmentChange}
                      value={noEquipmentLeft}
                      noMargin={true}
                      inMobile={true}
                    />
                    {
                      <div className={'flex mt-1'}>
                        {
                          <Icon
                            icon={Icons.PLUS}
                            className={
                              'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                            }
                            onClick={() => handleAddTask('noEquipmentLeft')}
                          />
                        }
                        <span
                          className={
                            'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border text-center'
                          }
                        >
                          {getTasksLength('noEquipmentLeft')}
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <div className={styleClass.row}>
                  <label className={styleClass.label}>{getLabel(2)}</label>
                  {clearedAndVacuumedError && (
                    <p className={styleClass.errorMessage}>
                      {clearedAndVacuumedError}
                    </p>
                  )}
                  <div className={styleClass.radioWrapper}>
                    <RadioItems
                      disableTitle={t(
                        'please_create_one_or_more_actions_to_select_medium_or_high',
                      )}
                      items={statusTypes(false)}
                      onClick={onCleanlinessChange}
                      value={clearedAndVacuumed}
                      noMargin={true}
                      inMobile={true}
                    />
                    {
                      <div className={'flex mt-1'}>
                        {
                          <Icon
                            icon={Icons.PLUS}
                            className={
                              'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                            }
                            onClick={() => handleAddTask('clearedAndVacuumed')}
                          />
                        }
                        <span
                          className={
                            'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border text-center'
                          }
                        >
                          {getTasksLength('clearedAndVacuumed')}
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <div className={styleClass.row}>
                  <label className={styleClass.label}>{getLabel(3)}</label>
                  {safeToWorkInError && (
                    <p className={styleClass.errorMessage}>
                      {safeToWorkInError}
                    </p>
                  )}
                  <div className={styleClass.radioWrapper}>
                    <RadioItems
                      disableTitle={t(
                        'please_create_one_or_more_actions_to_select_medium_or_high',
                      )}
                      items={statusTypes(false)}
                      onClick={onSafetyChange}
                      value={safeToWorkIn}
                      noMargin={true}
                      inMobile={true}
                    />
                    {
                      <div className={'flex mt-1'}>
                        {
                          <Icon
                            icon={Icons.PLUS}
                            className={
                              'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                            }
                            onClick={() => handleAddTask('safeToWorkIn')}
                          />
                        }
                        <span
                          className={
                            'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border text-center'
                          }
                        >
                          {getTasksLength('safeToWorkIn')}
                        </span>
                      </div>
                    }
                  </div>
                </div>
                <div className={styleClass.row}>
                  <label className={styleClass.label}>{getLabel(4)}</label>
                  {damageIsRegisteredError && (
                    <p className={styleClass.errorMessage}>
                      {damageIsRegisteredError}
                    </p>
                  )}
                  <div className={styleClass.radioWrapper}>
                    <RadioItems
                      disableTitle={t(
                        'please_create_one_or_more_actions_to_select_medium_or_high',
                      )}
                      items={statusTypes(false)}
                      onClick={onDamageChange}
                      value={damageIsRegistered}
                      noMargin={true}
                      inMobile={true}
                    />
                    {
                      <div className={'flex mt-1'}>
                        {
                          <Icon
                            icon={Icons.PLUS}
                            className={
                              'flex pl-px justify-content align-items rounded-full w-6 h-6 border-gray-200 border'
                            }
                            onClick={() => handleAddTask('damageIsRegistered')}
                          />
                        }
                        <span
                          className={
                            'ml-2 px-1 w-6 h-6 bg-gray-100 text-gray-800 rounded-full border-gray-200 border text-center'
                          }
                        >
                          {getTasksLength('damageIsRegistered')}
                        </span>
                      </div>
                    }
                  </div>
                </div>

                {!isCheckIn && (
                  <div className={'flex mb-2'}>
                    <div className={'w-1/2'}>
                      <span className="first-capitalize text-sm font-semibold ml-2">{`${t('expected')}: ${total_expected_staffing}`}</span>
                      <Input
                        inMobile={true}
                        disabled={false}
                        block={true}
                        label={t('staffing')}
                        required={true}
                        value={actualStaffing}
                        type={'number'}
                        minValue={0}
                        onChange={onChangeActualStaffing}
                        errorMessage={actualStaffingError}
                      />
                    </div>
                    <div className={'w-1/2'}>
                      <span className="first-capitalize text-sm font-semibold ml-2">{`${t('expected')}: ${total_expected_hours}`}</span>
                      <Input
                        inMobile={true}
                        disabled={false}
                        block={true}
                        label={t('total_hours_worked')}
                        required={true}
                        value={actualWorkHours}
                        type={'number'}
                        minValue={0}
                        onChange={onChangeActualWorkingHours}
                        errorMessage={actualWorkHoursError}
                      />
                    </div>
                  </div>
                )}

                <div className={'pl-1 mb-4'}>
                  {signatureError && (
                    <p className={styleClass.errorMessage}>{signatureError}</p>
                  )}
                  <SignatureInput
                    width={getWidth()}
                    height={225}
                    errorMessage={''}
                    label={t('add_your_signature_here')}
                    required={true}
                    inMobile={true}
                    data={(d: any) => {
                      setSignature(d)
                      setSignatureError('')
                    }}
                  />
                </div>

                <BottomActionBtns
                  onSave={(e) => submitCheckIn(e)}
                  onCancel={onBackClick}
                  saveDisabled={!selectedDiscipline}
                />
              </>
            )}
          </div>
        </>
      ) : (
        <MAddIssue
          projectId={projectId}
          wagon={wagon}
          controlAreaId={wagon?.control_area_id}
          parentDeadline={getWagonDeadline()}
          onClose={() => setShowTaskModal(false)}
          submitTask={(task) => setNewTasks(newTaskType, task)}
          title={t('add_w_param', {
            param: isCheckIn ? t('check_in_issue') : t('check_out_issue'),
          })}
        />
      )}
    </MPage>
  )
}

export default MCheckInOut
