import CheckBox from '@icons/check_box.svg'
import Checklist from '@icons/checklist.svg'
import Event from '@icons/event.svg'
import FormatListBulleted from '@icons/format_list_bulleted.svg'
import TextFields from '@icons/text_fields.svg'
import { FC } from 'react'
import { twMerge } from 'tailwind-merge'
import { BASE_METADATA_TYPES } from '../../../types/FolderMetadataTypes'

interface IFolderMetadataIconWrapper {
  icon?: JSX.Element
  type?: BASE_METADATA_TYPES
}

const FolderMetadataIconWrapper: FC<IFolderMetadataIconWrapper> = ({
  icon,
  type,
}): JSX.Element | null => {
  const iconStyle = twMerge('fill-blue-root text-lg')
  if (icon) return icon
  switch (type) {
    case BASE_METADATA_TYPES.time:
      return <Event className={iconStyle} />
    case BASE_METADATA_TYPES.boolean:
      return <CheckBox className={iconStyle} />
    case BASE_METADATA_TYPES.string:
    case BASE_METADATA_TYPES.other:
      return <TextFields className={iconStyle} />
    case BASE_METADATA_TYPES.enum:
      return <FormatListBulleted className={iconStyle} />
    case BASE_METADATA_TYPES.multiEnum:
      return <Checklist className={iconStyle} />
    default:
      console.error('Missing icon and/or type in FolderMetadataDisplayField')
      return null
  }
}

export default FolderMetadataIconWrapper
