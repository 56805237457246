import Add from '@icons/add.svg'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useKeyDown } from 'src/fdvu/hooks/useKeyDown'
import { classNames } from 'src/utility/utils'
import { useExpandableTableInteractionStoreState } from '../../hooks/useExpandableTableInteractionStore'
import { useExpandableTableStoreState } from '../../hooks/useExpandableTableStore'
import { IExpandableSection } from '../../types/IExpandableSection'

interface IAddRowButtonProps {
  section: IExpandableSection
  rowIndex: number
  initialItem?: Record<string, unknown>
  isLastSection?: boolean | undefined
}

const AddRowBottom = ({
  section,
  rowIndex,
  initialItem,
  isLastSection,
}: IAddRowButtonProps) => {
  const { t } = useTranslation()
  const [createRow, readonly] = useExpandableTableStoreState((state) => [
    state.createRow,
    state.readonly,
  ])
  const [loading, setLoading] = useState(false)

  const [width, focusedSectionId] = useExpandableTableInteractionStoreState(
    (state) => [
      Object.values(state.widthPerCol).reduce((sum, n) => sum + n, 0),
      state.focusedSectionId,
    ],
  )

  const onAddRow = async () => {
    setLoading(true)
    await createRow(section.id, initialItem ?? {}, rowIndex)
    setLoading(false)
  }

  const sectionIsDraggedOver = useExpandableTableInteractionStoreState(
    (state) =>
      state.dragTargetSection?.id === section.id &&
      state.draggingSection &&
      !state.draggingRow,
  )
  const shiftKeyDown = useKeyDown('Shift')

  const willCreateRowOnShiftEnter = useMemo(
    () =>
      focusedSectionId === undefined
        ? isLastSection
        : focusedSectionId === section.id,
    [focusedSectionId, isLastSection, section.id, shiftKeyDown],
  )

  if (readonly) return null

  return (
    <div
      className={classNames(
        'h-[29px] border border-gray-200 border-t-0 flex items-center gap-1 px-1 cursor-pointer hover:bg-gray-100',
        (sectionIsDraggedOver || (shiftKeyDown && willCreateRowOnShiftEnter)) &&
          'bg-gray-100',
      )}
      style={{ width }}
      onClick={() => !loading && onAddRow()}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.shiftKey || loading) return
        if (e.key === 'Enter') onAddRow()
      }}
    >
      <Add className={'fill-d-fontgrey'} />
      <span className={'text-sm text-d-fontgrey'}>
        {willCreateRowOnShiftEnter ? t('add_row_bottom') : t('add_row')}
      </span>
    </div>
  )
}

export default AddRowBottom
