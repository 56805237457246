import UploadProvider from 'src/document/components/FileUpload/context/FileUploadContext'
import { useCommentsForType } from 'src/query/comment'
import Spinner from 'src/ui-elements/loader/Spinner'
import { classNames } from 'src/utility/utils'
import AddComment from './AddComment'
import CommentItem from './CommentItem'

interface ICommentProps {
  parentId: number
  parentType: string
  disabled?: boolean
  readonly?: boolean
  className?: string
  onCommentAdded?: () => void
  onCommentDeleted?: () => void
  includeExportVisibility?: boolean
  canToggleExportVisibility?: boolean
}

const Comments = ({
  parentId,
  parentType,
  disabled = false,
  readonly = false,
  className = '',
  onCommentAdded,
  onCommentDeleted,
  includeExportVisibility,
  canToggleExportVisibility,
}: ICommentProps) => {
  const {
    data: comments,
    refetch: refetchComments,
    isPending,
  } = useCommentsForType(parentType, parentId)

  return (
    <UploadProvider>
      <div className={classNames('flex flex-col gap-4 relative', className)}>
        {!readonly && (
          <AddComment
            disabled={disabled}
            parentId={parentId}
            parentType={parentType}
            onCommentAdded={onCommentAdded}
          />
        )}
        {isPending ? (
          <Spinner />
        ) : (
          comments?.map((comment) => (
            <CommentItem
              key={comment.id}
              comment={comment}
              disabled={disabled}
              readonly={readonly}
              refetchComments={refetchComments}
              onCommentDeleted={onCommentDeleted}
              includeExportVisiblity={includeExportVisibility}
              canToggleExportVisibility={canToggleExportVisibility}
            />
          ))
        )}
      </div>
    </UploadProvider>
  )
}

export default Comments
