import { useTranslation } from 'react-i18next'
import MilestoneCardTimeline from 'src/components/milestone-canvas/MilestoneCardTimeline'
import MilestoneDetailTimeline from 'src/components/milestone-canvas/MilestoneDetailTimeline'
import MilestoneTemaTimeline from 'src/components/milestone-canvas/MilestoneTemaTimeline'
import MilestoneTimeline from 'src/components/milestone-canvas/MilestoneTimeline'
import TabView, { ITabs } from 'src/components/tab-view/TabView'
import { CanvasType } from 'src/service/OrgTypes'

const MilestoneTimelineTabs = () => {
  const { t } = useTranslation()

  const tabs: ITabs = {
    by_main_process: {
      title: t('by_main_process'),
      show: true,
      content: <MilestoneCardTimeline />,
      path: 'by_main_process',
    },
    main_process_with_progress: {
      title: t('main_process_with_progress'),
      show: true,
      content: <MilestoneTimeline canvasType={CanvasType.MAIN_PROCESS} />,
      path: 'main_process_with_progress',
    },
    by_discipline: {
      title: t('by_discipline'),
      show: true,
      content: <MilestoneTimeline canvasType={CanvasType.DISCIPLINE} />,
      path: 'by_discipline',
    },
    by_theme: {
      title: t('by_team'),
      show: true,
      content: <MilestoneTemaTimeline />,
      path: 'by_team',
    },
    milestone_gantt: {
      title: t('gantt'),
      show: true,
      content: <MilestoneDetailTimeline />,
      path: 'milestone_gantt',
    },
  }

  return <TabView level={2} tabs={tabs} />
}

export default MilestoneTimelineTabs
