import ContactSupport from '@icons/contact_support.svg'
import ForwardToInbox from '@icons/forward_to_inbox.svg'
import ArrowDown from '@icons/keyboard_arrow_down.svg'
import ArrowLeft from '@icons/keyboard_arrow_left.svg'
import ArrowRight from '@icons/keyboard_arrow_right.svg'
import ArrowUp from '@icons/keyboard_arrow_up.svg'
import Logout from '@icons/logout.svg'
import { changeLanguage } from 'i18next'
import { capitalize } from 'lodash'
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
  ChangeEvent,
  FC,
  createRef,
} from 'react'
import { useTranslation } from 'react-i18next'
import DraftModeBanner from 'src/components/DraftModeBanner'
import useDidMountEffect from 'src/components/hooks/UseDidMountEffect'
import InfoModal from 'src/components/info/info'
import LanguageSelector from 'src/components/language-selector/LanguageSelector'
import { onLogout } from 'src/components/logout/Logout'
import Selector from 'src/components/selectors/Selector'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import history from 'src/history'
import useDraftMode from 'src/hooks/useDraftMode'
import { IProject, IProjectData } from 'src/service/OrgTypes'
import { editUser } from 'src/service/UserService'
import Button from 'src/ui-elements/button/Button'
import Input from 'src/ui-elements/input/Input'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { classNames } from 'src/utility/utils'

const styleClass = {
  root: (mainMenuActive: boolean, mainMenuHidden: boolean) =>
    classNames(
      'flex',
      'flex-wrap',
      'justify-between',
      'items-center',
      'bg-white',
      'py-1',
      !mainMenuHidden
        ? mainMenuActive
          ? 'w-full'
          : 'w-full'
        : mainMenuActive
          ? 'w-full'
          : 'w-full',
    ),
  title: classNames(
    'text-gray-700',
    'text-sm',
    'font-light',
    'uppercase',
    'px-3',
    'truncate',
  ),
  dropdownOverride: classNames('topmenu-dropdown'),
  profileImage: classNames(
    'rounded-full',
    'w-8',
    'h-8',
    'border',
    'p-1',
    'border-black',
    'mr-2',
  ),
  menuWrapper: classNames(
    'flex-row',
    'items-center',
    'justify-end',
    'sm:w-full',
    'xl:w-mx-content',
  ),
  buttonIconWrapper: classNames(
    'h-auto',
    'w-mx-content',
    'xl:mx-auto',
    'pt-0.5',
    'pr-0.5',
    'mr-2',
    'inline-block',
  ),
  buttonsWrapper: (topMenuExpanded: boolean) =>
    classNames(
      topMenuExpanded ? 'flex' : 'hidden',
      'xl:flex',
      'flex-row',
      'flex-wrap',
      'lg:flex-nowrap',
      'w-full',
      'items-center',
      'md:items-center',
      'md:w-full',
      'xl:w-mx-content',
      'justify-start',
      'xl:justify-end',
      'xl:py-0',
      'border-solid',
      'border-t',
      'border-gray-400',
      'xl:border-none',
      'gap-2',
    ),
}

interface ITopMenuProps {
  projects: IProjectData[]
  currentProject: IProject
  mainMenuActive: boolean
  topMenuExpanded: boolean
  menuHidden: boolean
  toggleTopMenu: () => void
  toggleMenuVisibility: () => void
  readonly?: boolean
  hideSearch?: boolean
}

interface IButtonItem {
  icon: ReactElement
  action: () => void
  showBorder?: string
  tooltip: string
}

const TopMenu: FC<ITopMenuProps> = ({
  projects,
  currentProject,
  mainMenuActive,
  toggleTopMenu,
  topMenuExpanded,
  toggleMenuVisibility,
  menuHidden,
  hideSearch,
  readonly,
}) => {
  const { actions } = useContext(ProjectContext)
  const { user } = useContext(UserContext).state
  const { reload: reloadUser } = useContext(UserContext).actions
  const { showDraftModeBanner } = useDraftMode()

  const { t } = useTranslation()
  const [searchText, setSearchText] = useState('')

  const setDefaultLanguage = useCallback(async () => {
    if (user?.language && user?.language === 'en') {
      await changeLanguage('en')
    }
  }, [user])

  useEffect(() => {
    setDefaultLanguage()
  }, [setDefaultLanguage])

  const setProject = (projectId: string) => {
    actions.setProject(parseInt(projectId, 0))
    reloadUser()
  }

  const getProjects = () => {
    const filterProject: { id: number; name: string }[] = []
    projects.map((project: IProjectData) => {
      filterProject.push({
        id: project.project.id,
        name: project.project.projectNumber + ' ' + project.project.projectName,
      })
    })
    return filterProject || []
  }

  useDidMountEffect(() => {
    const timeOutId = setTimeout(() => {
      if (searchText) {
        history.push(`/search?q=${encodeURIComponent(searchText)}`)
      }
    }, 500)
    return () => clearTimeout(timeOutId)
  }, [searchText])

  const searchChange = async (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    const text = event.target.value
    setSearchText(text)
  }

  const buttonItems: IButtonItem[] = [
    {
      icon: <Logout className={'fill-blue-root text-xl'} />,
      action: () => onLogout(),
      tooltip: capitalize(t('log_out')),
    },
  ]

  const setUserLanguage = (language: string) => {
    user &&
      editUser({
        id: user.id,
        language,
      }).catch((err) => console.error(err))
  }

  const topMenuRef = createRef<HTMLDivElement>()

  return (
    <div
      ref={topMenuRef}
      className={styleClass.root(mainMenuActive, menuHidden)}
    >
      <div className="flex pro-flex-row justify-between items-center w-full md:hidden">
        <div className="p-2 flex w-full justify-start items-center">
          <div className="w-1/6 flex-shrink-0 ml-4">
            <button
              style={{ minWidth: '30px', height: 'auto' }}
              onClick={toggleMenuVisibility}
              className="sm:invisible"
            >
              {menuHidden ? <ArrowLeft /> : <ArrowRight />}
            </button>
          </div>
        </div>

        <div className="max-w-12 flex pr-4 md:hidden mr-6 sm:mr-4">
          <button
            onClick={toggleTopMenu}
            className="flex items-center px-3 py-1 border rounded text-gray-500 border-gray-600 hover:text-gray-800"
          >
            <svg
              className="fill-current h-3 w-3"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>{t('menu')}</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>
        </div>
      </div>

      <div className="flex flex-row flex-wrap xl:flex-nowrap w-full pl-4 pr-2">
        <div
          className={
            'w-full xl:w-full xl:flex-grow flex flex-row items-center justify-between px-4 py-0.5'
          }
        >
          <div className="flex flex-row flex-wrap sm:flex-nowrap items-center w-full lg:min-w-1/2 justify-between">
            <div className="flex items-center flex-nowrap sm:w-1/2 py-1 md:w-1/2 xl:w-1/4 w-full lg: min-w-1/4">
              {currentProject?.logo && (
                <img
                  style={{ maxHeight: 35, width: 'auto' }}
                  onClick={() => history.push('/')}
                  className="cursor-pointer pr-4 pl-2 md:pl-0 py-1"
                  src={currentProject.logo}
                  width="auto"
                  height="40"
                  alt={'Project logo'}
                />
              )}
              <img
                style={{ maxHeight: 35, width: 'auto' }}
                onClick={() => history.push('/')}
                className="cursor-pointer pr-4 pl-2 md:pl-0 py-1"
                src="/taskctr-logomork.png"
                width={'auto'}
                height={'40'}
                alt={'TaskCtrl logo'}
              />
              <Input
                type="search"
                block={true}
                placeholder={t('search')}
                value={searchText}
                onChange={searchChange}
                style={{
                  minHeight: 34,
                  display: hideSearch ? 'none' : 'initial',
                }}
                showSearchIcon
                noBorder
              />
            </div>
            <div className={'w-1/5 md:px-2 py-1 mt-4 ml-2 sm:mt-0'}>
              {readonly ? (
                <span>
                  {`${currentProject.projectNumber} ${currentProject.projectName}`}
                </span>
              ) : (
                <Selector
                  hidelabel={true}
                  label={t('project')}
                  items={getProjects()}
                  selectedItemId={currentProject.id}
                  onSelect={setProject}
                  dataFields={['name']}
                  scroll={true}
                  hasBorder={false}
                />
              )}
            </div>
            <div className="hidden xl:flex justify-center lg:min-w-1/4">
              <div className={styleClass.buttonsWrapper(topMenuExpanded)}>
                <LanguageSelector
                  inTopMenu
                  onLanguageSelected={setUserLanguage}
                />
                <div className="mr-5">
                  <InfoModal
                    modalOrigin="topRight"
                    className="z-[100]"
                    targetElement={({ onClick }) => (
                      <Tooltip
                        message={capitalize(t('contact_support'))}
                        direction="bottom"
                        offset={2}
                      >
                        <Button
                          type={Button.ButtonType.TOP_MENU}
                          size={Button.ButtonSize.NARROW}
                          onClick={onClick}
                        >
                          <ContactSupport
                            className={
                              'fill-blue-root text-xl cursor-pointer hover:bg'
                            }
                          />
                        </Button>
                      </Tooltip>
                    )}
                  >
                    <div className="m-3 min-w-75 text-grey-selector ">
                      <h2>{capitalize(t('contact_support'))}</h2>
                      <p className="text-sm">{t('support_info')}</p>
                      <a
                        href="mailto:support@taskctrl.no"
                        className="underline text-blue-700 text-sm mt-1 flex flex-row items-center"
                      >
                        <ForwardToInbox className="mr-1 fill-blue-700 text-lg" />
                        {capitalize(t('contact_us'))}
                      </a>
                    </div>
                  </InfoModal>
                </div>
                {!readonly &&
                  buttonItems.map((item, key) => {
                    return (
                      <Tooltip
                        key={key}
                        message={item.tooltip}
                        direction="bottom"
                        offset={2}
                      >
                        <Button
                          type={Button.ButtonType.TOP_MENU}
                          size={Button.ButtonSize.NARROW}
                          onClick={() => item.action()}
                        >
                          {item.icon}
                        </Button>
                      </Tooltip>
                    )
                  })}
              </div>
            </div>
          </div>

          <div className="w-12 xl:min-w-0 hidden md:flex xl:hidden h-full flex-shrink-0 items-center pl-2">
            <button onClick={toggleTopMenu} className="text-xxl">
              {topMenuExpanded ? <ArrowUp /> : <ArrowDown />}
            </button>
          </div>
        </div>

        <div className={`${styleClass.menuWrapper} flex xl:hidden w-full`}>
          <div
            className={`${styleClass.buttonsWrapper(topMenuExpanded)} p-2 pl-6`}
          >
            {buttonItems.map((item, key) => {
              return (
                <Button
                  key={key}
                  type={Button.ButtonType.TOP_MENU}
                  size={Button.ButtonSize.NARROW}
                  onClick={() => item.action()}
                >
                  {item.icon}
                </Button>
              )
            })}
            {
              <LanguageSelector
                inTopMenu
                onLanguageSelected={setUserLanguage}
              />
            }
          </div>
        </div>
      </div>
      {showDraftModeBanner && <DraftModeBanner />}
    </div>
  )
}

export default TopMenu
