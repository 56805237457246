import KeyboardArrowDown from '@material-symbols/svg-500/rounded/keyboard_arrow_down-fill.svg'
import KeyboardArrowUp from '@material-symbols/svg-500/rounded/keyboard_arrow_up-fill.svg'
import * as React from 'react'
import { useState, useEffect } from 'react'
import { twMerge } from 'tailwind-merge'
import FolderMetadataIconWrapper from 'src/document/components/Folders/FolderMetadataField/FolderMetadataIconWrapper'
import { useKeyDown } from 'src/fdvu/hooks/useKeyDown'
import CloseClickOutside from '../../../../components/click-outside/CloseClickOutside'
import { useMultipleKeysTranslation } from '../../../../service/useMultipleKeysTranslation'
import {
  IMetadataBaseType,
  IMetaDataType,
} from '../../../types/FolderMetadataTypes'
import FolderMetadataFieldDropdown from './FolderMetadataFieldDropdown'

interface IFolderMetadataField {
  value: IMetaDataType
  disabled?: boolean
  onTypeChange?: (newType: IMetaDataType) => void
  possibleMetadataTypes: Record<string, IMetadataBaseType>
  menuOnTheLeft?: boolean
  noBorder?: boolean
}

const FolderMetadataField: React.FC<IFolderMetadataField> = ({
  value,
  disabled,
  onTypeChange,
  menuOnTheLeft,
  possibleMetadataTypes,
  noBorder,
}) => {
  const [showMenu, setShowMenu] = useState(false)

  const { t } = useMultipleKeysTranslation()

  const onClick = () => {
    if (disabled) return
    setShowMenu(!showMenu)
  }

  const escapeKeyDown = useKeyDown('Escape')

  useEffect(() => {
    if (showMenu && escapeKeyDown) setShowMenu(false)
  }, [escapeKeyDown])

  const onTypeSelected = (newType: IMetaDataType) => {
    if (onTypeChange) onTypeChange(newType)
    setShowMenu(false)
  }

  return (
    <CloseClickOutside
      onClose={() => {
        setShowMenu(false)
      }}
      display={'inline'}
      className={'w-full h-full'}
    >
      <div
        onClick={onClick}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && !e.shiftKey) onClick()
        }}
        className={twMerge(
          !disabled && 'cursor-pointer hover:bg-blue-root-tab-wash',
          !!disabled && 'bg-[#efefef] cursor-not-allowed',
          'text-sm w-full h-full rounded-sm',
          'px-1 border',
          noBorder ? 'border-transparent' : 'border-gray-300',
          'flex justify-between',
          'items-center',
        )}
      >
        <div className={'flex gap-1'}>
          <FolderMetadataIconWrapper type={value.data_type} />
          {t(value.data_type)}
        </div>
        {showMenu ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
      </div>
      {showMenu && (
        <div className="relative">
          <FolderMetadataFieldDropdown
            possibleMetadataTypes={possibleMetadataTypes}
            menuOnTheLeft={menuOnTheLeft}
            closeMenu={() => setShowMenu(false)}
            value={value}
            onClick={onTypeSelected}
          />
        </div>
      )}
    </CloseClickOutside>
  )
}

export default FolderMetadataField
