import { createColumnHelper } from '@tanstack/react-table'
import * as React from 'react'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import Step from 'src/document/components/FolderDocument/Step'
import {
  defaultTextColumn,
  styleClass,
  defaultFilterColumn,
} from 'src/ui-elements/Table/Columns'
import SimpleTable from 'src/ui-elements/Table/SimpleTable'
import { IUploadResponse } from '../types'

interface ImportedFilesListProps {
  uploadedFiles: UploadedFile[]
}

export interface UploadedFile extends IUploadResponse {
  fileName: string
}

const ImportedFilesList: FC<ImportedFilesListProps> = ({ uploadedFiles }) => {
  const { t } = useTranslation()
  const columnHelper = createColumnHelper<UploadedFile>()

  const newColumns = [
    columnHelper.accessor(
      'fileName',
      defaultTextColumn('fileName', { name: t('uploaded_file') }),
    ),
    columnHelper.accessor('file_container', {
      ...defaultFilterColumn('file_container', { name: t('target_document') }),
      cell: (props) => (
        <span className={styleClass.cell}>
          {props.getValue()?.record_id ?? (
            <span className="text-d-danger">{t('document_not_found')}</span>
          )}
        </span>
      ),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('file_container', {
      ...defaultFilterColumn('file_container', { name: t('step') }),
      cell: (props) => {
        const fileContainer = props.getValue()
        return fileContainer?.current_step ? (
          <Step step={fileContainer.current_step} />
        ) : (
          <></>
        )
      },
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ]

  return (
    <SimpleTable
      data={uploadedFiles}
      columns={newColumns}
      name={'ImportedFilesList'}
    />
  )
}

export default ImportedFilesList
