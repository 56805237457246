import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ISystemUploadSummary } from 'src/components/system/SystemUploadSummary'
import FileUploadModal from 'src/document/components/FileUpload/FileUploadModal'
import { IFileUploadModalProps } from 'src/document/components/FileUpload/FileUploader'
import {
  IFormatData,
  ISystemValidationResponse,
  NEXT_STEP,
} from 'src/document/components/FileUpload/types'
import UploadValidation from 'src/document/components/FileUpload/validation/DocUpdateValidation'
import ExportService from 'src/service/ExportService'
import { ImportDataFiled } from '../../document/components/FileUpload/selectors/DataSelectors'
import { IMetaField } from '../system/system-type-fields/SystemTypeFieldsForm'
import { translateTemplate } from './ImportHelpers'

interface ImportUploadWithVerificationProps
  extends Partial<IFileUploadModalProps> {
  parentId: number
  show: boolean
  close: () => void
  type: string
  uploadUrl: string
  importTemplate: any
  columns?: ImportDataFiled[]
  uploadData: (data: any[]) => Promise<any>
  confirmStep?: (data: ISystemUploadSummary) => JSX.Element | null
  formatResponseData?: (data: any[]) => ISystemValidationResponse
  useDefinedFiled?: IMetaField[]
  responseData?: ISystemValidationResponse
  validateMultiple?: (
    newValues: Record<string, string | string[] | null>[],
    validRes?: ISystemValidationResponse,
  ) => Promise<ISystemValidationResponse>
}

const ImportUploadWithVerification: FC<ImportUploadWithVerificationProps> = ({
  show,
  close,
  type,
  uploadUrl,
  parentId,
  importTemplate,
  columns,
  title,
  uploadData,
  formatResponseData,
  confirmStep,
  useDefinedFiled,
  currentStep,
  responseData,
  validateMultiple,
  ...rest
}) => {
  const [validationRes, setValidationRes] =
    useState<ISystemValidationResponse>()
  const [reload, setReload] = useState(false)
  const [validateLoading, setValidateLoading] = useState(true)

  const multiValidationRef = useRef<Record<string, string | string[] | null>[]>(
    [],
  )

  const [data, setData] = useState<any[]>([])
  const [uploadRes, setUploadRes] = useState<any>()

  const { t } = useTranslation()

  useEffect(() => {
    if (responseData) {
      setValidateLoading(false)
      multiValidationRef.current = []
      setValidationRes(responseData)
      setUploadRes(responseData)
      setReload((n) => !n)
    }
  }, [responseData])

  const multiValidate = (
    header: string,
    index: number,
    value: string | string[] | null,
  ) => {
    const multiValue = multiValidationRef.current
    const _obj = multiValue[index] ?? {}
    _obj[header] = value
    const _multiValidation = [...multiValue]
    _multiValidation[index] = _obj
    multiValidationRef.current = _multiValidation
  }

  const onChange = async (
    key: IFormatData,
    header: string,
    index: number,
    value: string | string[] | null,
  ) => {
    if (validateMultiple) {
      multiValidate(header, index, value)
    }
  }

  const removeItem = (index: number) => {
    setValidationRes((n) => {
      if (!n?.[type]?.[index]) return n
      n[type].splice(index, 1)
      return n
    })
    setReload((n) => !n)
  }

  const getConfirmStep = useCallback(() => {
    return confirmStep?.(uploadRes) ?? null
  }, [confirmStep, uploadRes])

  const steps = [
    <UploadValidation
      response={validationRes}
      title={t(type)}
      key={1}
      reload={reload}
      parentId={parentId}
      onChange={onChange}
      sendData={setData}
      columns={columns}
      type={type}
      removeItem={removeItem}
      useDefinedFiled={useDefinedFiled}
      validateLoading={validateLoading}
      validateMultiple={!!validateMultiple}
    />,
    getConfirmStep(),
  ]

  const checkRow = (uploadedFiles: any) => {
    const key = Object.keys(uploadedFiles)[0]
    if (formatResponseData) {
      const formattedData = formatResponseData(uploadedFiles[key])
      setValidationRes(formattedData)
    } else {
      setValidationRes(uploadedFiles[key])
    }
    return NEXT_STEP.CONTINUE
  }

  const sendDataToRemote = async () => {
    if (!data.length && validateMultiple) {
      setValidateLoading(true)
      await validateMultiple(multiValidationRef.current, validationRes)
      return NEXT_STEP.STAY
    }
    const res = await uploadData(data)
    if (res?.error_count) {
      return NEXT_STEP.STAY
    }
    setUploadRes(res)
    return NEXT_STEP.CONTINUE
  }

  return (
    <FileUploadModal
      multiple={false}
      uploadUrl={uploadUrl}
      validateLoading={validateLoading}
      title={title}
      type=".xlsx,.csv,.xls"
      handleNext={async (step, uploadedFiles) => {
        if (step === 0) return checkRow(uploadedFiles)
        if (step === 1) return await sendDataToRemote()
        return NEXT_STEP.CLOSE
      }}
      stepElements={steps}
      currentStep={currentStep}
      show={show}
      close={close}
      {...rest}
      downloadTemplate={() =>
        ExportService.exportImportTemplate(translateTemplate(importTemplate, t))
      }
    />
  )
}

export default ImportUploadWithVerification
